import * as React from 'react';
import { Color } from '../styles/constantStyles';

export interface AlarmIconProps {
  color?: string;
  style?: React.CSSProperties;
  width?: string | number;
  height?: string | number;
}

export const AlarmIcon = ({ color, width, height, style }: AlarmIconProps) => (
  <svg style={style} width={width} height={height} viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M20 11.25V20H28.75"
      stroke={color || Color.MONOGREY3}
      strokeWidth="3"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M20 33.75C27.5939 33.75 33.75 27.5939 33.75 20C33.75 12.4061 27.5939 6.25 20 6.25C12.4061 6.25 6.25 12.4061 6.25 20C6.25 27.5939 12.4061 33.75 20 33.75Z"
      stroke={color || Color.MONOGREY3}
      strokeWidth="3"
      strokeMiterlimit="10"
    />
    <path
      d="M30.6094 4.09375L35.9063 9.39062"
      stroke={color || Color.MONOGREY3}
      strokeWidth="3"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M4.09375 9.39062L9.39062 4.09375"
      stroke={color || Color.MONOGREY3}
      strokeWidth="3"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
