import React from 'react';
import { NextAppointmentRow } from './NextAppointmentRow';
import { DocColor, StyleSheet } from '../../../../assets/styles/constantStyles';
import { User } from '../../../../authContext/domain/entities/User';
import { CalendarEvent } from '../../../../calendarContext/domain/entities/CalendarEvent';
import { Rpx } from '../../../../common/usecases/helpers/DimensionsConverter';
import { NavigationPayload } from '../../../../navigationContext/domain/entities/NavigationPayload';

type NextAppointmentComponentProps = {
  stateConnectedUser: User | undefined;
  nextAppointmentData: CalendarEvent[];
  navigateToAsync: (p: NavigationPayload) => void;
};

export const NextAppointmentComponent = ({
  stateConnectedUser,
  nextAppointmentData,
  navigateToAsync,
}: NextAppointmentComponentProps) => (
  <div style={styles.container}>
    <p style={{ ...styles.nextConsultationTitle, fontSize: Rpx(32, true) }}>Prochaine Consultation</p>
    <div style={styles.borderContainer}>
      {nextAppointmentData.length !== 0 ? (
        <div style={{ marginBottom: Rpx(10) }}>
          {nextAppointmentData.map((obj) => (
            <NextAppointmentRow
              stateConnectedUser={stateConnectedUser}
              key={obj.id}
              calendarEvent={obj}
              navigateToAsync={navigateToAsync}
            />
          ))}
        </div>
      ) : (
        <div style={{ ...styles.dataStateRow, height: Rpx(70, true), fontSize: Rpx(25, true) }}>
          <p>Vous n&apos;avez pas de consultations planifiées pour les prochains jours.</p>
        </div>
      )}
    </div>
  </div>
);

const styles: StyleSheet = {
  container: {
    margin: `${Rpx(90)} 2vw 0`,
  },
  nextConsultationTitle: {
    fontWeight: 700,
    color: DocColor.DARKBLUE,
    margin: `0 0 ${Rpx(30)} 2vw`,
  },
  borderContainer: {
    position: 'relative',
    outline: `${Rpx(10)} solid ${DocColor.DARKBLUE}`,
    borderRadius: Rpx(60),
    minHeight: Rpx(100),
    overflow: 'hidden',
    padding: `${Rpx(20)} ${Rpx(45)}`,
  },
  dataStateRow: {
    width: '100%',
    backgroundColor: DocColor.LIGHTGRAY,
    marginTop: Rpx(25),
    borderRadius: Rpx(9),
    textAlign: 'center',
    fontWeight: 500,
    whiteSpace: 'nowrap',
    justifyContent: 'center',
    color: DocColor.COALBLACK,
  },
};
