import React from 'react';
import { DocColor, StyleSheet } from '../../../../assets/styles/constantStyles';
import { ToRightArrow2 } from '../../../../assets/svg/ToRightArrow2';
import { Rpx } from '../../../../common/usecases/helpers/DimensionsConverter';

interface SettingsOptionComponentProps {
  optionTitle: string;
  optionOnClick?: () => void;
  isSelected?: boolean;
}

export const SettingsOptionComponent = ({ optionTitle, optionOnClick, isSelected }: SettingsOptionComponentProps) => (
  <div
    style={{ ...styles.settingsOption, background: isSelected ? DocColor.MEDIUMBLUE : 'white' }}
    onKeyDown={optionOnClick}
    tabIndex={0}
    role="button"
    onClick={optionOnClick}
  >
    <div style={{ ...styles.settingsOptionIconBackground, width: Rpx(20, true), height: Rpx(20, true) }}>
      <ToRightArrow2 color="white" width={Rpx(25, true)} />
    </div>
    <p
      style={{
        ...styles.settingsOptionParagraph,
        fontSize: Rpx(25, true),
        color: !isSelected ? DocColor.MEDIUMBLUE : 'white',
      }}
    >
      {optionTitle}
    </p>
  </div>
);

const styles: StyleSheet = {
  settingsOption: {
    flexDirection: 'row',
    width: 'fit-content',
    margin: `0 0 ${Rpx(45)} 15%`,
    padding: `0 ${Rpx(20)} 0 ${Rpx(10)}`,
    borderRadius: `${Rpx(10)} ${Rpx(10)} 0px ${Rpx(10)}`,
    gap: Rpx(20),
    alignItems: 'center',
    cursor: 'pointer',
  },
  settingsOptionIconBackground: {
    padding: Rpx(5),
    borderRadius: Rpx(20),
    background: DocColor.MEDIUMBLUE,
  },
  settingsOptionParagraph: {
    color: DocColor.MEDIUMBLUE,
    fontWeight: 600,
  },
};
