import * as React from 'react';
import { Color } from '../styles/constantStyles';

export interface EmailInputProps {
  color?: string;
  style?: React.CSSProperties;
  width?: string | number;
  height?: string | number;
}

export const EmailInputIcon = ({ color, style, width, height }: EmailInputProps) => (
  <svg style={style} viewBox="0 0 36 36" width={width} height={height} fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M29.9288 30.514C30.5184 30.3911 30.8693 29.7759 30.6027 29.2358C29.7137 27.4352 28.2001 25.8526 26.2182 24.6698C23.8605 23.2627 20.9718 22.5 18 22.5C15.0282 22.5 12.1394 23.2627 9.78168 24.6698C7.79979 25.8526 6.28626 27.4352 5.39725 29.2358C5.13062 29.7759 5.48149 30.3911 6.0711 30.514L9.83909 31.2992C15.2218 32.421 20.7781 32.421 26.1608 31.2992L29.9288 30.514Z"
      fill={color || Color.MONOGREY3}
    />
    <circle cx="18" cy="12" r="7.5" fill={color || Color.MONOGREY3} />
  </svg>
);
