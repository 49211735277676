export interface StyleSheet {
  [key: string]: React.CSSProperties;
}

// COLORS
export enum Color {
  THEMECOLOR = '#42489E',
  THEMECOLORINVERT = '#bdb761',
  PRIMARYBLUE = '#004562',
  PALEBLUE = '#5B738E',
  DARKGREEN = '#3BA5AC',
  DARKGREY = '#2C2C2C',
  DANGER = '#eb5757',
  MONOGREY1 = '#F4F4F4',
  MONOGREY2 = '#eeeeee',
  MONOGREY3 = '#E2E2E2',
  MONOGREY4 = '#979797',
  MONOGREY5 = '#3E3E3E',
  MONOGREY6 = '#2F2F2F',
  APPBLACK = '#1a1a1a',
  APPWHITE = '#fafafa',
}

export enum DocColor {
  DARKBLUE = '#015D8C',
  MEDIUMBLUE = '#1F9BDE',
  LIGHTBLUE = '#7CC1E8',
  GRAY = '#CACACA',
  MEDIUMGRAY = '#DEDBDB',
  LIGHTGREEN = '#42FF00',
  TEALISHGREEN = '#CAFACF',
  CLOUDYGREEN = '#94f9c2',
  LIGHTRED = '#f99494',
  MEDIUMRED = '#FF5555',
  FLAMINGOPINK = '#FFADAD',
  CLOUDYWHITE = '#EFF3F6',
  COALBLACK = '#323942',
  LIGHTGRAY = '#F4F4F4',
  CLOUDYBLUE = '#EAF7FF',
  AZUREISHWHITE = '#DFEFF8',
  MIDIUMCLOUDYBLUE = '#DFEFF8',
  AQUAHAZE = '#EFF3F6',
  HALFTRANSPARENTBLACK = 'rgba(0, 0, 0, 0.5)',
  QUARTERTRANSPARENTBLACK = 'rgba(0, 0, 0, 0.75)',
}

// FONT WEIGHTS
export enum FontWeight {
  REGULAR = 400,
  MEDIUM = 500,
  EMPHASIS = 600,
  BOLD = 700,
  BOLDER = 900,
}

// FONT SIZES
export enum FontSize {
  H1 = 48,
  H6 = 24,
  P1 = 18,
  P2 = 16,
  P3 = 14,
  P4 = 12,
  P5 = 10,
  CAPTION = 12,
}

// PADDINGS
export enum Padds {
  TINY1 = 1,
  TINY2 = 2,
  TINY4 = 4,
  DEFAULT = 8,
  PADD1 = 16,
  PADD2 = 24,
  PADD3 = 32,
  PADD4 = 40,
}

export enum BorderSize {
  CORNERS_DEFAULT = 5,
  CORNERS_SMALL1 = 10,
  CORNERS_SMALL2 = 20,
}

export const TopBarHeight = 48;
export const SideBarMaxWidth = 240;
