import * as React from 'react';
import { Color } from '../styles/constantStyles';

export interface MedicineIconProps {
  color?: string;
  style?: React.CSSProperties;
  width?: string | number;
  height?: string | number;
}

export const MedicineIcon = ({ color, width, height, style }: MedicineIconProps) => (
  <svg style={style} width={width} height={height} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M2.20101 8L1.84746 7.64645L2.20101 8ZM8 2.20101L7.64645 1.84746L8 2.20101ZM4.74695 5.45406L10.5459 11.253L11.253 10.5459L5.45406 4.74695L4.74695 5.45406ZM2.55456 8.35355L5.45406 5.45406L4.74695 4.74695L1.84746 7.64645L2.55456 8.35355ZM5.45406 5.45406L8.35355 2.55456L7.64645 1.84746L4.74695 4.74695L5.45406 5.45406ZM13.4454 7.64645L10.5459 10.5459L11.253 11.253L14.1525 8.35355L13.4454 7.64645ZM10.5459 10.5459L7.64645 13.4454L8.35355 14.1525L11.253 11.253L10.5459 10.5459ZM2.55456 13.4454C1.14848 12.0394 1.14848 9.75964 2.55456 8.35355L1.84746 7.64645C0.0508478 9.44306 0.0508478 12.3559 1.84746 14.1525L2.55456 13.4454ZM1.84746 14.1525C3.64407 15.9492 6.55694 15.9492 8.35355 14.1525L7.64645 13.4454C6.24036 14.8515 3.96065 14.8515 2.55456 13.4454L1.84746 14.1525ZM13.4454 2.55456C14.8515 3.96065 14.8515 6.24036 13.4454 7.64645L14.1525 8.35355C15.9492 6.55694 15.9492 3.64407 14.1525 1.84746L13.4454 2.55456ZM14.1525 1.84746C12.3559 0.0508478 9.44306 0.0508478 7.64645 1.84746L8.35355 2.55456C9.75964 1.14848 12.0394 1.14848 13.4454 2.55456L14.1525 1.84746Z"
      fill={color || Color.MONOGREY3}
    />
  </svg>
);
