import { connect } from 'react-redux';
import { dispatchAsync } from 'react-redux-dispatch-async';
import { Action } from 'redux';
import { ConsultationsHistoryDumb } from './ConsultationsHistory.dumb';
import { AppState } from '../../../common/configuration/app.states';
import { NavigationPayload } from '../../../navigationContext/domain/entities/NavigationPayload';
import { loadPatientRequest } from '../../usecases/Patients.actions';
import { getConnectedUser } from '../../../authContext/usecases/Auth.selectors';
import { loadPatientsConsultationsRequest } from '../../../consultationsContext/usecases/Consultations.actions';
import { navigateToRequest } from '../../../navigationContext/usecases/navigation.actions';

const mapState = (state: AppState) => ({
  stateConnectedUser: getConnectedUser(state),
});

const mapDispatch = (dispatch: (a: Action) => void) => ({
  // navigateTo: (nav: NavigationPayload) => dispatch(navigateTo(nav)),
  loadPatientAsync: (patientId: string) => dispatchAsync(dispatch, loadPatientRequest(patientId)),
  loadPatientsConsultationsAsync: (patientId?: string, consultationsLimit?: number, skipConsultations?: number) =>
    dispatchAsync(
      dispatch,
      loadPatientsConsultationsRequest({
        patientId,
        consultationsLimit,
        skipConsultations,
      }),
    ),
  navigateToAsync: (nav: NavigationPayload) => dispatch(navigateToRequest(nav)),
});

export const ConsultationsHistorySmart = connect(mapState, mapDispatch)(ConsultationsHistoryDumb);
