import * as React from 'react';
import { Color } from '../styles/constantStyles';

export interface EmailProps {
  color?: string;
  style?: React.CSSProperties;
  width?: string | number;
  height?: string | number;
}

export const EmailIcon = ({ color, width, height, style }: EmailProps) => (
  <svg style={style} width={width} height={height} viewBox="0 0 31 31" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M21.2211 15.4596H20.0988H21.2211ZM21.2211 17.3837H22.3435V17.3837L21.2211 17.3837ZM28.3168 19.4491L29.2633 20.0523L29.2633 20.0523L28.3168 19.4491ZM23.7873 27.4346C24.2957 27.08 24.4204 26.3803 24.0658 25.8719C23.7111 25.3635 23.0115 25.2389 22.5031 25.5935L23.7873 27.4346ZM15.4489 20.1095C12.8809 20.1095 10.7991 18.0277 10.7991 15.4596H8.55436C8.55436 19.2674 11.6412 22.3542 15.4489 22.3542V20.1095ZM20.0988 15.4596C20.0988 18.0277 18.017 20.1095 15.4489 20.1095V22.3542C19.2567 22.3542 22.3435 19.2674 22.3435 15.4596H20.0988ZM15.4489 10.8098C18.017 10.8098 20.0988 12.8916 20.0988 15.4596H22.3435C22.3435 11.6519 19.2567 8.56505 15.4489 8.56505V10.8098ZM15.4489 8.56505C11.6412 8.56505 8.55436 11.6519 8.55436 15.4596H10.7991C10.7991 12.8916 12.8809 10.8098 15.4489 10.8098V8.56505ZM20.0988 15.4596L20.0988 17.3837L22.3435 17.3837V15.4596L20.0988 15.4596ZM30.0398 17.3837V15.4597H27.795V17.3837H30.0398ZM0.858096 15.4597C0.858096 23.518 7.39064 30.0505 15.4489 30.0505V27.8058C8.63038 27.8058 3.10284 22.2782 3.10284 15.4597H0.858096ZM30.0398 15.4597C30.0398 7.40138 23.5072 0.868839 15.4489 0.868839V3.11358C22.2675 3.11358 27.795 8.64112 27.795 15.4597H30.0398ZM25.0693 22.3543C26.8339 22.3543 28.3828 21.4339 29.2633 20.0523L27.3703 18.8459C26.8846 19.608 26.0351 20.1095 25.0693 20.1095V22.3543ZM29.2633 20.0523C29.755 19.2807 30.0398 18.3638 30.0398 17.3837H27.795C27.795 17.9238 27.6391 18.424 27.3703 18.8459L29.2633 20.0523ZM15.4489 0.868839C7.39064 0.868839 0.858096 7.40138 0.858096 15.4597H3.10284C3.10284 8.64112 8.63038 3.11358 15.4489 3.11358V0.868839ZM20.0988 17.3837C20.0988 20.1289 22.3241 22.3543 25.0693 22.3543V20.1095C23.5639 20.1095 22.3435 18.8891 22.3435 17.3837H20.0988ZM15.4489 30.0505C18.5475 30.0505 21.4235 29.0834 23.7873 27.4346L22.5031 25.5935C20.5038 26.9881 18.0735 27.8058 15.4489 27.8058V30.0505Z"
      fill={color || Color.MONOGREY3}
    />
  </svg>
);
