import { useLocation } from 'react-router-dom';
import { DispatchAsyncResult } from 'react-redux-dispatch-async/lib/dispatchAsync';
import React, { useEffect, useState } from 'react';
import { useMediaQuery } from 'react-responsive';
import { Consultations } from './components/ConsultationsComponent';
import { NotesComponent } from './components/NotesComponent';
import { PersonalInformationComponent } from './components/PersonalInformationComponent';
import { TreatmentAntecedentComponent } from './components/TreatmentAntecedentComponent';
import { AttachmentsComponent } from '../../../common/ui/AttachmentsComponent';
import { hardCodedEmptyPatient, Patient } from '../../domain/entities/Patient';
import { DocColor, StyleSheet } from '../../../assets/styles/constantStyles';
import { CloseIcon } from '../../../assets/svg/CloseIcon';
import { useNotificationContext } from '../../../common/configuration/notificationContextProvider';
import { ConfirmOverlay } from '../../../common/ui/ConfirmOverlay';
import { AddIcon } from '../../../assets/svg/Addlcon';
import { AppointmentIcon } from '../../../assets/svg/AppointmentIcon';
import { TreatmentsAntecedents } from '../../domain/entities/TreatmentsAntecedents';
import { Rpx, SRpx } from '../../../common/usecases/helpers/DimensionsConverter';
import { NavigationPayload } from '../../../navigationContext/domain/entities/NavigationPayload';
import { Notes } from '../../domain/entities/Notes';
import CommonFunctions from '../../../common/usecases/helpers/CommonFunctions';
import { User } from '../../../authContext/domain/entities/User';
import { Attachment } from '../../../consultationsContext/domain/entities/Attachments';
import { bigScreenBreakpoint } from '../../../common/domain/entities/ReactResponsiveBreakpoints';
import { ToRightArrow3 } from '../../../assets/svg/ToRightArrow3';
import { PaperClipIcon } from '../../../assets/svg/PaperClipIcon';

type PatientDetailsProps = {
  stateConnectedUser: User | undefined;
  stateNotes: Notes | undefined;
  stateTreatmentsAntecedents: TreatmentsAntecedents | undefined;
  loadPatientAsync(patientId: string): Promise<DispatchAsyncResult>;
  updatePatientAsync(newPatient: Patient): Promise<DispatchAsyncResult>;
  deletePatientAsync(lookId: string): Promise<DispatchAsyncResult>;
  loadNotesAsync(patientId: string): Promise<DispatchAsyncResult>;
  updateNotesAsync(notes: Notes): Promise<DispatchAsyncResult>;
  loadTreatmentsAntecedentsAsync(patientId: string): Promise<DispatchAsyncResult>;
  updateTreatmentsAntecedentsAsync(treatmentsAndAntecedents: TreatmentsAntecedents): Promise<DispatchAsyncResult>;
  loadPatientsConsultationsAsync(
    patientId?: string,
    consultationsLimit?: number,
    skipConsultations?: number,
  ): Promise<DispatchAsyncResult>;
  loadAttachmentsAsync(
    patientId: string,
    attachmentsLimit?: number,
    skipAttachments?: number,
  ): Promise<DispatchAsyncResult>;
  insertAttachmentAsync(attachment: Attachment): Promise<DispatchAsyncResult>;
  navigateToAsync: (p: NavigationPayload) => void;
  navigateBack: () => void;
};

export const PatientDetailsDumb = ({
  stateConnectedUser,
  stateNotes,
  stateTreatmentsAntecedents,
  loadPatientAsync,
  updatePatientAsync,
  deletePatientAsync,
  loadNotesAsync,
  updateNotesAsync,
  loadTreatmentsAntecedentsAsync,
  updateTreatmentsAntecedentsAsync,
  loadPatientsConsultationsAsync,
  loadAttachmentsAsync,
  insertAttachmentAsync,
  navigateToAsync,
  navigateBack,
}: PatientDetailsProps) => {
  // isBigScreen working as a media breakpoint
  const isBigScreen = useMediaQuery({ query: `(min-width: ${bigScreenBreakpoint}px)` });

  const recievedPatientId: string = useLocation<string>().state;
  const [patient, setPatient] = useState<Patient>(hardCodedEmptyPatient);
  const notification = useNotificationContext();
  const [isConfirmFragmentClosed, setIsConfirmFragmentClosed] = useState<boolean>(true);
  const nextAppointmentDate: Date | undefined = patient.nextAppointment ? new Date(patient.nextAppointment) : undefined;
  useEffect(() => {
    if (recievedPatientId) {
      loadPatientAsync(recievedPatientId).then((res) => {
        if (res.success) {
          setPatient(res.result[0]);
          loadNotesAsync(recievedPatientId);
          loadTreatmentsAntecedentsAsync(recievedPatientId);
        }
      });
    }
  }, []);

  const deletePatient = (deletedPatient: Patient | undefined) => {
    if (deletedPatient !== undefined && deletedPatient.id) {
      deletePatientAsync(deletedPatient.id)
        .then((res) => {
          if (res.success && res.result) {
            navigateBack();
            notification.setOnSuccess({
              message:
                deletedPatient.temporaryPatient === null
                  ? `Patient actuel ${deletedPatient.firstName} ${deletedPatient.lastName} est supprimé.`
                  : `Patient actuel Temp${deletedPatient.temporaryPatient} est supprimé.`,
            });
          }
        })
        .catch((error) => {
          alert(`An error detected through the process of deleting: ${error}`);
        });
    }
  };
  const navigateToConsultationHandler = () => {
    navigateToAsync({ routeName: 'Consultation Addition', params: { patient } });
  };

  const navigateToScheduleAppointmentHandler = () => {
    navigateToAsync({ routeName: 'Mon Calendrier', params: { scheduleAppointment: true } });
  };

  const navigateToConsultationsHistoryHandler = () => {
    navigateToAsync({ routeName: 'Historique des consultations', params: recievedPatientId });
  };

  const navigateToAttachmentsHandler = () => {
    navigateToAsync({ routeName: 'Pièces jointes', params: recievedPatientId });
  };

  const onConfirmClick = () => {
    deletePatient(patient);
    setIsConfirmFragmentClosed(true);
  };

  return (
    <div style={isBigScreen ? { ...styles.container } : { ...styles.container, ...responsiveStyles.container }}>
      <ConfirmOverlay
        message={`Êtes-vous sûr de bien vouloir supprimer le patient ?
        Toutes les données, historique, consultations et pièces jointes
        du patient seront supprimés.`}
        onConfirmClick={onConfirmClick}
        isClosedState={isConfirmFragmentClosed}
        setIsClosedState={setIsConfirmFragmentClosed}
      />
      <div style={{ ...responsiveStyles.titleContainer, display: isBigScreen ? 'none' : '' }}>
        <ToRightArrow3 width={SRpx(10)} height={SRpx(10)} color={DocColor.DARKBLUE} />
        <p style={{ ...responsiveStyles.title, fontSize: SRpx(17, true) }}>Fiche Patient</p>
      </div>
      <div style={{ ...responsiveStyles.line, display: isBigScreen ? 'none' : '' }} />
      <div
        style={isBigScreen ? { ...styles.topContainer } : { ...styles.topContainer, ...responsiveStyles.topContainer }}
      >
        <div style={{ width: isBigScreen ? '23vw' : '50%' }}>
          <p
            style={
              isBigScreen
                ? { ...styles.patientNameParagraph, fontSize: Rpx(30, true) }
                : { ...responsiveStyles.patientNameParagraph, fontSize: SRpx(15, true) }
            }
          >
            {patient.temporaryPatient === null
              ? `${patient.firstName} ${patient.lastName}`
              : `Temp ${patient.temporaryPatient}`}
          </p>
          <p
            style={
              isBigScreen
                ? { ...styles.codePatientParagraph, fontSize: Rpx(24, true) }
                : { ...responsiveStyles.codePatientParagraph, fontSize: SRpx(15, true) }
            }
          >
            Code Patient{' '}
            <span style={styles.codePatientSpan}>{patient.id ? patient.id.slice(-5).toUpperCase() : '-'}</span>
          </p>
          <div
            style={
              isBigScreen
                ? { ...styles.deletePatientButton, height: Rpx(50, true) }
                : { ...styles.deletePatientButton, ...responsiveStyles.deletePatientButton, height: SRpx(30, true) }
            }
            onKeyDown={() => {
              setIsConfirmFragmentClosed(false);
            }}
            tabIndex={0}
            role="button"
            onClick={() => {
              setIsConfirmFragmentClosed(false);
            }}
          >
            <CloseIcon
              style={isBigScreen ? { ...styles.icon } : { ...styles.icon, ...responsiveStyles.icon }}
              width={isBigScreen ? Rpx(15, true) : SRpx(10, true)}
              height={isBigScreen ? Rpx(15, true) : SRpx(10, true)}
              color="white"
            />
            <p style={{ fontWeight: 500, fontSize: isBigScreen ? Rpx(23, true) : SRpx(12, true) }}>
              Supprimer le patient
            </p>
          </div>
        </div>

        <div
          style={
            isBigScreen
              ? {
                  margin: '0 0 auto auto',
                  position: 'absolute',
                  bottom: 0,
                  right: 0,
                }
              : {
                  width: '40%',
                  height: '100%',
                  margin: '0 0 auto auto',
                  position: 'absolute',
                  bottom: 0,
                  right: 0,
                }
          }
        >
          <div
            style={
              isBigScreen
                ? { ...styles.addPatientButton, height: Rpx(55, true) }
                : { ...styles.addPatientButton, ...responsiveStyles.addPatientButton, height: '100%' }
            }
            onKeyDown={() => {
              if (stateConnectedUser?.role === 'DOCTOR') {
                navigateToConsultationHandler();
              } else {
                navigateToScheduleAppointmentHandler();
              }
            }}
            tabIndex={0}
            role="button"
            onClick={() => {
              if (stateConnectedUser?.role === 'DOCTOR') {
                navigateToConsultationHandler();
              } else {
                navigateToScheduleAppointmentHandler();
              }
            }}
          >
            <AddIcon
              style={{ ...styles.icon, display: !isBigScreen ? 'none' : '' }}
              width={Rpx(20, true)}
              height={Rpx(20, true)}
              color="white"
            />
            <p
              style={
                isBigScreen
                  ? { margin: `auto 0 auto ${Rpx(10)}`, fontSize: Rpx(28, true) }
                  : { margin: `auto 0 auto ${SRpx(5)}`, fontSize: SRpx(15, true), textAlign: 'center' }
              }
            >
              {stateConnectedUser?.role === 'DOCTOR' ? `Nouvelle consultation` : `Programmer un RDV`}
            </p>
          </div>
          <div style={{ flexDirection: 'row', marginTop: Rpx(23), display: !isBigScreen ? 'none' : '' }}>
            <AppointmentIcon
              style={{
                margin: `auto ${Rpx(8)}`,
              }}
              width={Rpx(32, true)}
              height={Rpx(32, true)}
              color={DocColor.MEDIUMBLUE}
            />
            <p style={{ ...styles.nextRDVParagraph, fontSize: Rpx(28, true) }}>
              Prochain RDV :{' '}
              <span style={styles.nextRDVSpan}>
                {nextAppointmentDate
                  ? `${CommonFunctions.formatDate(nextAppointmentDate)} ${CommonFunctions.padTo2Digits(
                      nextAppointmentDate.getHours(),
                    )}h${CommonFunctions.padTo2Digits(nextAppointmentDate.getMinutes())}`
                  : '-'}
              </span>
            </p>
          </div>
        </div>
      </div>
      <div style={{ ...responsiveStyles.line, display: isBigScreen ? 'none' : '' }} />
      <NotesComponent stateNotes={stateNotes} updateNotesAsync={updateNotesAsync} />
      <div style={{ ...responsiveStyles.line, display: isBigScreen ? 'none' : '' }} />
      <PersonalInformationComponent
        setPatient={setPatient}
        recievedPatient={patient}
        updatePatientAsync={updatePatientAsync}
      />
      <div style={{ ...responsiveStyles.line, display: isBigScreen ? 'none' : '' }} />
      <TreatmentAntecedentComponent
        stateTreatmentsAntecedents={stateTreatmentsAntecedents}
        updateTreatmentsAntecedentsAsync={updateTreatmentsAntecedentsAsync}
      />
      <div style={{ ...responsiveStyles.line, display: isBigScreen ? 'none' : '' }} />
      {isBigScreen ? (
        <Consultations
          stateConnectedUser={stateConnectedUser}
          patientId={patient.id}
          loadPatientsConsultationsAsync={loadPatientsConsultationsAsync}
          navigateToAsync={navigateToAsync}
        />
      ) : (
        <button
          type="button"
          style={{
            ...responsiveStyles.patientConsultationsButton,
            fontSize: SRpx(15),
          }}
          onClick={() => {
            navigateToConsultationsHistoryHandler();
          }}
        >
          Historique des consultations du patient
        </button>
      )}
      {isBigScreen ? (
        <AttachmentsComponent
          patientId={patient.id}
          loadAttachmentsAsync={loadAttachmentsAsync}
          insertAttachmentAsync={insertAttachmentAsync}
        />
      ) : (
        <div
          role="button"
          tabIndex={0}
          onClick={() => navigateToAttachmentsHandler()}
          onKeyPress={() => navigateToAttachmentsHandler()}
          style={{ ...responsiveStyles.patientAttachmentsButton }}
        >
          <PaperClipIcon width={SRpx(20, true)} />
          <p style={{ fontWeight: 600, fontSize: SRpx(15, true) }}>Pièces jointes</p>
        </div>
      )}
    </div>
  );
};

const styles: StyleSheet = {
  container: {
    flex: 1,
    margin: `${Rpx(180)} 0`,
  },
  topContainer: {
    position: 'relative',
    margin: `0 ${Rpx(82)}`,
  },
  icon: {
    border: `${Rpx(4)} solid white`,
    borderRadius: Rpx(20),
    padding: Rpx(5),
    margin: 'auto 0',
  },
  patientNameParagraph: {
    padding: `${Rpx(10)} 0`,
    background: DocColor.DARKBLUE,
    borderRadius: `${Rpx(30)} ${Rpx(30)} 0 0`,
    color: 'white',
    fontWeight: 700,
    textAlign: 'center',
    textTransform: 'capitalize',
  },
  codePatientSpan: {
    fontWeight: 800,
    color: DocColor.MEDIUMBLUE,
    letterSpacing: Rpx(3),
    marginLeft: Rpx(20),
  },
  codePatientParagraph: {
    border: `${Rpx(6)} solid ${DocColor.DARKBLUE}`,
    borderRadius: `0 0 ${Rpx(10)} ${Rpx(10)}`,
    textAlign: 'center',
    padding: `${Rpx(10)} 0`,
    fontWeight: 800,
    color: DocColor.DARKBLUE,
  },
  deletePatientButton: {
    flexDirection: 'row',
    width: '20vw',
    background: DocColor.DARKBLUE,
    borderRadius: `0 0 ${Rpx(21)} ${Rpx(21)}`,
    margin: '0 auto',
    justifyContent: 'center',
    color: 'white',
    fontSize: Rpx(23),
    cursor: 'pointer',
    alignItems: 'center',
    gap: Rpx(10),
  },
  addPatientButton: {
    flexDirection: 'row',
    width: 'fit-content',
    background: DocColor.MEDIUMBLUE,
    borderRadius: Rpx(10),
    margin: '0 0 0 auto',
    justifyContent: 'center',
    color: 'white',
    fontSize: Rpx(28),
    cursor: 'pointer',
    fontWeight: 500,
    padding: `0 ${Rpx(15)}`,
  },
  nextRDVParagraph: {
    color: DocColor.DARKBLUE,
    fontWeight: 700,
  },
  nextRDVSpan: {
    fontWeight: 700,
    color: DocColor.MEDIUMBLUE,
    fontSize: Rpx(28),
  },
};

const responsiveStyles: StyleSheet = {
  container: {
    margin: `${SRpx(30)} 0`,
  },
  titleContainer: {
    flexDirection: 'row',
    width: '80%',
    gap: SRpx(3),
    alignItems: 'center',
    margin: `0 auto ${SRpx(20)} auto`,
  },
  title: {
    color: DocColor.MEDIUMBLUE,
    fontWeight: 700,
    letterSpacing: SRpx(1),
  },
  line: {
    width: '90%',
    height: 2,
    background: DocColor.MEDIUMGRAY,
    margin: `0 auto ${SRpx(20)} auto`,
  },
  topContainer: {
    margin: `0 10% ${SRpx(20)} 10%`,
  },
  patientNameParagraph: {
    color: DocColor.DARKBLUE,
    marginBottom: SRpx(3),
    fontWeight: 800,
    textAlign: 'center',
    textTransform: 'capitalize',
  },
  codePatientParagraph: {
    textAlign: 'center',
    marginBottom: SRpx(3),
    fontWeight: 800,
    color: DocColor.DARKBLUE,
  },
  deletePatientButton: {
    width: '100%',
    background: DocColor.DARKBLUE,
    borderRadius: SRpx(2),
    gap: SRpx(5),
  },
  icon: {
    border: `${SRpx(2)} solid white`,
    borderRadius: SRpx(10),
    padding: SRpx(3),
  },
  addPatientButton: {
    width: '100%',
    borderRadius: SRpx(5),
    cursor: 'pointer',
    fontWeight: 500,
  },
  patientConsultationsButton: {
    flexDirection: 'row',
    fontWeight: 600,
    color: 'white',
    margin: `0 auto ${SRpx(10)} auto`,
    cursor: 'pointer',
    background: DocColor.DARKBLUE,
    width: '80%',
    borderRadius: SRpx(10),
    padding: `2.5% 10%`,
  },
  patientAttachmentsButton: {
    width: '80%',
    backgroundColor: DocColor.DARKBLUE,
    color: 'white',
    flexDirection: 'row',
    cursor: 'pointer',
    borderRadius: SRpx(10),
    padding: `2.5% 10%`,
    margin: `0 auto`,
    boxSizing: 'border-box',
    gap: SRpx(5),
    alignItems: 'center',
    justifyContent: 'center',
  },
};
