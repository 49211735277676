import { createAsyncActions } from '../../common/usecases/helpers/actions.helper';
import { Notes } from '../domain/entities/Notes';
import { Patient } from '../domain/entities/Patient';
import { TreatmentsAntecedents } from '../domain/entities/TreatmentsAntecedents';

export const {
  request: loadAllPatientsRequest,
  success: loadAllPatientsSuccess,
  failure: loadAllPatientsFailure,
} = createAsyncActions<void, Patient[], unknown>('LOAD_ALL_PATIENTS');

export const {
  request: loadPatientRequest,
  success: loadPatientSuccess,
  failure: loadPatientFailure,
} = createAsyncActions<string, Patient, unknown>('LOAD_PATIENT');

export const {
  request: fetchPatientsRequest,
  success: fetchPatientsSuccess,
  failure: fetchPatientsFailure,
} = createAsyncActions<string, Patient[], unknown>('FETCH_PATIENTS');

export const {
  request: getTemporaryPatientNumberRequest,
  success: getTemporaryPatientNumberSuccess,
  failure: getTemporaryPatientNumberFailure,
} = createAsyncActions<void, number, unknown>('GET_TEMPORARY_PATIENT_NUMBER');

export const {
  request: loadPatientsNamesRequest,
  success: loadPatientsNamesSuccess,
  failure: loadPatientsNamesFailure,
} = createAsyncActions<void, { patientId: string; fullName: string }[], unknown>('LOAD_PATIENTS_NAMES');

export const {
  request: insertPatientRequest,
  success: insertPatientSuccess,
  failure: insertPatientFailure,
} = createAsyncActions<Patient, Patient, unknown>('INSERT_PATIENT');

export const {
  request: updatePatientRequest,
  success: updatePatientSuccess,
  failure: updatePatientFailure,
} = createAsyncActions<Patient, Patient, unknown>('UPDATE_PATIENT');

export const {
  request: deletePatientRequest,
  success: deletePatientSuccess,
  failure: deletePatientFailure,
} = createAsyncActions<string, boolean, unknown>('DELETE_PATIENT');

export const {
  request: loadNotesRequest,
  success: loadNotesSuccess,
  failure: loadNotesFailure,
} = createAsyncActions<string, Notes, unknown>('LOAD_NOTES');

export const {
  request: updateNotesRequest,
  success: updateNotesSuccess,
  failure: updateNotesFailure,
} = createAsyncActions<Notes, Notes, unknown>('UPDATE_NOTES');

export const {
  request: loadTreatmentsAntecedentsRequest,
  success: loadTreatmentsAntecedentsSuccess,
  failure: loadTreatmentsAntecedentsFailure,
} = createAsyncActions<string, TreatmentsAntecedents, unknown>('LOAD_TREATMENTS_ANTECEDENTS');

export const {
  request: updateTreatmentsAntecedentsRequest,
  success: updateTreatmentsAntecedentsSuccess,
  failure: updateTreatmentsAntecedentsFailure,
} = createAsyncActions<TreatmentsAntecedents, TreatmentsAntecedents, unknown>('UPDATE_TREATMENTS_ANTECEDENTS');
