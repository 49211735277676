import * as React from 'react';
import { Color } from '../styles/constantStyles';

export interface Medicine2IconProps {
  color?: string;
  style?: React.CSSProperties;
  width?: string | number;
  height?: string | number;
}

export const Medicine2Icon = ({ color, width, height, style }: Medicine2IconProps) => (
  <svg style={style} width={width} height={height} viewBox="0 0 20 19" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M11.5893 2.65288L3.64439 10.5978C2.17962 12.0626 2.17844 14.4363 3.64176 15.8996C5.10508 17.3629 7.47877 17.3617 8.94354 15.8969L16.8884 7.95203C18.3532 6.48725 18.3544 4.11357 16.8911 2.65025C15.4278 1.18693 13.0541 1.18811 11.5893 2.65288Z"
      fill={color || Color.MONOGREY3}
      stroke="white"
      strokeWidth="1.16484"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M7.61719 6.63281L12.9163 11.9319"
      stroke="white"
      strokeWidth="1.16484"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M12.9219 7.96968L14.9422 6.01562"
      stroke="white"
      strokeWidth="1.16484"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
