import React, { useEffect, useRef } from 'react';
import JsPDF from 'jspdf';
import * as htmlToImage from 'html-to-image';
import { DocColor, StyleSheet } from '../../../../assets/styles/constantStyles';
import { DocLogo } from '../../../../assets/svg/docLogo';
import { Medicine2Icon } from '../../../../assets/svg/Medicine2Icon';
import CommonFunctions from '../../../../common/usecases/helpers/CommonFunctions';
import { Patient } from '../../../../patientsContext/domain/entities/Patient';

type NotesTemplateOverlayProps = {
  patient?: Patient | null;
  temporaryPatientName: string;
  onSuccess?: () => void;
  onFailure?: () => void;
};

export const NotesTemplateOverlay = ({
  patient,
  temporaryPatientName,
  onSuccess,
  onFailure,
}: NotesTemplateOverlayProps) => {
  const containerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (containerRef.current !== null) {
      htmlToImage
        .toPng(containerRef.current)
        .then((dataUrl) => {
          const pdf = new JsPDF('p', 'pt', 'a4');
          const pdfWidth = pdf.internal.pageSize.getWidth();
          const pdfHeight = pdf.internal.pageSize.getHeight();
          pdf.addImage(dataUrl, 'PNG', 0, 0, pdfWidth, pdfHeight);
          pdf.setProperties({
            title: `Ordonnance médicale (${temporaryPatientName || `${patient?.firstName} ${patient?.lastName}`})`,
          });
          window.open(pdf.output('bloburl'));
          if (onSuccess) {
            onSuccess();
          }
        })
        .catch(() => {
          if (onFailure) {
            onFailure();
          }
        });
    }
  }, []);

  return (
    <div style={styles.container}>
      <div style={styles.templateContainer} ref={containerRef}>
        <div style={styles.header}>
          <div style={styles.headerDiv}>
            <p style={{ ...styles.headerFooterParagraph, ...styles.headerFooterSpan }}>Dr. Hmada Mous</p>
            <p style={styles.headerFooterParagraph}>Human Mechanics</p>
            <p style={styles.headerFooterParagraph}>
              <span style={styles.headerFooterSpan}>Tel :</span> 06 07 08 09 00
            </p>
            <p style={styles.headerFooterParagraph}>dr.everything@mous.com</p>
          </div>
          <div style={styles.headerDiv}>
            <DocLogo style={styles.logoImage} color="white" />
          </div>
          <div style={styles.headerDiv}>
            <p style={{ ...styles.headerFooterParagraph, ...styles.headerFooterSpan }}>Cabinet Mous Health</p>
            <p style={styles.headerFooterParagraph}>
              <span style={styles.headerFooterSpan}>Adresse :</span> Rue 7 eyes , somewhere over the rainbow ,
              Argentina.
            </p>
          </div>
        </div>

        <div style={styles.content}>
          <div style={styles.backgroundLogoImage}>
            <DocLogo color={DocColor.CLOUDYBLUE} width="60%" />
          </div>
          <div style={styles.titleDiv}>
            <Medicine2Icon height="50%" color={DocColor.DARKBLUE} />
            <p style={styles.title}>Notes</p>
          </div>
          <div style={styles.horizotalLine} />
          <div
            style={{
              width: '80%',
              flexDirection: 'row',
              justifyContent: 'space-between',
              margin: '5% auto 0 auto',
              zIndex: 2,
            }}
          >
            <p style={styles.contentParagraph}>
              Fait à :<span style={styles.contentSpan}> Casablanca</span>
            </p>
            <p style={styles.contentParagraph}>
              Le :<span style={styles.contentSpan}> {CommonFunctions.formatDate(new Date())} </span>
            </p>
          </div>
          <div
            style={{
              width: '80%',
              flexDirection: 'row',
              justifyContent: 'space-between',
              margin: '0 auto 0 auto',
              zIndex: 2,
            }}
          >
            <p style={styles.contentParagraph}>
              Nom & prénom:{' '}
              <span style={styles.contentSpan}>
                {temporaryPatientName || `${patient?.firstName} ${patient?.lastName}`}
              </span>
            </p>
          </div>
        </div>

        <div style={styles.footer}>
          <p style={{ ...styles.headerFooterParagraph, ...styles.headerFooterSpan }}>Cabinet Mous Health</p>
          <p style={styles.headerFooterParagraph}>DR. Hmada Mouss specialised in Human Mechanics</p>
          <p style={{ ...styles.headerFooterParagraph, whiteSpace: 'nowrap' }}>
            <span style={styles.headerFooterSpan}>Adresse :</span> Rue 7 eyes , somewhere over the rainbow
          </p>
          <div style={{ flexDirection: 'row', gap: '10%', width: '100%', justifyContent: 'center', zIndex: 2 }}>
            <p style={styles.headerFooterParagraph}>
              <span style={styles.headerFooterSpan}>Tel :</span> 06 07 08 09 00
            </p>
            <p style={styles.headerFooterParagraph}>
              <span style={styles.headerFooterSpan}>Mail :</span> dr.everything@mous.com
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

const styles: StyleSheet = {
  container: {
    position: 'fixed',
    inset: 0,
    width: '100%',
    height: '100%',
    zIndex: 6,
    marginTop: '100%',
  },
  templateContainer: {
    width: '210mm',
    height: '297mm',
    background: 'white',
    boxSizing: 'border-box',
  },
  header: {
    width: '100%',
    height: '15.5%',
    background: DocColor.DARKBLUE,
    flexDirection: 'row',
    justifyContent: 'space-between',
    gap: '2.5%',
    padding: '2.5%',
    boxSizing: 'border-box',
  },
  headerDiv: {
    maxWidth: '30%',
  },
  headerFooterParagraph: {
    color: 'white',
    fontWeight: 400,
    justifyContent: 'center',
  },
  headerFooterSpan: {
    fontWeight: 600,
  },
  logoImage: {
    width: '80%',
    height: '90%',
    margin: 'auto',
    borderStyle: 'solid',
    borderColor: 'white',
    borderWidth: '1%',
    borderRadius: '5%',
    padding: `5% 10%`,
  },

  content: {
    position: 'relative',
    width: '100%',
    height: '100%',
    padding: '2.5% 5%',
    boxSizing: 'border-box',
  },
  backgroundLogoImage: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 0,
  },
  titleDiv: {
    width: '100%',
    height: '7%',
    flexDirection: 'row',
    gap: '2%',
    zIndex: 2,
    justifyContent: 'center',
    alignItems: 'center',
    letterSpacing: 'center',
  },
  title: {
    fontSize: '100%',
    fontWeight: 600,
    color: DocColor.DARKBLUE,
    letterSpacing: 'center',
  },
  horizotalLine: {
    height: '1%',
    width: '100%',
    background: DocColor.DARKBLUE,
    borderRadius: '50%',
  },
  contentParagraph: {
    fontSize: '150%',
    fontWeight: 600,
    color: DocColor.DARKBLUE,
    letterSpacing: 'center',
  },
  contentSpan: {
    color: 'black',
    textDecorationStyle: 'dotted',
  },
  prescriptionContent: {
    marginTop: '5%',
    marginLeft: '5%',
    width: '100%',
    zIndex: 2,
  },
  medicineParagraph: {
    fontSize: '8mm',
    color: DocColor.DARKBLUE,
    fontWeight: 700,
  },
  medicineInfosParagraph: {
    fontSize: '5mm',
    color: 'black',
    fontWeight: 500,
  },

  footer: {
    width: '100%',
    height: '15.5%',
    background: DocColor.DARKBLUE,
    justifyContent: 'center',
    alignItems: 'center',
    gap: '2.5%',
    padding: '2.5%',
    boxSizing: 'border-box',
    zIndex: 2,
  },
};
