import { fetchV2, getTokensToFetch, removeTokensToFetch, setTokensToFetch } from '../../../common/adapter/fetch.helper';
import { AuthTokens } from '../../../common/domain/entities/AuthTokens';
import { BACKEND_CONFIG } from '../../../configuration';
import { defaultPassword, User } from '../../domain/entities/User';
import { UserAccount } from '../../domain/entities/UserAccount';
import { AuthInteractor } from '../../domain/gateways/Auth.interactor';

export class RealAuthInteractor implements AuthInteractor {
  async loadUsers(): Promise<User[]> {
    const url = `${BACKEND_CONFIG.endpoints.users}/loadUsers`;
    const response = await fetch(url, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Accept-Encoding': 'gzip',
        'Content-Type': 'application/json',
      },
    });
    const receivedUsers = await response.json();
    return receivedUsers;
  }

  async loadUser(id: string) {
    const url = `${BACKEND_CONFIG.endpoints.users}/loadUser`;
    const response = await fetch(`${url}?id=${id}`, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Accept-Encoding': 'gzip',
        'Content-Type': 'application/json',
      },
    });
    const result = await response.json();
    return result;
  }

  async resetPassword(id: string, accessToken: string, newPassword: string): Promise<User> {
    const url = `${BACKEND_CONFIG.endpoints.users}/resetPassword`;
    const response = await fetch(url, {
      method: 'PUT',
      headers: {
        Accept: 'application/json',
        'Accept-Encoding': 'gzip',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ id, accessToken, newPassword }),
    });
    const result = await response.json();
    return result;
  }

  async deleteUser(id: string): Promise<User> {
    const url = `${BACKEND_CONFIG.endpoints.users}/deleteUser?id=${id}`;
    const response = await fetchV2(url, undefined, 'DELETE');

    if (response.ok) {
      const jsonRes = await response.json();
      return jsonRes;
    }
    const error = await response.json();
    throw new Error(response.status.toString(), { cause: error.message });
  }

  async sendSignUp(newUser: User) {
    const url = `${BACKEND_CONFIG.endpoints.signUp}`;
    const objectToSend = {
      email: newUser.email,
      password: defaultPassword,
      firstName: newUser.firstName,
      lastName: newUser.lastName,
      gender: newUser.gender,
      linkedTo: newUser.linkedTo,
      role: newUser.role,
    };
    const response = await fetch(url, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Accept-Encoding': 'gzip',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(objectToSend),
    });
    const receivedUser = await response.json();
    return receivedUser;
  }

  /* async sendAutoSignIn(): Promise<AuthTokens> {
    // probably doesn't work yet
    const { renewToken } = getTokensToFetch();
    if (!renewToken) {
      throw new Error('no token');
    }
    const url = `${BACKEND_CONFIG.endpoints.renew}`;
    const response = await fetchV2(url, renewToken, 'POST');
    if (response.ok) {
      const receivedTokens: AuthTokens = await response.json();
      return receivedTokens;
    }
    const error = await response.json();
    throw new Error(response.status.toString(), { cause: error.message });
  } */

  async sendSignIn(userAccount: UserAccount): Promise<AuthTokens> {
    const url = `${BACKEND_CONFIG.endpoints.signInV2}`;
    const response = await fetchV2(
      url,
      undefined,
      'POST',
      JSON.stringify({
        email: userAccount.email,
        password: userAccount.password,
      }),
    );
    if (response.ok) {
      const receivedTokens: AuthTokens = await response.json();
      setTokensToFetch(receivedTokens);
      return receivedTokens;
    }
    const error = await response.json();
    throw new Error(response.status.toString(), { cause: error.message });
  }

  async sendSignOut(): Promise<undefined> {
    getTokensToFetch().then(async (val: any) => {
      if (!val.renewToken) {
        throw new Error('no token');
      }
      const url = `${BACKEND_CONFIG.endpoints.signOut}?renewToken=${val.renewToken}`;
      const response = await fetchV2(url, undefined, 'DELETE');
      removeTokensToFetch();
      if (response.ok) {
        const jsonRes = await response.json();
        return jsonRes;
      }
      const error = await response.json();
      throw new Error(response.status.toString(), { cause: error.message });
    });
    return undefined;
  }

  async sendResetPasswordRequest(mail: string): Promise<boolean> {
    const url = `${BACKEND_CONFIG.endpoints.users}/sendResetPasswordMail`;
    const response = await fetch(url, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Accept-Encoding': 'gzip',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        mail,
      }),
    });
    const result = await response.json();
    return result;
  }
}
