import React from 'react';

export interface ReturnProps {
  style?: React.CSSProperties;
  width?: string | number;
  height?: string | number;
}

export const ReturnIcon = ({ width, height, style }: ReturnProps) => (
  <svg width={width} height={height} style={style} viewBox="0 0 32 31" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="16.0387" cy="15.3981" r="15.3981" fill="white" />
    <path
      d="M11.5814 15.905L7.32666 11.6502L11.5814 7.39551"
      stroke="#015D8C"
      strokeWidth="4"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M11.5814 21.5781H19.3818C20.6983 21.5781 21.9608 21.0551 22.8917 20.1242C23.8226 19.1933 24.3456 17.9308 24.3456 16.6143V16.6143C24.3456 15.2978 23.8226 14.0352 22.8917 13.1043C21.9608 12.1734 20.6983 11.6504 19.3818 11.6504H7.32666"
      stroke="#015D8C"
      strokeWidth="4"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
