import React, { useEffect, useState } from 'react';
import { DispatchAsyncResult } from 'react-redux-dispatch-async/lib/dispatchAsync';
import { useMediaQuery } from 'react-responsive';
import { CustomEmailInputComponent } from './components/CustomEmailInputComponent';
import { DocColor, StyleSheet } from '../../../assets/styles/constantStyles';
import { ReturnIcon } from '../../../assets/svg/ReturnIcon';
import { Rpx, SRpx } from '../../../common/usecases/helpers/DimensionsConverter';
import CommonFunctions from '../../../common/usecases/helpers/CommonFunctions';
import { useLoaderContext } from '../../../common/configuration/loaderContextProvider';
import { bigScreenBreakpoint } from '../../../common/domain/entities/ReactResponsiveBreakpoints';
import { ReturnIcon2 } from '../../../assets/svg/ReturnIcon2';

interface ForgotPasswordProps {
  sendResetPasswordRequestAsync(mail: string): Promise<DispatchAsyncResult<boolean>>;
  navigateBack: () => void;
}
export const ForgotPasswordDumb = ({ navigateBack, sendResetPasswordRequestAsync }: ForgotPasswordProps) => {
  // isBigScreen working as a media breakpoint
  const isBigScreen = useMediaQuery({ query: `(min-width: ${bigScreenBreakpoint}px)` });

  const loader = useLoaderContext();
  const [emailInput, setEmailInput] = useState<string>('');
  const [mistakesArray, setMistakesArray] = useState<string[]>([]);
  const [onResendRequest, setOnResendRequest] = useState(false);
  const [initStateRender, setInitStateRender] = useState<boolean>(true);
  const [timerInMs, setTimerInMs] = useState<number>(0);

  useEffect(() => {
    if (timerInMs !== 0) {
      setTimeout(() => {
        setTimerInMs(timerInMs - 1000);
      }, 1000);
    }
  }, [timerInMs]);

  const checkEmailValidity = (mail: string) => {
    setMistakesArray([]);
    if (!CommonFunctions.isValidEmail(mail)) {
      setMistakesArray((element) => [...element, "L'email n'est pas valide"]);
    }
    if (mail.trim() === '') {
      setMistakesArray((element) => [...element, 'Le champ ne doit pas être vide']);
    }
  };

  const onCustomInputChange = (value: string) => {
    checkEmailValidity(value);
    setEmailInput(value);
  };

  const onSubmitButtonClickHandler = () => {
    if (mistakesArray.length === 0 && emailInput !== '' && timerInMs === 0) {
      loader.setMessage('_');
      setMistakesArray([]);
      sendResetPasswordRequestAsync(emailInput)
        .then((res) => {
          if (res.success && !res.result) {
            setMistakesArray((element) => [...element, "Nous n'avons pas trouvé votre e-mail!"]);
          } else if (res.success && res.result) {
            setOnResendRequest(true);
            setTimerInMs(60000);
          } else if (!res.success) {
            alert("Une erreur s'est produite");
          }
        })
        .finally(() => loader.setMessage(''));
    } else {
      checkEmailValidity('');
    }
    setInitStateRender(false);
  };

  const onReturnButtonClickHandler = () => {
    navigateBack();
  };

  return (
    <div style={styles.container}>
      <div style={styles.forgotPasswordContainer}>
        <p style={isBigScreen ? { ...styles.title } : { ...styles.title, ...responsiveStyles.title }}>
          Mot de passe oublié ?
        </p>
        <div style={{ ...responsiveStyles.titleLine, display: isBigScreen ? 'none' : '' }} />
        <p
          style={
            isBigScreen
              ? {
                  ...styles.paragraph,
                  display: onResendRequest === true ? 'none' : '',
                }
              : {
                  ...styles.paragraph,
                  ...responsiveStyles.paragraph,
                  display: onResendRequest === true ? 'none' : '',
                }
          }
        >
          Réinitialisez le mot de passe en deux étapes rapides
        </p>
        {onResendRequest !== true ? (
          <CustomEmailInputComponent
            initStateRender={initStateRender}
            onChange={(value) => {
              onCustomInputChange(value);
            }}
            mistakesArray={mistakesArray}
          />
        ) : (
          <p
            style={
              isBigScreen
                ? {
                    ...styles.resendRequestParagraph,
                  }
                : {
                    ...styles.resendRequestParagraph,
                    ...responsiveStyles.resendRequestParagraph,
                  }
            }
          >
            Un email vous a été envoyé Si vous n’arrivez pas à retrouver l’email, cliquez sur le bouton ci-dessous
          </p>
        )}

        <button
          disabled={!initStateRender && (mistakesArray.length > 0 || timerInMs !== 0)}
          type="button"
          style={
            isBigScreen
              ? {
                  ...styles.submitButton,
                  pointerEvents: !initStateRender && (mistakesArray.length > 0 || timerInMs !== 0) ? 'none' : 'auto',
                  background:
                    !initStateRender && (mistakesArray.length > 0 || timerInMs !== 0)
                      ? DocColor.GRAY
                      : DocColor.DARKBLUE,
                }
              : {
                  ...styles.submitButton,
                  ...responsiveStyles.submitButton,
                  pointerEvents: !initStateRender && (mistakesArray.length > 0 || timerInMs !== 0) ? 'none' : 'auto',
                  background:
                    !initStateRender && (mistakesArray.length > 0 || timerInMs !== 0)
                      ? DocColor.GRAY
                      : DocColor.DARKBLUE,
                }
          }
          onClick={onSubmitButtonClickHandler}
        >
          {onResendRequest ? `RENVOYER${timerInMs !== 0 ? ` (${timerInMs / 1000}s)` : ''}` : 'Réinitialiser'}
        </button>

        <div
          style={
            isBigScreen ? { ...styles.returnButton } : { ...styles.returnButton, ...responsiveStyles.returnButton }
          }
          onKeyDown={onReturnButtonClickHandler}
          tabIndex={0}
          role="button"
          onClick={onReturnButtonClickHandler}
        >
          <ReturnIcon
            style={{ margin: 'auto 0', display: isBigScreen ? '' : 'none' }}
            width={Rpx(30)}
            height={Rpx(30)}
          />
          <ReturnIcon2
            style={{ margin: `auto ${Rpx(20)}`, display: isBigScreen ? 'none' : '' }}
            width={SRpx(15)}
            height={SRpx(15)}
            color={DocColor.DARKBLUE}
          />
          <div style={{ ...styles.returnButtonVerticalLine, display: isBigScreen ? '' : 'none' }} />
          <p
            style={
              isBigScreen
                ? { ...styles.returnButtonParagraph }
                : { ...styles.returnButtonParagraph, ...responsiveStyles.returnButtonParagraph }
            }
          >
            Retour
          </p>
        </div>
      </div>
    </div>
  );
};

const styles: StyleSheet = {
  container: {
    flex: 1,
  },
  forgotPasswordContainer: {
    width: '100%',
    margin: 'auto',
  },
  title: {
    margin: `0 auto 0 auto`,
    color: DocColor.DARKBLUE,
    fontSize: Rpx(64),
    fontWeight: 700,
    letterSpacing: Rpx(1),
  },
  paragraph: {
    letterSpacing: Rpx(3),
    margin: `${Rpx(30)} auto 0 auto`,
    fontSize: Rpx(24),
    color: DocColor.DARKBLUE,
    fontWeight: 500,
    width: '80%',
    textAlign: 'center',
  },
  resendRequestParagraph: {
    letterSpacing: Rpx(3),
    margin: `${Rpx(50)} auto`,
    fontSize: Rpx(28),
    fontWeight: 700,
    width: '80%',
    textAlign: 'center',
    color: DocColor.MEDIUMBLUE,
  },
  submitButton: {
    letterSpacing: Rpx(3),
    color: 'white',
    width: '20vw',
    height: Rpx(58),
    margin: `${Rpx(40)} auto 0 auto`,
    background: DocColor.DARKBLUE,
    borderRadius: Rpx(15),
    textTransform: 'uppercase',
    fontSize: Rpx(30),
    fontWeight: 600,
  },
  returnButton: {
    height: Rpx(58),
    margin: `${Rpx(40)} auto 0 auto`,
    background: DocColor.DARKBLUE,
    borderRadius: Rpx(20),
    flexDirection: 'row',
    padding: `${Rpx(4)} ${Rpx(20)}`,
    cursor: 'pointer',
  },
  returnButtonVerticalLine: {
    width: Rpx(3),
    height: '80%',
    background: 'white',
    borderRadius: Rpx(2),
    margin: `auto ${Rpx(20)}`,
  },
  returnButtonParagraph: {
    color: 'white',
    textTransform: 'capitalize',
    fontSize: Rpx(27),
    fontWeight: 700,
    margin: 'auto 0',
  },
};

const responsiveStyles: StyleSheet = {
  title: {
    fontSize: SRpx(25),
    letterSpacing: SRpx(2),
  },
  titleLine: {
    width: '80%',
    height: SRpx(3),
    background: DocColor.DARKBLUE,
    borderRadius: SRpx(3),
    margin: '0 auto',
  },
  paragraph: {
    letterSpacing: SRpx(1),
    margin: `${SRpx(15)} auto 0 auto`,
    fontSize: SRpx(12),
  },
  resendRequestParagraph: {
    letterSpacing: SRpx(1),
    margin: `${SRpx(25)} auto`,
    fontSize: SRpx(12),
  },
  submitButton: {
    letterSpacing: SRpx(2),
    width: '50vw',
    height: SRpx(29),
    margin: `${SRpx(20)} auto 0 auto`,
    borderRadius: SRpx(8),
    fontSize: SRpx(15),
  },
  returnButton: {
    height: SRpx(29),
    margin: `${SRpx(10)} auto 0 auto`,
    background: 'white',
    borderRadius: SRpx(10),
    padding: `${SRpx(2)} ${SRpx(10)}`,
  },
  returnButtonParagraph: {
    color: DocColor.DARKBLUE,
    letterSpacing: SRpx(2),
    fontSize: SRpx(13),
    textTransform: 'uppercase',
  },
};
