import { all, call, put, takeLatest } from 'redux-saga/effects';
import { Action } from 'redux-actions';
import {
  deletePatientConsultationFailure,
  deletePatientConsultationRequest,
  deletePatientConsultationSuccess,
  fetchMedicinesFailure,
  fetchMedicinesRequest,
  fetchMedicinesSuccess,
  fetchPatientsConsultationsFailure,
  fetchPatientsConsultationsRequest,
  fetchPatientsConsultationsSuccess,
  getConsultationNumberFailure,
  getConsultationNumberRequest,
  getConsultationNumberSuccess,
  getTodaysConsultationsStatsFailure,
  getTodaysConsultationsStatsRequest,
  getTodaysConsultationsStatsSuccess,
  insertAttachmentFailure,
  insertAttachmentRequest,
  insertAttachmentSuccess,
  insertConsultationNotesFailure,
  insertConsultationNotesRequest,
  insertConsultationNotesSuccess,
  insertConsultationReasonFailure,
  insertConsultationReasonRequest,
  insertConsultationReasonSuccess,
  insertExaminationsFailure,
  insertExaminationsRequest,
  insertExaminationsSuccess,
  insertExaminationsToRequestFailure,
  insertExaminationsToRequestRequest,
  insertExaminationsToRequestSuccess,
  insertInterrogationFailure,
  insertInterrogationRequest,
  insertInterrogationSuccess,
  insertMedicalCertificateFailure,
  insertMedicalCertificateRequest,
  insertMedicalCertificateSuccess,
  insertMesuresFailure,
  insertMesuresRequest,
  insertMesuresSuccess,
  insertPatientsConsultationFailure,
  insertPatientsConsultationRequest,
  insertPatientsConsultationSuccess,
  insertPrescriptionsFailure,
  insertPrescriptionsRequest,
  insertPrescriptionsSuccess,
  loadAttachmentsFailure,
  loadAttachmentsRequest,
  loadAttachmentsSuccess,
  loadConsultationNotesFailure,
  loadConsultationNotesRequest,
  loadConsultationNotesSuccess,
  loadConsultationReasonFailure,
  loadConsultationReasonRequest,
  loadConsultationReasonSuccess,
  loadExaminationsFailure,
  loadExaminationsRequest,
  loadExaminationsSuccess,
  loadExaminationsToRequestFailure,
  loadExaminationsToRequestRequest,
  loadExaminationsToRequestSuccess,
  loadInterrogationFailure,
  loadInterrogationRequest,
  loadInterrogationSuccess,
  loadMedicalCertificateFailure,
  loadMedicalCertificateRequest,
  loadMedicalCertificateSuccess,
  loadMesuresFailure,
  loadMesuresRequest,
  loadMesuresSuccess,
  loadPatientConsultationFailure,
  loadPatientConsultationRequest,
  loadPatientConsultationSuccess,
  loadPatientsConsultationsFailure,
  loadPatientsConsultationsRequest,
  loadPatientsConsultationsSuccess,
  loadPrescriptionsFailure,
  loadPrescriptionsRequest,
  loadPrescriptionsSuccess,
  sendAttachmentByMailFailure,
  sendAttachmentByMailRequest,
  sendAttachmentByMailSuccess,
} from './Consultations.actions';
import { getDependencies } from '../../common/usecases/common.sagas';
import { DependenciesType } from '../../configuration/dependencies';
import { PatientConsultation } from '../domain/entities/PatientConsultation';
import { Attachment } from '../domain/entities/Attachments';
import { ConsultationReason } from '../domain/entities/ConsultationReason';
import { Interrogation } from '../domain/entities/Interrogation';
import { Mesures } from '../domain/entities/Mesures';
import { Examination } from '../domain/entities/Examination';
import { ExaminationToRequest } from '../domain/entities/ExaminationToRequest';
import { Prescription } from '../domain/entities/Prescriptions';
import { MedicalCertificate } from '../domain/entities/MedicalCertificate';
import { TodaysConsultationsStats } from '../domain/entities/TodaysConsultationsStats';
import { Medicine } from '../domain/entities/Medicine';
import { ConsultationNotes } from '../domain/entities/ConsultationNotes';

function* loadAttachmentsSaga(
  action: Action<{ patientId: string; attachmentsLimit?: number; skipAttachments?: number }>,
) {
  try {
    // const membership: Membership = yield select(getMembership)
    const props: { patientId: string; attachmentsLimit?: number; skipAttachments?: number } = action.payload;
    const dep: DependenciesType = yield getDependencies();
    const res: Attachment[] = yield call(
      dep.patientConsultationsInteractor.loadAttachments,
      props.patientId,
      props.attachmentsLimit,
      props.skipAttachments,
    );
    yield put(loadAttachmentsSuccess(res));
  } catch (error) {
    yield put(loadAttachmentsFailure(error));
  }
}

function* insertAttachmentsSaga(action: Action<Attachment>) {
  try {
    const attachment: Attachment = action.payload;
    // const membership: Membership = yield select(getMembership)
    const dep: DependenciesType = yield getDependencies();
    const res: Attachment = yield call(dep.patientConsultationsInteractor.insertAttachment, attachment);
    yield put(insertAttachmentSuccess(res));
  } catch (error) {
    yield put(insertAttachmentFailure(error));
  }
}

function* loadPatientsConsultationsSaga(
  action: Action<{ patientId?: string; consultationsLimit?: number; skipConsultations?: number }>,
) {
  try {
    const props: { patientId?: string; consultationsLimit?: number; skipConsultations?: number } = action.payload;
    // const membership: Membership = yield select(getMembership)
    const dep: DependenciesType = yield getDependencies();
    const res: PatientConsultation[] = yield call(
      dep.patientConsultationsInteractor.loadPatientsConsultations,
      props.patientId,
      props.consultationsLimit,
      props.skipConsultations,
    );
    yield put(loadPatientsConsultationsSuccess(res));
  } catch (error) {
    yield put(loadPatientsConsultationsFailure(error));
  }
}

function* loadPatientConsultationSaga(action: Action<string>) {
  try {
    const consultationId: string = action.payload;
    const dep: DependenciesType = yield getDependencies();
    const res: PatientConsultation = yield call(
      dep.patientConsultationsInteractor.loadPatientConsultation,
      consultationId,
    );
    yield put(loadPatientConsultationSuccess(res));
  } catch (error) {
    yield put(loadPatientConsultationFailure(error));
  }
}

function* fetchPatientsConsultationsSaga(action: Action<string>) {
  try {
    const value: string = action.payload;
    const dep: DependenciesType = yield getDependencies();
    const res: PatientConsultation[] = yield call(dep.patientConsultationsInteractor.fetchPatientsConsultations, value);
    yield put(fetchPatientsConsultationsSuccess(res));
  } catch (error) {
    yield put(fetchPatientsConsultationsFailure(error));
  }
}

function* getTodaysConsultationsStatsSaga() {
  try {
    const dep: DependenciesType = yield getDependencies();
    const res: TodaysConsultationsStats = yield call(dep.patientConsultationsInteractor.getTodaysConsultationsStats);
    yield put(getTodaysConsultationsStatsSuccess(res));
  } catch (error) {
    yield put(getTodaysConsultationsStatsFailure(error));
  }
}

function* getConsultationNumberSaga() {
  try {
    const dep: DependenciesType = yield getDependencies();
    const res: number = yield call(dep.patientConsultationsInteractor.getPatientConsultationNumber);
    yield put(getConsultationNumberSuccess(res));
  } catch (error) {
    yield put(getConsultationNumberFailure(error));
  }
}

function* insertPatientsConsultationsSaga(action: Action<PatientConsultation>) {
  try {
    const patientConsultation: PatientConsultation = action.payload;
    // const membership: Membership = yield select(getMembership)
    const dep: DependenciesType = yield getDependencies();
    const res: PatientConsultation = yield call(
      dep.patientConsultationsInteractor.insertPatientsConsultation,
      patientConsultation,
    );
    yield put(insertPatientsConsultationSuccess(res));
  } catch (error) {
    yield put(insertPatientsConsultationFailure(error));
  }
}

function* loadConsultationReasonSaga(action: Action<string>) {
  try {
    const consultationId: string = action.payload;
    const dep: DependenciesType = yield getDependencies();
    const res: ConsultationReason = yield call(
      dep.patientConsultationsInteractor.loadConsultationReason,
      consultationId,
    );
    yield put(loadConsultationReasonSuccess(res));
  } catch (error) {
    yield put(loadConsultationReasonFailure(error));
  }
}

function* insertConsultationReasonSaga(action: Action<ConsultationReason>) {
  try {
    const consultationReason: ConsultationReason = action.payload;
    const dep: DependenciesType = yield getDependencies();
    const res: ConsultationReason = yield call(
      dep.patientConsultationsInteractor.insertConsultationReason,
      consultationReason,
    );
    yield put(insertConsultationReasonSuccess(res));
  } catch (error) {
    yield put(insertConsultationReasonFailure(error));
  }
}

function* loadInterrogationSaga(action: Action<string>) {
  try {
    const consultationId: string = action.payload;
    const dep: DependenciesType = yield getDependencies();
    const res: Interrogation = yield call(dep.patientConsultationsInteractor.loadInterrogation, consultationId);
    yield put(loadInterrogationSuccess(res));
  } catch (error) {
    yield put(loadInterrogationFailure(error));
  }
}

function* insertInterrogationSaga(action: Action<Interrogation>) {
  try {
    const interrogation: Interrogation = action.payload;
    const dep: DependenciesType = yield getDependencies();
    const res: Interrogation = yield call(dep.patientConsultationsInteractor.insertInterrogation, interrogation);
    yield put(insertInterrogationSuccess(res));
  } catch (error) {
    yield put(insertInterrogationFailure(error));
  }
}

function* loadConsultationNotesSaga(action: Action<string>) {
  try {
    const consultationId: string = action.payload;
    const dep: DependenciesType = yield getDependencies();
    const res: ConsultationNotes = yield call(dep.patientConsultationsInteractor.loadConsultationNotes, consultationId);
    yield put(loadConsultationNotesSuccess(res));
  } catch (error) {
    yield put(loadConsultationNotesFailure(error));
  }
}

function* insertConsultationNotesSaga(action: Action<ConsultationNotes>) {
  try {
    const consultationNotes: ConsultationNotes = action.payload;
    const dep: DependenciesType = yield getDependencies();
    const res: ConsultationNotes = yield call(
      dep.patientConsultationsInteractor.insertConsultationNotes,
      consultationNotes,
    );
    yield put(insertConsultationNotesSuccess(res));
  } catch (error) {
    yield put(insertConsultationNotesFailure(error));
  }
}

function* loadMesuresSaga(action: Action<string>) {
  try {
    const consultationId: string = action.payload;
    const dep: DependenciesType = yield getDependencies();
    const res: Mesures = yield call(dep.patientConsultationsInteractor.loadMesures, consultationId);
    yield put(loadMesuresSuccess(res));
  } catch (error) {
    yield put(loadMesuresFailure(error));
  }
}

function* insertMesuresSaga(action: Action<Mesures>) {
  try {
    const mesures: Mesures = action.payload;
    const dep: DependenciesType = yield getDependencies();
    const res: Mesures = yield call(dep.patientConsultationsInteractor.insertMesures, mesures);
    yield put(insertMesuresSuccess(res));
  } catch (error) {
    yield put(insertMesuresFailure(error));
  }
}

function* loadExaminationsSaga(action: Action<string>) {
  try {
    // const membership: Membership = yield select(getMembership)
    const consultationId: string = action.payload;
    const dep: DependenciesType = yield getDependencies();
    const res: Examination[] = yield call(dep.patientConsultationsInteractor.loadExaminations, consultationId);
    yield put(loadExaminationsSuccess(res));
  } catch (error) {
    yield put(loadExaminationsFailure(error));
  }
}

function* insertExaminationsSaga(
  action: Action<{ patientId: string; consultationId: string; examinations: Examination[] }>,
) {
  try {
    // const membership: Membership = yield select(getMembership)
    const props: { patientId: string; consultationId: string; examinations: Examination[] } = action.payload;
    const dep: DependenciesType = yield getDependencies();
    const res: boolean = yield call(
      dep.patientConsultationsInteractor.insertExaminations,
      props.patientId,
      props.consultationId,
      props.examinations,
    );
    yield put(insertExaminationsSuccess(res));
  } catch (error) {
    yield put(insertExaminationsFailure(error));
  }
}

function* sendAttachmentByMailSaga(action: Action<{ mail: string; fileName: string; fileContent: string }>) {
  try {
    // const membership: Membership = yield select(getMembership)
    const props: { mail: string; fileName: string; fileContent: string } = action.payload;
    const dep: DependenciesType = yield getDependencies();
    const res: boolean = yield call(
      dep.patientConsultationsInteractor.sendAttachmentByMail,
      props.mail,
      props.fileName,
      props.fileContent,
    );
    yield put(sendAttachmentByMailSuccess(res));
  } catch (error) {
    yield put(sendAttachmentByMailFailure(error));
  }
}

function* loadExaminationsToRequestSaga(action: Action<string>) {
  try {
    // const membership: Membership = yield select(getMembership)
    const consultationId: string = action.payload;
    const dep: DependenciesType = yield getDependencies();
    const res: ExaminationToRequest[] = yield call(
      dep.patientConsultationsInteractor.loadExaminationsToRequest,
      consultationId,
    );
    yield put(loadExaminationsToRequestSuccess(res));
  } catch (error) {
    yield put(loadExaminationsToRequestFailure(error));
  }
}

function* insertExaminationsToRequestSaga(
  action: Action<{ consultationId: string; examinationsToRequest: ExaminationToRequest[] }>,
) {
  try {
    // const membership: Membership = yield select(getMembership)
    const props: { consultationId: string; examinationsToRequest: ExaminationToRequest[] } = action.payload;
    const dep: DependenciesType = yield getDependencies();
    const res: boolean = yield call(
      dep.patientConsultationsInteractor.insertExaminationsToRequest,
      props.consultationId,
      props.examinationsToRequest,
    );
    yield put(insertExaminationsToRequestSuccess(res));
  } catch (error) {
    yield put(insertExaminationsToRequestFailure(error));
  }
}

function* loadPrescriptionsSaga(action: Action<string>) {
  try {
    // const membership: Membership = yield select(getMembership)
    const consultationId: string = action.payload;
    const dep: DependenciesType = yield getDependencies();
    const res: Prescription[] = yield call(dep.patientConsultationsInteractor.loadPrescriptions, consultationId);
    yield put(loadPrescriptionsSuccess(res));
  } catch (error) {
    yield put(loadPrescriptionsFailure(error));
  }
}

function* insertPrescriptionsSaga(action: Action<{ consultationId: string; prescriptions: Prescription[] }>) {
  try {
    // const membership: Membership = yield select(getMembership)
    const props: { consultationId: string; prescriptions: Prescription[] } = action.payload;
    const dep: DependenciesType = yield getDependencies();
    const res: boolean = yield call(
      dep.patientConsultationsInteractor.insertPrescriptions,
      props.consultationId,
      props.prescriptions,
    );
    yield put(insertPrescriptionsSuccess(res));
  } catch (error) {
    yield put(insertPrescriptionsFailure(error));
  }
}

function* fetchMedicinesSaga(action: Action<{ value: string; composition: string }>) {
  try {
    const props: { value: string; composition: string } = action.payload;
    const dep: DependenciesType = yield getDependencies();
    const res: Medicine[] = yield call(
      dep.patientConsultationsInteractor.fetchMedicines,
      props.value,
      props.composition,
    );
    yield put(fetchMedicinesSuccess(res));
  } catch (error) {
    yield put(fetchMedicinesFailure(error));
  }
}

function* loadMedicalCertificateSaga(action: Action<string>) {
  try {
    const consultationId: string = action.payload;
    const dep: DependenciesType = yield getDependencies();
    const res: MedicalCertificate = yield call(
      dep.patientConsultationsInteractor.loadMedicalCertificate,
      consultationId,
    );
    yield put(loadMedicalCertificateSuccess(res));
  } catch (error) {
    yield put(loadMedicalCertificateFailure(error));
  }
}

function* insertMedicalCertificateSaga(action: Action<MedicalCertificate>) {
  try {
    const medicalCertificate: MedicalCertificate = action.payload;
    const dep: DependenciesType = yield getDependencies();
    const res: MedicalCertificate = yield call(
      dep.patientConsultationsInteractor.insertMedicalCertificate,
      medicalCertificate,
    );
    yield put(insertMedicalCertificateSuccess(res));
  } catch (error) {
    yield put(insertMedicalCertificateFailure(error));
  }
}

function* deletePatientConsultationSaga(action: Action<string>) {
  try {
    const lookId: string = action.payload;
    // const membership: Membership = yield select(getMembership)
    const dep: DependenciesType = yield getDependencies();
    const res: PatientConsultation = yield call(dep.patientConsultationsInteractor.deletePatientConsultation, lookId);
    yield put(deletePatientConsultationSuccess(res));
  } catch (error) {
    yield put(deletePatientConsultationFailure(error));
  }
}

export function* consultationsSagas() {
  yield all([
    takeLatest(loadAttachmentsRequest, loadAttachmentsSaga),
    takeLatest(insertAttachmentRequest, insertAttachmentsSaga),
    takeLatest(loadPatientsConsultationsRequest, loadPatientsConsultationsSaga),
    takeLatest(loadPatientConsultationRequest, loadPatientConsultationSaga),
    takeLatest(fetchPatientsConsultationsRequest, fetchPatientsConsultationsSaga),
    takeLatest(getTodaysConsultationsStatsRequest, getTodaysConsultationsStatsSaga),
    takeLatest(getConsultationNumberRequest, getConsultationNumberSaga),
    takeLatest(insertPatientsConsultationRequest, insertPatientsConsultationsSaga),
    takeLatest(loadConsultationReasonRequest, loadConsultationReasonSaga),
    takeLatest(insertConsultationReasonRequest, insertConsultationReasonSaga),
    takeLatest(loadInterrogationRequest, loadInterrogationSaga),
    takeLatest(insertInterrogationRequest, insertInterrogationSaga),
    takeLatest(loadConsultationNotesRequest, loadConsultationNotesSaga),
    takeLatest(insertConsultationNotesRequest, insertConsultationNotesSaga),
    takeLatest(loadMesuresRequest, loadMesuresSaga),
    takeLatest(insertMesuresRequest, insertMesuresSaga),
    takeLatest(loadExaminationsRequest, loadExaminationsSaga),
    takeLatest(insertExaminationsRequest, insertExaminationsSaga),
    takeLatest(sendAttachmentByMailRequest, sendAttachmentByMailSaga),
    takeLatest(loadExaminationsToRequestRequest, loadExaminationsToRequestSaga),
    takeLatest(insertExaminationsToRequestRequest, insertExaminationsToRequestSaga),
    takeLatest(loadPrescriptionsRequest, loadPrescriptionsSaga),
    takeLatest(insertPrescriptionsRequest, insertPrescriptionsSaga),
    takeLatest(fetchMedicinesRequest, fetchMedicinesSaga),
    takeLatest(loadMedicalCertificateRequest, loadMedicalCertificateSaga),
    takeLatest(insertMedicalCertificateRequest, insertMedicalCertificateSaga),
    takeLatest(deletePatientConsultationRequest, deletePatientConsultationSaga),
  ]);
}
