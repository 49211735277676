import { applyMiddleware, createStore } from 'redux';
import { persistStore } from 'redux-persist';
import { dispatchAsyncMiddleware } from 'react-redux-dispatch-async';
import { createBrowserHistory } from 'history';
import { routerMiddleware } from 'connected-react-router';
import { sagas } from './sagas';
import { logger } from './middleware/logger';
import { sagaMiddleware } from './middleware/sagaMiddleware';
import { createRootReducer } from './reducers';

export const history = createBrowserHistory();

export const configureStore = () => {
  const store = createStore(
    createRootReducer(history),
    applyMiddleware(
      routerMiddleware(history),
      sagaMiddleware,
      logger,
      dispatchAsyncMiddleware({
        request: 'REQUEST',
        success: 'SUCCESS',
        failure: 'FAILURE',
      }),
    ),
  );

  let sagaTask = sagaMiddleware.run(sagas);
  if (module.hot) {
    // Enable Fast-Refresh hot module replacement for reducers
    module.hot.accept('./reducers', () => {
      const nextRootReducer = require('./reducers');
      store.replaceReducer(nextRootReducer);
    });
    module.hot.accept('./sagas', () => {
      const getNewSagas = require('./sagas');
      sagaTask.cancel(); // TODO: also channel?
      sagaTask.toPromise().then(() => {
        sagaTask = sagaMiddleware.run(getNewSagas);
      });
    });
  }

  const persistor = persistStore(store);
  return { store, persistor };
};

export const appStore = configureStore();
