import * as React from 'react';
import { Color } from '../styles/constantStyles';

export interface ToBottomArrow2Props {
  color?: string;
  style?: React.CSSProperties;
  width?: string | number;
  height?: string | number;
}

export const ToBottomArrow2 = ({ color, width, height, style }: ToBottomArrow2Props) => (
  <svg style={style} width={width} height={height} viewBox="0 0 14 12" xmlns="http://www.w3.org/2000/svg">
    <path d="M7 12L0.0717973 -6.02284e-07L13.9282 -1.81365e-06L7 12Z" fill={color || Color.MONOGREY3} />
  </svg>
);
