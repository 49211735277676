import { Action } from 'redux-actions';
import {
  loadAllPatientsSuccess,
  loadNotesSuccess,
  loadTreatmentsAntecedentsSuccess,
  updateNotesSuccess,
  updateTreatmentsAntecedentsSuccess,
} from './Patients.actions';
import { createTypedHandler, handleTypedActions } from '../../common/usecases/helpers/actions.helper';
import { PatientsState } from '../configuration/Patients.state';
import { Notes } from '../domain/entities/Notes';
import { Patient } from '../domain/entities/Patient';
import { TreatmentsAntecedents } from '../domain/entities/TreatmentsAntecedents';

const patientState: PatientsState = {
  patients: [],
  notes: undefined,
  treatmentsAntecedents: undefined,
};

const loadAllPatientsReducer = (state: PatientsState, action: Action<Patient[]>) => ({
  ...state,
  patients: action.payload,
});

const loadNotesReducer = (state: PatientsState, action: Action<Notes | undefined>) => ({
  ...state,
  notes: action.payload,
});

const updateNotesReducer = (state: PatientsState, action: Action<Notes | undefined>) => ({
  ...state,
  notes: action.payload,
});

const loadTreatmentsAntecedentsReducer = (state: PatientsState, action: Action<TreatmentsAntecedents | undefined>) => ({
  ...state,
  treatmentsAntecedents: action.payload,
});

const updateTreatmentsAntecedentsReducer = (
  state: PatientsState,
  action: Action<TreatmentsAntecedents | undefined>,
) => ({
  ...state,
  treatmentsAntecedents: action.payload,
});

export const patientsReducer = handleTypedActions(
  [
    createTypedHandler(loadAllPatientsSuccess, loadAllPatientsReducer),
    createTypedHandler(loadNotesSuccess, loadNotesReducer),
    createTypedHandler(updateNotesSuccess, updateNotesReducer),
    createTypedHandler(loadTreatmentsAntecedentsSuccess, loadTreatmentsAntecedentsReducer),
    createTypedHandler(updateTreatmentsAntecedentsSuccess, updateTreatmentsAntecedentsReducer),
    // createTypedHandler(loadSSSuccess, loadSSReducer),
  ],
  patientState,
);
