import * as React from 'react';
import { Color } from '../styles/constantStyles';

export interface CalendarIconProps {
  color?: string;
  style?: React.CSSProperties;
  width?: string | number;
  height?: string | number;
}

export const CalendarIcon = ({ color, width, height, style }: CalendarIconProps) => (
  <svg style={style} width={width} height={height} viewBox="0 0 27 30" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M1.75098 12.8534C1.20022 12.8534 0.753739 13.2999 0.753739 13.8506C0.753739 14.4014 1.20022 14.8479 1.75098 14.8479V12.8534ZM19.2026 1.88379C19.2026 1.33303 18.7561 0.886552 18.2054 0.886552C17.6546 0.886552 17.2082 1.33303 17.2082 1.88379H19.2026ZM17.2082 7.86721C17.2082 8.41797 17.6546 8.86445 18.2054 8.86445C18.7561 8.86445 19.2026 8.41797 19.2026 7.86721H17.2082ZM10.2275 1.88379C10.2275 1.33303 9.78101 0.886552 9.23026 0.886552C8.6795 0.886552 8.23302 1.33303 8.23302 1.88379H10.2275ZM8.23302 7.86721C8.23302 8.41797 8.6795 8.86445 9.23026 8.86445C9.78101 8.86445 10.2275 8.41797 10.2275 7.86721H8.23302ZM25.6847 12.8534H1.75098V14.8479H25.6847V12.8534ZM2.74821 25.8175V7.86721H0.753739V25.8175H2.74821ZM4.74269 27.812C3.64117 27.812 2.74821 26.919 2.74821 25.8175H0.753739C0.753739 28.0205 2.53965 29.8064 4.74269 29.8064V27.812ZM4.74269 3.87826C2.53965 3.87826 0.753739 5.66418 0.753739 7.86721H2.74821C2.74821 6.76569 3.64117 5.87274 4.74269 5.87274V3.87826ZM26.6819 25.8175V13.8506H24.6874V25.8175H26.6819ZM26.6819 13.8506V7.86721H24.6874V13.8506H26.6819ZM22.693 29.8064C24.896 29.8064 26.6819 28.0205 26.6819 25.8175H24.6874C24.6874 26.919 23.7945 27.812 22.693 27.812V29.8064ZM22.693 5.87274C23.7945 5.87274 24.6874 6.76569 24.6874 7.86721H26.6819C26.6819 5.66418 24.896 3.87826 22.693 3.87826V5.87274ZM22.693 3.87826H4.74269V5.87274H22.693V3.87826ZM22.693 27.812H4.74269V29.8064H22.693V27.812ZM17.2082 1.88379V7.86721H19.2026V1.88379H17.2082ZM8.23302 1.88379V7.86721H10.2275V1.88379H8.23302Z"
      fill={color || Color.MONOGREY3}
    />
  </svg>
);
