import React, { useCallback, useEffect, useRef, useState } from 'react';
import { DispatchAsyncResult } from 'react-redux-dispatch-async/lib/dispatchAsync';
import { DocColor, StyleSheet } from '../../../../assets/styles/constantStyles';
import { BellIcon } from '../../../../assets/svg/BellIcon';
import { CalendarAddPatientIcon } from '../../../../assets/svg/CalendarAddPatientIcon';
import { CalendarOptionsIcon } from '../../../../assets/svg/CalendarOptionsIcon';
import { CloseIcon } from '../../../../assets/svg/CloseIcon';
import { DownLoadIcon } from '../../../../assets/svg/DownLoadIcon';
import { PenIcon } from '../../../../assets/svg/PenIcon';
import { PlayIcon } from '../../../../assets/svg/Playlcon';
import { TrashIcon } from '../../../../assets/svg/TrashIcon';
import { User } from '../../../../authContext/domain/entities/User';
import { useNotificationContext } from '../../../../common/configuration/notificationContextProvider';
import { ButtonWithIcon } from '../../../../common/ui/ButtonWithIcon';
import { ConfirmOverlay } from '../../../../common/ui/ConfirmOverlay';
import { CustomInput } from '../../../../common/ui/CustomInput';
import { CustomSelector } from '../../../../common/ui/CustomSelector';
import CommonFunctions from '../../../../common/usecases/helpers/CommonFunctions';
import { Rpx } from '../../../../common/usecases/helpers/DimensionsConverter';
import { NavigationPayload } from '../../../../navigationContext/domain/entities/NavigationPayload';
import { CalendarEvent } from '../../../domain/entities/CalendarEvent';

export interface EventAdditionModificationFragmentProps {
  stateConnectedUser: User | undefined;
  data?: CalendarEvent;
  loadPatientsNamesAsync: () => Promise<DispatchAsyncResult>;
  loadCalendarEventsAsync: () => Promise<DispatchAsyncResult>;
  insertCalendarEventAsync: (calendarEvent: CalendarEvent) => Promise<DispatchAsyncResult>;
  updateCalendarEventAsync: (calendarEvent: CalendarEvent) => Promise<DispatchAsyncResult>;
  deleteCalendarEventAsync: (lookId: string) => Promise<DispatchAsyncResult>;
  loadProgramedAppointmentsAsync: (start: string, end: string) => Promise<DispatchAsyncResult>;
  fragmentTask: 'add' | 'edit';
  isClosedState: boolean;
  setIsClosedState: React.Dispatch<React.SetStateAction<boolean>>;
  navigateToAsync: (p: NavigationPayload) => void;
}
export const EventAdditionModificationFragment = ({
  stateConnectedUser,
  data,
  loadPatientsNamesAsync,
  loadCalendarEventsAsync,
  insertCalendarEventAsync,
  updateCalendarEventAsync,
  deleteCalendarEventAsync,
  loadProgramedAppointmentsAsync,
  fragmentTask,
  isClosedState,
  setIsClosedState,
  navigateToAsync,
}: EventAdditionModificationFragmentProps) => {
  const notification = useNotificationContext();
  const startDateRef = useRef<HTMLInputElement>(null);
  const hoursInputRef = useRef<HTMLInputElement>(null);
  const minutesInputRef = useRef<HTMLInputElement>(null);
  const endDateRef = useRef<HTMLInputElement>(null);
  const [dataState, setDataState] = useState<CalendarEvent>();
  const [isConfirmFragmentClosed, setIsConfirmFragmentClosed] = useState<boolean>(true);
  const [inputOptionsState, setInputOptionsState] = useState<{ id: string; value: string }[]>([]);
  const [initRender, setInitRender] = useState<boolean>(true);
  const [programedAppointments, setProgramedAppointments] = useState<CalendarEvent[]>([]);

  useEffect(() => {
    document.addEventListener('keydown', escFunction, false);
    if (data?.start && data?.end && new Date(data?.start).getDate() === new Date(data?.end).getDate()) {
      setDataState({ ...data });
    } else {
      setDataState({
        ...data,
        end: data?.start ? new Date(data?.start).toLocaleString('en-US', { hourCycle: 'h23' }) : undefined,
      });
    }
    loadPatientsNamesAsync().then((res) => {
      if (res.success) {
        res.result.forEach((patient: { patientId: string; fullName: string }) => {
          setInputOptionsState((prevData) => [...prevData, { id: patient.patientId, value: patient.fullName }]);
        });
      }
    });
    return () => {
      document.removeEventListener('keydown', escFunction, false);
    };
  }, []);

  useEffect(() => {
    if (dataState) {
      if (dataState.start && dataState.end) {
        loadProgramedAppointmentsAsync(dataState.start, dataState.end).then((res) => {
          if (res.success) {
            setProgramedAppointments([...res.result]);
          }
        });
      }
    }
  }, [dataState]);

  const escFunction = useCallback((event) => {
    if (event.key === 'Escape') {
      setIsClosedState(true);
    }
  }, []);

  const navigateToConsultationHandler = (
    patientId: string | undefined,
    temporaryPatientName: string | undefined,
    consultationReason: string | undefined,
  ) => {
    if (patientId) {
      navigateToAsync({ routeName: 'Consultation Addition', params: { patientId, consultationReason } });
    } else {
      navigateToAsync({
        routeName: 'Consultation Addition',
        params: { patientId: 'temporaryPatient', temporaryPatientName, consultationReason },
      });
    }
  };

  const onSaveClickHandler = () => {
    if (dataState) {
      if (fragmentTask === 'add') {
        insertCalendarEventAsync(dataState).then((res) => {
          if (res.success && res.result) {
            notification.setOnSuccess({
              message: `L'évenement a été ajouté avec succès!`,
            });
            setIsClosedState(true);
            loadCalendarEventsAsync();
          }
        });
      } else {
        updateCalendarEventAsync(dataState).then((res) => {
          if (res.success && res.result) {
            notification.setOnSuccess({
              message: `L'évenement a été modifié avec succès!`,
            });
            setIsClosedState(true);
            loadCalendarEventsAsync();
          }
        });
      }
    }
  };

  const onDeleteClickHandler = () => {
    if (dataState && dataState.id) {
      deleteCalendarEventAsync(dataState.id.toString()).then((res) => {
        if (res.success && res.result) {
          notification.setOnSuccess({
            message: `L'évenement a été supprimé avec succès!`,
          });
          setIsClosedState(true);
          loadCalendarEventsAsync();
        }
      });
    }
  };

  const onConfirmClick = () => {
    onDeleteClickHandler();
    setIsConfirmFragmentClosed(true);
  };

  return (
    <div
      style={{
        ...styles.container,
        display: isClosedState ? 'none' : '',
      }}
    >
      <div
        style={{ overflow: 'auto', minHeight: '100%' }}
        onClick={(e) => {
          if (e.target !== e.currentTarget) {
            return;
          }
          setIsClosedState(true);
        }}
        onKeyDown={(e) => {
          if (e.target !== e.currentTarget) {
            return;
          }
          setIsClosedState(true);
        }}
        tabIndex={0}
        role="button"
      >
        <ConfirmOverlay
          onConfirmClick={onConfirmClick}
          message="Êtes-vous sûr de bien vouloir supprimer le rendez-vous ?"
          isClosedState={isConfirmFragmentClosed}
          setIsClosedState={setIsConfirmFragmentClosed}
        />
        <div style={styles.eventContainer}>
          <div style={styles.titleDiv}>
            {fragmentTask === 'add' ? (
              <BellIcon width={Rpx(35, true)} height={Rpx(35, true)} color="white" />
            ) : (
              <PenIcon width={Rpx(35, true)} height={Rpx(35, true)} color="white" />
            )}

            <p style={{ ...styles.title, fontSize: Rpx(28, true) }}>{`${
              fragmentTask === 'add' ? 'Nouveau' : 'Modifier'
            } rendez-vous`}</p>
          </div>
          <div
            style={styles.closeButton}
            onKeyDown={() => {
              setIsClosedState(true);
            }}
            tabIndex={0}
            role="button"
            onClick={() => {
              setIsClosedState(true);
            }}
          >
            <CloseIcon color={DocColor.DARKBLUE} />
          </div>
          {stateConnectedUser?.role === 'DOCTOR' && (
            <div
              style={{ ...styles.startUpConsultationButton, height: Rpx(50, true) }}
              onKeyDown={() =>
                navigateToConsultationHandler(dataState?.patientId, dataState?.fullName, dataState?.consultationReason)
              }
              tabIndex={0}
              role="button"
              onClick={() =>
                navigateToConsultationHandler(dataState?.patientId, dataState?.fullName, dataState?.consultationReason)
              }
            >
              <PlayIcon
                style={{ margin: `auto 0 auto 0` }}
                color="white"
                width={Rpx(28, true)}
                height={Rpx(28, true)}
              />
              <p style={{ ...styles.startUpConsultationButtonParagraph, fontSize: Rpx(25, true) }}>
                Démarrer la consultation
              </p>
            </div>
          )}

          <div style={{ marginTop: Rpx(20) }}>
            <p style={{ ...styles.RDVTypeParagraph, fontSize: Rpx(24, true) }}>Type de RDV</p>
            <CustomSelector
              onChange={(value) => {
                setDataState((prevData) => ({
                  ...prevData,
                  RDVType: value === 'Consultation Patient' ? 'consultation' : 'control',
                }));
              }}
              OnMistake={!dataState?.RDVType && !initRender}
              value={
                dataState?.RDVType === 'consultation'
                  ? 'Consultation Patient'
                  : dataState?.RDVType === 'control'
                  ? 'Contrôle Patient'
                  : ''
              }
              optionsArray={['Consultation Patient', 'Contrôle Patient']}
              inputName=""
              inputNameWidth={0}
              style={{ borderRadius: Rpx(5), marginTop: Rpx(10), fontSize: Rpx(20, true) }}
              optionsColor={DocColor.MEDIUMBLUE}
              optionsSize={20}
              placeholder="Choisir type de RDV"
              width="100%"
              height={Rpx(45)}
            />
          </div>

          <div style={{ marginTop: Rpx(20) }}>
            <p style={{ ...styles.RDVTypeParagraph, fontSize: Rpx(24, true) }}>Motif de consultation</p>
            <CustomInput
              onChange={(value) => {
                setDataState((prevData) => ({
                  ...prevData,
                  consultationReason: value.target.value,
                }));
              }}
              defaultValue={data?.consultationReason}
              inputName=""
              inputNameWidth={0}
              style={{ borderRadius: Rpx(5), marginTop: Rpx(10), fontSize: Rpx(20, true) }}
              placeholder="Entrez le motif de consultation"
              width="100%"
              height={Rpx(45)}
            />
          </div>

          <div style={{ marginTop: Rpx(30) }}>
            <div style={{ ...styles.subTitleDiv, height: Rpx(55, true) }}>
              <CalendarOptionsIcon width={Rpx(30, true)} height={Rpx(30, true)} color="white" />
              <p style={{ ...styles.subTitleParagraph, fontSize: Rpx(28, true) }}>Options</p>
            </div>
            <div style={styles.subDiv}>
              <div style={{ flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
                <p style={{ ...styles.subParagraph, fontSize: Rpx(22, true) }}>Debut du RDV</p>
                <div style={{ position: 'relative', height: Rpx(40, true), width: '50%' }}>
                  <input
                    onWheel={(e) => (e.target as HTMLElement).blur()}
                    ref={startDateRef}
                    style={{
                      ...styles.dateInput,
                      height: 'inherit',
                      fontSize: Rpx(23, true),
                      borderColor:
                        dataState?.start === undefined && !initRender ? DocColor.LIGHTRED : DocColor.LIGHTGRAY,
                    }}
                    type="date"
                    defaultValue={dataState?.start && CommonFunctions.dateToDateInputValue(new Date(dataState?.start))}
                    onChange={(v) => {
                      if (dataState?.start) {
                        const startTempDate: Date = new Date(dataState?.start);
                        startTempDate?.setFullYear(new Date(v.target.value).getFullYear());
                        startTempDate?.setMonth(new Date(v.target.value).getMonth());
                        startTempDate?.setDate(new Date(v.target.value).getDate());
                        setDataState((prevData) => ({
                          ...prevData,
                          start: startTempDate.toLocaleString('en-US', { hourCycle: 'h23' }) || undefined,
                        }));
                      }

                      if (dataState?.end && endDateRef.current) {
                        const endTempDate: Date = new Date(dataState?.end);
                        endTempDate?.setFullYear(new Date(v.target.value).getFullYear());
                        endTempDate?.setMonth(new Date(v.target.value).getMonth());
                        endTempDate?.setDate(new Date(v.target.value).getDate());
                        endDateRef.current.value = CommonFunctions.dateToDateTimeInputValue(endTempDate);
                        setDataState((prevData) => ({
                          ...prevData,
                          end: endTempDate.toLocaleString('en-US', { hourCycle: 'h23' }) || undefined,
                        }));
                      }
                    }}
                  />
                </div>
              </div>

              <div
                style={{
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                  marginTop: Rpx(20, true),
                  alignItems: 'center',
                }}
              >
                <div style={{ flexDirection: 'row', height: Rpx(40, true), gap: Rpx(10), alignItems: 'center' }}>
                  <input
                    onWheel={(e) => (e.target as HTMLElement).blur()}
                    ref={hoursInputRef}
                    placeholder="0"
                    min="7"
                    max="19"
                    style={{
                      ...styles.timeInput,
                      height: 'inherit',
                      fontSize: Rpx(22, true),
                      borderColor:
                        dataState?.start === undefined && !initRender ? DocColor.LIGHTRED : DocColor.LIGHTGRAY,
                      color:
                        programedAppointments.length === 0 &&
                        dataState?.start &&
                        new Date(dataState?.start).getHours() > 6 &&
                        new Date(dataState?.start).getHours() < 20
                          ? DocColor.MEDIUMBLUE
                          : DocColor.MEDIUMRED,
                    }}
                    type="number"
                    defaultValue={dataState?.start && new Date(dataState?.start).getHours().toString()}
                    onChange={(v) => {
                      if (hoursInputRef.current) {
                        if (hoursInputRef.current.valueAsNumber < 0) {
                          hoursInputRef.current.valueAsNumber = 0;
                        } else if (hoursInputRef.current.valueAsNumber > 23) {
                          hoursInputRef.current.valueAsNumber = 23;
                        }
                      }
                      if (dataState?.start && dataState?.end && endDateRef.current) {
                        const startTempDate: Date = new Date(dataState?.start);
                        startTempDate?.setHours(Number.isNaN(v.target.valueAsNumber) ? 0 : v.target.valueAsNumber);
                        const endTempDate: Date = CommonFunctions.addMinutes(
                          startTempDate,
                          CommonFunctions.calculateDateDifferenceInMinutes(
                            new Date(dataState?.start),
                            new Date(dataState?.end),
                          ),
                        );
                        endDateRef.current.value = CommonFunctions.dateToDateTimeInputValue(endTempDate);
                        setDataState((prevData) => ({
                          ...prevData,
                          start: startTempDate.toLocaleString('en-US', { hourCycle: 'h23' }) || undefined,
                          end: endTempDate.toLocaleString('en-US', { hourCycle: 'h23' }) || undefined,
                        }));
                      }
                    }}
                  />
                  <p style={{ ...styles.subParagraph, fontSize: Rpx(22, true) }}>h</p>
                  <input
                    onWheel={(e) => (e.target as HTMLElement).blur()}
                    ref={minutesInputRef}
                    placeholder="0"
                    min="0"
                    max="59"
                    style={{
                      ...styles.timeInput,
                      height: 'inherit',
                      fontSize: Rpx(22, true),
                      borderColor:
                        dataState?.start === undefined && !initRender ? DocColor.LIGHTRED : DocColor.LIGHTGRAY,
                      color: programedAppointments.length === 0 ? DocColor.MEDIUMBLUE : DocColor.MEDIUMRED,
                    }}
                    type="number"
                    defaultValue={dataState?.start && new Date(dataState?.start).getMinutes().toString()}
                    onChange={(v) => {
                      if (minutesInputRef.current) {
                        if (minutesInputRef.current.valueAsNumber < 0) {
                          minutesInputRef.current.valueAsNumber = 0;
                        } else if (minutesInputRef.current.valueAsNumber > 59) {
                          minutesInputRef.current.valueAsNumber = 59;
                        }
                      }
                      if (dataState?.start && dataState?.end && endDateRef.current) {
                        const startTempDate: Date = new Date(dataState?.start);
                        startTempDate?.setMinutes(Number.isNaN(v.target.valueAsNumber) ? 0 : v.target.valueAsNumber);
                        const endTempDate: Date = CommonFunctions.addMinutes(
                          startTempDate,
                          CommonFunctions.calculateDateDifferenceInMinutes(
                            new Date(dataState?.start),
                            new Date(dataState?.end),
                          ),
                        );
                        endDateRef.current.value = CommonFunctions.dateToDateTimeInputValue(endTempDate);
                        setDataState((prevData) => ({
                          ...prevData,
                          start: startTempDate.toLocaleString('en-US', { hourCycle: 'h23' }) || undefined,
                          end: endTempDate.toLocaleString('en-US', { hourCycle: 'h23' }) || undefined,
                        }));
                      }
                    }}
                  />
                  <p style={{ ...styles.subParagraph, fontSize: Rpx(22, true) }}>min</p>
                </div>
                <div style={{ flexDirection: 'row', height: Rpx(40, true), gap: Rpx(15), alignItems: 'center' }}>
                  <p style={{ ...styles.subParagraph, fontSize: Rpx(22, true) }}>Durée</p>
                  <input
                    onWheel={(e) => (e.target as HTMLElement).blur()}
                    placeholder="0"
                    min="0"
                    max="780"
                    style={{
                      ...styles.timeInput,
                      height: 'inherit',
                      fontSize: Rpx(22, true),
                      width: Rpx(90),
                      borderColor:
                        dataState?.start === undefined && !initRender ? DocColor.LIGHTRED : DocColor.LIGHTGRAY,
                      color: programedAppointments.length === 0 ? DocColor.MEDIUMBLUE : DocColor.MEDIUMRED,
                    }}
                    type="number"
                    defaultValue={
                      dataState?.start &&
                      dataState?.end &&
                      CommonFunctions.calculateDateDifferenceInMinutes(
                        new Date(dataState?.start),
                        new Date(dataState?.end),
                      )
                    }
                    onChange={(v) => {
                      if (minutesInputRef.current) {
                        if (minutesInputRef.current.valueAsNumber < 0) {
                          minutesInputRef.current.valueAsNumber = 0;
                        } else if (minutesInputRef.current.valueAsNumber > 780) {
                          minutesInputRef.current.valueAsNumber = 780;
                        }
                      }
                      if (dataState?.start && endDateRef.current) {
                        const tempDate: Date = CommonFunctions.addMinutes(
                          new Date(dataState?.start),
                          Number.isNaN(v.target.valueAsNumber) ? 0 : v.target.valueAsNumber,
                        );
                        endDateRef.current.value = CommonFunctions.dateToDateTimeInputValue(tempDate);
                        setDataState((prevData) => ({
                          ...prevData,
                          end: tempDate.toLocaleString('en-US', { hourCycle: 'h23' }) || undefined,
                        }));
                      }
                    }}
                  />
                  <p style={{ ...styles.subParagraph, fontSize: Rpx(22, true) }}>min</p>
                </div>
              </div>

              <p style={{ ...styles.subParagraph, marginTop: Rpx(20, true), fontSize: Rpx(22, true) }}>Fin du RDV</p>
              <input
                ref={endDateRef}
                style={{
                  ...styles.dateInput,
                  height: Rpx(40, true),
                  fontSize: Rpx(23, true),
                  borderColor: dataState?.end === undefined && !initRender ? DocColor.LIGHTRED : DocColor.LIGHTGRAY,
                  color: programedAppointments.length === 0 ? DocColor.MEDIUMBLUE : DocColor.MEDIUMRED,
                }}
                type="datetime-local"
                defaultValue={dataState?.end && CommonFunctions.dateToDateTimeInputValue(new Date(dataState?.end))}
                onChange={(v) => {
                  setDataState((prevData) => ({
                    ...prevData,
                    end:
                      v.target.value !== ''
                        ? new Date(v.target.value).toLocaleString('en-US', { hourCycle: 'h23' })
                        : undefined,
                  }));
                  if (startDateRef.current && dataState?.start) {
                    const tempDate: Date = new Date(dataState?.start);
                    tempDate?.setFullYear(new Date(v.target.value).getFullYear());
                    tempDate?.setMonth(new Date(v.target.value).getMonth());
                    tempDate?.setDate(new Date(v.target.value).getDate());
                    startDateRef.current.value = CommonFunctions.dateToDateTimeInputValue(tempDate);
                  }
                }}
                disabled
              />
              {programedAppointments &&
                programedAppointments.map((appointment) => (
                  <p
                    key={appointment.id}
                    style={{
                      ...styles.programedAppointmentsParagraph,
                      fontSize: Rpx(23, true),
                      display: programedAppointments.length === 0 ? 'none' : '',
                    }}
                  >
                    • Vous avez déjà un rendez-vous programmé de{' '}
                    {appointment.start ? CommonFunctions.padTo2Digits(new Date(appointment.start).getHours()) : '-'}h
                    {appointment.start ? CommonFunctions.padTo2Digits(new Date(appointment.start).getMinutes()) : '-'} à
                    {appointment.end ? CommonFunctions.padTo2Digits(new Date(appointment.end).getHours()) : '-'}h
                    {appointment.end ? CommonFunctions.padTo2Digits(new Date(appointment.end).getMinutes()) : '-'} avec{' '}
                    {appointment.fullName}.
                  </p>
                ))}
            </div>
          </div>
          <div style={{ marginTop: Rpx(10) }}>
            <div style={{ ...styles.subTitleDiv, height: Rpx(55, true) }}>
              <CalendarAddPatientIcon width={Rpx(30, true)} height={Rpx(30, true)} color="white" />
              <p style={{ ...styles.subTitleParagraph, fontSize: Rpx(28, true) }}>Ajouter un Patient</p>
            </div>
            <div style={styles.subDiv}>
              <p style={{ ...styles.subParagraph, fontSize: Rpx(22, true) }}>{`Patient ${
                dataState?.patientId === '' || dataState?.patientId === null ? '(Nouveau)' : ''
              }`}</p>
              <CustomInput
                onOptionChange={(id, value) => {
                  setDataState((prevData) => ({
                    ...prevData,
                    patientId: id,
                    fullName: value,
                  }));
                }}
                OnMistake={(dataState?.fullName === undefined || dataState?.fullName === '') && !initRender}
                defaultValue={data?.fullName}
                style={styles.nameInput}
                options={[...inputOptionsState]}
                height={Rpx(50)}
              />
            </div>
          </div>
          <div style={{ flexDirection: 'row', justifyContent: 'space-between', marginTop: Rpx(35) }}>
            <ButtonWithIcon
              disabled={
                (!dataState?.RDVType ||
                  !dataState?.start ||
                  new Date(dataState?.start).getHours() < 7 ||
                  new Date(dataState?.start).getHours() > 19 ||
                  !dataState?.end ||
                  !dataState.fullName) &&
                !initRender
              }
              label="Enregistrer"
              style={{
                fontSize: Rpx(22, true),
                width: 'fit-content',
                height: Rpx(55, true),
                padding: `0 ${Rpx(15)}`,
                borderRadius: Rpx(5),
              }}
              icon={DownLoadIcon({
                width: Rpx(20, true),
                height: Rpx(20, true),
                color: 'white',
                style: { margin: `auto 0 auto ${Rpx(10)}` },
              })}
              onClick={() => {
                if (
                  dataState?.RDVType &&
                  dataState?.start &&
                  new Date(dataState?.start).getHours() > 6 &&
                  new Date(dataState?.start).getHours() < 20 &&
                  dataState?.end &&
                  dataState.fullName
                ) {
                  onSaveClickHandler();
                } else {
                  setInitRender(false);
                }
              }}
            />
            <ButtonWithIcon
              label={fragmentTask === 'add' ? 'Annuler' : 'Supprimer RDV'}
              style={{
                background: DocColor.DARKBLUE,
                fontSize: Rpx(22, true),
                width: 'fit-content',
                height: Rpx(55, true),
                padding: `0 ${Rpx(15)}`,
                borderRadius: Rpx(5),
              }}
              icon={
                fragmentTask === 'add'
                  ? CloseIcon({
                      width: Rpx(20, true),
                      height: Rpx(20, true),
                      color: 'white',
                      style: { margin: `auto 0 auto ${Rpx(10)}` },
                    })
                  : TrashIcon({
                      width: Rpx(20, true),
                      height: Rpx(20, true),
                      color: 'white',
                      style: { margin: `auto 0 auto ${Rpx(10)}` },
                    })
              }
              onClick={() => {
                if (fragmentTask === 'add') {
                  setIsClosedState(true);
                } else {
                  setIsConfirmFragmentClosed(false);
                }
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

const styles: StyleSheet = {
  container: {
    position: 'fixed',
    inset: 0,
    width: '100%',
    height: '100%',
    backgroundColor: DocColor.HALFTRANSPARENTBLACK,
    zIndex: 5,
  },
  eventContainer: {
    position: 'relative',
    height: 'auto',
    margin: 'auto',
    backgroundColor: 'white',
    minWidth: '30%',
    maxWidth: Rpx(650),
    width: 'fit-content',
    padding: `0 2.5% ${Rpx(30)} 2.5%`,
    outline: `${Rpx(8)} solid ${DocColor.DARKBLUE}`,
    borderRadius: Rpx(35),
  },
  titleDiv: {
    flexDirection: 'row',
    width: '80%',
    height: Rpx(65),
    borderRadius: `0 0 ${Rpx(22)} ${Rpx(22)}`,
    margin: `0 auto`,
    background: DocColor.DARKBLUE,
    justifyContent: 'center',
    alignItems: 'center',
    gap: Rpx(20),
  },
  title: {
    fontWeight: 600,
    color: 'white',
  },
  closeButton: {
    background: DocColor.AQUAHAZE,
    width: Rpx(20),
    height: Rpx(20),
    borderRadius: Rpx(7),
    padding: Rpx(10),
    margin: `${Rpx(15)} 0 0 auto`,
    cursor: 'pointer',
  },
  startUpConsultationButton: {
    width: 'fit-content',
    backgroundColor: DocColor.MEDIUMBLUE,
    color: 'white',
    borderRadius: Rpx(18),
    flexDirection: 'row',
    cursor: 'pointer',
    padding: `0 ${Rpx(30)}`,
    alignSelf: 'center',
  },
  startUpConsultationButtonParagraph: {
    margin: 'auto 0px auto 10px',
    fontWeight: 700,
  },
  RDVTypeParagraph: {
    marginLeft: Rpx(15),
    fontWeight: 700,
    color: DocColor.DARKBLUE,
  },
  subTitleDiv: {
    flexDirection: 'row',
    background: DocColor.DARKBLUE,
    borderRadius: `${Rpx(15)} ${Rpx(15)} 0 0`,
    justifyContent: 'center',
    alignItems: 'center',
    gap: Rpx(15),
  },
  subTitleParagraph: {
    fontWeight: 600,
    color: 'white',
  },
  subDiv: {
    background: DocColor.LIGHTGRAY,
    borderRadius: `0 0 ${Rpx(15)} ${Rpx(15)}`,
    padding: `${Rpx(25)} 10%`,
  },
  subParagraph: {
    color: DocColor.DARKBLUE,
    fontWeight: 600,
  },
  dateInput: {
    background: 'white',
    padding: `0 ${Rpx(30)}`,
    border: `${Rpx(4)} solid ${DocColor.LIGHTGRAY}`,
    borderRadius: Rpx(9),
    outline: 'none',
    caretColor: DocColor.DARKBLUE,
    color: DocColor.DARKBLUE,
    fontWeight: 500,
  },
  timeInput: {
    textAlign: 'center',
    fontWeight: 600,
    color: DocColor.MEDIUMBLUE,
    outline: 'none',
    border: `none`,
    borderRadius: Rpx(9),
    background: 'white',
    width: Rpx(70),
  },
  programedAppointmentsParagraph: {
    textAlign: 'center',
    color: DocColor.MEDIUMRED,
    marginTop: Rpx(20),
    fontWeight: 600,
  },
  nameInput: {
    background: 'white',
    fontSize: Rpx(20),
    fontWeight: 600,
    color: DocColor.MEDIUMBLUE,
  },
};
