import { createAsyncActions, createTypedAction } from '../../common/usecases/helpers/actions.helper';
import { NavigationPayload } from '../domain/entities/NavigationPayload';

export const navigateBackRequest = createTypedAction<void>('NAVIGATE_BACK');
// export const popToTop = createTypedAction<null>('POP_TO_TOP')

export const {
  request: navigateToRequest,
  success: navigateToSuccess,
  failure: navigateToFailure,
} = createAsyncActions<NavigationPayload, string, unknown>('NAVIGATE_TO');

export const {
  request: navigateReplaceRequest,
  success: navigateReplaceSuccess,
  failure: navigateReplaceFailure,
} = createAsyncActions<NavigationPayload, string, unknown>('NAVIGATE_REPLACE');
