import React, { useEffect, useState } from 'react';
import { useMediaQuery } from 'react-responsive';
import { DocColor, StyleSheet } from '../../../../assets/styles/constantStyles';
import { AddIcon } from '../../../../assets/svg/Addlcon';
import { bigScreenBreakpoint } from '../../../../common/domain/entities/ReactResponsiveBreakpoints';
import CommonFunctions from '../../../../common/usecases/helpers/CommonFunctions';
import { Rpx, SRpx } from '../../../../common/usecases/helpers/DimensionsConverter';

type TreatmentsAntecedentsRowProps = {
  dataListState: string[];
  title: string;
  titleWidth?: string | number;
  addButtonTitle: string;
  onArrayChange: (arrayList: string[]) => void;
  disabled?: boolean;
};

export const TreatmentsAntecedentsRow = ({
  dataListState,
  title,
  titleWidth,
  addButtonTitle,
  onArrayChange,
  disabled,
}: TreatmentsAntecedentsRowProps) => {
  // isBigScreen working as a media breakpoint
  const isBigScreen = useMediaQuery({ query: `(min-width: ${bigScreenBreakpoint}px)` });

  const [dataList, setdataList] = useState<string[]>(['']);
  const tempDataList = dataList;

  useEffect(() => {
    setdataList([...dataListState]);
  }, [dataListState]);

  const updateDataOnChange = (value: string, index: number) => {
    if (value === '' && index > 0) {
      tempDataList.splice(index, 1);
    } else {
      tempDataList[index] = value;
    }
    setdataList([...tempDataList]);
    onArrayChange([...tempDataList]);
  };

  return (
    <div style={isBigScreen ? { ...styles.rowDiv } : { ...styles.rowDiv, ...responsiveStyles.rowDiv }}>
      <div
        style={isBigScreen ? { height: Rpx(70, true), width: '20vw' } : { height: 'fit-content', width: 'fit-content' }}
      >
        <p
          style={
            isBigScreen
              ? { ...styles.TreatmentAntecedentTypeParagraph, fontSize: Rpx(25, true), width: titleWidth }
              : {
                  ...styles.TreatmentAntecedentTypeParagraph,
                  ...responsiveStyles.TreatmentAntecedentTypeParagraph,
                  fontSize: SRpx(12, true),
                  width: titleWidth,
                }
          }
        >
          {title}
        </p>
      </div>
      <div style={{ width: '100%' }}>
        {Array.from(Array(dataList.length), (v, i) => (
          <input
            key={i}
            style={
              isBigScreen
                ? { ...styles.input, height: Rpx(70, true), fontSize: Rpx(25, true) }
                : { ...styles.input, ...responsiveStyles.input, height: SRpx(25, true), fontSize: SRpx(12, true) }
            }
            disabled={disabled}
            value={dataList[i]}
            onChange={(e) => {
              updateDataOnChange(e.target.value, i);
            }}
          />
        ))}

        <div
          style={
            isBigScreen
              ? {
                  ...styles.addButton,
                  display: disabled || CommonFunctions.isEmptyOrSpaces(dataList[dataList.length - 1]) ? 'none' : '',
                }
              : {
                  ...styles.addButton,
                  ...responsiveStyles.addButton,
                  display: disabled || CommonFunctions.isEmptyOrSpaces(dataList[dataList.length - 1]) ? 'none' : '',
                }
          }
          onKeyDown={() => setdataList((objs) => [...objs, ''])}
          tabIndex={0}
          role="button"
          onClick={() => setdataList((objs) => [...objs, ''])}
        >
          <AddIcon
            width={isBigScreen ? Rpx(10, true) : SRpx(8, true)}
            height={isBigScreen ? Rpx(10, true) : SRpx(8, true)}
            color={DocColor.MEDIUMBLUE}
            style={isBigScreen ? { ...styles.buttonIcon } : { ...styles.buttonIcon, ...responsiveStyles.buttonIcon }}
          />
          <p style={{ ...styles.buttonParagraph, fontSize: isBigScreen ? Rpx(25, true) : SRpx(12, true) }}>
            {addButtonTitle}
          </p>
        </div>
      </div>
    </div>
  );
};

const styles: StyleSheet = {
  rowDiv: {
    flexDirection: 'row',
    marginTop: Rpx(35),
    marginRight: Rpx(50),
  },
  TreatmentAntecedentTypeParagraph: {
    fontWeight: 500,
    margin: `auto ${Rpx(20)} auto ${Rpx(40)}`,
    whiteSpace: 'nowrap',
  },
  input: {
    maxWidth: '100%',
    background: DocColor.LIGHTGRAY,
    border: `${Rpx(2)} solid ${DocColor.LIGHTGRAY}`,
    borderRadius: Rpx(9),
    outline: 'none',
    caretColor: DocColor.DARKBLUE,
    padding: `0 ${Rpx(20)}`,
    marginBottom: Rpx(20),
  },
  addButton: {
    flexDirection: 'row',
    margin: `${Rpx(-25)} 0 0 ${Rpx(60)}`,
    cursor: 'pointer',
    marginTop: 0,
  },
  buttonParagraph: {
    fontWeight: 500,
    fontStyle: 'italic',
    color: DocColor.MEDIUMBLUE,
  },
  buttonIcon: {
    outline: `${Rpx(2)} solid ${DocColor.MEDIUMBLUE}`,
    borderRadius: Rpx(20),
    padding: Rpx(7),
    margin: `auto ${Rpx(15)} auto 0`,
  },
};

const responsiveStyles: StyleSheet = {
  rowDiv: {
    flexDirection: 'column',
    marginRight: SRpx(5),
  },
  TreatmentAntecedentTypeParagraph: {
    margin: `0 0 ${SRpx(5)} 0`,
    fontWeight: 600,
  },
  input: {
    border: `${SRpx(2)} solid ${DocColor.LIGHTGRAY}`,
    borderRadius: SRpx(5),
    outline: 'none',
    caretColor: DocColor.DARKBLUE,
    padding: `0 ${SRpx(5)}`,
  },
  addButton: {
    margin: `${SRpx(0)} 0 0 ${SRpx(15)}`,
    gap: SRpx(5),
  },
  buttonIcon: {
    outline: `${SRpx(1)} solid ${DocColor.MEDIUMBLUE}`,
    borderRadius: SRpx(10),
    padding: SRpx(2),
    margin: `auto ${Rpx(8)} auto 0`,
  },
};
