import React, { useEffect, useState } from 'react';
import { DocColor, StyleSheet } from '../../../../assets/styles/constantStyles';
import { CustomInput } from '../../../../common/ui/CustomInput';
import { Rpx } from '../../../../common/usecases/helpers/DimensionsConverter';
import { ConsultationAdditionFilledDataType } from '../../../domain/entities/ConsultationAdditionFilledDataType';
import { hardCodedEmptyMesures, Mesures } from '../../../domain/entities/Mesures';

type MesuresComponentProps = {
  mesuresData: Mesures | undefined;
  isOnlyReadable?: boolean;
  setFilledData: React.Dispatch<React.SetStateAction<ConsultationAdditionFilledDataType>>;
};

export const MesuresComponent = ({ mesuresData, isOnlyReadable, setFilledData }: MesuresComponentProps) => {
  const [mesuresDataState, setMesuresDataState] = useState<Mesures>(hardCodedEmptyMesures);

  useEffect(() => {
    if (isOnlyReadable !== true) {
      setFilledData((prevState) => ({ ...prevState, mesures: mesuresDataState }));
    }
  }, [mesuresDataState]);

  return (
    <div style={styles.container}>
      <div style={styles.titleDiv}>
        <p key="firstHistoryDateDiv" style={{ ...styles.title, fontSize: Rpx(32, true) }}>
          Mesures
        </p>
      </div>
      <div style={styles.spacedRowDivs}>
        <CustomInput
          unit="degree"
          placeholder="0"
          disabled={isOnlyReadable}
          inputNameWidth={Rpx(240)}
          style={{ ...styles.customInput, height: Rpx(45, true), fontSize: Rpx(25, true) }}
          inputName="Température"
          width="50%"
          defaultValue={isOnlyReadable ? `${mesuresData?.temperature}` : ''}
          onChange={(v) => {
            setMesuresDataState((prevMesuresData) => ({
              ...prevMesuresData,
              temperature: !Number.isNaN(v.target.valueAsNumber) ? v.target.valueAsNumber : 0,
            }));
          }}
        />
        <CustomInput
          unit="bpm"
          placeholder="0"
          disabled={isOnlyReadable}
          inputNameWidth={Rpx(150)}
          style={{ ...styles.customInput, height: Rpx(45, true), fontSize: Rpx(25, true) }}
          inputName="Pouls"
          width="45%"
          defaultValue={isOnlyReadable ? `${mesuresData?.pulse}` : ''}
          onChange={(v) => {
            setMesuresDataState((prevMesuresData) => ({
              ...prevMesuresData,
              pulse: !Number.isNaN(v.target.valueAsNumber) ? v.target.valueAsNumber : 0,
            }));
          }}
        />
      </div>
      <div style={styles.spacedRowDivs}>
        <CustomInput
          unit="cmHg"
          placeholder="0"
          disabled={isOnlyReadable}
          inputNameWidth={Rpx(240)}
          style={{ ...styles.customInput, height: Rpx(45, true), fontSize: Rpx(25, true) }}
          inputName="Tension arterielle"
          width="50%"
          defaultValue={isOnlyReadable ? `${mesuresData?.bloodPressure}` : ''}
          onChange={(v) => {
            setMesuresDataState((prevMesuresData) => ({
              ...prevMesuresData,
              bloodPressure: !Number.isNaN(v.target.valueAsNumber) ? v.target.valueAsNumber : 0,
            }));
          }}
        />
        <CustomInput
          unit="SaO2"
          placeholder="0"
          disabled={isOnlyReadable}
          inputNameWidth={Rpx(150)}
          style={{ ...styles.customInput, height: Rpx(45, true), fontSize: Rpx(25, true) }}
          inputName="Saturation"
          width="45%"
          defaultValue={isOnlyReadable ? `${mesuresData?.saturation}` : ''}
          onChange={(v) => {
            setMesuresDataState((prevMesuresData) => ({
              ...prevMesuresData,
              saturation: !Number.isNaN(v.target.valueAsNumber) ? v.target.valueAsNumber : 0,
            }));
          }}
        />
      </div>
      <div style={styles.spacedRowDivs}>
        <CustomInput
          unit="mmol/L"
          placeholder="0"
          disabled={isOnlyReadable}
          inputNameWidth={Rpx(240)}
          style={{ ...styles.customInput, height: Rpx(45, true), fontSize: Rpx(25, true) }}
          inputName="Glycémie"
          width="50%"
          defaultValue={isOnlyReadable ? `${mesuresData?.bloodSugar}` : ''}
          onChange={(v) => {
            setMesuresDataState((prevMesuresData) => ({
              ...prevMesuresData,
              bloodSugar: !Number.isNaN(v.target.valueAsNumber) ? v.target.valueAsNumber : 0,
            }));
          }}
        />
        <CustomInput
          unit="kg/m²"
          placeholder="0"
          disabled={isOnlyReadable}
          inputNameWidth={Rpx(150)}
          style={{ ...styles.customInput, height: Rpx(45, true), fontSize: Rpx(25, true) }}
          inputName="IMC"
          width="45%"
          defaultValue={isOnlyReadable ? `${mesuresData?.imc}` : ''}
          onChange={(v) => {
            setMesuresDataState((prevMesuresData) => ({
              ...prevMesuresData,
              imc: !Number.isNaN(v.target.valueAsNumber) ? v.target.valueAsNumber : 0,
            }));
          }}
        />
      </div>
      <div style={styles.spacedRowDivs}>
        <CustomInput
          unit="KG"
          placeholder="0"
          disabled={isOnlyReadable}
          inputNameWidth={Rpx(240)}
          style={{ ...styles.customInput, height: Rpx(45, true), fontSize: Rpx(25, true) }}
          inputName="Poids"
          width="50%"
          defaultValue={isOnlyReadable ? `${mesuresData?.weight}` : ''}
          onChange={(v) => {
            setMesuresDataState((prevMesuresData) => ({
              ...prevMesuresData,
              weight: !Number.isNaN(v.target.valueAsNumber) ? v.target.valueAsNumber : 0,
            }));
          }}
        />
        <CustomInput
          unit="M"
          placeholder="0"
          disabled={isOnlyReadable}
          inputNameWidth={Rpx(150)}
          style={{ ...styles.customInput, height: Rpx(45, true), fontSize: Rpx(25, true) }}
          inputName="Taille "
          width="45%"
          defaultValue={isOnlyReadable ? `${mesuresData?.height}` : ''}
          onChange={(v) => {
            setMesuresDataState((prevMesuresData) => ({
              ...prevMesuresData,
              height: !Number.isNaN(v.target.valueAsNumber) ? v.target.valueAsNumber : 0,
            }));
          }}
        />
      </div>
    </div>
  );
};
const styles: StyleSheet = {
  container: {
    position: 'relative',
    margin: `2vw 2vw 0`,
    outline: `${Rpx(10)} solid ${DocColor.DARKBLUE}`,
    borderRadius: Rpx(60),
    minHeight: Rpx(100),
    paddingBottom: Rpx(47),
  },
  titleDiv: {
    width: 'fit-content',
    background: DocColor.DARKBLUE,
    borderRadius: `${Rpx(50)} 0 ${Rpx(20)} 0`,
    minHeight: Rpx(60),
    padding: `0 ${Rpx(40)}`,
    marginBottom: Rpx(15),
  },
  title: {
    color: 'white',
    fontWeight: 600,
    fontStyle: 'italic',
    margin: 'auto',
  },
  spacedRowDivs: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    padding: `0 ${Rpx(45)}`,
  },
  customInput: {
    fontWeight: 700,
    color: DocColor.DARKBLUE,
    borderRadius: Rpx(5),
    textAlign: 'center',
    marginTop: Rpx(5),
  },
};
