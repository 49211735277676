import { createAsyncActions } from '../../common/usecases/helpers/actions.helper';
import { Patient } from '../../patientsContext/domain/entities/Patient';
import { Attachment } from '../domain/entities/Attachments';
import { ConsultationNotes } from '../domain/entities/ConsultationNotes';
import { ConsultationReason } from '../domain/entities/ConsultationReason';
import { Examination } from '../domain/entities/Examination';
import { ExaminationToRequest } from '../domain/entities/ExaminationToRequest';
import { Interrogation } from '../domain/entities/Interrogation';
import { MedicalCertificate } from '../domain/entities/MedicalCertificate';
import { Medicine } from '../domain/entities/Medicine';
import { Mesures } from '../domain/entities/Mesures';
import { PatientConsultation } from '../domain/entities/PatientConsultation';
import { Prescription } from '../domain/entities/Prescriptions';
import { TodaysConsultationsStats } from '../domain/entities/TodaysConsultationsStats';

export const {
  request: loadAttachmentsRequest,
  success: loadAttachmentsSuccess,
  failure: loadAttachmentsFailure,
} = createAsyncActions<
  { patientId: string; attachmentsLimit?: number; skipAttachments?: number },
  Attachment[],
  unknown
>('LOAD_ATTACHMENTS');

export const {
  request: insertAttachmentRequest,
  success: insertAttachmentSuccess,
  failure: insertAttachmentFailure,
} = createAsyncActions<Attachment, Attachment, unknown>('INSERT_ATTACHMENT');

export const {
  request: loadPatientsConsultationsRequest,
  success: loadPatientsConsultationsSuccess,
  failure: loadPatientsConsultationsFailure,
} = createAsyncActions<
  { patientId?: string; consultationsLimit?: number; skipConsultations?: number },
  PatientConsultation[],
  unknown
>('LOAD_PATIENTS_CONSULTATIONS');

export const {
  request: loadPatientConsultationRequest,
  success: loadPatientConsultationSuccess,
  failure: loadPatientConsultationFailure,
} = createAsyncActions<string, PatientConsultation, unknown>('LOAD_PATIENT_CONSULTATION');

export const {
  request: fetchPatientsConsultationsRequest,
  success: fetchPatientsConsultationsSuccess,
  failure: fetchPatientsConsultationsFailure,
} = createAsyncActions<string, Patient[], unknown>('FETCH_PATIENTS_CONSULTATIONS');

export const {
  request: getTodaysConsultationsStatsRequest,
  success: getTodaysConsultationsStatsSuccess,
  failure: getTodaysConsultationsStatsFailure,
} = createAsyncActions<void, TodaysConsultationsStats, unknown>('GET_TODAYS_CONSULTATIONS_STATS');

export const {
  request: getConsultationNumberRequest,
  success: getConsultationNumberSuccess,
  failure: getConsultationNumberFailure,
} = createAsyncActions<void, number, unknown>('GET_CONSULTATION_NUMBER');

export const {
  request: insertPatientsConsultationRequest,
  success: insertPatientsConsultationSuccess,
  failure: insertPatientsConsultationFailure,
} = createAsyncActions<PatientConsultation, PatientConsultation, unknown>('INSERT_PATIENTS_CONSULTATIONS');

export const {
  request: loadConsultationReasonRequest,
  success: loadConsultationReasonSuccess,
  failure: loadConsultationReasonFailure,
} = createAsyncActions<string, ConsultationReason, unknown>('LOAD_CONSULTATION_REASON');

export const {
  request: insertConsultationReasonRequest,
  success: insertConsultationReasonSuccess,
  failure: insertConsultationReasonFailure,
} = createAsyncActions<ConsultationReason, ConsultationReason, unknown>('INSERT_CONSULTATION_REASON');

export const {
  request: loadInterrogationRequest,
  success: loadInterrogationSuccess,
  failure: loadInterrogationFailure,
} = createAsyncActions<string, Interrogation, unknown>('LOAD_INTERROGATION');

export const {
  request: insertInterrogationRequest,
  success: insertInterrogationSuccess,
  failure: insertInterrogationFailure,
} = createAsyncActions<Interrogation, Interrogation, unknown>('INSERT_INTERROGATION');

export const {
  request: loadConsultationNotesRequest,
  success: loadConsultationNotesSuccess,
  failure: loadConsultationNotesFailure,
} = createAsyncActions<string, ConsultationNotes, unknown>('LOAD_CONSULTATION_NOTES');

export const {
  request: insertConsultationNotesRequest,
  success: insertConsultationNotesSuccess,
  failure: insertConsultationNotesFailure,
} = createAsyncActions<ConsultationNotes, ConsultationNotes, unknown>('INSERT_CONSULTATION_NOTES');

export const {
  request: loadMesuresRequest,
  success: loadMesuresSuccess,
  failure: loadMesuresFailure,
} = createAsyncActions<string, Mesures, unknown>('LOAD_MESURES');

export const {
  request: insertMesuresRequest,
  success: insertMesuresSuccess,
  failure: insertMesuresFailure,
} = createAsyncActions<Mesures, Mesures, unknown>('INSERT_MESURES');

export const {
  request: loadExaminationsRequest,
  success: loadExaminationsSuccess,
  failure: loadExaminationsFailure,
} = createAsyncActions<string, Examination[], unknown>('LOAD_EXAMINATIONS');

export const {
  request: insertExaminationsRequest,
  success: insertExaminationsSuccess,
  failure: insertExaminationsFailure,
} = createAsyncActions<{ patientId: string; consultationId: string; examinations: Examination[] }, boolean, unknown>(
  'INSERT_EXAMINATIONS',
);

export const {
  request: sendAttachmentByMailRequest,
  success: sendAttachmentByMailSuccess,
  failure: sendAttachmentByMailFailure,
} = createAsyncActions<{ mail: string; fileName: string; fileContent: string }, boolean, unknown>(
  'SEND_ATTACHMENT_BY_MAIL',
);

export const {
  request: loadExaminationsToRequestRequest,
  success: loadExaminationsToRequestSuccess,
  failure: loadExaminationsToRequestFailure,
} = createAsyncActions<string, ExaminationToRequest[], unknown>('LOAD_EXAMINATIONS_TO_REQUEST');

export const {
  request: insertExaminationsToRequestRequest,
  success: insertExaminationsToRequestSuccess,
  failure: insertExaminationsToRequestFailure,
} = createAsyncActions<{ consultationId: string; examinationsToRequest: ExaminationToRequest[] }, boolean, unknown>(
  'INSERT_EXAMINATIONS_TO_REQUEST',
);

export const {
  request: loadPrescriptionsRequest,
  success: loadPrescriptionsSuccess,
  failure: loadPrescriptionsFailure,
} = createAsyncActions<string, Prescription[], unknown>('LOAD_PRESCRIPTIONS');

export const {
  request: insertPrescriptionsRequest,
  success: insertPrescriptionsSuccess,
  failure: insertPrescriptionsFailure,
} = createAsyncActions<{ consultationId: string; prescriptions: Prescription[] }, boolean, unknown>(
  'INSERT_PRESCRIPTIONS',
);

export const {
  request: fetchMedicinesRequest,
  success: fetchMedicinesSuccess,
  failure: fetchMedicinesFailure,
} = createAsyncActions<{ value?: string; composition?: string }, Medicine[], unknown>('FETCH_MEDICINES');

export const {
  request: loadMedicalCertificateRequest,
  success: loadMedicalCertificateSuccess,
  failure: loadMedicalCertificateFailure,
} = createAsyncActions<string, MedicalCertificate, unknown>('LOAD_MEDICAL_CERTIFICATE');

export const {
  request: insertMedicalCertificateRequest,
  success: insertMedicalCertificateSuccess,
  failure: insertMedicalCertificateFailure,
} = createAsyncActions<MedicalCertificate, MedicalCertificate, unknown>('INSERT_MEDICAL_CERTIFICATE');

export const {
  request: deletePatientConsultationRequest,
  success: deletePatientConsultationSuccess,
  failure: deletePatientConsultationFailure,
} = createAsyncActions<string, PatientConsultation, unknown>('DELETE_PATIENT_CONSULTATION');
