import { ENV } from '../../configuration';
import { InMemoryCalendarInteractor } from '../adapter/inmemory/InMemoryCalendarInteractor';
import { RealCalendarInteractor } from '../adapter/real/RealCalendarInteractor';
import { CalendarInteractor } from '../domain/gateways/Calendar.interactor';

export class CalendarContextFactory {
  static calendarInteractor(): CalendarInteractor {
    switch (ENV) {
      case 'prod':
      case 'dev':
        return new RealCalendarInteractor();
      default:
        return new InMemoryCalendarInteractor();
    }
  }
}
