import React from 'react';
import { useMediaQuery } from 'react-responsive';
import { Link } from 'react-router-dom';
import { DocColor, StyleSheet } from '../../../assets/styles/constantStyles';
import { CalendarIcon } from '../../../assets/svg/CalendarIcon';
import { ConsultationIcon } from '../../../assets/svg/ConsultationIcon';
import { PatientIcon } from '../../../assets/svg/PatientIcon';
import { User } from '../../../authContext/domain/entities/User';
import { bigScreenBreakpoint } from '../../../common/domain/entities/ReactResponsiveBreakpoints';
import { Rpx, SRpx } from '../../../common/usecases/helpers/DimensionsConverter';

interface DashBoardProps {
  stateConnectedUser: User | undefined;
}
export const DashBoardDumb = ({ stateConnectedUser }: DashBoardProps) => {
  // isBigScreen working as a media breakpoint
  const isBigScreen = useMediaQuery({ query: `(min-width: ${bigScreenBreakpoint}px)` });

  return (
    // const changeDate = () => {};
    <div style={isBigScreen ? { ...styles.container } : { ...styles.container, ...responsiveStyles.container }}>
      <div
        style={
          isBigScreen
            ? { ...styles.fullNameContainer }
            : { ...styles.fullNameContainer, ...responsiveStyles.fullNameContainer }
        }
      >
        <p
          style={
            isBigScreen
              ? { ...styles.fullNameParagraph }
              : { ...styles.fullNameParagraph, ...responsiveStyles.fullNameParagraph }
          }
        >{`Bonjour ${stateConnectedUser?.role === 'DOCTOR' ? 'Dr.' : ''}${stateConnectedUser?.firstName} ${
          stateConnectedUser?.role === 'DOCTOR' ? stateConnectedUser?.lastName : ''
        }`}</p>
      </div>
      <div style={{ ...responsiveStyles.navLinksContainer, display: isBigScreen ? 'none' : '' }}>
        <Link
          to="Mon Calendrier"
          style={{ ...responsiveStyles.navLink, ...responsiveStyles.calendarNavLink, fontSize: SRpx(15, true) }}
        >
          <CalendarIcon color="white" width={SRpx(20, true)} height={SRpx(20, true)} />
          <span style={responsiveStyles.navLinkSpan}>Calendrier</span>
        </Link>
        <Link
          to="Mes Patients"
          style={{ ...responsiveStyles.navLink, ...responsiveStyles.patientsNavLink, fontSize: SRpx(15, true) }}
        >
          <PatientIcon color="white" width={SRpx(20, true)} height={SRpx(20, true)} />
          <span style={responsiveStyles.navLinkSpan}>Patients</span>
        </Link>
        <Link
          to="Mes Consultations"
          style={{ ...responsiveStyles.navLink, ...responsiveStyles.consultationsNavLink, fontSize: SRpx(15, true) }}
        >
          <ConsultationIcon color="white" width={SRpx(20, true)} height={SRpx(20, true)} />
          <span style={responsiveStyles.navLinkSpan}>Consultations</span>
        </Link>
      </div>
    </div>
  );
};

const styles: StyleSheet = {
  container: {
    position: 'relative',
    flex: 1,
    maxHeight: '100%',
  },
  fullNameContainer: {
    height: '100%',
  },
  fullNameParagraph: {
    margin: 'auto',
    fontSize: Rpx(110),
    color: DocColor.DARKBLUE,
    width: '80%',
    textAlign: 'center',
    textTransform: 'capitalize',
    fontWeight: 800,
    letterSpacing: 2,
  },
};

const responsiveStyles: StyleSheet = {
  container: {
    background: DocColor.DARKBLUE,
  },
  fullNameContainer: {
    height: '25%',
    borderRadius: `0 0 ${SRpx(10)} ${SRpx(10)}`,
    background: 'white',
  },
  fullNameParagraph: {
    fontSize: SRpx(30),
    color: DocColor.MEDIUMBLUE,
    width: '90%',
  },
  navLinksContainer: {
    flexFlow: 'column wrap',
    height: '100%',
  },
  navLink: {
    display: 'flex',
    flex: '1 0 auto',
    alignItems: 'center',
    gap: SRpx(10),
    paddingLeft: '20%',
    transition: 'all 0.3s ease',
  },
  navLinkSpan: {
    fontSize: SRpx(25),
    color: 'white',
    fontWeight: 500,
  },

  calendarNavLink: {
    background: DocColor.DARKBLUE,
  },
  patientsNavLink: {
    background: DocColor.MEDIUMBLUE,
  },
  consultationsNavLink: {
    background: DocColor.LIGHTBLUE,
  },
};
