import { Action } from 'redux';
import { connect } from 'react-redux';
import { dispatchAsync } from 'react-redux-dispatch-async';
import { ResetPasswordDumb } from './ResetPassword.dumb';
import { loadUserRequest, resetPasswordRequest, sendSignInRequest } from '../../usecases/Auth.actions';
import { navigateToRequest } from '../../../navigationContext/usecases/navigation.actions';
import { NavigationPayload } from '../../../navigationContext/domain/entities/NavigationPayload';
import { loadUserSettingsRequest } from '../../../settingsContext/usecases/UserSettings.actions';

const mapState = () => ({});

const mapDispatch = (dispatch: (a: Action) => void) => ({
  loadUsersAsync: (userId: string) => dispatchAsync(dispatch, loadUserRequest(userId)),
  sendSignInAsync: (email: string, password: string) => dispatchAsync(dispatch, sendSignInRequest({ email, password })),
  loadUserSettingsAsync: () => dispatchAsync(dispatch, loadUserSettingsRequest()),
  navigateToAsync: (nav: NavigationPayload) => dispatch(navigateToRequest(nav)),
  resetPasswordAsync: (id: string, accessToken: string, newPassword: string) =>
    dispatchAsync(dispatch, resetPasswordRequest({ id, accessToken, newPassword })),
});

export const ResetPasswordSmart = connect(mapState, mapDispatch)(ResetPasswordDumb);
