import React, { useEffect, useState } from 'react';
import { DispatchAsyncResult } from 'react-redux-dispatch-async/lib/dispatchAsync';
import { DocColor, StyleSheet } from '../../../../assets/styles/constantStyles';
import { EyeIcon } from '../../../../assets/svg/EyeIcon';
import { User } from '../../../../authContext/domain/entities/User';
import { useNotificationContext } from '../../../../common/configuration/notificationContextProvider';
import { ConfirmOverlay } from '../../../../common/ui/ConfirmOverlay';
import CommonFunctions from '../../../../common/usecases/helpers/CommonFunctions';
import { Rpx } from '../../../../common/usecases/helpers/DimensionsConverter';

interface ChangePasswordComponentProps {
  connectedUser: User | undefined;
  updatePasswordAsync(id: string, currentPassword: string, newPassword: string): Promise<DispatchAsyncResult<User>>;
  setChosenOption: React.Dispatch<React.SetStateAction<string>>;
}

export const ChangePasswordComponent = ({
  connectedUser,
  updatePasswordAsync,
  setChosenOption,
}: ChangePasswordComponentProps) => {
  const notification = useNotificationContext();
  const [isConfirmFragmentClosed, setIsConfirmFragmentClosed] = useState<boolean>(true);
  const [currentPasswordShown, setCurrentPasswordShown] = useState<boolean>(false);
  const [newPasswordShown, setNewPasswordShown] = useState<boolean>(false);
  const [matchPasswordShown, setMatchPasswordShown] = useState<boolean>(false);
  const [InputMistakes, setInputMistakes] = useState<{
    isPasswordMatched: boolean;
    isNewPasswordWeak: boolean;
    isCurrentPasswordValid: boolean;
  }>({
    isPasswordMatched: true,
    isNewPasswordWeak: false,
    isCurrentPasswordValid: true,
  });
  const [currentPassword, setCurrentPassword] = useState<string>('');
  const [newPassword, setNewPassword] = useState<string>('');
  const [matchNewPassword, setMatchNewPassword] = useState<string>('');

  useEffect(() => {
    setInputMistakes((prevState) => ({ ...prevState, isCurrentPasswordValid: true }));
  }, [currentPassword]);

  useEffect(() => {
    if (newPassword !== '' || matchNewPassword !== '') {
      setInputMistakes((prevState) => ({ ...prevState, isPasswordMatched: newPassword === matchNewPassword }));
    }
  }, [newPassword, matchNewPassword]);

  useEffect(() => {
    if (newPassword !== '') {
      setInputMistakes((prevState) => ({
        ...prevState,
        isNewPasswordWeak: CommonFunctions.isPasswordWeak(newPassword),
      }));
    }
  }, [newPassword]);

  const onConfirmClick = () => {
    setIsConfirmFragmentClosed(true);
    if (connectedUser && connectedUser.id) {
      updatePasswordAsync(connectedUser.id, currentPassword, newPassword).then((res) => {
        if (res.success) {
          setChosenOption('');
          notification.setOnSuccess({
            message: `Votre le mot de passe vient d’être modifié.
          `,
          });
        } else {
          setInputMistakes((prevState) => ({ ...prevState, isCurrentPasswordValid: false }));
        }
      });
    }
  };

  const onSubmitClickHandler = () => {
    setIsConfirmFragmentClosed(false);
  };

  return (
    <div style={styles.container}>
      <ConfirmOverlay
        onConfirmClick={onConfirmClick}
        message="Êtes-vous sûr de bien vouloir mettre à jour le mot de passe?"
        isClosedState={isConfirmFragmentClosed}
        setIsClosedState={setIsConfirmFragmentClosed}
      />
      <div style={styles.titleDiv}>
        <p style={{ ...styles.titleParagraph, fontSize: Rpx(23, true) }}>Changer le mot de passe</p>
      </div>
      <div style={{ margin: 'auto', alignItems: 'center' }}>
        <p style={{ ...styles.explanationParagraph, fontSize: Rpx(25, true) }}>
          Changer le mot de passe qui vous permet de vous connecter à l’application.
        </p>
        <div style={styles.horizontalLine} />
        <div style={styles.newPasswordContainer}>
          <p style={{ ...styles.newPasswordTitle, fontSize: Rpx(23, true) }}>Mot de passe actuel</p>
          <div
            style={{
              position: 'relative',
              width: '80%',
              margin: `${Rpx(10)} auto 0 auto`,
            }}
          >
            <input
              style={{ ...styles.input, height: Rpx(70, true), fontSize: Rpx(20, true) }}
              placeholder="Entrez le Mot de passe actuel"
              onChange={(v) => {
                setCurrentPassword(v.target.value);
              }}
              type={currentPasswordShown ? 'text' : 'password'}
            />
            <div
              style={styles.iconBackground}
              onKeyDown={() => setCurrentPasswordShown((v) => !v)}
              tabIndex={0}
              role="button"
              onClick={() => setCurrentPasswordShown((v) => !v)}
            >
              <EyeIcon
                isClosed={!currentPasswordShown}
                color="white"
                style={{
                  ...styles.icon,
                  width: Rpx(30),
                  margin: 'auto',
                }}
              />
            </div>
          </div>

          <p style={{ ...styles.newPasswordTitle, fontSize: Rpx(23, true) }}>Nouveau mot de passe</p>
          <div
            style={{
              position: 'relative',
              width: '80%',
              margin: `${Rpx(10)} auto 0 auto`,
            }}
          >
            <input
              style={{
                ...styles.input,
                height: Rpx(70, true),
                fontSize: Rpx(20, true),
                borderColor:
                  !InputMistakes.isPasswordMatched || InputMistakes.isNewPasswordWeak
                    ? DocColor.LIGHTRED
                    : DocColor.CLOUDYBLUE,
              }}
              placeholder="Entrez le Nouveau mot de passe"
              onChange={(v) => {
                setNewPassword(v.target.value);
              }}
              type={newPasswordShown ? 'text' : 'password'}
            />
            <div
              style={styles.iconBackground}
              onKeyDown={() => setNewPasswordShown((v) => !v)}
              tabIndex={0}
              role="button"
              onClick={() => setNewPasswordShown((v) => !v)}
            >
              <EyeIcon
                isClosed={!newPasswordShown}
                color="white"
                style={{
                  ...styles.icon,
                  width: Rpx(30),
                  margin: 'auto',
                }}
              />
            </div>
          </div>
          <p style={{ ...styles.newPasswordTitle, fontSize: Rpx(23, true) }}>Confirmer le nouveau mot de passe</p>
          <div
            style={{
              position: 'relative',
              width: '80%',
              margin: `${Rpx(10)} auto 0 auto`,
            }}
          >
            <input
              style={{
                ...styles.input,
                height: Rpx(70, true),
                fontSize: Rpx(20, true),
                borderColor: !InputMistakes.isPasswordMatched ? DocColor.LIGHTRED : DocColor.CLOUDYBLUE,
              }}
              placeholder="Rentrez le nouveau mot de passe"
              onChange={(v) => {
                setMatchNewPassword(v.target.value);
              }}
              type={matchPasswordShown ? 'text' : 'password'}
              onPaste={(e) => {
                e.preventDefault();
                return false;
              }}
            />
            <div
              style={styles.iconBackground}
              onKeyDown={() => setMatchPasswordShown((v) => !v)}
              tabIndex={0}
              role="button"
              onClick={() => setMatchPasswordShown((v) => !v)}
            >
              <EyeIcon
                isClosed={!matchPasswordShown}
                color="white"
                style={{
                  ...styles.icon,
                  width: Rpx(30),
                  margin: 'auto',
                }}
              />
            </div>
          </div>

          <div style={{ marginTop: Rpx(20) }}>
            {!InputMistakes.isPasswordMatched && (
              <p style={styles.mistakeParagraph}>• Les deux mots de passe ne sont pas compatibles.</p>
            )}
            {InputMistakes.isNewPasswordWeak && (
              <p style={styles.mistakeParagraph}>• Le nouveau mot de passe est faible.</p>
            )}
            {!InputMistakes.isCurrentPasswordValid && (
              <p style={styles.mistakeParagraph}>• Votre mot de passe actuel est incorrect.</p>
            )}
          </div>

          <div style={styles.newPasswordButtonsContainer}>
            <button
              style={{
                ...styles.button,
                height: Rpx(70, true),
                fontSize: Rpx(28, true),
                background:
                  currentPassword === '' ||
                  newPassword === '' ||
                  matchNewPassword === '' ||
                  InputMistakes.isPasswordMatched === false ||
                  InputMistakes.isNewPasswordWeak === true
                    ? DocColor.GRAY
                    : DocColor.MEDIUMBLUE,
              }}
              type="button"
              disabled={
                currentPassword === '' ||
                newPassword === '' ||
                matchNewPassword === '' ||
                InputMistakes.isPasswordMatched === false ||
                InputMistakes.isNewPasswordWeak === true
              }
              onClick={onSubmitClickHandler}
            >
              Valider
            </button>
            <button
              style={{ ...styles.button, height: Rpx(70, true), fontSize: Rpx(28, true) }}
              type="button"
              onClick={() => setChosenOption('')}
            >
              Annuler
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

const styles: StyleSheet = {
  container: {
    position: 'relative',
    width: '52.5%',
    marginLeft: '5%',
    outline: `${Rpx(10)} solid ${DocColor.DARKBLUE}`,
    borderRadius: Rpx(60),
    height: '100%',
    overflow: 'hidden',
    alignItems: 'center',
  },
  titleDiv: {
    width: 'fit-content',
    background: DocColor.DARKBLUE,
    borderRadius: `0 0 ${Rpx(15)} ${Rpx(15)}`,
    minHeight: Rpx(45),
    padding: `0 ${Rpx(40)}`,
    margin: `0 auto`,
    alignItems: 'center',
    justifyContent: 'center',
  },
  titleParagraph: {
    color: 'white',
    fontWeight: 600,
    marginBottom: Rpx(10),
  },
  explanationParagraph: {
    color: DocColor.DARKBLUE,
    fontWeight: 700,
    padding: `0 ${Rpx(32)}`,
    marginTop: Rpx(40),
    textAlign: 'center',
  },
  horizontalLine: {
    width: '80%',
    background: DocColor.MEDIUMBLUE,
    height: Rpx(8),
    borderRadius: Rpx(4),
    marginTop: Rpx(30),
  },
  newPasswordContainer: {
    width: '90%',
    minHeight: Rpx(50),
    background: DocColor.CLOUDYBLUE,
    marginTop: Rpx(30),
    marginBottom: Rpx(20),
    padding: `${Rpx(30)} 0`,
    borderRadius: Rpx(30),
  },
  newPasswordTitle: {
    color: DocColor.DARKBLUE,
    fontWeight: 700,
    marginLeft: '10%',
    marginTop: Rpx(20),
  },
  input: {
    maxWidth: '100%',
    width: '100%',
    background: '',
    border: `${Rpx(4)} solid ${DocColor.CLOUDYBLUE}`,
    borderRadius: Rpx(9),
    outline: 'none',
    fontWeight: 600,
    letterSpacing: Rpx(2),
    margin: `0 auto 0 auto`,
    textAlign: 'center',
  },
  iconBackground: {
    background: DocColor.MEDIUMBLUE,
    borderRadius: Rpx(40),
    width: Rpx(50),
    height: Rpx(50),
    position: 'absolute',
    margin: `auto ${Rpx(10)} auto auto`,
    inset: 0,
    cursor: 'pointer',
  },
  newPasswordButtonsContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    width: '70%',
    margin: `${Rpx(30)} auto 0 auto`,
  },
  button: {
    width: 'fit-content',
    backgroundColor: DocColor.DARKBLUE,
    color: 'white',
    fontWeight: 700,
    borderRadius: Rpx(12),
    flexDirection: 'row',
    padding: `0px ${Rpx(40)}`,
  },
  mistakeParagraph: {
    width: 'fit-content',
    margin: `${Rpx(5)} auto 0 auto`,
    textAlign: 'center',
    color: 'red',
    fontSize: Rpx(24),
    fontWeight: 600,
    letterSpacing: Rpx(2),
  },
};
