import { useLocation } from 'react-router-dom';
import { DispatchAsyncResult } from 'react-redux-dispatch-async/lib/dispatchAsync';
import React, { useEffect, useState } from 'react';
import { useMediaQuery } from 'react-responsive';
import { hardCodedEmptyPatient, Patient } from '../../domain/entities/Patient';
import { DocColor, StyleSheet } from '../../../assets/styles/constantStyles';
import { Rpx, SRpx } from '../../../common/usecases/helpers/DimensionsConverter';
import { NavigationPayload } from '../../../navigationContext/domain/entities/NavigationPayload';
import { User } from '../../../authContext/domain/entities/User';
import { bigScreenBreakpoint } from '../../../common/domain/entities/ReactResponsiveBreakpoints';
import { ToRightArrow3 } from '../../../assets/svg/ToRightArrow3';
import { Consultations } from '../patientDetails/components/ConsultationsComponent';

type ConsultationsHistoryProps = {
  stateConnectedUser: User | undefined;
  loadPatientAsync(patientId: string): Promise<DispatchAsyncResult>;
  loadPatientsConsultationsAsync(
    patientId?: string,
    consultationsLimit?: number,
    skipConsultations?: number,
  ): Promise<DispatchAsyncResult>;
  navigateToAsync: (p: NavigationPayload) => void;
};

export const ConsultationsHistoryDumb = ({
  stateConnectedUser,
  loadPatientAsync,
  loadPatientsConsultationsAsync,
  navigateToAsync,
}: ConsultationsHistoryProps) => {
  // isBigScreen working as a media breakpoint
  const isBigScreen = useMediaQuery({ query: `(min-width: ${bigScreenBreakpoint}px)` });

  const recievedPatientId: string = useLocation<string>().state;
  const [patient, setPatient] = useState<Patient>(hardCodedEmptyPatient);
  useEffect(() => {
    if (recievedPatientId) {
      loadPatientAsync(recievedPatientId).then((res) => {
        if (res.success) {
          setPatient(res.result[0]);
        }
      });
    }
  }, [recievedPatientId]);

  return (
    <div style={isBigScreen ? { ...styles.container } : { ...styles.container, ...responsiveStyles.container }}>
      <div style={{ ...responsiveStyles.titleContainer, display: isBigScreen ? 'none' : '' }}>
        <ToRightArrow3 width={SRpx(10)} height={SRpx(10)} color={DocColor.DARKBLUE} />
        <p style={{ ...responsiveStyles.title, fontSize: SRpx(17, true) }}>Historique des consultations</p>
      </div>
      <div style={{ ...responsiveStyles.line, display: isBigScreen ? 'none' : '' }} />
      <Consultations
        stateConnectedUser={stateConnectedUser}
        patientId={patient.id}
        loadPatientsConsultationsAsync={loadPatientsConsultationsAsync}
        navigateToAsync={navigateToAsync}
      />
    </div>
  );
};

const styles: StyleSheet = {
  container: {
    flex: 1,
    margin: `${Rpx(180)} 0`,
  },
};

const responsiveStyles: StyleSheet = {
  container: {
    margin: `${SRpx(30)} 0`,
  },
  titleContainer: {
    flexDirection: 'row',
    width: '80%',
    gap: SRpx(3),
    alignItems: 'center',
    margin: `0 auto ${SRpx(20)} auto`,
  },
  title: {
    color: DocColor.MEDIUMBLUE,
    fontWeight: 700,
    letterSpacing: SRpx(1),
  },
  line: {
    width: '90%',
    height: 2,
    background: DocColor.MEDIUMGRAY,
    margin: `0 auto ${SRpx(20)} auto`,
  },
};
