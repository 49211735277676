import { connect } from 'react-redux';
import { DashBoardDumb } from './DashBoard.dumb';
import { AppState } from '../../../common/configuration/app.states';
import { getConnectedUser } from '../../../authContext/usecases/Auth.selectors';

const mapState = (state: AppState) => ({
  // statePatients: getAllPatients(state),
  stateConnectedUser: getConnectedUser(state),
});

const mapDispatch = () => ({
  // navigateTo: (nav: NavigationPayload) => dispatch(navigateTo(nav)),
});

export const DashBoardSmart = connect(mapState, mapDispatch)(DashBoardDumb);
