import React, { useEffect, useState } from 'react';
import { DispatchAsyncResult } from 'react-redux-dispatch-async/lib/dispatchAsync';
import { PrescriptionTemplateOverlay } from './PrescriptionTemplateOverlay';
import { PrescriptionRow } from './prescriptionRow';
import { DocColor, StyleSheet } from '../../../../assets/styles/constantStyles';
import { AddIcon } from '../../../../assets/svg/Addlcon';
import { EyeIcon } from '../../../../assets/svg/EyeIcon';
import { ButtonWithIcon } from '../../../../common/ui/ButtonWithIcon';
import { Rpx } from '../../../../common/usecases/helpers/DimensionsConverter';
import { ConsultationAdditionFilledDataType } from '../../../domain/entities/ConsultationAdditionFilledDataType';
import { Prescription } from '../../../domain/entities/Prescriptions';
import { useLoaderContext } from '../../../../common/configuration/loaderContextProvider';
import { Medicine } from '../../../domain/entities/Medicine';
import { Patient } from '../../../../patientsContext/domain/entities/Patient';

type PrescriptionComponentProps = {
  patient?: Patient | null;
  fetchMedicinesAsync: (value?: string, composition?: string) => Promise<DispatchAsyncResult>;
  temporaryPatientName: string;
  prescriptionData: Prescription[] | undefined;
  isOnlyReadable?: boolean;
  setFilledData: React.Dispatch<React.SetStateAction<ConsultationAdditionFilledDataType>>;
  disabled?: boolean;
};

export const PrescriptionComponent = ({
  patient,
  fetchMedicinesAsync,
  temporaryPatientName,
  prescriptionData,
  isOnlyReadable,
  setFilledData,
  disabled,
}: PrescriptionComponentProps) => {
  const loader = useLoaderContext();
  const [prescriptionDataState, setPrescriptionDataState] = useState<Prescription[]>([
    {
      medicineName: '',
      medicineForm: '',
      medicineComposition: '',
      quantity: '',
      measure: '',
      doseWeight: 0,
      numberOfUses: 0,
      frequency: '',
      duration: '',
      consumptionTime: [],
      note: '',
    },
  ]);
  const [medicinesData, setMedicinesData] = useState<Medicine[]>([]);
  const [medicineInputChosenOption, setMedicineInputChosenOption] = useState<{ id: string; value: string }>({
    id: '',
    value: '',
  });
  const [medicineInputOptionsState, setMedicineInputOptionsState] = useState<{ id: string; value: string }[]>([]);
  const [isAddButtonHidden, setIsAddButtonHidden] = useState<boolean>(true);
  const [isPrescriptionTemplateOverlayClosed, setIsPrescriptionTemplateOverlayClosed] = useState<boolean>(true);

  useEffect(() => {
    fetchMedicinesAsync(medicineInputChosenOption.value).then((res) => {
      if (res.success) {
        const tempData: { id: string; value: string }[] = [];
        res.result.forEach((medicine: Medicine) => {
          tempData.push({ id: medicine.uuid, value: `${medicine.product_name} - ${medicine.product_form}` });
        });
        setMedicinesData([...res.result]);
        setMedicineInputOptionsState([...tempData]);
      }
    });
  }, []);

  useEffect(() => {
    if (isOnlyReadable === true && prescriptionData && prescriptionData.length > 0) {
      setPrescriptionDataState([...prescriptionData]);
    }
  }, [prescriptionData]);

  useEffect(() => {
    if (isOnlyReadable !== true) {
      if (prescriptionDataState[prescriptionDataState.length - 1].medicineName !== '') {
        setFilledData((prevState) => ({ ...prevState, prescriptions: prescriptionDataState }));
        setIsAddButtonHidden(false);
      } else if (prescriptionDataState.length === 1 && prescriptionDataState[0].medicineName === '') {
        setFilledData((prevState) => ({ ...prevState, prescriptions: [] }));
        setIsAddButtonHidden(true);
      }
    }
  }, [prescriptionDataState]);

  const addElementHandler = () => {
    setPrescriptionDataState((objs) => [
      ...objs,
      {
        medicineName: '',
        medicineForm: '',
        medicineComposition: '',
        quantity: '',
        measure: '',
        doseWeight: 0,
        numberOfUses: 0,
        frequency: '',
        duration: '',
        consumptionTime: [],
        note: '',
      },
    ]);
  };

  const showPrescriptionPdfHandler = () => {
    loader.setMessage('Ouverture du fichier en cours');
    setIsPrescriptionTemplateOverlayClosed(false);
  };

  return (
    <div style={{ ...styles.container, outlineColor: disabled ? DocColor.GRAY : DocColor.DARKBLUE }}>
      {!isPrescriptionTemplateOverlayClosed && (
        <PrescriptionTemplateOverlay
          onSuccess={() => {
            setIsPrescriptionTemplateOverlayClosed(true);
            loader.setMessage('');
          }}
          onFailure={() => {
            setIsPrescriptionTemplateOverlayClosed(true);
            loader.setMessage('');
          }}
          patient={patient}
          temporaryPatientName={temporaryPatientName}
          prescriptionData={prescriptionDataState}
        />
      )}

      <div
        style={{
          ...styles.titleDiv,
          minHeight: Rpx(60, true),
          background: disabled ? DocColor.GRAY : DocColor.DARKBLUE,
        }}
      >
        <p key="firstHistoryDateDiv" style={{ ...styles.title, fontSize: Rpx(32, true) }}>
          Ordonnance
        </p>
      </div>
      <div style={{ width: '90%', margin: '0 auto' }}>
        {prescriptionDataState &&
          prescriptionDataState.map((obj, index) => {
            const key = `prescription ${index}`;
            return (
              <PrescriptionRow
                key={key}
                medicinesData={medicinesData}
                medicineInputOptionsState={medicineInputOptionsState}
                dataState={obj}
                prescriptionDataState={prescriptionDataState}
                index={index}
                setMedicineInputChosenOption={setMedicineInputChosenOption}
                setPrescriptionDataState={setPrescriptionDataState}
                fetchMedicinesAsync={fetchMedicinesAsync}
                medicineInputChosenOption={medicineInputChosenOption}
                disabled={disabled}
                isOnlyReadable={isOnlyReadable}
              />
            );
          })}

        <div
          style={{
            flexDirection: 'row',
            justifyContent: isAddButtonHidden || disabled ? 'end' : 'space-between',
            marginTop: Rpx(40),
          }}
        >
          <div
            style={{ ...styles.addButton, display: isAddButtonHidden || disabled ? 'none' : '' }}
            onClick={() => addElementHandler()}
            onKeyDown={() => addElementHandler()}
            tabIndex={0}
            role="button"
          >
            <AddIcon
              width={Rpx(14, true)}
              height={Rpx(14, true)}
              color={DocColor.MEDIUMBLUE}
              style={styles.buttonIcon}
            />
            <p style={{ ...styles.buttonParagraph, fontSize: Rpx(25, true) }}>Ajouter un autre médicament</p>
          </div>
          <div
            style={{
              flexDirection: 'row',
              gap: Rpx(30),
              display:
                prescriptionDataState.length === 1 && prescriptionDataState[0]?.medicineName === '' ? 'none' : '',
            }}
          >
            <ButtonWithIcon
              disabled={disabled}
              label="Afficher un aperçu de l’ordonnance"
              style={{ fontSize: Rpx(20, true) }}
              icon={EyeIcon({
                width: Rpx(25, true),
                height: Rpx(25, true),
                color: 'white',
                isClosed: false,
                style: { margin: `auto 0 auto ${Rpx(10)}` },
              })}
              onClick={() => showPrescriptionPdfHandler()}
            />
            {/* <ButtonWithIcon
              disabled={disabled}
              label="Imprimer l’ordonnance"
              style={{ fontSize: Rpx(20, true) }}
              icon={PrinterIcon({
                width: Rpx(25, true),
                height: Rpx(25, true),
                color: 'white',
                style: { margin: `auto 0 auto ${Rpx(10)}` },
              })}
            /> */}
          </div>
        </div>
      </div>
    </div>
  );
};
const styles: StyleSheet = {
  container: {
    position: 'relative',
    margin: `2vw 2vw 0`,
    outline: `${Rpx(10)} solid ${DocColor.DARKBLUE}`,
    borderRadius: Rpx(60),
    minHeight: Rpx(100),
    paddingBottom: Rpx(47),
  },
  titleDiv: {
    width: 'fit-content',
    background: DocColor.DARKBLUE,
    borderRadius: `${Rpx(50)} 0 ${Rpx(20)} 0`,
    padding: `0 ${Rpx(40)}`,
    marginBottom: Rpx(15),
  },
  title: {
    color: 'white',
    fontWeight: 600,
    fontStyle: 'italic',
    margin: 'auto',
  },
  addButton: {
    flexDirection: 'row',
    margin: `auto 0`,
    cursor: 'pointer',
    marginTop: 0,
  },
  buttonParagraph: {
    fontWeight: 500,
    fontStyle: 'italic',
    color: DocColor.MEDIUMBLUE,
  },
  buttonIcon: {
    outline: `${Rpx(3)} solid ${DocColor.MEDIUMBLUE}`,
    borderRadius: Rpx(20),
    padding: Rpx(3),
    margin: `auto ${Rpx(15)} auto 0`,
  },
};
