import React, { CSSProperties } from 'react';
import { DocColor, StyleSheet } from '../../assets/styles/constantStyles';
import { Rpx } from '../usecases/helpers/DimensionsConverter';

export interface ButtonWithIconProps {
  label?: string;
  disabled?: boolean | true;
  style?: CSSProperties;
  icon: JSX.Element;
  onClick?: () => void;
}
export const ButtonWithIcon = ({ label, disabled, style, icon, onClick }: ButtonWithIconProps) => (
  <div
    style={{
      ...styles.buttonContainer,
      height: Rpx(55, true),
      fontSize: Rpx(24, true),
      background: disabled ? DocColor.GRAY : DocColor.MEDIUMBLUE,
      ...style,
    }}
    onKeyDown={() => {
      if (onClick !== undefined && !disabled) {
        onClick();
      }
    }}
    tabIndex={0}
    role="button"
    onClick={() => {
      if (onClick !== undefined && !disabled) {
        onClick();
      }
    }}
  >
    {icon}
    {label && <p style={styles.buttonParagraph}>{label}</p>}
  </div>
);

const styles: StyleSheet = {
  buttonContainer: {
    flexDirection: 'row',
    borderRadius: Rpx(18),
    justifyContent: 'center',
    color: 'white',
    cursor: 'pointer',
    fontWeight: 500,
  },
  buttonParagraph: {
    margin: `auto ${Rpx(10)}`,
    whiteSpace: 'nowrap',
  },
};
