import React, { useState } from 'react';
import { PatientConsultation } from '../../../domain/entities/PatientConsultation';
import { DocColor, StyleSheet } from '../../../../assets/styles/constantStyles';
import { Rpx } from '../../../../common/usecases/helpers/DimensionsConverter';
import { ConfirmOverlay } from '../../../../common/ui/ConfirmOverlay';
import { NavigationPayload } from '../../../../navigationContext/domain/entities/NavigationPayload';
import { ButtonWithIcon } from '../../../../common/ui/ButtonWithIcon';
import { NoteBlankIcon } from '../../../../assets/svg/NoteBlankIcon';

interface PatientConsultationRowProps {
  dataState: PatientConsultation;
  navigateReplace: (p: NavigationPayload) => void;
  currentConsultationNumber: string;
}

export const PatientConsutationRow = ({
  dataState,
  navigateReplace,
  currentConsultationNumber,
}: PatientConsultationRowProps) => {
  const startDateTime = new Date(dataState.startDateTime);
  const [isConfirmFragmentClosed, setIsConfirmFragmentClosed] = useState<boolean>(true);

  const showConsultationInformationHandler = (consultation: PatientConsultation) => {
    navigateReplace({
      routeName: 'Consultation Addition',
      params: {
        patientId: consultation.patientId,
        consultationId: consultation.id,
        consultationNumber: consultation.number,
        isOnlyReadableConsultation: true,
      },
    });
    window.location.reload();
  };

  const onConfirmClick = () => {
    showConsultationInformationHandler(dataState);
    setIsConfirmFragmentClosed(true);
  };

  return (
    <div style={styles.consultationContainer}>
      <ConfirmOverlay
        message="Les données renseignées dans la consultation seront
        perdus. Êtes-vous sûr de bien vouloir continuer ?"
        onConfirmClick={onConfirmClick}
        isClosedState={isConfirmFragmentClosed}
        setIsClosedState={setIsConfirmFragmentClosed}
      />
      <div>
        <div style={styles.consultationRowDiv}>
          <p style={{ ...styles.consultationParagraph, fontSize: Rpx(25, true) }}>
            Consultation :
            <span style={styles.consultationSpan}>{`N°${dataState.number ? dataState.number : '-'}`}</span>
          </p>
          <p style={{ ...styles.consultationParagraph, fontSize: Rpx(25, true) }}>
            Date :{' '}
            <span style={styles.consultationSpan}>
              {startDateTime.toLocaleDateString('fr-FR', {
                day: 'numeric',
                year: 'numeric',
                month: 'short',
              })}
            </span>
          </p>
        </div>
      </div>
      <div style={styles.consultationRowDiv}>
        <p style={{ ...styles.consultationParagraph, fontSize: Rpx(25, true) }}>
          Motif :{' '}
          <span style={styles.consultationSpan}>
            {dataState.consultationReason ? dataState.consultationReason : '-'}
          </span>
        </p>
        <ButtonWithIcon
          disabled={currentConsultationNumber === dataState.number}
          label="Voir le compte rendu de la consultation"
          icon={NoteBlankIcon({
            width: Rpx(25, true),
            height: Rpx(25, true),
            color: 'white',
            style: { margin: `auto 0 auto ${Rpx(10)}` },
          })}
          onClick={() => setIsConfirmFragmentClosed(false)}
        />
      </div>
    </div>
  );
};

const styles: StyleSheet = {
  consultationContainer: {
    background: DocColor.LIGHTGRAY,
    borderRadius: Rpx(25),
    margin: `0 ${Rpx(30)} ${Rpx(20)} 0`,
    padding: `${Rpx(10)} 0`,
  },
  consultationRowDiv: {
    flexDirection: 'row',
    width: '100%',
    padding: `${Rpx(15)} 2.5%`,
    boxSizing: 'border-box',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  consultationParagraph: {
    color: DocColor.DARKBLUE,
    fontWeight: 600,
  },
  consultationSpan: {
    color: DocColor.MEDIUMBLUE,
    fontWeight: 500,
  },
  consultationButton: {
    flexDirection: 'row',
    margin: `${Rpx(20)} auto`,
    cursor: 'pointer',
  },
  consultationButtonParagraph: {
    color: DocColor.MEDIUMBLUE,
    fontWeight: 700,
    marginLeft: Rpx(12),
  },
};
