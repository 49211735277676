import { Action } from 'redux-actions';
import { getTodaysConsultationsStatsSuccess, loadPatientsConsultationsSuccess } from './Consultations.actions';
import { createTypedHandler, handleTypedActions } from '../../common/usecases/helpers/actions.helper';
import { PatientConsultation } from '../domain/entities/PatientConsultation';
import { TodaysConsultationsStats } from '../domain/entities/TodaysConsultationsStats';
import { ConsultationsState } from '../configuration/Consultations.state';

const consultationState: ConsultationsState = {
  patientsConsultations: [],
  todaysConsultationsStats: undefined,
};

const loadPatientsConsultationsReducer = (state: ConsultationsState, action: Action<PatientConsultation[]>) => ({
  ...state,
  patientsConsultations: action.payload,
});

const getTodaysConsultationsStatsReducer = (
  state: ConsultationsState,
  action: Action<TodaysConsultationsStats | undefined>,
) => ({
  ...state,
  todaysConsultationsStats: action.payload,
});

export const consultationsReducer = handleTypedActions(
  [
    createTypedHandler(loadPatientsConsultationsSuccess, loadPatientsConsultationsReducer),
    createTypedHandler(getTodaysConsultationsStatsSuccess, getTodaysConsultationsStatsReducer),
    // createTypedHandler(loadSSSuccess, loadSSReducer),
  ],
  consultationState,
);
