export interface ExaminationToRequest {
  id?: number;
  consultationId?: string;
  type: string;
  examinationsList: string[];
}
export const hardCodedEmptyExaminationsToRequest: ExaminationToRequest[] = [
  {
    type: '',
    examinationsList: [],
  },
];

export const hardCodedExaminationsToRequests: ExaminationToRequest[] = [];
