export interface Examination {
  id?: number;
  consultationId?: string;
  type?: string;
  file: string;
  fileName: string;
}
export const hardCodedEmptyExaminations: Examination[] = [
  {
    type: '',
    file: '',
    fileName: '',
  },
];

export const hardCodedExaminations: Examination[] = [];
