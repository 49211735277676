import { all, call, put, takeLatest } from 'redux-saga/effects';
import { Action } from 'redux-actions';
import {
  deletePatientFailure,
  deletePatientRequest,
  deletePatientSuccess,
  fetchPatientsFailure,
  fetchPatientsRequest,
  fetchPatientsSuccess,
  getTemporaryPatientNumberFailure,
  getTemporaryPatientNumberRequest,
  getTemporaryPatientNumberSuccess,
  insertPatientFailure,
  insertPatientRequest,
  insertPatientSuccess,
  loadAllPatientsFailure,
  loadAllPatientsRequest,
  loadAllPatientsSuccess,
  loadNotesFailure,
  loadNotesRequest,
  loadNotesSuccess,
  loadPatientFailure,
  loadPatientRequest,
  loadPatientsNamesFailure,
  loadPatientsNamesRequest,
  loadPatientsNamesSuccess,
  loadPatientSuccess,
  loadTreatmentsAntecedentsFailure,
  loadTreatmentsAntecedentsRequest,
  loadTreatmentsAntecedentsSuccess,
  updateNotesFailure,
  updateNotesRequest,
  updateNotesSuccess,
  updatePatientFailure,
  updatePatientRequest,
  updatePatientSuccess,
  updateTreatmentsAntecedentsFailure,
  updateTreatmentsAntecedentsRequest,
  updateTreatmentsAntecedentsSuccess,
} from './Patients.actions';
import { getDependencies } from '../../common/usecases/common.sagas';
import { DependenciesType } from '../../configuration/dependencies';
import { Patient } from '../domain/entities/Patient';
import { TreatmentsAntecedents } from '../domain/entities/TreatmentsAntecedents';
import { Notes } from '../domain/entities/Notes';

function* loadAllPatientsSaga() {
  try {
    // const membership: Membership = yield select(getMembership)
    const dep: DependenciesType = yield getDependencies();
    const res: Patient[] = yield call(dep.patientsInteractor.loadAllPatients);
    yield put(loadAllPatientsSuccess(res));
  } catch (error) {
    yield put(loadAllPatientsFailure(error));
  }
}

function* loadPatientSaga(action: Action<string>) {
  try {
    const id: string = action.payload;
    const dep: DependenciesType = yield getDependencies();
    const res: Patient = yield call(dep.patientsInteractor.loadPatient, id);
    yield put(loadPatientSuccess(res));
  } catch (error) {
    yield put(loadPatientFailure(error));
  }
}

function* fetchPatientsSaga(action: Action<string>) {
  try {
    const value: string = action.payload;
    const dep: DependenciesType = yield getDependencies();
    const res: Patient[] = yield call(dep.patientsInteractor.fetchPatients, value);
    yield put(fetchPatientsSuccess(res));
  } catch (error) {
    yield put(fetchPatientsFailure(error));
  }
}

function* getTemporaryPatientNumberSaga() {
  try {
    const dep: DependenciesType = yield getDependencies();
    const res: number = yield call(dep.patientsInteractor.getTemporaryPatientNumber);
    yield put(getTemporaryPatientNumberSuccess(res));
  } catch (error) {
    yield put(getTemporaryPatientNumberFailure(error));
  }
}

function* loadPatientsNamesSaga() {
  try {
    const dep: DependenciesType = yield getDependencies();
    const res: { patientId: string; fullName: string }[] = yield call(dep.patientsInteractor.loadPatientsNames);
    yield put(loadPatientsNamesSuccess(res));
  } catch (error) {
    yield put(loadPatientsNamesFailure(error));
  }
}

function* insertPatientsSaga(action: Action<Patient>) {
  try {
    const newPatient: Patient = action.payload;
    // const membership: Membership = yield select(getMembership)
    const dep: DependenciesType = yield getDependencies();
    const res: Patient = yield call(dep.patientsInteractor.insertPatient, newPatient);
    yield put(insertPatientSuccess(res));
  } catch (error) {
    yield put(insertPatientFailure(error));
  }
}

function* updatePatientsSaga(action: Action<Patient>) {
  try {
    const updatePatient: Patient = action.payload;
    // const membership: Membership = yield select(getMembership)
    const dep: DependenciesType = yield getDependencies();
    const res: Patient = yield call(dep.patientsInteractor.updatePatient, updatePatient);
    yield put(updatePatientSuccess(res));
  } catch (error) {
    yield put(updatePatientFailure(error));
  }
}

function* deletePatientsSaga(action: Action<string>) {
  try {
    const lookId: string = action.payload;
    // const membership: Membership = yield select(getMembership)
    const dep: DependenciesType = yield getDependencies();
    const res: boolean = yield call(dep.patientsInteractor.deletePatient, lookId);
    yield put(deletePatientSuccess(res));
  } catch (error) {
    yield put(deletePatientFailure(error));
  }
}

function* loadNotesSaga(action: Action<string>) {
  try {
    // const membership: Membership = yield select(getMembership)
    const patientId: string = action.payload;
    const dep: DependenciesType = yield getDependencies();
    const res: Notes = yield call(dep.patientsInteractor.loadNotes, patientId);
    yield put(loadNotesSuccess(res));
  } catch (error) {
    yield put(loadNotesFailure(error));
  }
}

function* updateNotesSaga(action: Action<Notes>) {
  try {
    // const membership: Membership = yield select(getMembership)
    const notes: Notes = action.payload;
    const dep: DependenciesType = yield getDependencies();
    const res: Notes = yield call(dep.patientsInteractor.updateNotes, notes);
    yield put(updateNotesSuccess(res));
  } catch (error) {
    yield put(updateNotesFailure(error));
  }
}

function* loadTreatmentsAntecedentsSaga(action: Action<string>) {
  try {
    // const membership: Membership = yield select(getMembership)
    const patientId: string = action.payload;
    const dep: DependenciesType = yield getDependencies();
    const res: TreatmentsAntecedents = yield call(dep.patientsInteractor.loadTreatmentsAntecedents, patientId);
    yield put(loadTreatmentsAntecedentsSuccess(res));
  } catch (error) {
    yield put(loadTreatmentsAntecedentsFailure(error));
  }
}

function* updateTreatmentsAntecedentsSaga(action: Action<TreatmentsAntecedents>) {
  try {
    // const membership: Membership = yield select(getMembership)
    const treatmentsAndAntecedents: TreatmentsAntecedents = action.payload;
    const dep: DependenciesType = yield getDependencies();
    const res: TreatmentsAntecedents = yield call(
      dep.patientsInteractor.updateTreatmentsAntecedents,
      treatmentsAndAntecedents,
    );
    yield put(updateTreatmentsAntecedentsSuccess(res));
  } catch (error) {
    yield put(updateTreatmentsAntecedentsFailure(error));
  }
}

export function* patientsSagas() {
  yield all([
    takeLatest(loadAllPatientsRequest, loadAllPatientsSaga),
    takeLatest(loadPatientRequest, loadPatientSaga),
    takeLatest(fetchPatientsRequest, fetchPatientsSaga),
    takeLatest(getTemporaryPatientNumberRequest, getTemporaryPatientNumberSaga),
    takeLatest(loadPatientsNamesRequest, loadPatientsNamesSaga),
    takeLatest(insertPatientRequest, insertPatientsSaga),
    takeLatest(updatePatientRequest, updatePatientsSaga),
    takeLatest(deletePatientRequest, deletePatientsSaga),
    takeLatest(loadNotesRequest, loadNotesSaga),
    takeLatest(updateNotesRequest, updateNotesSaga),
    takeLatest(loadTreatmentsAntecedentsRequest, loadTreatmentsAntecedentsSaga),
    takeLatest(updateTreatmentsAntecedentsRequest, updateTreatmentsAntecedentsSaga),
    // takeEvery(loadFBDitemsRequest, loadFBDitemsSaga),
  ]);
}
