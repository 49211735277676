import React, { useState } from 'react';
import { DispatchAsyncResult } from 'react-redux-dispatch-async/lib/dispatchAsync';
import { DocColor, StyleSheet } from '../../../../assets/styles/constantStyles';
import { WarningIcon } from '../../../../assets/svg/WarningIcon';
import { User } from '../../../../authContext/domain/entities/User';
import { useNotificationContext } from '../../../../common/configuration/notificationContextProvider';
import { ToggleButton } from '../../../../common/ui/ToggleButton';
import { Rpx } from '../../../../common/usecases/helpers/DimensionsConverter';
import { UserSettings } from '../../../domain/entities/UserSettings';

interface EmailNotificationsComponentProps {
  connectedUser: User | undefined;
  userSettingsState: UserSettings;
  loadUserSettingsAsync(): Promise<DispatchAsyncResult<User>>;
  updateUserSettingsAsync(userSettings: UserSettings): Promise<DispatchAsyncResult<UserSettings>>;
  setChosenOption: React.Dispatch<React.SetStateAction<string>>;
}

export const EmailNotificationsComponent = ({
  connectedUser,
  userSettingsState,
  loadUserSettingsAsync,
  updateUserSettingsAsync,
  setChosenOption,
}: EmailNotificationsComponentProps) => {
  const notification = useNotificationContext();
  const [userSettingsUseState, setUserSettingsUseState] = useState<UserSettings>(userSettingsState);

  const onSubmitClickHandler = () => {
    if (JSON.stringify(userSettingsUseState) !== JSON.stringify(userSettingsState)) {
      updateUserSettingsAsync(userSettingsUseState).then((res) => {
        if (res.success) {
          loadUserSettingsAsync();
          setChosenOption('');
          notification.setOnSuccess({
            message: `Vos préférences ont été mis à jour.
          `,
          });
        } else {
          notification.setOnError({
            message: `Nous n'avons pas pu mettre à jour votre préférences.
          `,
          });
        }
      });
    }
  };

  return (
    <div style={styles.container}>
      <div style={styles.titleDiv}>
        <p style={{ ...styles.titleParagraph, fontSize: Rpx(23, true) }}>Notifications par email</p>
      </div>
      <div style={{ margin: connectedUser?.role === 'DOCTOR' ? 'auto' : 'unset', alignItems: 'center' }}>
        <p style={{ ...styles.explanationParagraph, fontSize: Rpx(28, true) }}>
          Choisir les notifications que vous voulez recevoir par email
        </p>
        <div style={styles.horizontalLine} />
        <div style={styles.emailNotificationsContainer}>
          <p style={{ ...styles.emailNotificationsInfoTitle, fontSize: Rpx(30, true) }}>Changement de Mot de passe</p>
          <div style={styles.emailNotificationsInfoContainer}>
            <WarningIcon color={DocColor.MEDIUMBLUE} width={Rpx(40, true)} height={Rpx(40, true)} />
            <p style={{ ...styles.emailNotificationsInfoParagraph, fontSize: Rpx(26, true) }}>
              Vous receviez une notification par défaut pour des raisons de sécurité.
            </p>
          </div>
        </div>
        {connectedUser?.role === 'DOCTOR' && (
          <>
            <div style={styles.emailNotificationsContainer}>
              <div
                style={styles.emailNotificationsStateContainer}
                onKeyDown={() => {
                  setUserSettingsUseState((prevData) => ({
                    ...prevData,
                    deleteClientEmailNotificationState: !prevData.deleteClientEmailNotificationState,
                  }));
                }}
                tabIndex={0}
                role="button"
                onClick={() => {
                  setUserSettingsUseState((prevData) => ({
                    ...prevData,
                    deleteClientEmailNotificationState: !prevData.deleteClientEmailNotificationState,
                  }));
                }}
              >
                <ToggleButton
                  selected={userSettingsUseState.deleteClientEmailNotificationState}
                  width={Rpx(40, true)}
                  height={Rpx(18, true)}
                />
                <p style={{ ...styles.emailNotificationsStateLabel, fontSize: Rpx(28, true) }}>
                  Suppression d’un client
                </p>
              </div>
              <div
                style={styles.emailNotificationsStateContainer}
                onKeyDown={() => {
                  setUserSettingsUseState((prevData) => ({
                    ...prevData,
                    deleteConsultationEmailNotificationState: !prevData.deleteConsultationEmailNotificationState,
                  }));
                }}
                tabIndex={0}
                role="button"
                onClick={() => {
                  setUserSettingsUseState((prevData) => ({
                    ...prevData,
                    deleteConsultationEmailNotificationState: !prevData.deleteConsultationEmailNotificationState,
                  }));
                }}
              >
                <ToggleButton
                  selected={userSettingsUseState.deleteConsultationEmailNotificationState}
                  width={Rpx(40)}
                  height={Rpx(18)}
                />
                <p style={{ ...styles.emailNotificationsStateLabel, fontSize: Rpx(28, true) }}>
                  Suppression d’une consultation
                </p>
              </div>
              <div
                style={styles.emailNotificationsStateContainer}
                onKeyDown={() => {
                  setUserSettingsUseState((prevData) => ({
                    ...prevData,
                    deleteAppointmentEmailNotificationState: !prevData.deleteAppointmentEmailNotificationState,
                  }));
                }}
                tabIndex={0}
                role="button"
                onClick={() => {
                  setUserSettingsUseState((prevData) => ({
                    ...prevData,
                    deleteAppointmentEmailNotificationState: !prevData.deleteAppointmentEmailNotificationState,
                  }));
                }}
              >
                <ToggleButton
                  selected={userSettingsUseState.deleteAppointmentEmailNotificationState}
                  width={Rpx(40)}
                  height={Rpx(18)}
                />
                <p style={{ ...styles.emailNotificationsStateLabel, fontSize: Rpx(28, true) }}>
                  Suppression d’un rendez-vous
                </p>
              </div>
            </div>
            <div style={styles.emailNotificationsButtonsContainer}>
              <button
                style={{
                  ...styles.button,
                  height: Rpx(70, true),
                  fontSize: Rpx(28, true),
                  background:
                    JSON.stringify(userSettingsUseState) === JSON.stringify(userSettingsState)
                      ? DocColor.GRAY
                      : DocColor.MEDIUMBLUE,
                }}
                type="button"
                disabled={JSON.stringify(userSettingsUseState) === JSON.stringify(userSettingsState)}
                onClick={onSubmitClickHandler}
              >
                Valider
              </button>
              <button
                style={{ ...styles.button, height: Rpx(70, true), fontSize: Rpx(28, true) }}
                type="button"
                onClick={() => setChosenOption('')}
              >
                Annuler
              </button>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

const styles: StyleSheet = {
  container: {
    position: 'relative',
    width: '52.5%',
    marginLeft: '5%',
    outline: `${Rpx(10)} solid ${DocColor.DARKBLUE}`,
    borderRadius: Rpx(60),
    height: '100%',
    overflow: 'hidden',
  },
  titleDiv: {
    width: 'fit-content',
    background: DocColor.DARKBLUE,
    borderRadius: `0 0 ${Rpx(15)} ${Rpx(15)}`,
    minHeight: Rpx(45),
    padding: `0 ${Rpx(40)}`,
    margin: `0 auto`,
    alignItems: 'center',
    justifyContent: 'center',
  },
  titleParagraph: {
    color: 'white',
    fontWeight: 600,
    marginBottom: Rpx(10),
  },
  explanationParagraph: {
    color: DocColor.DARKBLUE,
    fontWeight: 700,
    padding: `0 ${Rpx(32)}`,
    marginTop: Rpx(40),
    textAlign: 'center',
  },
  horizontalLine: {
    width: '80%',
    background: DocColor.MEDIUMBLUE,
    height: Rpx(8),
    borderRadius: Rpx(4),
    marginTop: Rpx(30),
  },
  emailNotificationsContainer: {
    width: '90%',
    minHeight: Rpx(50),
    background: DocColor.CLOUDYBLUE,
    margin: `${Rpx(40)} 5% 0 5%`,
    padding: `${Rpx(30)} 0 0 0`,
    borderRadius: Rpx(30),
    justifyContent: 'center',
    alignItems: 'center',
  },
  emailNotificationsInfoTitle: {
    color: DocColor.DARKBLUE,
    fontWeight: 700,
    whiteSpace: 'nowrap',
  },
  emailNotificationsInfoContainer: {
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    gap: Rpx(20),
    margin: `${Rpx(20)} auto`,
  },
  emailNotificationsInfoParagraph: {
    width: '80%',
    color: DocColor.MEDIUMBLUE,
    fontWeight: 600,
  },
  emailNotificationsStateContainer: {
    width: '100%',
    cursor: 'pointer',
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: Rpx(20),
    marginLeft: '18%',
    gap: Rpx(15),
  },
  emailNotificationsStateLabel: {
    color: DocColor.DARKBLUE,
    fontWeight: 600,
    whiteSpace: 'nowrap',
  },
  emailNotificationsButtonsContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    width: '65%',
    margin: `${Rpx(40)} auto`,
  },
  button: {
    width: 'fit-content',
    backgroundColor: DocColor.DARKBLUE,
    color: 'white',
    fontWeight: 700,
    borderRadius: Rpx(12),
    flexDirection: 'row',
    padding: `0px ${Rpx(40)}`,
  },
};
