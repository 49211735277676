import React, { useState } from 'react';
import { MariageCertificateTemplateOverlay } from './MariageCertificateTemplateOverlay';
import { PhysicalMentalCertificateTemplateOverlay } from './physicalMentalFitnessCertificateTemplateOverlay';
import { GoodHealthCertificateTemplateOverlay } from './GoodHealthCertificateTemplateOverlay';
import { SportsExemptionCertificateTemplateOverlay } from './SportsExemptionCertificateTemplateOverlay';
import { DocColor, StyleSheet } from '../../../../assets/styles/constantStyles';
import { useLoaderContext } from '../../../../common/configuration/loaderContextProvider';
import { Rpx } from '../../../../common/usecases/helpers/DimensionsConverter';
import { Patient } from '../../../../patientsContext/domain/entities/Patient';

type OtherCertificatesComponentProps = {
  patient?: Patient | null;
  temporaryPatientName: string;
  disabled?: boolean;
};

export const OtherCertificatesComponent = ({
  patient,
  temporaryPatientName,
  disabled,
}: OtherCertificatesComponentProps) => {
  const loader = useLoaderContext();
  const [isMarriageCertificateTemplateOverlayClosed, setIsMarriageCertificateTemplateOverlayClosed] =
    useState<boolean>(true);
  const [
    isPhysicalMentalFitnessCertificateTemplateOverlayClosed,
    setIsPhysicalMentalFitnessCertificateTemplateOverlayClosed,
  ] = useState<boolean>(true);
  const [isGoodHealthCertificateTemplateOverlayClosed, setIsGoodHealthCertificateTemplateOverlayClosed] =
    useState<boolean>(true);
  const [isSportsExemptionCertificateTemplateOverlayClosed, setIsSportsExemptionCertificateTemplateOverlayClosed] =
    useState<boolean>(true);

  return (
    <div style={{ ...styles.container, outlineColor: disabled ? DocColor.GRAY : DocColor.DARKBLUE }}>
      {!isMarriageCertificateTemplateOverlayClosed && (
        <MariageCertificateTemplateOverlay
          onSuccess={() => {
            setIsMarriageCertificateTemplateOverlayClosed(true);
            loader.setMessage('');
          }}
          onFailure={() => {
            setIsMarriageCertificateTemplateOverlayClosed(true);
            loader.setMessage('');
          }}
          patient={patient}
          temporaryPatientName={temporaryPatientName}
        />
      )}
      {!isPhysicalMentalFitnessCertificateTemplateOverlayClosed && (
        <PhysicalMentalCertificateTemplateOverlay
          onSuccess={() => {
            setIsPhysicalMentalFitnessCertificateTemplateOverlayClosed(true);
            loader.setMessage('');
          }}
          onFailure={() => {
            setIsPhysicalMentalFitnessCertificateTemplateOverlayClosed(true);
            loader.setMessage('');
          }}
          patient={patient}
          temporaryPatientName={temporaryPatientName}
        />
      )}
      {!isGoodHealthCertificateTemplateOverlayClosed && (
        <GoodHealthCertificateTemplateOverlay
          onSuccess={() => {
            setIsGoodHealthCertificateTemplateOverlayClosed(true);
            loader.setMessage('');
          }}
          onFailure={() => {
            setIsGoodHealthCertificateTemplateOverlayClosed(true);
            loader.setMessage('');
          }}
          patient={patient}
          temporaryPatientName={temporaryPatientName}
        />
      )}
      {!isSportsExemptionCertificateTemplateOverlayClosed && (
        <SportsExemptionCertificateTemplateOverlay
          onSuccess={() => {
            setIsSportsExemptionCertificateTemplateOverlayClosed(true);
            loader.setMessage('');
          }}
          onFailure={() => {
            setIsSportsExemptionCertificateTemplateOverlayClosed(true);
            loader.setMessage('');
          }}
          patient={patient}
          temporaryPatientName={temporaryPatientName}
        />
      )}
      <div style={{ ...styles.titleDiv, background: disabled ? DocColor.GRAY : DocColor.DARKBLUE }}>
        <p key="firstHistoryDateDiv" style={{ ...styles.title, fontSize: Rpx(32, true) }}>
          Autres Certificats
        </p>
      </div>
      <div style={{ flexDirection: 'row', justifyContent: 'center', gap: '5%', marginTop: Rpx(20) }}>
        <button
          type="button"
          disabled={disabled}
          style={{
            ...styles.button,
            background: disabled ? DocColor.GRAY : DocColor.MEDIUMBLUE,
            fontSize: Rpx(24, true),
          }}
          onClick={() => {
            loader.setMessage('Ouverture du fichier en cours');
            setIsMarriageCertificateTemplateOverlayClosed(false);
          }}
        >
          Certificat de Mariage
        </button>
        <button
          type="button"
          disabled={disabled}
          style={{
            ...styles.button,
            background: disabled ? DocColor.GRAY : DocColor.MEDIUMBLUE,
            fontSize: Rpx(24, true),
          }}
          onClick={() => {
            loader.setMessage('Ouverture du fichier en cours');
            setIsPhysicalMentalFitnessCertificateTemplateOverlayClosed(false);
          }}
        >
          Certificat d’Aptitude physique et mentale
        </button>
      </div>
      <div style={{ flexDirection: 'row', justifyContent: 'center', gap: '5%', marginTop: Rpx(40) }}>
        <button
          type="button"
          disabled={disabled}
          style={{
            ...styles.button,
            background: disabled ? DocColor.GRAY : DocColor.MEDIUMBLUE,
            fontSize: Rpx(24, true),
          }}
          onClick={() => {
            loader.setMessage('Ouverture du fichier en cours');
            setIsGoodHealthCertificateTemplateOverlayClosed(false);
          }}
        >
          Certificat de Bonne santé
        </button>
        <button
          type="button"
          disabled={disabled}
          style={{
            ...styles.button,
            background: disabled ? DocColor.GRAY : DocColor.MEDIUMBLUE,
            fontSize: Rpx(24, true),
          }}
          onClick={() => {
            loader.setMessage('Ouverture du fichier en cours');
            setIsSportsExemptionCertificateTemplateOverlayClosed(false);
          }}
        >
          Certificat d’Exemption de sport
        </button>
      </div>
    </div>
  );
};

const styles: StyleSheet = {
  container: {
    position: 'relative',
    margin: `2vw 2vw 0`,
    outline: `${Rpx(10)} solid ${DocColor.DARKBLUE}`,
    borderRadius: Rpx(60),
    minHeight: Rpx(100),
    paddingBottom: Rpx(47),
  },
  titleDiv: {
    width: 'fit-content',
    background: DocColor.DARKBLUE,
    borderRadius: `${Rpx(50)} 0 ${Rpx(20)} 0`,
    minHeight: Rpx(60),
    padding: `0 ${Rpx(40)}`,
    marginBottom: Rpx(15),
  },
  title: {
    color: 'white',
    fontWeight: 600,
    fontStyle: 'italic',
    margin: 'auto',
  },
  button: {
    color: 'white',
    fontWeight: 700,
    width: Rpx(475),
    padding: `${Rpx(10)} ${Rpx(25)}`,
    borderRadius: Rpx(15),
    minHeight: Rpx(70),
  },
};
