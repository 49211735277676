import React from 'react';
import { StyleSheet } from '../../assets/styles/constantStyles';

export interface ProgressBarProps {
  progressPercentage: string;
  duration: string;
}
export const ProgressBar = ({ progressPercentage, duration }: ProgressBarProps) => (
  <div style={styles.progressContainer}>
    <div
      style={{
        ...styles.progressFill,
        width: progressPercentage,
        transitionDuration: duration,
      }}
    />
  </div>
);

const styles: StyleSheet = {
  progressContainer: {
    width: '100%',
    height: 5,
    backgroundColor: 'rgba(0, 0, 0, 0.44)',
    borderRadius: 10,
  },
  progressFill: {
    height: '100%',
    backgroundColor: '#000',
    borderRadius: 10,
    transition: 'all linear',
  },
};
