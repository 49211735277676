import * as React from 'react';
import { Color } from '../styles/constantStyles';

export interface DownLoadIconProps {
  color?: string;
  style?: React.CSSProperties;
  width?: string | number;
  height?: string | number;
}

export const DownLoadIcon = ({ color, width, height, style }: DownLoadIconProps) => (
  <svg style={style} width={width} height={height} viewBox="0 0 18 19" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M17 13V16C17 17.1046 16.1046 18 15 18H3C1.89543 18 1 17.1046 1 16L1 13M5 9L9 13M9 13L13 9M9 13V1"
      stroke={color || Color.MONOGREY3}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
