import { ENV } from '../../configuration';
import { RealUserSettingsInteractor } from '../adapter/real/RealUserSettingsInteractor';
import { UserSettingsInteractor } from '../domain/gateways/UserSettings.interactor';

export class UserSettingsContextFactory {
  static userSettingsInteractor(): UserSettingsInteractor {
    switch (ENV) {
      case 'prod':
      case 'dev':
        return new RealUserSettingsInteractor();
      default:
        return new RealUserSettingsInteractor();
    }
  }
}
