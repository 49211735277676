import { Action } from 'redux-actions';
import { loadUserSettingsSuccess } from './UserSettings.actions';
import { createTypedHandler, handleTypedActions } from '../../common/usecases/helpers/actions.helper';
import { UserSettingsState } from '../configuration/UserSettings.state';
import { UserSettings } from '../domain/entities/UserSettings';

const userSettingsState: UserSettingsState = {
  userSettings: undefined,
};

const loadUserSettingsReducer = (state: UserSettingsState, action: Action<UserSettings | undefined>) => ({
  ...state,
  userSettings: action.payload,
});

export const userSettingsReducer = handleTypedActions(
  [
    createTypedHandler(loadUserSettingsSuccess, loadUserSettingsReducer),
    // createTypedHandler(loadSSSuccess, loadSSReducer),
  ],
  userSettingsState,
);
