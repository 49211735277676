import React, { useCallback, useEffect, useState } from 'react';
import { Color, DocColor, StyleSheet } from '../../../../assets/styles/constantStyles';
import { CustomCheckBox } from '../../../../common/ui/CustomCheckButton';
import { Rpx } from '../../../../common/usecases/helpers/DimensionsConverter';

export interface ExaminationsToRequestOverlayProps {
  ExaminationToRequestType: string;
  optionsList: string[] | undefined;
  onCancelClick: () => void;
  isClosedState: boolean;
  setIsClosedState: React.Dispatch<React.SetStateAction<boolean>>;
  dataState: string[];
  onValidClick: (arrayList: string[]) => void;
}
export const ExaminationsToRequestOverlay = ({
  ExaminationToRequestType,
  optionsList,
  isClosedState,
  setIsClosedState,
  onCancelClick,
  dataState,
  onValidClick,
}: ExaminationsToRequestOverlayProps) => {
  const [data, setData] = useState<string[]>([]);

  useEffect(() => {
    document.addEventListener('keydown', escFunction, false);

    return () => {
      document.removeEventListener('keydown', escFunction, false);
    };
  }, []);

  useEffect(() => {
    if (!isClosedState) {
      setData(dataState);
    }
  }, [isClosedState]);

  const escFunction = useCallback((event) => {
    if (event.key === 'Escape') {
      setIsClosedState(true);
    }
  }, []);

  const addToData = (obj: string) => {
    const tempData: string[] = data;
    tempData.push(obj);
    setData([...tempData]);
  };
  const deleteFromData = (obj: string) => {
    setData(data.filter((item) => item !== obj));
  };
  return (
    <div
      style={{
        ...styles.container,
        display: isClosedState ? 'none' : '',
      }}
    >
      <div style={styles.confirmContainer}>
        <p
          style={{ ...styles.title, fontSize: Rpx(32, true) }}
        >{`Choisir les ${ExaminationToRequestType} à demander`}</p>
        <div style={{ flexFlow: 'row wrap' }}>
          {optionsList &&
            optionsList.map((obj) => (
              <div
                key={obj}
                style={{ flexDirection: 'row', width: 'fit-content', marginBottom: Rpx(15), flexBasis: '33.333333%' }}
              >
                <CustomCheckBox
                  checkBoxName={obj}
                  backgroundColor={DocColor.MEDIUMGRAY}
                  selected={data.includes(obj)}
                  width={Rpx(37, true)}
                  height={Rpx(37, true)}
                  activeColor={DocColor.MEDIUMBLUE}
                  checkBoxStyle="SQUARE"
                  onCheckBoxClick={() => (data.includes(obj) ? deleteFromData(obj) : addToData(obj))}
                />
              </div>
            ))}
        </div>

        <div style={styles.buttonsDiv}>
          <button
            style={{ ...styles.button, height: Rpx(75, true), fontSize: Rpx(30, true) }}
            type="button"
            onClick={() => {
              onValidClick(data);
              setIsClosedState(true);
              setData([]);
            }}
          >
            Valider
          </button>
          <button
            style={{ ...styles.button, height: Rpx(75, true), fontSize: Rpx(30, true), background: DocColor.DARKBLUE }}
            type="button"
            onClick={onCancelClick}
          >
            Annuler
          </button>
        </div>
      </div>
    </div>
  );
};

const styles: StyleSheet = {
  container: {
    position: 'fixed',
    inset: 0,
    width: '100%',
    height: '100%',
    backgroundColor: DocColor.HALFTRANSPARENTBLACK,
    zIndex: 7,
  },
  confirmContainer: {
    margin: 'auto',
    backgroundColor: 'white',
    minWidth: '30%',
    maxWidth: '60%',
    width: 'fit-content',
    height: 'fit-content',
    padding: `${Rpx(70)} ${Rpx(50)}`,
    borderRadius: Rpx(10),
    zIndex: 3,
    justifyContent: 'center',
    alignItems: 'center',
  },
  buttonsDiv: {
    width: '90%',
    marginTop: Rpx(65),
    flexDirection: 'row',
    justifyContent: 'end',
    gap: Rpx(50),
  },
  title: {
    fontWeight: 800,
    marginBottom: Rpx(70),
    outline: `${Rpx(7)} solid ${DocColor.DARKBLUE}`,
    borderRadius: Rpx(22),
    color: DocColor.DARKBLUE,
    padding: `${Rpx(20)} ${Rpx(30)}`,
    width: 'fit-content',
  },
  paragraph: {
    whiteSpace: 'nowrap',
    fontSize: Rpx(26),
    fontWeight: 600,
    marginLeft: Rpx(33),
  },
  button: {
    width: 'fit-content',
    backgroundColor: DocColor.MEDIUMBLUE,
    color: Color.APPWHITE,
    borderRadius: Rpx(15),
    flexDirection: 'row',
    cursor: 'pointer',
    padding: `0 ${Rpx(40)}`,
    fontWeight: 700,
    whiteSpace: 'nowrap',
  },
};
