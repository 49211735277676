import { User } from '../../../authContext/domain/entities/User';
import { fetchWithToken } from '../../../common/adapter/fetch.helper';
import { BACKEND_CONFIG } from '../../../configuration';
import { UserSettings } from '../../domain/entities/UserSettings';
import { UserSettingsInteractor } from '../../domain/gateways/UserSettings.interactor';

export class RealUserSettingsInteractor implements UserSettingsInteractor {
  async updateUsername(id: string, firstName: string, lastName: string) {
    const url = `${BACKEND_CONFIG.endpoints.userSettings}/updateUsername`;
    const response = await fetchWithToken(url, {
      method: 'PUT',
      body: JSON.stringify({ id, firstName, lastName }),
    });
    const result = await response.json();
    return result;
  }

  async updatePassword(id: string, currentPassword: string, newPassword: string): Promise<User> {
    const url = `${BACKEND_CONFIG.endpoints.userSettings}/updatePassword`;
    const response = await fetchWithToken(url, {
      method: 'PUT',
      body: JSON.stringify({ id, currentPassword, newPassword }),
    });
    const result = await response.json();
    return result;
  }

  async loadUserSettings() {
    const url = `${BACKEND_CONFIG.endpoints.userSettings}/loadUserSettings`;
    const response = await fetchWithToken(url); // need to do token stuff first
    const result = await response.json();
    return result;
  }

  async updateUserSettings(userSettings: UserSettings) {
    const url = `${BACKEND_CONFIG.endpoints.userSettings}/updateUserSettings`;
    const response = await fetchWithToken(url, {
      method: 'PUT',
      body: JSON.stringify(userSettings),
    });
    const result = await response.json();
    return result;
  }
}
