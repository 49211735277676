import React from 'react';

type Props = {
  children: React.ReactNode;
};
interface State {
  hasError: boolean;
}
export class ErrorBoundary extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  componentDidCatch(error: Error, errorInfo: any) {
    console.error(error, errorInfo);
  }

  render() {
    const { hasError } = this.state;
    const { children } = this.props;
    if (hasError) {
      return (
        <div style={{ justifyContent: 'center', alignItems: 'center' }}>
          <p>Something went wrong.</p>
        </div>
      );
    }

    return children;
  }
}
