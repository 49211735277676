export interface Mesures {
  consultationId?: string;
  temperature: number;
  pulse: number;
  bloodPressure: number;
  saturation: number;
  bloodSugar: number;
  imc: number;
  weight: number;
  height: number;
}
export const hardCodedEmptyMesures: Mesures = {
  temperature: 0,
  pulse: 0,
  bloodPressure: 0,
  saturation: 0,
  bloodSugar: 0,
  imc: 0,
  weight: 0,
  height: 0,
};

export const hardCodedMesures: Mesures = {
  temperature: 0,
  pulse: 10,
  bloodPressure: 20,
  saturation: 6,
  bloodSugar: 5,
  imc: 0,
  weight: 0,
  height: 2,
};
