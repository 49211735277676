import { CalendarEvent } from '../../domain/entities/CalendarEvent';
import { CalendarInteractor } from '../../domain/gateways/Calendar.interactor';

export class InMemoryCalendarInteractor implements CalendarInteractor {
  async loadCalendarEvents(): Promise<CalendarEvent[]> {
    return Promise.resolve([]);
  }

  insertCalendarEvent(): Promise<CalendarEvent> {
    throw new Error('Method not implemented.');
  }

  updateCalendarEvent(): Promise<CalendarEvent> {
    throw new Error('Method not implemented.');
  }

  deleteCalendarEvent(): Promise<boolean> {
    throw new Error('Method not implemented.');
  }

  loadNextAppointment(): Promise<CalendarEvent[]> {
    throw new Error('Method not implemented.');
  }

  loadProgramedAppointments(): Promise<CalendarEvent[]> {
    throw new Error('Method not implemented.');
  }
}
