import { connect } from 'react-redux';
import { dispatchAsync } from 'react-redux-dispatch-async';
import { Action } from 'redux';
import { AttachmentsDumb } from './Attachments.dumb';
import { NavigationPayload } from '../../../navigationContext/domain/entities/NavigationPayload';
import { loadPatientRequest } from '../../usecases/Patients.actions';
import {
  insertAttachmentRequest,
  loadAttachmentsRequest,
} from '../../../consultationsContext/usecases/Consultations.actions';
import { navigateToRequest } from '../../../navigationContext/usecases/navigation.actions';
import { Attachment } from '../../../consultationsContext/domain/entities/Attachments';

const mapState = () => ({
  // stateConnectedUser: getConnectedUser(state),
});

const mapDispatch = (dispatch: (a: Action) => void) => ({
  // navigateTo: (nav: NavigationPayload) => dispatch(navigateTo(nav)),
  loadPatientAsync: (patientId: string) => dispatchAsync(dispatch, loadPatientRequest(patientId)),
  loadAttachmentsAsync: (patientId: string, attachmentsLimit?: number, skipAttachments?: number) =>
    dispatchAsync(dispatch, loadAttachmentsRequest({ patientId, attachmentsLimit, skipAttachments })),
  insertAttachmentAsync: (attachment: Attachment) => dispatchAsync(dispatch, insertAttachmentRequest(attachment)),
  navigateToAsync: (nav: NavigationPayload) => dispatch(navigateToRequest(nav)),
});

export const AttachmentsSmart = connect(mapState, mapDispatch)(AttachmentsDumb);
