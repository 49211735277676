export interface Patient {
  id?: string;
  temporaryPatient?: string;
  temporaryPatientName?: string;
  firstName?: string;
  lastName?: string;
  birthDate?: Date;
  profession?: string;
  assurance?: string;
  familySituation?: string;
  address?: string;
  email?: string;
  phone?: string;
  identityCode?: string;
  gender?: string;
  nationality?: string;
  countConsultations?: number;
  lastConsultationId?: string;
  lastConsultationNumber?: number;
  lastConsultationDate?: Date;
  nextAppointment?: Date;
}
export const hardCodedEmptyPatient: Patient = {
  id: '',
  temporaryPatient: '',
  firstName: '',
  lastName: '',
  birthDate: undefined,
  profession: '',
  assurance: '',
  familySituation: '',
  address: '',
  email: '',
  phone: '',
  identityCode: '',
  gender: 'male',
  nationality: '',
  countConsultations: 0,
  lastConsultationId: '',
  lastConsultationDate: new Date(),
  nextAppointment: new Date(),
};

export const hardCodedPatients: Patient[] = [
  {
    id: 'p00111',
    temporaryPatient: '',
    firstName: 'fay',
    lastName: 'Diepie',
    birthDate: new Date(1996, 7, 10),
    familySituation: 'married',
    address: 'Quartier El kassaba bloc01 Nr08',
    identityCode: 'SH0000',
    profession: 'developer',
    assurance: '651dfgd165',
    email: 'jimmy.hendrix@example.com',
    phone: '+212664879865',
    gender: 'female',
    nationality: 'Morocco',
    countConsultations: 50,
    lastConsultationDate: new Date(2007, 7, 7),
    nextAppointment: new Date(2008, 8, 8),
  },
];
