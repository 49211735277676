import { connect } from 'react-redux';
import { Action } from 'redux';
import { dispatchAsync } from 'react-redux-dispatch-async';
import { ConsultationAdditionDumb } from './ConsultationAddition.dumb';
import {
  navigateBackRequest,
  navigateReplaceRequest,
  navigateToRequest,
} from '../../../navigationContext/usecases/navigation.actions';
import {
  fetchMedicinesRequest,
  getConsultationNumberRequest,
  insertAttachmentRequest,
  insertConsultationNotesRequest,
  insertConsultationReasonRequest,
  insertExaminationsRequest,
  insertExaminationsToRequestRequest,
  insertInterrogationRequest,
  insertMedicalCertificateRequest,
  insertMesuresRequest,
  insertPatientsConsultationRequest,
  insertPrescriptionsRequest,
  loadAttachmentsRequest,
  loadConsultationNotesRequest,
  loadConsultationReasonRequest,
  loadExaminationsRequest,
  loadExaminationsToRequestRequest,
  loadInterrogationRequest,
  loadMedicalCertificateRequest,
  loadMesuresRequest,
  loadPatientsConsultationsRequest,
  loadPrescriptionsRequest,
  sendAttachmentByMailRequest,
} from '../../usecases/Consultations.actions';
import { PatientConsultation } from '../../domain/entities/PatientConsultation';
import { NavigationPayload } from '../../../navigationContext/domain/entities/NavigationPayload';
import { ConsultationReason } from '../../domain/entities/ConsultationReason';
import { Interrogation } from '../../domain/entities/Interrogation';
import { Mesures } from '../../domain/entities/Mesures';
import { Examination } from '../../domain/entities/Examination';
import { ExaminationToRequest } from '../../domain/entities/ExaminationToRequest';
import { Prescription } from '../../domain/entities/Prescriptions';
import { MedicalCertificate } from '../../domain/entities/MedicalCertificate';
import { Attachment } from '../../domain/entities/Attachments';
import { getConnectedUser } from '../../../authContext/usecases/Auth.selectors';
import { AppState } from '../../../common/configuration/app.states';
import { ConsultationNotes } from '../../domain/entities/ConsultationNotes';
import {
  getTemporaryPatientNumberRequest,
  insertPatientRequest,
  loadPatientRequest,
  loadPatientsNamesRequest,
} from '../../../patientsContext/usecases/Patients.actions';
import { Patient } from '../../../patientsContext/domain/entities/Patient';

const mapState = (state: AppState) => ({
  // statePatients: getAllPatients(state),
  stateConnectedUser: getConnectedUser(state),
});

const mapDispatch = (dispatch: (a: Action) => void) => ({
  loadPatientAsync: (id: string) => dispatchAsync(dispatch, loadPatientRequest(id)),
  getTemporaryPatientNumberAsync: () => dispatchAsync(dispatch, getTemporaryPatientNumberRequest()),
  loadPatientsNamesAsync: () => dispatchAsync(dispatch, loadPatientsNamesRequest()),
  insertPatientAsync: (newPatient: Patient) => dispatchAsync(dispatch, insertPatientRequest(newPatient)),

  loadAttachmentsAsync: (patientId: string, attachmentsLimit?: number, skipAttachments?: number) =>
    dispatchAsync(dispatch, loadAttachmentsRequest({ patientId, attachmentsLimit, skipAttachments })),
  insertAttachmentAsync: (attachment: Attachment) => dispatchAsync(dispatch, insertAttachmentRequest(attachment)),
  getConsultationNumberAsync: () => dispatchAsync(dispatch, getConsultationNumberRequest()),
  /* loadPatientConsultationAsync: (consultationId: string) =>
    dispatchAsync(dispatch, loadPatientConsultationRequest(consultationId)), */
  loadPatientsConsultationsAsync: (patientId?: string, consultationsLimit?: number, skipConsultations?: number) =>
    dispatchAsync(
      dispatch,
      loadPatientsConsultationsRequest({
        patientId,
        consultationsLimit,
        skipConsultations,
      }),
    ),
  insertPatientConsultationAsync: (patientConsultation: PatientConsultation) =>
    dispatchAsync(dispatch, insertPatientsConsultationRequest(patientConsultation)),
  loadConsultationReasonAsync: (consultationId: string) =>
    dispatchAsync(dispatch, loadConsultationReasonRequest(consultationId)),
  insertConsultationReasonAsync: (consultationReason: ConsultationReason) =>
    dispatchAsync(dispatch, insertConsultationReasonRequest(consultationReason)),
  loadInterrogationAsync: (consultationId: string) => dispatchAsync(dispatch, loadInterrogationRequest(consultationId)),
  insertInterrogationAsync: (interrogation: Interrogation) =>
    dispatchAsync(dispatch, insertInterrogationRequest(interrogation)),
  loadConsultationNotesAsync: (consultationId: string) =>
    dispatchAsync(dispatch, loadConsultationNotesRequest(consultationId)),
  insertConsultationNotesAsync: (consultationNotes: ConsultationNotes) =>
    dispatchAsync(dispatch, insertConsultationNotesRequest(consultationNotes)),
  loadMesuresAsync: (consultationId: string) => dispatchAsync(dispatch, loadMesuresRequest(consultationId)),
  insertMesuresAsync: (mesures: Mesures) => dispatchAsync(dispatch, insertMesuresRequest(mesures)),
  loadExaminationsAsync: (consultationId: string) => dispatchAsync(dispatch, loadExaminationsRequest(consultationId)),
  insertExaminationsAsync: (patientId: string, consultationId: string, examinations: Examination[]) =>
    dispatchAsync(dispatch, insertExaminationsRequest({ patientId, consultationId, examinations })),
  sendAttchmentByMailAsync: (mail: string, fileName: string, fileContent: string) =>
    dispatchAsync(dispatch, sendAttachmentByMailRequest({ mail, fileName, fileContent })),
  loadExaminationsToRequestAsync: (consultationId: string) =>
    dispatchAsync(dispatch, loadExaminationsToRequestRequest(consultationId)),
  insertExaminationsToRequestAsync: (consultationId: string, examinationsToRequest: ExaminationToRequest[]) =>
    dispatchAsync(dispatch, insertExaminationsToRequestRequest({ consultationId, examinationsToRequest })),
  loadPrescriptionsAsync: (consultationId: string) => dispatchAsync(dispatch, loadPrescriptionsRequest(consultationId)),
  insertPrescriptionsAsync: (consultationId: string, prescriptions: Prescription[]) =>
    dispatchAsync(dispatch, insertPrescriptionsRequest({ consultationId, prescriptions })),
  fetchMedicinesAsync: (value?: string, composition?: string) =>
    dispatchAsync(dispatch, fetchMedicinesRequest({ value, composition })),
  loadMedicalCertificateAsync: (consultationId: string) =>
    dispatchAsync(dispatch, loadMedicalCertificateRequest(consultationId)),
  insertMedicalCertificateAsync: (medicalCertificate: MedicalCertificate) =>
    dispatchAsync(dispatch, insertMedicalCertificateRequest(medicalCertificate)),
  navigateToAsync: (nav: NavigationPayload) => dispatch(navigateToRequest(nav)),
  navigateReplace: (nav: NavigationPayload) => dispatch(navigateReplaceRequest(nav)),
  navigateBack: () => dispatch(navigateBackRequest()),
  // navigateTo: (nav: NavigationPayload) => dispatch(navigateTo(nav)),
});

export const ConsultationAdditionSmart = connect(mapState, mapDispatch)(ConsultationAdditionDumb);
