import * as React from 'react';
import { Color } from '../styles/constantStyles';

export interface NoteBlankIconProps {
  color?: string;
  style?: React.CSSProperties;
  width?: string | number;
  height?: string | number;
}

export const NoteBlankIcon = ({ color, width, height, style }: NoteBlankIconProps) => (
  <svg style={style} width={width} height={height} viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M19.5875 27H6C5.73478 27 5.48043 26.8946 5.29289 26.7071C5.10536 26.5196 5 26.2652 5 26V6C5 5.73478 5.10536 5.48043 5.29289 5.29289C5.48043 5.10536 5.73478 5 6 5H26C26.2652 5 26.5196 5.10536 26.7071 5.29289C26.8946 5.48043 27 5.73478 27 6V19.5875C27.0005 19.7174 26.9753 19.846 26.926 19.9661C26.8766 20.0862 26.8041 20.1955 26.7125 20.2875L20.2875 26.7125C20.1955 26.8041 20.0862 26.8766 19.9661 26.926C19.846 26.9753 19.7174 27.0005 19.5875 27V27Z"
      stroke={color || Color.MONOGREY3}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M26.9125 20H20V26.9125"
      stroke={color || Color.MONOGREY3}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
