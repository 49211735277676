import React, { useState } from 'react';
import { DispatchAsyncResult } from 'react-redux-dispatch-async/lib/dispatchAsync';
import { DocColor, StyleSheet } from '../../../../assets/styles/constantStyles';
import { User } from '../../../../authContext/domain/entities/User';
import { useNotificationContext } from '../../../../common/configuration/notificationContextProvider';
import { ToggleButton } from '../../../../common/ui/ToggleButton';
import { Rpx } from '../../../../common/usecases/helpers/DimensionsConverter';
import { UserSettings } from '../../../domain/entities/UserSettings';

interface FontSizeComponentProps {
  userSettingsState: UserSettings;
  loadUserSettingsAsync(): Promise<DispatchAsyncResult<User>>;
  updateUserSettingsAsync(userSettings: UserSettings): Promise<DispatchAsyncResult<UserSettings>>;
  setChosenOption: React.Dispatch<React.SetStateAction<string>>;
}

export const FontSizeComponent = ({
  userSettingsState,
  loadUserSettingsAsync,
  updateUserSettingsAsync,
  setChosenOption,
}: FontSizeComponentProps) => {
  const notification = useNotificationContext();
  const [userSettingsUseState, setUserSettingsUseState] = useState<UserSettings>(userSettingsState);

  const onSubmitClickHandler = () => {
    if (JSON.stringify(userSettingsUseState) !== JSON.stringify(userSettingsState)) {
      updateUserSettingsAsync(userSettingsUseState).then((res) => {
        if (res.success) {
          loadUserSettingsAsync();
          setChosenOption('');
          notification.setOnSuccess({
            message: `Vos préférences ont été mis à jour.`,
          });
        } else {
          notification.setOnError({
            message: `Nous n'avons pas pu mettre à jour votre préférences.`,
          });
        }
      });
    }
  };

  return (
    <div style={styles.container}>
      <div style={styles.titleDiv}>
        <p style={{ ...styles.titleParagraph, fontSize: Rpx(23, true) }}>Taille de police</p>
      </div>
      <div style={{ margin: 'auto', alignItems: 'center' }}>
        <p style={{ ...styles.explanationParagraph, fontSize: Rpx(28, true) }}>
          Permet de changer la taille de la police du moniteur.
        </p>
        <div style={styles.horizontalLine} />
        <div style={styles.fontSizeContainer}>
          <div
            style={styles.fontSizeStateContainer}
            onKeyDown={() => {
              setUserSettingsUseState((prevData) => ({
                ...prevData,
                fontSizeState: 'S',
              }));
            }}
            tabIndex={0}
            role="button"
            onClick={() => {
              setUserSettingsUseState((prevData) => ({
                ...prevData,
                fontSizeState: 'S',
              }));
            }}
          >
            <ToggleButton
              selected={userSettingsUseState.fontSizeState === 'S'}
              width={Rpx(40, true)}
              height={Rpx(18, true)}
            />
            <p style={{ ...styles.fontSizeStateLabel, fontSize: Rpx(27, true, 'S') }}>Police plus petite</p>
          </div>
          <div
            style={styles.fontSizeStateContainer}
            onKeyDown={() => {
              setUserSettingsUseState((prevData) => ({
                ...prevData,
                fontSizeState: 'M',
              }));
            }}
            tabIndex={0}
            role="button"
            onClick={() => {
              setUserSettingsUseState((prevData) => ({
                ...prevData,
                fontSizeState: 'M',
              }));
            }}
          >
            <ToggleButton
              selected={userSettingsUseState.fontSizeState === 'M'}
              width={Rpx(40, true)}
              height={Rpx(18, true)}
            />
            <p style={styles.fontSizeStateLabel}>Police moyenne</p>
          </div>
          <div
            style={styles.fontSizeStateContainer}
            onKeyDown={() => {
              setUserSettingsUseState((prevData) => ({
                ...prevData,
                fontSizeState: 'L',
              }));
            }}
            tabIndex={0}
            role="button"
            onClick={() => {
              setUserSettingsUseState((prevData) => ({
                ...prevData,
                fontSizeState: 'L',
              }));
            }}
          >
            <ToggleButton
              selected={userSettingsUseState.fontSizeState === 'L'}
              width={Rpx(40, true)}
              height={Rpx(18, true)}
            />
            <p style={{ ...styles.fontSizeStateLabel, fontSize: Rpx(27, true, 'L') }}>Police plus grande</p>
          </div>
        </div>
        <div style={styles.fontSizeButtonsContainer}>
          <button
            style={{
              ...styles.button,
              height: Rpx(70, true),
              fontSize: Rpx(28, true),
              background:
                JSON.stringify(userSettingsUseState) === JSON.stringify(userSettingsState)
                  ? DocColor.GRAY
                  : DocColor.MEDIUMBLUE,
            }}
            type="button"
            disabled={JSON.stringify(userSettingsUseState) === JSON.stringify(userSettingsState)}
            onClick={onSubmitClickHandler}
          >
            Valider
          </button>
          <button
            style={{ ...styles.button, height: Rpx(70, true), fontSize: Rpx(28, true) }}
            type="button"
            onClick={() => setChosenOption('')}
          >
            Annuler
          </button>
        </div>
      </div>
    </div>
  );
};

const styles: StyleSheet = {
  container: {
    position: 'relative',
    width: '52.5%',
    marginLeft: '5%',
    outline: `${Rpx(10)} solid ${DocColor.DARKBLUE}`,
    borderRadius: Rpx(60),
    height: '100%',
    overflow: 'hidden',
  },
  titleDiv: {
    width: 'fit-content',
    background: DocColor.DARKBLUE,
    borderRadius: `0 0 ${Rpx(15)} ${Rpx(15)}`,
    minHeight: Rpx(45),
    padding: `0 ${Rpx(40)}`,
    margin: `0 auto`,
    alignItems: 'center',
    justifyContent: 'center',
  },
  titleParagraph: {
    color: 'white',
    fontWeight: 600,
    marginBottom: Rpx(10),
  },
  explanationParagraph: {
    color: DocColor.DARKBLUE,
    fontWeight: 700,
    padding: `0 ${Rpx(32)}`,
    textAlign: 'center',
  },
  horizontalLine: {
    width: '80%',
    background: DocColor.MEDIUMBLUE,
    height: Rpx(8),
    borderRadius: Rpx(4),
    marginTop: Rpx(60),
  },
  fontSizeContainer: {
    width: '90%',
    minHeight: Rpx(50),
    background: DocColor.CLOUDYBLUE,
    margin: `${Rpx(60)} 5% 0 5%`,
    padding: `${Rpx(30)} 0 0 0`,
    borderRadius: Rpx(30),
    justifyContent: 'center',
    alignItems: 'center',
  },
  fontSizeStateContainer: {
    width: '100%',
    cursor: 'pointer',
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: Rpx(40),
    marginLeft: '15%',
    gap: Rpx(15),
  },
  fontSizeStateLabel: {
    color: DocColor.DARKBLUE,
    fontSize: Rpx(27),
    fontWeight: 600,
  },
  fontSizeButtonsContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    width: '65%',
    margin: `${Rpx(40)} auto`,
  },
  button: {
    width: 'fit-content',
    backgroundColor: DocColor.DARKBLUE,
    color: 'white',
    fontWeight: 700,
    borderRadius: Rpx(12),
    flexDirection: 'row',
    padding: `0px ${Rpx(40)}`,
  },
};
