import _ from 'lodash';
import { isMobile } from 'react-device-detect';

export default class CommonFunctions {
  static containsLetter = (str: string | undefined) => {
    const regExp = /[a-zA-Z]/g;
    if (str) {
      return regExp.test(str);
    }
    return false;
  };

  static containsNumber = (str: string | undefined) => {
    const regExp = /\d/;
    if (str) {
      return regExp.test(str);
    }
    return false;
  };

  static containsNoOtherUnwantedChars = (str: string | undefined) => {
    if (str) {
      return !str.includes(' ') && !str.includes('"') && !str.includes('^');
    }
    return false;
  };

  static sleep = (ms: number) =>
    new Promise((resolve) => {
      setTimeout(resolve, ms);
    });

  static isValidEmail = (email: string | undefined) => {
    const re =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (typeof email === 'string' && email.length) {
      return re.test(_.trim(String(email).toLowerCase()));
    }
    return false;
  };

  static mobileSized = () => {
    const res = isMobile || window.innerWidth < 800;
    return res;
  };

  static searchArrayForString = (SearchQuery: string, searchPool: any[]) => {
    if (_.trim(SearchQuery).length === 0) {
      return searchPool;
    }
    const trimmedQuery: string = _.trim(SearchQuery).toLowerCase();
    const foundResults: any[] = searchPool.filter((item) => JSON.stringify(item).toLowerCase().includes(trimmedQuery));
    return foundResults;
  };

  static patientStatusString = (progress: number, max: number) => {
    let status = 'Offen';
    if (progress > 0) {
      status = 'Begonnen';
    }
    if (progress === max) {
      status = 'Abgeschlossen';
    }
    if (progress > max) {
      status = `Error ${progress}/${max}`;
    }
    return status;
  };

  static isEmptyOrSpaces(str: string) {
    return str === null || str.match(/^ *$/) !== null;
  }

  static numberToOurDate = (date: number) => {
    const realDate = new Date(date);
    return `${realDate.toString().substring(8, 10)}.${realDate.getMonth() + 1 < 10 ? '0' : ''}${
      realDate.getMonth() + 1
    }.${realDate.getFullYear()}`;
  };

  static dateToDateInputValue = (date: Date) => {
    const getDateAsMM = date.getDate() < 10 ? `0${date.getDate()}` : `${date.getDate()}`;
    const month = date.getMonth() + 1;
    const getMonthAsMM = month < 10 ? `0${month}` : `${month}`;
    return `${date.getFullYear()}-${getMonthAsMM}-${getDateAsMM}`;
  };

  static dateToDateTimeInputValue = (date: Date) => {
    const getDateAsMM = date.getDate() < 10 ? `0${date.getDate()}` : `${date.getDate()}`;
    const month = date.getMonth() + 1;
    const getMonthAsMM = month < 10 ? `0${month}` : `${month}`;
    return `${date.getFullYear()}-${getMonthAsMM}-${getDateAsMM}T${CommonFunctions.padTo2Digits(
      date.getHours(),
    )}:${CommonFunctions.padTo2Digits(date.getMinutes())}`;
  };

  static formatDate = (date: Date) => {
    const d: Date = new Date(date);
    const formattedDate = `${d.getDate() < 10 ? `0${d.getDate()}` : d.getDate()}\\${
      d.getMonth() + 1 < 10 ? `0${d.getMonth() + 1}` : d.getMonth() + 1
    }\\${d.getFullYear()}`;
    return formattedDate;
  };

  static getBase64 = (file: File | null) =>
    new Promise<string>((resolve) => {
      const reader = new FileReader();
      if (file) {
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result ? reader.result.toString() : '');
      } else {
        resolve('');
      }
    });

  static padTo2Digits = (num: number) => String(num).padStart(2, '0');

  static isPasswordWeak = (password: string) => {
    const mediumRegex = /^(((?=.*[a-z])(?=.*[A-Z]))|((?=.*[a-z])(?=.*[0-9]))|((?=.*[A-Z])(?=.*[0-9])))(?=.{6,})/;
    if (password.length < 6) {
      return true;
    }
    if (!mediumRegex.test(password)) {
      return true;
    }
    return false;
  };

  static calculateDateDifferenceInMinutes = (firstDate: Date, secondDate: Date) => {
    const result = Math.ceil(secondDate.getTime() - firstDate.getTime()) / (1000 * 60);
    return result;
  };

  static addMinutes = (date: Date, minutes: number) => new Date(date.getTime() + minutes * 60000);

  static objectsEqual = (o1: any, o2: any) =>
    Object.keys(o1).length === Object.keys(o2).length && Object.keys(o1).every((p) => o1[p] === o2[p]);
}
