import React, { useState } from 'react';
import { useMediaQuery } from 'react-responsive';
import { Patient } from '../../../domain/entities/Patient';
import { DocColor, StyleSheet } from '../../../../assets/styles/constantStyles';
import { EyeIcon } from '../../../../assets/svg/EyeIcon';
import { NavigationPayload } from '../../../../navigationContext/domain/entities/NavigationPayload';
import { CircledToRightArrow } from '../../../../assets/svg/CircledToRightArrow';
import { PatientIcon } from '../../../../assets/svg/PatientIcon';
import { PhoneIcon } from '../../../../assets/svg/PhoneIcon';
import { EmailIcon } from '../../../../assets/svg/EmailIcon';
import { Rpx, SRpx } from '../../../../common/usecases/helpers/DimensionsConverter';
import CommonFunctions from '../../../../common/usecases/helpers/CommonFunctions';
import { bigScreenBreakpoint } from '../../../../common/domain/entities/ReactResponsiveBreakpoints';

interface PatientRowProps {
  data: Patient;
  navigateToAsync: (p: NavigationPayload) => void;
}
export const PatientRow = ({ data, navigateToAsync }: PatientRowProps) => {
  // isBigScreen working as a media breakpoint
  const isBigScreen = useMediaQuery({ query: `(min-width: ${bigScreenBreakpoint}px)` });

  const [isExpanded, setIsExpanded] = useState<boolean>(false);
  const patientBirthDay: Date | undefined = data.birthDate ? new Date(data.birthDate) : undefined;
  const today = Date.now();
  let age: number | undefined;
  if (patientBirthDay) {
    const msDiff = today - patientBirthDay.getTime();
    age = Math.floor(msDiff / (365.25 * 24 * 60 * 60 * 1000));
  }
  const lastConsultationDate: Date | undefined = data.lastConsultationDate
    ? new Date(data.lastConsultationDate)
    : undefined;
  const nextAppointmentDate: Date | undefined = data.nextAppointment ? new Date(data.nextAppointment) : undefined;

  const navToPatientDetails = () => {
    navigateToAsync({ routeName: 'Patient details', params: data.id });
  };

  const showPatientConsultationsHandler = () => {
    navigateToAsync({
      routeName: 'Mes Consultations',
      params: {
        patient: data,
      },
    });
  };

  const showConsultationInformationHandler = () => {
    navigateToAsync({
      routeName: 'Consultation Addition',
      params: {
        patientId: data.id,
        consultationId: data.lastConsultationId,
        consultationNumber: data.lastConsultationNumber,
        isOnlyReadableConsultation: true,
      },
    });
  };

  const expandRowHandler = () => {
    setIsExpanded((v) => !v);
  };

  return (
    <div>
      <div
        role="button"
        tabIndex={0}
        onClick={expandRowHandler}
        onKeyPress={expandRowHandler}
        style={
          isBigScreen
            ? { ...styles.fullRowContainer, ...styles.rowContainer, height: Rpx(70, true) }
            : {
                ...styles.fullRowContainer,
                ...responsiveStyles.fullRowContainer,
                ...styles.rowContainer,
                ...responsiveStyles.rowContainer,
                height: SRpx(45, true),
              }
        }
      >
        <div
          style={{
            flexDirection: 'row',
            width: '90%',
          }}
        >
          <div
            style={isBigScreen ? { ...styles.rowDivision } : { ...styles.rowDivision, ...responsiveStyles.rowDivision }}
          >
            <PatientIcon
              color={DocColor.MEDIUMBLUE}
              style={styles.icon}
              width={isBigScreen ? Rpx(27, true) : SRpx(20, true)}
              height={isBigScreen ? Rpx(27, true) : SRpx(20, true)}
            />
            <div
              style={
                isBigScreen ? { ...styles.verticalLine } : { ...styles.verticalLine, ...responsiveStyles.verticalLine }
              }
            />
            <p
              style={{
                ...styles.paragraph,
                textTransform: 'capitalize',
                fontSize: isBigScreen ? Rpx(25, true) : SRpx(15, true),
              }}
              title={
                data.temporaryPatient === null ? `${data.firstName} ${data.lastName}` : `Temp ${data.temporaryPatient}`
              }
            >
              {data.temporaryPatient === null ? `${data.firstName} ${data.lastName}` : `Temp ${data.temporaryPatient}`}
            </p>
          </div>

          <div style={{ ...styles.rowDivision, display: !isBigScreen ? 'none' : '' }}>
            <PhoneIcon color={DocColor.MEDIUMBLUE} style={styles.icon} width={Rpx(27, true)} height={Rpx(27, true)} />
            <div style={styles.verticalLine} />
            <p
              style={{
                ...styles.paragraph,
                fontSize: Rpx(25, true),
                color: data.phone ? DocColor.DARKBLUE : DocColor.GRAY,
              }}
              title={data.phone ? data.phone : 'Vide'}
            >
              {data.phone ? data.phone : 'Vide'}
            </p>
          </div>

          <div style={{ ...styles.rowDivision, display: !isBigScreen ? 'none' : '' }}>
            <EmailIcon color={DocColor.MEDIUMBLUE} style={styles.icon} width={Rpx(27, true)} height={Rpx(27, true)} />
            <div style={styles.verticalLine} />
            <p
              style={{
                ...styles.paragraph,
                fontSize: Rpx(25, true),
                color: data.email ? DocColor.DARKBLUE : DocColor.GRAY,
              }}
              title={data.email ? data.email : 'Vide'}
            >
              {data.email ? data.email : 'Vide'}
            </p>
          </div>
        </div>

        <div
          role="button"
          tabIndex={0}
          onClick={navToPatientDetails}
          onKeyPress={navToPatientDetails}
          style={{ flexDirection: 'row', margin: '0 0 0 auto' }}
        >
          <div
            style={
              isBigScreen ? { ...styles.verticalLine } : { ...styles.verticalLine, ...responsiveStyles.verticalLine }
            }
          />
          <EyeIcon
            color={DocColor.MEDIUMBLUE}
            style={styles.icon}
            width={isBigScreen ? Rpx(27, true) : SRpx(20, true)}
            height={isBigScreen ? Rpx(27, true) : SRpx(20, true)}
          />
        </div>
      </div>
      <div
        style={
          isBigScreen
            ? {
                ...styles.fullRowContainer,
                ...styles.expandedRowContainer,
                maxHeight: isExpanded ? Rpx(400) : 0,
              }
            : {
                ...styles.fullRowContainer,
                ...responsiveStyles.fullRowContainer,
                ...styles.expandedRowContainer,
                ...responsiveStyles.expandedRowContainer,
                maxHeight: isExpanded ? SRpx(200) : 0,
              }
        }
      >
        <div
          style={{
            flexFlow: 'row wrap',
            justifyContent: 'space-between',
            marginTop: isBigScreen ? Rpx(13) : SRpx(7),
            gap: isBigScreen ? Rpx(13) : SRpx(7),
          }}
        >
          <p
            style={{
              ...styles.expandedParagraph,
              display: !isBigScreen ? 'contents' : 'unset',
              fontSize: isBigScreen ? Rpx(25, true) : SRpx(14, true),
            }}
          >
            Code du patient : <span style={styles.expandedSpan}>{data.id ? data.id.slice(-5).toUpperCase() : '-'}</span>
          </p>
          <p
            style={{
              ...styles.expandedParagraph,
              display: !isBigScreen ? 'contents' : 'unset',
              fontSize: isBigScreen ? Rpx(25, true) : SRpx(14, true),
            }}
          >
            Age : <span style={styles.expandedSpan}>{data.temporaryPatient === null ? age : '-'}</span>
          </p>
          <p
            style={{
              ...styles.expandedParagraph,
              display: !isBigScreen ? 'contents' : 'unset',
              fontSize: isBigScreen ? Rpx(25, true) : SRpx(14, true),
            }}
          >
            Sexe :{' '}
            <span style={styles.expandedSpan}>
              {data.temporaryPatient === null ? (data.gender === 'male' ? 'M' : 'F') : '-'}
            </span>
          </p>
          <p
            style={{
              ...styles.expandedParagraph,
              display: !isBigScreen ? 'contents' : 'unset',
              fontSize: isBigScreen ? Rpx(25, true) : SRpx(14, true),
            }}
          >
            Nombre total des consultations : <span style={styles.expandedSpan}>{data.countConsultations}</span>
          </p>
        </div>
        <div
          style={{
            flexFlow: 'row wrap',
            justifyContent: 'space-between',
            marginTop: isBigScreen ? Rpx(13) : SRpx(7),
            gap: isBigScreen ? Rpx(13) : SRpx(7),
          }}
        >
          <p
            style={{
              ...styles.expandedParagraph,
              display: !isBigScreen ? 'contents' : 'unset',
              fontSize: isBigScreen ? Rpx(25, true) : SRpx(14, true),
            }}
          >
            Dernière consultation le :
            <span style={styles.expandedSpan}>
              {lastConsultationDate ? CommonFunctions.formatDate(lastConsultationDate) : '-'}
            </span>
          </p>
          <button
            type="button"
            style={{
              ...styles.expandedRowButtons,
              ...responsiveStyles.expandedRowButtons,
              display: isBigScreen ? 'none' : '',
              fontSize: SRpx(12),
            }}
            onClick={() => {
              if (data.countConsultations && data.countConsultations > 0) {
                showConsultationInformationHandler();
              }
            }}
          >
            Voir le résumé de la consultation
          </button>
          <div
            style={{
              flexDirection: 'row',
              margin: '0 0 0 auto',
              cursor: 'pointer',
              display: !isBigScreen ? 'none' : '',
            }}
            onClick={() => {
              if (data.countConsultations && data.countConsultations > 0) {
                showConsultationInformationHandler();
              }
            }}
            role="button"
            tabIndex={0}
            onKeyPress={() => {
              if (data.countConsultations && data.countConsultations > 0) {
                showConsultationInformationHandler();
              }
            }}
          >
            <CircledToRightArrow
              color={data.countConsultations === 0 ? DocColor.GRAY : DocColor.DARKBLUE}
              width={Rpx(22, true)}
              height={Rpx(22, true)}
              style={{ margin: `auto ${Rpx(5)} auto` }}
            />
            <p
              style={{
                ...styles.expandedParagraph,
                fontSize: Rpx(25, true),
                ...styles.lastConsultationResume,
                color: data.countConsultations === 0 ? DocColor.GRAY : DocColor.DARKBLUE,
              }}
            >
              Voir le résumé de la consultation
            </p>
          </div>
        </div>
        <div
          style={{
            flexFlow: 'row wrap',
            justifyContent: 'space-between',
            marginTop: isBigScreen ? Rpx(13) : SRpx(7),
            marginBottom: isBigScreen ? Rpx(13) : SRpx(10),
            gap: isBigScreen ? Rpx(13) : SRpx(7),
          }}
        >
          <p
            style={{
              ...styles.expandedParagraph,
              display: !isBigScreen ? 'contents' : 'unset',
              fontSize: isBigScreen ? Rpx(25, true) : SRpx(14, true),
            }}
          >
            Prochain RDV le :{' '}
            <span style={styles.expandedSpan}>
              {nextAppointmentDate
                ? `${CommonFunctions.formatDate(nextAppointmentDate)} ${CommonFunctions.padTo2Digits(
                    nextAppointmentDate.getHours(),
                  )}h${CommonFunctions.padTo2Digits(nextAppointmentDate.getMinutes())}`
                : '-'}
            </span>
          </p>
          <button
            type="button"
            style={
              isBigScreen
                ? { ...styles.expandedRowButtons }
                : {
                    ...styles.expandedRowButtons,
                    ...responsiveStyles.expandedRowButtons,
                    fontSize: SRpx(11),
                  }
            }
            onClick={() => {
              showPatientConsultationsHandler();
            }}
          >
            Accéder à l’historique de Consultation du patient
          </button>
        </div>
      </div>
    </div>
  );
};

const styles: StyleSheet = {
  fullRowContainer: {
    width: '85%',
    borderRadius: Rpx(9),
    padding: `0 ${Rpx(20)}`,
    boxSizing: 'border-box',
  },
  rowContainer: {
    flexDirection: 'row',
    cursor: 'pointer',
    backgroundColor: DocColor.LIGHTGRAY,
    margin: `${Rpx(15)} auto 0 auto`,
  },
  rowDivision: {
    flexDirection: 'row',
    width: '33.33%',
  },
  expandedRowContainer: {
    width: '83%',
    flexDirection: 'column',
    backgroundColor: DocColor.LIGHTGRAY,
    height: 'fit-content',
    margin: `${Rpx(5)} auto 0 auto`,
    overflow: 'hidden',
    transition: 'all 0.8s ease 0s',
  },
  paragraph: {
    width: 'fit-content',
    height: 'fit-content',
    margin: 'auto 0 auto 0',
    color: DocColor.DARKBLUE,
    fontWeight: 500,
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
  },
  icon: { margin: 'auto 0' },
  verticalLine: {
    height: '60%',
    width: Rpx(2),
    background: DocColor.GRAY,
    margin: `auto ${Rpx(22)}`,
  },
  expandedParagraph: {
    color: DocColor.DARKBLUE,
    fontWeight: 400,
  },
  expandedSpan: {
    fontWeight: 700,
    color: DocColor.DARKBLUE,
  },
  lastConsultationResume: {
    color: DocColor.DARKBLUE,
    fontWeight: 600,
    fontStyle: 'italic',
  },
  expandedRowButtons: {
    fontWeight: 600,
    color: 'white',
    margin: 'auto 0 auto auto',
    cursor: 'pointer',
    fontSize: Rpx(27),
    background: DocColor.MEDIUMBLUE,
    height: Rpx(47),
    borderRadius: Rpx(12),
    padding: `0 ${Rpx(10)}`,
  },
};

const responsiveStyles: StyleSheet = {
  fullRowContainer: {
    width: '90%',
    borderRadius: SRpx(5),
    padding: `0 ${SRpx(10)}`,
  },
  rowContainer: {
    margin: `${SRpx(8)} auto 0 auto`,
  },
  rowDivision: {
    width: '100%',
  },
  expandedRowContainer: {
    width: '86%',
    margin: `${SRpx(5)} auto 0 auto`,
    backgroundColor: DocColor.CLOUDYBLUE,
    padding: '0 5%',
  },
  verticalLine: {
    height: '80%',
    width: SRpx(1),
    margin: `auto ${SRpx(12)}`,
  },
  expandedRowButtons: {
    margin: `0 auto`,
    width: '95%',
    height: SRpx(26),
    borderRadius: SRpx(6),
    padding: `0 ${SRpx(5)}`,
  },
};
