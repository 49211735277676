export interface PatientConsultation {
  id?: string;
  number?: string;
  patientId?: string;
  firstName?: string;
  lastName?: string;
  temporaryPatient?: string;
  startDateTime: Date;
  endDateTime: Date;
  consultationReason?: string;
}

export const hardCodedEmptyPatientConsultation: PatientConsultation = {
  startDateTime: new Date(),
  endDateTime: new Date(),
};

export const hardCodedPatientsConsultations: PatientConsultation[] = [
  {
    id: '1',
    patientId: 'p00111',
    startDateTime: new Date(2022, 4, 24, 8, 15),
    endDateTime: new Date(2022, 4, 24, 10, 45),
  },
  {
    id: '2',
    patientId: 'p00111',
    startDateTime: new Date(2022, 4, 24, 10, 15),
    endDateTime: new Date(2022, 4, 24, 12, 45),
  },
  {
    id: '3',
    patientId: 'p00111',
    startDateTime: new Date(2022, 4, 24, 12, 15),
    endDateTime: new Date(2022, 4, 24, 2, 45),
  },
  {
    id: '4',
    patientId: 'p00111',
    startDateTime: new Date(2022, 3, 15, 7, 15),
    endDateTime: new Date(2022, 3, 15, 7, 45),
  },
  {
    id: '5',
    patientId: 'p00111',
    startDateTime: new Date(2021, 1, 23, 12, 15),
    endDateTime: new Date(2021, 1, 23, 12, 45),
  },
  {
    id: '6',
    patientId: 'p00111',
    startDateTime: new Date(2019, 4, 23, 12, 15),
    endDateTime: new Date(2019, 4, 23, 12, 20),
  },
];
export const hardCodedPatientsConsultations1: PatientConsultation[] = [
  {
    id: '7',
    patientId: 'p00111',
    startDateTime: new Date(2018, 4, 24, 12, 15),
    endDateTime: new Date(2018, 4, 24, 12, 45),
  },
];
export const hardCodedSpecificPatientConsultations: PatientConsultation[] = [
  {
    id: '1',
    patientId: 'p00111',
    startDateTime: new Date(2018, 4, 24, 12, 15),
    endDateTime: new Date(2018, 4, 24, 12, 45),
  },
];
