import * as React from 'react';
import { Color } from '../styles/constantStyles';

export interface LogoutIconProps {
  color?: string;
  style?: React.CSSProperties;
  width?: string | number;
  height?: string | number;
}

export const LogoutIcon = ({ color, width, height, style }: LogoutIconProps) => (
  <svg style={style} width={width} height={height} viewBox="0 0 20 19" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M19.7451 7.13018C20.042 6.84133 20.0485 6.3665 19.7596 6.06962C19.4708 5.77275 18.9959 5.76624 18.6991 6.0551L19.7451 7.13018ZM14.7741 9.87402C14.4772 10.1629 14.4707 10.6377 14.7595 10.9346C15.0484 11.2315 15.5232 11.238 15.8201 10.9491L14.7741 9.87402ZM15.8201 6.0551C15.5232 5.76624 15.0484 5.77275 14.7595 6.06962C14.4707 6.3665 14.4772 6.84133 14.7741 7.13018L15.8201 6.0551ZM18.6991 10.9491C18.9959 11.238 19.4708 11.2315 19.7596 10.9346C20.0485 10.6377 20.042 10.1629 19.7451 9.87402L18.6991 10.9491ZM17.2596 18.0494V18.7994C17.6738 18.7994 18.0096 18.4636 18.0096 18.0494H17.2596ZM1.55957 18.0494H0.80957C0.80957 18.4636 1.14536 18.7994 1.55957 18.7994L1.55957 18.0494ZM18.6991 6.0551L14.7741 9.87402L15.8201 10.9491L19.7451 7.13018L18.6991 6.0551ZM14.7741 7.13018L18.6991 10.9491L19.7451 9.87402L15.8201 6.0551L14.7741 7.13018ZM5.48457 13.071H13.3346V11.571H5.48457V13.071ZM16.5096 16.1399V18.0494H18.0096V16.1399H16.5096ZM2.30957 18.0494V16.1399H0.80957V18.0494H2.30957ZM13.3346 13.071C15.1076 13.071 16.5096 14.4643 16.5096 16.1399H18.0096C18.0096 13.5974 15.897 11.571 13.3346 11.571V13.071ZM5.48457 11.571C2.92214 11.571 0.80957 13.5974 0.80957 16.1399H2.30957C2.30957 14.4643 3.71156 13.071 5.48457 13.071V11.571ZM12.5846 4.68318C12.5846 6.35886 11.1826 7.7521 9.40958 7.7521V9.2521C11.972 9.2521 14.0846 7.22576 14.0846 4.68318H12.5846ZM9.40958 7.7521C7.63657 7.7521 6.23457 6.35886 6.23457 4.68318H4.73457C4.73457 7.22576 6.84715 9.2521 9.40958 9.2521V7.7521ZM6.23457 4.68318C6.23457 3.0075 7.63657 1.61426 9.40958 1.61426V0.114258C6.84715 0.114258 4.73457 2.14059 4.73457 4.68318H6.23457ZM9.40958 1.61426C11.1826 1.61426 12.5846 3.0075 12.5846 4.68318H14.0846C14.0846 2.14059 11.972 0.114258 9.40958 0.114258V1.61426ZM1.55957 18.7994H17.2596V17.2994H1.55957V18.7994Z"
      fill={color || Color.MONOGREY6}
    />
  </svg>
);
