import React, { useCallback, useEffect, useRef, useState } from 'react';
import { DispatchAsyncResult } from 'react-redux-dispatch-async/lib/dispatchAsync';
import { DocColor, StyleSheet } from '../../../../assets/styles/constantStyles';
import { CloseIcon } from '../../../../assets/svg/CloseIcon';
import { ConfirmOverlay } from '../../../../common/ui/ConfirmOverlay';
import { Rpx } from '../../../../common/usecases/helpers/DimensionsConverter';
import { Medicine } from '../../../domain/entities/Medicine';

export interface SubstancedMedicinesOverlayProps {
  setIsSubstancedMedicinesOverlayClosed: React.Dispatch<React.SetStateAction<boolean>>;
  substance: string;
  fetchMedicinesAsync: (value?: string, composition?: string) => Promise<DispatchAsyncResult>;
  onOptionClick?: (medicine: Medicine) => void;
}
//  || !patientsNames.includes(inputValue)
export const SubstancedMedicinesOverlay = ({
  setIsSubstancedMedicinesOverlayClosed,
  substance,
  fetchMedicinesAsync,
  onOptionClick,
}: SubstancedMedicinesOverlayProps) => {
  let timer: any = null;
  const loaderRef = useRef<HTMLDivElement>(null);
  const [medicinesData, setMedicinesData] = useState<Medicine[] | undefined>(undefined);
  const [isConfirmFragmentClosed, setIsConfirmFragmentClosed] = useState<boolean>(true);
  const [chosenOption, setChosenOption] = useState<Medicine | undefined>();
  useEffect(() => {
    loaderAnimate();
    document.addEventListener('keydown', escFunction, false);
    fetchMedicines();

    return () => {
      document.removeEventListener('keydown', escFunction, false);
    };
  }, []);

  const escFunction = useCallback((event) => {
    if (event.key === 'Escape') {
      closeOverlayHandler();
    }
  }, []);

  const fetchMedicines = () => {
    fetchMedicinesAsync(undefined, substance)
      .then((res) => {
        if (res.success) {
          setMedicinesData([...res.result]);
        }
      })
      .catch(() => {
        timer = setTimeout(() => {
          fetchMedicines();
        }, 4000);
        window.clearTimeout(timer);
      });
  };

  const loaderAnimate = () => {
    const loaderEffect = new KeyframeEffect(
      loaderRef.current,
      [{ transform: 'rotate(0deg)' }, { transform: 'rotate(360deg)' }],
      {
        duration: 1000,
        fill: 'forwards',
        iterations: Infinity,
      },
    );
    const loaderAnimation = new Animation(loaderEffect, document.timeline);
    loaderAnimation.play();
  };

  const closeOverlayHandler = () => {
    setIsSubstancedMedicinesOverlayClosed(true);
  };

  const onConfirmClick = () => {
    if (chosenOption && onOptionClick) {
      onOptionClick(chosenOption);
      closeOverlayHandler();
    }
  };

  return (
    <div
      style={styles.container}
      onClick={(e) => {
        if (e.target !== e.currentTarget) {
          return;
        }
        closeOverlayHandler();
      }}
      onKeyDown={(e) => {
        if (e.target !== e.currentTarget) {
          return;
        }
        closeOverlayHandler();
      }}
      tabIndex={0}
      role="button"
    >
      <ConfirmOverlay
        message="Voulez-vous remplacer le médicament choisi par "
        highlightedMessage={`${chosenOption?.product_name}, ${chosenOption?.product_form} ?`}
        width="45%"
        onConfirmClick={onConfirmClick}
        isClosedState={isConfirmFragmentClosed}
        setIsClosedState={setIsConfirmFragmentClosed}
      />
      <div style={styles.SubstancedMedicinesContainer}>
        <div
          style={styles.closeButton}
          onKeyDown={() => {
            closeOverlayHandler();
          }}
          tabIndex={0}
          role="button"
          onClick={() => {
            closeOverlayHandler();
          }}
        >
          <CloseIcon color={DocColor.DARKBLUE} />
        </div>
        <p style={{ ...styles.title, fontSize: Rpx(36, true) }}>
          Médicaments dont la substance active est : <br />
          <span style={{ ...styles.titleSpan }}>{substance}</span>
        </p>
        <div style={styles.medicinesContainer}>
          {medicinesData ? (
            medicinesData.map((medicine) => (
              <p
                key={medicine.uuid}
                style={styles.paragraph}
                onClick={() => {
                  setChosenOption({ ...medicine });
                  setIsConfirmFragmentClosed(false);
                }}
                role="presentation"
                onKeyDown={() => {}}
              >{`${medicine.product_name} - ${medicine.product_form}`}</p>
            ))
          ) : (
            <div style={{ justifyContent: 'center', alignItems: 'center' }}>
              <p style={styles.paragraph}>Chargement en cours</p>
              <div ref={loaderRef} style={styles.loader} />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

const styles: StyleSheet = {
  container: {
    position: 'fixed',
    inset: 0,
    width: '100%',
    height: '100%',
    backgroundColor: DocColor.HALFTRANSPARENTBLACK,
    zIndex: 5,
  },
  SubstancedMedicinesContainer: {
    position: 'relative',
    justifyContent: 'center',
    alignItems: 'center',
    margin: 'auto',
    backgroundColor: 'white',
    width: '80%',
    height: 'fit-content',
    padding: `${Rpx(45)} 0`,
    borderRadius: Rpx(10),
    zIndex: 5,
  },
  closeButton: {
    position: 'absolute',
    inset: 0,
    margin: `${Rpx(40)} ${Rpx(40)} 0 auto`,
    background: DocColor.AQUAHAZE,
    width: Rpx(25),
    height: Rpx(25),
    borderRadius: Rpx(7),
    padding: Rpx(10),
    cursor: 'pointer',
    zIndex: 2,
  },
  title: {
    fontWeight: 700,
    color: DocColor.DARKBLUE,
    textAlign: 'center',
  },
  titleSpan: {
    fontWeight: 700,
    color: DocColor.MEDIUMBLUE,
  },
  medicinesContainer: {
    width: '80%',
    marginTop: Rpx(30),
    borderRadius: Rpx(25),
    background: DocColor.MIDIUMCLOUDYBLUE,
    overflow: 'auto',
    maxHeight: '60vh',
    alignItems: 'center',
    padding: `2vw 0`,
    gap: Rpx(20),
  },
  paragraph: {
    fontWeight: 700,
    color: DocColor.DARKBLUE,
    cursor: 'pointer',
  },
  loader: {
    width: Rpx(50),
    height: Rpx(50),
    display: 'inline-block',
    boxSizing: 'border-box',
    border: `${Rpx(4)} solid ${DocColor.DARKBLUE}`,
    borderRadius: '50%',
    borderBottomColor: 'transparent',
    marginTop: Rpx(10),
  },
  button: {
    width: 'fit-content',
    backgroundColor: DocColor.MEDIUMBLUE,
    color: 'white',
    borderRadius: Rpx(15),
    flexDirection: 'row',
    cursor: 'pointer',
    padding: `0 ${Rpx(20)}`,
    fontWeight: 700,
  },
};
