import React from 'react';
import { useMediaQuery } from 'react-responsive';
import { DocColor, StyleSheet } from '../../../../assets/styles/constantStyles';
import { EmailInputIcon } from '../../../../assets/svg/EmailInputIcon';
import { bigScreenBreakpoint } from '../../../../common/domain/entities/ReactResponsiveBreakpoints';
import { Rpx, SRpx } from '../../../../common/usecases/helpers/DimensionsConverter';

interface CustomEmailInputComponentProps {
  initStateRender: boolean;
  onChange: (value: string) => void;
  mistakesArray: string[];
}

export const CustomEmailInputComponent = ({
  initStateRender,
  onChange,
  mistakesArray,
}: CustomEmailInputComponentProps) => {
  // isBigScreen working as a media breakpoint
  const isBigScreen = useMediaQuery({ query: `(min-width: ${bigScreenBreakpoint}px)` });

  return (
    <div style={isBigScreen ? { ...styles.container } : { ...styles.container, ...responsiveStyles.container }}>
      <div
        style={
          isBigScreen ? { ...styles.inputContainer } : { ...styles.inputContainer, ...responsiveStyles.inputContainer }
        }
      >
        <div
          style={
            isBigScreen ? { ...styles.inputTitleDiv } : { ...styles.inputTitleDiv, ...responsiveStyles.inputTitleDiv }
          }
        >
          <EmailInputIcon
            width={isBigScreen ? Rpx(33) : SRpx(16)}
            height={isBigScreen ? Rpx(33) : SRpx(16)}
            color={DocColor.COALBLACK}
            style={{ margin: 'auto 0' }}
          />
          <p
            style={
              isBigScreen
                ? {
                    ...styles.inputParagraph,
                    color: mistakesArray.length > 0 && !initStateRender ? 'red' : 'black',
                  }
                : {
                    ...styles.inputParagraph,
                    ...responsiveStyles.inputParagraph,
                    color: mistakesArray.length > 0 && !initStateRender ? 'red' : 'black',
                  }
            }
          >
            Email
          </p>
        </div>

        <input
          style={
            isBigScreen
              ? {
                  ...styles.input,
                  borderColor: mistakesArray.length > 0 && !initStateRender ? DocColor.LIGHTRED : DocColor.CLOUDYWHITE,
                }
              : {
                  ...styles.input,
                  ...responsiveStyles.input,
                  borderColor: mistakesArray.length > 0 && !initStateRender ? DocColor.LIGHTRED : DocColor.CLOUDYWHITE,
                }
          }
          type="text"
          onChange={(event) => onChange(event.target.value)}
        />
      </div>
      <div style={{ marginTop: Rpx(15) }}>
        {!initStateRender &&
          mistakesArray.map((mistake) => {
            if (mistake !== '') {
              return (
                <p
                  key={mistake}
                  style={
                    isBigScreen
                      ? { ...styles.mistakeParagraph }
                      : { ...styles.mistakeParagraph, ...responsiveStyles.mistakeParagraph }
                  }
                >{`• ${mistake}`}</p>
              );
            }
            return null;
          })}
      </div>
    </div>
  );
};

const styles: StyleSheet = {
  container: {
    paddingTop: Rpx(110),
  },
  inputContainer: {
    flexDirection: 'row',
    margin: `0 auto`,
  },
  inputTitleDiv: {
    flexDirection: 'row',
    paddingRight: '2vw',
    marginBottom: Rpx(16),
  },
  input: {
    width: '30vw',
    maxWidth: '100%',
    height: Rpx(78),
    background: DocColor.CLOUDYWHITE,
    border: 'none',
    borderRadius: Rpx(9),
    outline: 'none',
    caretColor: DocColor.DARKBLUE,
    borderColor: DocColor.CLOUDYWHITE,
    borderWidth: Rpx(4),
    borderStyle: 'solid',
    fontSize: Rpx(24),
  },
  inputParagraph: {
    letterSpacing: Rpx(3),
    margin: `auto 0 auto ${Rpx(15)}`,
    fontSize: Rpx(24),
    fontWeight: 500,
  },
  mistakeParagraph: {
    width: 'fit-content',
    margin: `${Rpx(5)} auto 0 auto`,
    color: 'red',
    fontSize: Rpx(24),
    fontWeight: 700,
    letterSpacing: Rpx(3),
  },
};

const responsiveStyles: StyleSheet = {
  container: {
    paddingTop: SRpx(40),
  },
  inputContainer: {
    flexDirection: 'column',
  },
  inputTitleDiv: {
    paddingRight: '4vw',
    marginBottom: SRpx(10),
  },
  input: {
    width: '60vw',
    maxWidth: '100%',
    height: SRpx(45),
    borderRadius: SRpx(5),
    borderWidth: SRpx(3),
    fontSize: SRpx(12),
  },
  inputParagraph: {
    letterSpacing: SRpx(1),
    margin: `auto 0 auto ${SRpx(8)}`,
    fontSize: SRpx(12),
  },
  mistakeParagraph: {
    margin: `${SRpx(3)} auto 0 auto`,
    fontSize: SRpx(10),
    letterSpacing: Rpx(2),
  },
};
