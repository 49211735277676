import React, { useEffect, useState } from 'react';
import { DispatchAsyncResult } from 'react-redux-dispatch-async/lib/dispatchAsync';
import { AttachmentAdditionOverlay } from '../../../../common/ui/AttachmentAdditionOverlay';
import { DocColor, StyleSheet } from '../../../../assets/styles/constantStyles';
import { Rpx } from '../../../../common/usecases/helpers/DimensionsConverter';
import { CustomSelector } from '../../../../common/ui/CustomSelector';
import { ButtonWithIcon } from '../../../../common/ui/ButtonWithIcon';
import { Examination } from '../../../domain/entities/Examination';
import { ToTopArrowFatLinesIcon } from '../../../../assets/svg/ToTopArrowFatLinesIcon';
import CommonFunctions from '../../../../common/usecases/helpers/CommonFunctions';
import { CustomFileInput } from '../../../../common/ui/CustomFileInput';
import { useNotificationContext } from '../../../../common/configuration/notificationContextProvider';
import { useLoaderContext } from '../../../../common/configuration/loaderContextProvider';

type ExaminationRowProps = {
  patientEmail: string | undefined;
  sendAttchmentByMailAsync: (mail: string, fileName: string, fileContent: string) => Promise<DispatchAsyncResult>;
  inputName: string;
  examinationData: Examination;
  onDataChange: (examinationData: Examination) => void;
  isOnlyReadable?: boolean;
  mailButtonVisibility?: boolean;
};

export const ExaminationRow = ({
  patientEmail,
  sendAttchmentByMailAsync,
  inputName,
  examinationData,
  onDataChange,
  isOnlyReadable,
  mailButtonVisibility,
}: ExaminationRowProps) => {
  const loader = useLoaderContext();
  const notification = useNotificationContext();
  const [isAttachmentAdditionOverlayClosed, setIsAttachmentAdditionOverlayClosed] = useState<boolean>(true);
  const fileInputRefs = React.useRef<HTMLInputElement>(null);
  const [timerInMs, setTimerInMs] = useState<number>(0);

  useEffect(() => {
    if (timerInMs !== 0) {
      setTimeout(() => {
        setTimerInMs(timerInMs - 1000);
      }, 1000);
    }
  }, [timerInMs]);

  const sendAttachmentByMail = () => {
    if (patientEmail) {
      loader.setMessage('_');
      sendAttchmentByMailAsync(patientEmail, examinationData.fileName, examinationData.file)
        .then((res) => {
          if (res.success && res.result) {
            setTimerInMs(6000);
            notification.setOnSuccess({
              message: `La pièce jointe est envoyée avec succès.`,
            });
          } else {
            notification.setOnError({
              message: `la pièce jointe n'a pas été envoyée.`,
            });
          }
        })
        .finally(() => loader.setMessage(''));
    }
  };

  const onCancelClick = () => {
    if (fileInputRefs.current !== null) {
      fileInputRefs.current.value = '';
      const event = new Event('change', { bubbles: true });
      fileInputRefs.current.dispatchEvent(event);
      setIsAttachmentAdditionOverlayClosed(true);
    }
  };

  return (
    <div style={{ width: '100%' }}>
      <AttachmentAdditionOverlay
        fileName={examinationData.fileName}
        examinationType={examinationData.type}
        isClosedState={isAttachmentAdditionOverlayClosed}
        onBrowseClick={() => {
          fileInputRefs.current?.click();
        }}
        onChangeClick={() => {
          fileInputRefs.current?.click();
        }}
        onValidClick={() => setIsAttachmentAdditionOverlayClosed(true)}
        onCancelClick={onCancelClick}
      />
      <div style={{ width: '100%' }}>
        <div style={{ flexDirection: 'row' }}>
          <CustomSelector
            disabled={isOnlyReadable}
            onChange={(value) => {
              if (examinationData.type !== value) {
                onDataChange({ ...examinationData, type: value, file: '', fileName: '' });
                setIsAttachmentAdditionOverlayClosed(false);
              }
            }}
            value={examinationData.type}
            optionsArray={['Examen Cardio Vasculaire', 'Examen Pleuro Pulmonaire', 'Examen ORL', 'Examen Abdominal']}
            inputName={inputName}
            inputNameWidth={Rpx(150)}
            style={{ borderRadius: Rpx(5) }}
            optionsColor={DocColor.MEDIUMBLUE}
            placeholder="Choisir un examen"
            width="100%"
            height={Rpx(48, true)}
          />
        </div>
        <div
          style={{
            ...styles.spacedRowDivs,
            margin: `${Rpx(17)} 0 0 0`,
            display: examinationData.type === '' ? 'none' : '',
          }}
        >
          {!isOnlyReadable ? (
            <CustomFileInput
              ref={fileInputRefs}
              inputName=""
              inputNameWidth={Rpx(150)}
              color={DocColor.DARKBLUE}
              style={{ borderRadius: Rpx(5) }}
              height={Rpx(48)}
              placeholder="Choisir un fichier"
              fileName={examinationData.fileName}
              width="50%"
              onChange={(v) => {
                if (v.target.files && v.target.files?.length > 0) {
                  CommonFunctions.getBase64(v.target.files[0]).then((res) => {
                    onDataChange({
                      ...examinationData,
                      file: res,
                      fileName: v.target.files ? v.target.files[0].name : '',
                    });
                  });
                } else {
                  onDataChange({ ...examinationData, file: '', fileName: '' });
                }
              }}
            />
          ) : (
            <a
              style={{
                ...styles.fileAnchor,
                pointerEvents: examinationData.file === '' ? 'none' : 'auto',
                color: examinationData.file === '' ? DocColor.GRAY : DocColor.DARKBLUE,
              }}
              href={examinationData.file}
              download={examinationData.fileName}
            >
              {examinationData.file
                ? examinationData.fileName !== ''
                  ? `● ${examinationData.fileName}`
                  : '● Fichier sans nom'
                : "● Il n'y a pas de fichier"}
            </a>
          )}
          {mailButtonVisibility && (
            <ButtonWithIcon
              disabled={
                timerInMs !== 0 ||
                (isOnlyReadable
                  ? examinationData.file === ''
                  : fileInputRefs.current === undefined || fileInputRefs.current?.value === '')
              }
              label={`Envoyer au patient par mail ${timerInMs !== 0 ? `en ${timerInMs / 1000}s` : ''}`}
              style={{ height: Rpx(46, true), borderRadius: Rpx(13) }}
              icon={ToTopArrowFatLinesIcon({
                width: Rpx(25, true),
                height: Rpx(25, true),
                color: 'white',
                style: { margin: `auto 0 auto ${Rpx(10)}` },
              })}
              onClick={() => sendAttachmentByMail()}
            />
          )}
        </div>
      </div>
    </div>
  );
};

const styles: StyleSheet = {
  spacedRowDivs: {
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  fileAnchor: {
    fontSize: Rpx(25),
    fontWeight: 700,
    marginRight: Rpx(20),
    whiteSpace: 'nowrap',
    marginLeft: Rpx(150),
    color: DocColor.DARKBLUE,
  },
};
