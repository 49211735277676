import { connect } from 'react-redux';
import { dispatchAsync } from 'react-redux-dispatch-async';
import { Action } from 'redux';
import { SettingsdDumb } from './Settings.dumb';
import { getConnectedUser } from '../../../authContext/usecases/Auth.selectors';
import { AppState } from '../../../common/configuration/app.states';
import { getUserSettingsState } from '../../usecases/UserSettings.selectors';
import { UserSettings } from '../../domain/entities/UserSettings';
import {
  loadUserSettingsRequest,
  updatePasswordRequest,
  updateUsernameRequest,
  updateUserSettingsRequest,
} from '../../usecases/UserSettings.actions';

const mapState = (state: AppState) => ({
  // statePatients: getAllPatients(state),
  connectedUser: getConnectedUser(state),
  userSettingsState: getUserSettingsState(state),
});

const mapDispatch = (dispatch: (a: Action) => void) => ({
  updateUsernameAsync: (id: string, firstName: string, lastName: string) =>
    dispatchAsync(dispatch, updateUsernameRequest({ id, firstName, lastName })),
  loadUserSettingsAsync: () => dispatchAsync(dispatch, loadUserSettingsRequest()),
  updatePasswordAsync: (id: string, currentPassword: string, newPassword: string) =>
    dispatchAsync(dispatch, updatePasswordRequest({ id, currentPassword, newPassword })),
  updateUserSettingsAsync: (userSettings: UserSettings) =>
    dispatchAsync(dispatch, updateUserSettingsRequest(userSettings)),

  // navigateTo: (nav: NavigationPayload) => dispatch(navigateTo(nav)),
});

export const SettingsSmart = connect(mapState, mapDispatch)(SettingsdDumb);
