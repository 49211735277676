import { useMediaQuery } from 'react-responsive';
import { DispatchAsyncResult } from 'react-redux-dispatch-async/lib/dispatchAsync';
import React, { useEffect, useState } from 'react';
import { defaultPassword, hardcodedUsers, User } from '../../domain/entities/User';
import { DocColor, StyleSheet } from '../../../assets/styles/constantStyles';
import { EmailInputIcon } from '../../../assets/svg/EmailInputIcon';
import { PasswordInputIcon } from '../../../assets/svg/PasswordInputIcon';
import CommonFunctions from '../../../common/usecases/helpers/CommonFunctions';
import { EyeIcon } from '../../../assets/svg/EyeIcon';
import { NavigationPayload } from '../../../navigationContext/domain/entities/NavigationPayload';
import { Rpx, SRpx } from '../../../common/usecases/helpers/DimensionsConverter';
import { useLoaderContext } from '../../../common/configuration/loaderContextProvider';
import { UserAccount } from '../../domain/entities/UserAccount';
import { bigScreenBreakpoint } from '../../../common/domain/entities/ReactResponsiveBreakpoints';

interface SignInProps {
  sendSignInAsync(userAccount: UserAccount): Promise<DispatchAsyncResult<User | undefined>>;
  loadUserSettingsAsync(): Promise<DispatchAsyncResult<void>>;
  navigateToAsync: (p: NavigationPayload) => void;
}
export const SignInDumb = ({ sendSignInAsync, loadUserSettingsAsync, navigateToAsync }: SignInProps) => {
  const isBigScreen = useMediaQuery({ query: `(min-width: ${bigScreenBreakpoint}px)` });
  const loader = useLoaderContext();
  const [emailInput, setEmailInput] = useState<string>('');
  const [passwordInput, setPasswordInput] = useState<string>('');
  const [isEmailValid, setisEmailValid] = useState<boolean>(false);
  const [isPasswordValid, setIsPasswordValid] = useState<boolean>(false);
  const [InputMistakes, setInputMistakes] = useState<string[]>([]);
  const [passwordShown, setPasswordShown] = useState<boolean>(false);
  const [initRender, setInitRender] = useState<boolean>(true);

  useEffect(() => {
    checkLoginValidity();
  }, [emailInput, passwordInput, initRender]);

  const checkLoginValidity = () => {
    let isValidEmailMistake = '';
    let passwordLengthMistake = '';
    let emptyInputsMistake = '';
    if (!CommonFunctions.isValidEmail(emailInput)) {
      isValidEmailMistake = "L'email n'est pas valide";
      setisEmailValid(false);
    } else {
      setisEmailValid(true);
    }

    if (passwordInput.length < 6) {
      passwordLengthMistake = 'Le mot de passe doit contenir 6 caractères minimum';
      setIsPasswordValid(false);
    } else {
      setIsPasswordValid(true);
    }
    if (emailInput.trim() === '' && passwordInput.trim() === '') {
      emptyInputsMistake = 'Tous les champs doivent être renseignés';
    }
    setInputMistakes([isValidEmailMistake, passwordLengthMistake, emptyInputsMistake]);
  };

  const forgotPasswordHandler = () => {
    navigateToAsync({ routeName: 'Mot de passe oublié', params: emailInput });
  };

  const submitClickHandler = () => {
    if (isEmailValid === true && isPasswordValid === true) {
      loader.setMessage('En cours de connexion');
      sendSignInAsync({ email: emailInput, password: passwordInput })
        .then((res) => {
          if (res.success) {
            loadUserSettingsAsync();
            navigateToAsync({
              routeName: 'Accueil',
            });
          } else {
            setInputMistakes(['Veuillez vérifier votre adresse e-mail ou votre mot de passe']);
          }
        })
        .finally(() => {
          loader.setMessage('');
        });
    }
    setInitRender(false);
  };

  return (
    <div style={styles.container}>
      <div style={styles.loginForm}>
        <p
          style={
            isBigScreen
              ? { ...styles.inputParagraph, color: isPasswordValid || initRender ? 'black' : 'red' }
              : {
                  ...styles.inputParagraph,
                  ...responsiveStyles.inputParagraph,
                  color: isPasswordValid || initRender ? 'black' : 'red',
                }
          }
        >
          {/* Please contact me for login credentials. */}
          Test Login:
          <br /> {hardcodedUsers[0].email}
          <br /> {defaultPassword}
        </p>
        <p style={isBigScreen ? { ...styles.title } : { ...styles.title, ...responsiveStyles.title }}>Connexion</p>

        <div style={{ ...responsiveStyles.titleLine, display: isBigScreen ? 'none' : '' }} />

        <div
          style={
            isBigScreen
              ? { ...styles.inputContainer }
              : { ...styles.inputContainer, ...responsiveStyles.inputContainer }
          }
        >
          <div
            style={
              isBigScreen ? { ...styles.inputTitleDiv } : { ...styles.inputTitleDiv, ...responsiveStyles.inputTitleDiv }
            }
          >
            <EmailInputIcon
              width={isBigScreen ? Rpx(33) : SRpx(16)}
              height={isBigScreen ? Rpx(33) : SRpx(16)}
              color={DocColor.COALBLACK}
              style={{ margin: 'auto 0' }}
            />
            <p
              style={
                isBigScreen
                  ? { ...styles.inputParagraph, color: isPasswordValid || initRender ? 'black' : 'red' }
                  : {
                      ...styles.inputParagraph,
                      ...responsiveStyles.inputParagraph,
                      color: isPasswordValid || initRender ? 'black' : 'red',
                    }
              }
            >
              Email
            </p>
          </div>

          <input
            style={
              isBigScreen
                ? {
                    ...styles.input,
                    borderColor: isPasswordValid || initRender ? DocColor.CLOUDYWHITE : DocColor.LIGHTRED,
                  }
                : {
                    ...styles.input,
                    ...responsiveStyles.input,
                    borderColor: isPasswordValid || initRender ? DocColor.CLOUDYWHITE : DocColor.LIGHTRED,
                  }
            }
            onKeyPress={(e) => {
              if (e.key === 'Enter') {
                submitClickHandler();
              }
            }}
            type="email"
            onChange={(event) => setEmailInput(event.target.value)}
          />
        </div>

        <div
          style={
            isBigScreen
              ? { ...styles.inputContainer }
              : { ...styles.inputContainer, ...responsiveStyles.inputContainer }
          }
        >
          <div
            style={
              isBigScreen ? { ...styles.inputTitleDiv } : { ...styles.inputTitleDiv, ...responsiveStyles.inputTitleDiv }
            }
          >
            <PasswordInputIcon
              width={isBigScreen ? Rpx(33) : SRpx(16)}
              height={isBigScreen ? Rpx(33) : SRpx(16)}
              color={DocColor.COALBLACK}
              style={{ margin: 'auto 0' }}
            />
            <p
              style={
                isBigScreen
                  ? { ...styles.inputParagraph, color: isPasswordValid || initRender ? 'black' : 'red' }
                  : {
                      ...styles.inputParagraph,
                      ...responsiveStyles.inputParagraph,
                      color: isPasswordValid || initRender ? 'black' : 'red',
                    }
              }
            >
              Mot de passe
            </p>
          </div>
          <div style={{ position: 'relative' }}>
            <input
              autoComplete="on"
              style={
                isBigScreen
                  ? {
                      ...styles.input,
                      borderColor: isPasswordValid || initRender ? DocColor.CLOUDYWHITE : DocColor.LIGHTRED,
                    }
                  : {
                      ...styles.input,
                      ...responsiveStyles.input,
                      borderColor: isPasswordValid || initRender ? DocColor.CLOUDYWHITE : DocColor.LIGHTRED,
                    }
              }
              onKeyPress={(e) => {
                if (e.key === 'Enter') {
                  submitClickHandler();
                }
              }}
              type={passwordShown ? 'text' : 'password'}
              onChange={(event) => setPasswordInput(event.target.value)}
            />
            <button
              style={
                isBigScreen
                  ? { ...styles.iconBackground }
                  : { ...styles.iconBackground, ...responsiveStyles.iconBackground }
              }
              type="button"
              onClick={() => setPasswordShown((v) => !v)}
            >
              <EyeIcon
                isClosed={!passwordShown}
                color="white"
                style={{
                  width: isBigScreen ? Rpx(35) : SRpx(17),
                  margin: 'auto',
                }}
              />
            </button>
          </div>
        </div>

        <div style={{ marginTop: Rpx(42) }}>
          {!initRender &&
            InputMistakes.map((mistake, i) => {
              if (mistake !== '') {
                return (
                  <p
                    key={`error-no-${i + 1}`}
                    style={
                      isBigScreen
                        ? { ...styles.mistakeParagraph }
                        : { ...styles.mistakeParagraph, ...responsiveStyles.mistakeParagraph }
                    }
                  >{`• ${mistake}`}</p>
                );
              }
              return null;
            })}
        </div>
        <div onKeyDown={forgotPasswordHandler} tabIndex={0} role="button" onClick={forgotPasswordHandler}>
          <p
            style={
              isBigScreen
                ? { ...styles.forgotPasswordParagraph }
                : { ...styles.forgotPasswordParagraph, ...responsiveStyles.forgotPasswordParagraph }
            }
          >
            Mot de passe oublié ?
          </p>
        </div>
        <button
          disabled={!initRender && (!isEmailValid || !isPasswordValid)}
          type="button"
          style={
            isBigScreen
              ? {
                  ...styles.submitButton,
                  background: !initRender && (!isEmailValid || !isPasswordValid) ? DocColor.GRAY : DocColor.DARKBLUE,
                }
              : {
                  ...styles.submitButton,
                  ...responsiveStyles.submitButton,
                  background: !initRender && (!isEmailValid || !isPasswordValid) ? DocColor.GRAY : DocColor.DARKBLUE,
                }
          }
          onClick={submitClickHandler}
        >
          Se connecter
        </button>
      </div>
    </div>
  );
};

const styles: StyleSheet = {
  container: {
    flex: 1,
  },
  loginForm: {
    margin: 'auto',
    textAlign: 'center',
  },
  title: {
    color: DocColor.DARKBLUE,
    fontSize: Rpx(64),
    fontWeight: 700,
    letterSpacing: Rpx(3),
  },
  inputContainer: {
    width: '25vw',
    margin: `${Rpx(20)} auto 0 auto`,
  },
  inputTitleDiv: {
    flexDirection: 'row',
    marginLeft: Rpx(10),
    marginBottom: Rpx(16),
  },
  inputParagraph: {
    letterSpacing: Rpx(3),
    marginLeft: Rpx(10),
    fontSize: Rpx(24),
    fontWeight: 500,
  },
  input: {
    maxWidth: '100%',
    height: Rpx(78),
    background: DocColor.CLOUDYWHITE,
    border: 'none',
    borderRadius: Rpx(9),
    outline: 'none',
    caretColor: DocColor.DARKBLUE,
    borderColor: DocColor.CLOUDYWHITE,
    borderWidth: Rpx(4),
    borderStyle: 'solid',
    fontSize: Rpx(25),
  },
  iconBackground: {
    background: DocColor.MEDIUMBLUE,
    borderRadius: Rpx(40),
    width: Rpx(55),
    height: Rpx(55),
    position: 'absolute',
    margin: `auto 3% auto auto`,
    inset: 0,
    cursor: 'pointer',
  },
  mistakeParagraph: {
    width: 'fit-content',
    margin: `${Rpx(5)} auto 0 auto`,
    color: 'red',
    fontSize: Rpx(24),
    fontWeight: 700,
    letterSpacing: Rpx(3),
  },
  forgotPasswordParagraph: {
    letterSpacing: Rpx(3),
    fontWeight: 500,
    margin: `${Rpx(10)} auto 0 auto`,
    fontSize: Rpx(24),
    color: DocColor.MEDIUMBLUE,
    cursor: 'pointer',
  },
  submitButton: {
    letterSpacing: Rpx(3),
    color: 'white',
    width: '24vw',
    height: Rpx(65),
    margin: `${Rpx(40)} auto ${Rpx(40)} auto`,
    background: DocColor.DARKBLUE,
    borderRadius: Rpx(15),
    textTransform: 'uppercase',
    fontSize: Rpx(30),
    fontWeight: 600,
  },
};

const responsiveStyles: StyleSheet = {
  title: {
    fontSize: SRpx(40),
    letterSpacing: SRpx(2),
  },
  titleLine: {
    height: SRpx(3),
    background: DocColor.DARKBLUE,
    borderRadius: SRpx(3),
  },
  inputContainer: {
    width: '65vw',
    margin: `${SRpx(20)} auto 0 auto`,
  },
  inputTitleDiv: {
    marginLeft: SRpx(5),
    marginBottom: SRpx(10),
  },
  inputParagraph: {
    letterSpacing: SRpx(2),
    marginLeft: SRpx(10),
    fontSize: SRpx(14),
  },
  input: {
    height: SRpx(45),
    borderRadius: SRpx(5),
    borderWidth: SRpx(3),
    fontSize: SRpx(12),
  },
  iconBackground: {
    borderRadius: SRpx(20),
    width: SRpx(27),
    height: SRpx(27),
  },
  mistakeParagraph: {
    margin: `${SRpx(3)} auto 0 auto`,
    fontSize: SRpx(10),
    letterSpacing: Rpx(2),
  },
  forgotPasswordParagraph: {
    letterSpacing: SRpx(2),
    margin: `${SRpx(5)} auto 0 auto`,
    fontSize: SRpx(12),
  },
  submitButton: {
    letterSpacing: SRpx(2),
    width: '60vw',
    height: SRpx(35),
    margin: `${SRpx(20)} auto ${SRpx(20)} auto`,
    borderRadius: SRpx(8),
    fontSize: SRpx(15),
  },
};
