import { NavLink as Link, useLocation } from 'react-router-dom';
import { DispatchAsyncResult } from 'react-redux-dispatch-async/lib/dispatchAsync';
import React, { useState } from 'react';
import { useMediaQuery } from 'react-responsive';
import { DocColor, StyleSheet } from '../../../assets/styles/constantStyles';
import { SettingsIcon } from '../../../assets/svg/SettingsIcon';
import { sideBarScreens } from '../../configuration/NavScreens';
import { LogoutIcon } from '../../../assets/svg/LogoutIcon';
import { ConfirmOverlay } from '../../../common/ui/ConfirmOverlay';
import { Rpx, SRpx } from '../../../common/usecases/helpers/DimensionsConverter';
import { NavigationPayload } from '../../domain/entities/NavigationPayload';
import { User } from '../../../authContext/domain/entities/User';
import { bigScreenBreakpoint } from '../../../common/domain/entities/ReactResponsiveBreakpoints';
import { ReturnIcon2 } from '../../../assets/svg/ReturnIcon2';
import { FooterSignature } from '../../../common/ui/FooterSignature';

interface SideBarProps {
  stateConnectedUser: User | undefined;
  onUnauthorizedView?: boolean;
  sendSignOutAsync?(): Promise<DispatchAsyncResult<void>>;
  navigateTo: (p: NavigationPayload) => void;
  onBackClick: () => void;
}

export const SideBar = ({
  stateConnectedUser,
  navigateTo,
  onBackClick,
  onUnauthorizedView,
  sendSignOutAsync,
}: SideBarProps) => {
  // isBigScreen working as a media breakpoint
  const isBigScreen = useMediaQuery({ query: `(min-width: ${bigScreenBreakpoint}px)` });

  const currentRouteName = useLocation().pathname.replace('/', '');
  const [isConfirmFragmentClosed, setIsConfirmFragmentClosed] = useState<boolean>(true);
  const [isNavigationLinksExpanded, setIsNavigationLinksExpanded] = useState<boolean>(false);

  const sendSignOut = () => {
    if (sendSignOutAsync) {
      sendSignOutAsync().then((res) => {
        if (res.success) {
          navigateTo({
            routeName: 'SignIn',
          });
        } else {
          alert(`une erreur s'est produite lors de la tentative de déconnexion!`);
        }
      });
    }
  };

  const onConfirmClick = () => {
    sendSignOut();
    setIsConfirmFragmentClosed(true);
  };

  return (
    <div style={isBigScreen ? { ...styles.container } : { ...styles.container, ...responsiveStyles.container }}>
      <div
        style={
          isBigScreen
            ? { ...styles.sideBarContainer, display: !onUnauthorizedView ? 'none' : '' }
            : {
                ...styles.sideBarContainer,
                ...responsiveStyles.sideBarContainer,
                display: !onUnauthorizedView ? 'none' : '',
              }
        }
      >
        <img
          style={
            isBigScreen
              ? { ...styles.onUnAuthorizedLogo }
              : { ...styles.onUnAuthorizedLogo, ...responsiveStyles.onUnAuthorizedLogo }
          }
          alt="DocLogo"
          src={require('../../../assets/images/docLogo.png')}
        />
      </div>
      {!stateConnectedUser?.role && isBigScreen && <FooterSignature />}
      <div style={{ display: onUnauthorizedView ? 'none' : '' }}>
        <ConfirmOverlay
          message="Êtes-vous sûr de vouloir vous déconnecter?"
          onConfirmClick={onConfirmClick}
          isClosedState={isConfirmFragmentClosed}
          setIsClosedState={setIsConfirmFragmentClosed}
        />
        <div
          style={
            isBigScreen ? { ...styles.sideBarContainer } : { ...styles.sideBarContainer, ...responsiveStyles.container }
          }
        >
          <div style={{ ...responsiveStyles.navigationButtons, display: isBigScreen ? 'none' : '' }}>
            <div
              onClick={() => setIsNavigationLinksExpanded((v) => !v)}
              onKeyDown={() => {
                setIsNavigationLinksExpanded((v) => !v);
              }}
              tabIndex={0}
              role="button"
              style={{ ...responsiveStyles.navigationButton }}
            >
              <div style={responsiveStyles.navigationButtonLine} />
              <div style={responsiveStyles.navigationButtonLine} />
              <div style={responsiveStyles.navigationButtonLine} />
            </div>
            <div
              onClick={() => {
                setIsNavigationLinksExpanded(false);
                onBackClick();
              }}
              onKeyDown={() => {
                setIsNavigationLinksExpanded(false);
                onBackClick();
              }}
              tabIndex={0}
              role="button"
              style={{ display: currentRouteName === 'Accueil' ? 'none' : '' }}
            >
              <ReturnIcon2 width={SRpx(30)} height={SRpx(30)} />
            </div>
          </div>

          <img
            alt="logoImage"
            style={
              isBigScreen
                ? { ...styles.onAuthorizedLogo }
                : { ...styles.onAuthorizedLogo, ...responsiveStyles.onAuthorizedLogo }
            }
            src={require('../../../assets/images/docLogo.png')}
          />
          <div style={{ height: 'inherit', justifyContent: 'space-between', display: isBigScreen ? '' : 'none' }}>
            <div>
              <div style={styles.line} />
              {sideBarScreens.map((scr) => (
                <Link
                  key={scr.routeName}
                  to={`${scr.routeName}`}
                  style={
                    scr.routeName === 'Mes Patients' &&
                    (currentRouteName === 'Patient details' || currentRouteName === 'Patient Addition')
                      ? { ...styles.navLink, ...styles.navLinkActive, fontSize: Rpx(25, true) }
                      : scr.routeName === 'Mes Consultations' && currentRouteName === 'Consultation Addition'
                      ? { ...styles.navLink, ...styles.navLinkActive, fontSize: Rpx(25, true) }
                      : { ...styles.navLink, fontSize: Rpx(25, true) }
                  }
                  activeStyle={styles.navLinkActive}
                >
                  {scr.routeIcon ? scr.routeIcon('inherit', { margin: 'auto 0' }, Rpx(25, true), Rpx(25, true)) : null}

                  <span>
                    {stateConnectedUser?.role !== 'DOCTOR'
                      ? scr.routeName === 'Mon Calendrier'
                        ? 'Calendrier'
                        : scr.routeName === 'Mes Patients'
                        ? 'Patients'
                        : scr.routeName === 'Mes Consultations'
                        ? 'Consultations'
                        : scr.routeName
                      : scr.routeName}
                  </span>
                </Link>
              ))}
            </div>
            <div>
              <div style={styles.line} />
              <div
                style={{
                  ...styles.button,
                  background: currentRouteName === 'Paramètres' ? 'white' : DocColor.DARKBLUE,
                  fontSize: Rpx(20, true),
                }}
                onKeyDown={() => {
                  navigateTo({ routeName: 'Paramètres' });
                }}
                tabIndex={0}
                role="button"
                onClick={() => {
                  navigateTo({ routeName: 'Paramètres' });
                }}
              >
                <SettingsIcon
                  style={{
                    margin: 'auto 0',
                  }}
                  width={Rpx(18)}
                  height={Rpx(18)}
                  color={currentRouteName !== 'Paramètres' ? 'white' : DocColor.DARKBLUE}
                />
                <span style={{ color: currentRouteName !== 'Paramètres' ? 'white' : DocColor.DARKBLUE }}>
                  Paramètres
                </span>
              </div>
              <div
                style={{ ...styles.button, fontSize: Rpx(20, true) }}
                onKeyDown={() => {
                  setIsConfirmFragmentClosed(false);
                }}
                tabIndex={0}
                role="button"
                onClick={() => {
                  setIsConfirmFragmentClosed(false);
                }}
              >
                <LogoutIcon style={{ margin: 'auto 0' }} width={Rpx(18)} height={Rpx(18)} color="white" />
                <span>Déconnexion</span>
              </div>
              <FooterSignature />
            </div>
          </div>
        </div>
      </div>
      <div
        style={{
          ...responsiveStyles.navigationLinksContainer,
          display: isBigScreen ? 'none' : '',
          height: isNavigationLinksExpanded ? '80vh' : '0vh',
        }}
      >
        <div>
          <p style={responsiveStyles.usernameParagraph}>{`${stateConnectedUser?.role === 'DOCTOR' ? 'Dr.' : ''}${
            stateConnectedUser?.firstName
          } ${stateConnectedUser?.role === 'DOCTOR' ? stateConnectedUser?.lastName : ''}`}</p>
          <div style={{ ...styles.line, ...responsiveStyles.line }} />
        </div>
        <div style={{ height: ' 100%' }}>
          {sideBarScreens.map((scr) => (
            <div key={scr.routeName}>
              <Link
                to={`${scr.routeName}`}
                style={{ ...responsiveStyles.navLink, fontSize: SRpx(15, true) }}
                onClick={() => setIsNavigationLinksExpanded(false)}
              >
                {scr.routeIcon
                  ? scr.routeIcon(DocColor.DARKBLUE, { margin: '0 5%' }, SRpx(15, true), SRpx(15, true))
                  : null}

                <span>
                  {stateConnectedUser?.role !== 'DOCTOR'
                    ? scr.routeName === 'Mon Calendrier'
                      ? 'Calendrier'
                      : scr.routeName === 'Mes Patients'
                      ? 'Patients'
                      : scr.routeName === 'Mes Consultations'
                      ? 'Consultations'
                      : scr.routeName
                    : scr.routeName}
                </span>
              </Link>
              <div style={{ ...styles.line, ...responsiveStyles.line }} />
            </div>
          ))}
        </div>
        <div>
          <div
            style={{
              ...styles.button,
              ...responsiveStyles.button,
              fontSize: SRpx(15, true),
            }}
            onKeyDown={() => {
              setIsNavigationLinksExpanded(false);
              navigateTo({ routeName: 'Paramètres' });
            }}
            tabIndex={0}
            role="button"
            onClick={() => {
              setIsNavigationLinksExpanded(false);
              navigateTo({ routeName: 'Paramètres' });
            }}
          >
            <SettingsIcon
              style={{
                margin: 'auto 0',
              }}
              width={SRpx(12)}
              height={SRpx(12)}
              color="white"
            />
            <span style={{ color: 'white' }}>Paramètres</span>
          </div>
          <div
            style={{
              ...styles.button,
              ...responsiveStyles.button,
              background: DocColor.MEDIUMBLUE,
              fontSize: SRpx(15, true),
            }}
            onKeyDown={() => {
              setIsNavigationLinksExpanded(false);
              setIsConfirmFragmentClosed(false);
            }}
            tabIndex={0}
            role="button"
            onClick={() => {
              setIsNavigationLinksExpanded(false);
              setIsConfirmFragmentClosed(false);
            }}
          >
            <LogoutIcon style={{ margin: 'auto 0' }} width={SRpx(12)} height={SRpx(12)} color="white" />
            <span>Déconnexion</span>
          </div>
        </div>
      </div>
    </div>
  );
};

const styles: StyleSheet = {
  container: {
    position: 'relative',
    width: '25vw',
    background: DocColor.DARKBLUE,
    borderRadius: `0px ${Rpx(160)} 0 0`,
    zIndex: 5,
  },
  sideBarContainer: {
    height: '100vh',
  },
  onUnAuthorizedLogo: {
    position: 'absolute',
    inset: 0,
    width: '50%',
    margin: 'auto',
    borderStyle: 'solid',
    borderColor: 'white',
    borderWidth: Rpx(6),
    borderRadius: Rpx(7),
    padding: `${Rpx(60)} ${Rpx(30)}`,
  },
  onAuthorizedLogo: {
    width: '50%',
    margin: '10% auto',
  },
  navLink: {
    display: 'flex',
    flexDirection: 'row',
    fontWeight: 400,
    columnGap: Rpx(20),
    cursor: 'pointer',
    color: 'white',
    width: '18.5vw',
    height: Rpx(50),
    padding: `0 ${Rpx(30)}`,
    margin: `0 0 ${Rpx(20)} auto`,
    borderRadius: `${Rpx(42)} 0 0 ${Rpx(42)}`,
    alignItems: 'center',
    fill: 'white',
    transition: 'all .3s ease',
  },
  navLinkActive: {
    background: 'white',
    color: DocColor.DARKBLUE,
    fill: DocColor.DARKBLUE,
  },
  button: {
    flexDirection: 'row',
    borderRadius: Rpx(40),
    borderStyle: 'solid',
    width: 'fit-content',
    margin: `0px auto ${Rpx(20)} auto`,
    height: Rpx(35),
    padding: `0 ${Rpx(20)}`,
    columnGap: Rpx(10),
    fontWeight: 600,
    alignItems: 'center',
    color: 'white',
    cursor: 'pointer',
  },
  line: {
    width: '80%',
    height: Rpx(2),
    background: 'white',
    margin: '0 auto 10% auto',
  },
};

const responsiveStyles: StyleSheet = {
  container: {
    position: 'fixed',
    width: '100%',
    height: '25vw',
    borderRadius: `0 0 ${SRpx(40)} ${SRpx(40)}`,
  },
  sideBarContainer: {
    height: 'unset',
  },
  onUnAuthorizedLogo: {
    height: '50%',
    width: 'unset',
  },
  onAuthorizedLogo: {
    position: 'absolute',
    inset: 0,
    height: '50%',
    width: 'unset',
    margin: 'auto 10% auto auto',
  },
  navigationButtons: {
    position: 'absolute',
    inset: 0,
    flexDirection: 'row',
    gap: SRpx(20),
    margin: 'auto auto auto 10%',
    width: 'fit-content',
    height: 'fit-content',
  },
  navigationButton: {
    flexDirection: 'column',
    gap: SRpx(8),
    width: SRpx(30),
  },
  navigationButtonLine: {
    background: 'white',
    height: SRpx(3),
    borderRadius: SRpx(2),
  },
  navigationLinksContainer: {
    position: 'absolute',
    inset: 0,
    margin: '25vw auto',
    width: '80%',
    background: DocColor.LIGHTGRAY,
    borderRadius: `0 0 ${SRpx(20)} ${SRpx(20)}`,
    overflow: 'hidden',
    justifyContent: 'space-between',
    zIndex: 6,
    transition: 'all .3s ease-in-out',
  },
  usernameParagraph: {
    margin: '5% auto',
    fontSize: SRpx(25),
    color: DocColor.MEDIUMBLUE,
    width: '90%',
    textAlign: 'center',
    textTransform: 'capitalize',
    fontWeight: 800,
    letterSpacing: 2,
  },
  line: {
    height: 1,
    background: DocColor.DARKBLUE,
    margin: '0 auto 5% auto',
  },
  navLink: {
    fontWeight: 700,
    color: DocColor.DARKBLUE,
    textDecoration: 'none',
    margin: `0 0 ${SRpx(10)} ${SRpx(20)}`,
  },
  button: {
    borderRadius: SRpx(20),
    width: 'fit-content',
    margin: `0px auto ${SRpx(10)} auto`,
    height: SRpx(25),
    padding: `0 ${SRpx(10)}`,
    columnGap: SRpx(5),
    background: DocColor.DARKBLUE,
    border: 'none',
  },
};
