import { connect } from 'react-redux';
import { dispatchAsync } from 'react-redux-dispatch-async';
import { Action } from 'redux';
import { PatientDetailsDumb } from './PatientDetails.dumb';
import { AppState } from '../../../common/configuration/app.states';
import { NavigationPayload } from '../../../navigationContext/domain/entities/NavigationPayload';
import { navigateBackRequest, navigateToRequest } from '../../../navigationContext/usecases/navigation.actions';
import { Notes } from '../../domain/entities/Notes';
import { Patient } from '../../domain/entities/Patient';
import { TreatmentsAntecedents } from '../../domain/entities/TreatmentsAntecedents';
import {
  deletePatientRequest,
  loadNotesRequest,
  loadPatientRequest,
  loadTreatmentsAntecedentsRequest,
  updateNotesRequest,
  updatePatientRequest,
  updateTreatmentsAntecedentsRequest,
} from '../../usecases/Patients.actions';
import { getAllTreatmentsAntecedents, getNotes } from '../../usecases/Patients.selectors';
import { getConnectedUser } from '../../../authContext/usecases/Auth.selectors';
import { getPatientsConsultations } from '../../../consultationsContext/usecases/Consultations.selectors';
import {
  insertAttachmentRequest,
  loadAttachmentsRequest,
  loadPatientsConsultationsRequest,
} from '../../../consultationsContext/usecases/Consultations.actions';
import { Attachment } from '../../../consultationsContext/domain/entities/Attachments';

const mapState = (state: AppState) => ({
  stateConnectedUser: getConnectedUser(state),
  statePatientsConsultations: getPatientsConsultations(state),
  stateTreatmentsAntecedents: getAllTreatmentsAntecedents(state),
  stateNotes: getNotes(state),
});

const mapDispatch = (dispatch: (a: Action) => void) => ({
  // navigateTo: (nav: NavigationPayload) => dispatch(navigateTo(nav)),
  loadPatientAsync: (patientId: string) => dispatchAsync(dispatch, loadPatientRequest(patientId)),
  loadPatientsConsultationsAsync: (patientId?: string, consultationsLimit?: number, skipConsultations?: number) =>
    dispatchAsync(
      dispatch,
      loadPatientsConsultationsRequest({
        patientId,
        consultationsLimit,
        skipConsultations,
      }),
    ),
  loadNotesAsync: (patientId: string) => dispatchAsync(dispatch, loadNotesRequest(patientId)),
  updateNotesAsync: (notes: Notes) => dispatchAsync(dispatch, updateNotesRequest(notes)),
  loadTreatmentsAntecedentsAsync: (patientId: string) =>
    dispatchAsync(dispatch, loadTreatmentsAntecedentsRequest(patientId)),
  updateTreatmentsAntecedentsAsync: (treatmentsAndAntecedents: TreatmentsAntecedents) =>
    dispatchAsync(dispatch, updateTreatmentsAntecedentsRequest(treatmentsAndAntecedents)),
  loadAttachmentsAsync: (patientId: string, attachmentsLimit?: number, skipAttachments?: number) =>
    dispatchAsync(dispatch, loadAttachmentsRequest({ patientId, attachmentsLimit, skipAttachments })),
  insertAttachmentAsync: (attachment: Attachment) => dispatchAsync(dispatch, insertAttachmentRequest(attachment)),
  updatePatientAsync: (newPatient: Patient) => dispatchAsync(dispatch, updatePatientRequest(newPatient)),
  deletePatientAsync: (lookId: string) => dispatchAsync(dispatch, deletePatientRequest(lookId)),
  navigateToAsync: (nav: NavigationPayload) => dispatch(navigateToRequest(nav)),
  navigateBack: () => dispatch(navigateBackRequest()),
});

export const PatientDetailsSmart = connect(mapState, mapDispatch)(PatientDetailsDumb);
