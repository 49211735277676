import React, { useState } from 'react';
import { PatientConsultation } from '../../../domain/entities/PatientConsultation';
import { DocColor, StyleSheet } from '../../../../assets/styles/constantStyles';
import { NoteBookIcon } from '../../../../assets/svg/NoteBookIcon';
import { Rpx } from '../../../../common/usecases/helpers/DimensionsConverter';
import { ConfirmOverlay } from '../../../../common/ui/ConfirmOverlay';
import { NavigationPayload } from '../../../../navigationContext/domain/entities/NavigationPayload';
import { User } from '../../../../authContext/domain/entities/User';

interface PatientConsultationRowProps {
  stateConnectedUser: User | undefined;
  patientConsultationData: PatientConsultation;
  onDeleteClick?: (consultationId: string) => void;
  navigateToAsync: (p: NavigationPayload) => void;
}

export const PatientConsutationRow = ({
  stateConnectedUser,
  patientConsultationData,
  onDeleteClick,
  navigateToAsync,
}: PatientConsultationRowProps) => {
  const [isConfirmFragmentClosed, setIsConfirmFragmentClosed] = useState<boolean>(true);
  const startDateTime = new Date(patientConsultationData.startDateTime);
  const endDateTime = new Date(patientConsultationData.endDateTime);

  const showConsultationInformationHandler = () => {
    navigateToAsync({
      routeName: 'Consultation Addition',
      params: {
        patientId: patientConsultationData.patientId,
        consultationId: patientConsultationData.id,
        consultationNumber: patientConsultationData.number,
        isOnlyReadableConsultation: true,
      },
    });
  };

  const onConfirmClick = () => {
    if (patientConsultationData.id && onDeleteClick) {
      onDeleteClick(patientConsultationData.id);
    }
    setIsConfirmFragmentClosed(true);
  };
  return (
    <div style={styles.container}>
      <ConfirmOverlay
        message={`Êtes-vous sûr de vouloir supprimer la consultation N°${patientConsultationData.number}? `}
        onConfirmClick={onConfirmClick}
        isClosedState={isConfirmFragmentClosed}
        setIsClosedState={setIsConfirmFragmentClosed}
      />
      <div style={styles.consultationRowDiv}>
        <p style={{ ...styles.consultationParagraph, fontSize: Rpx(25, true) }}>
          Consultation :
          <span style={styles.consultationSpan}>{`N°${
            patientConsultationData.number ? patientConsultationData.number : '-'
          }`}</span>
        </p>
        <p style={{ ...styles.consultationParagraph, textTransform: 'capitalize', fontSize: Rpx(25, true) }}>
          Patient :
          <span style={styles.consultationSpan}>
            {patientConsultationData.temporaryPatient === null
              ? `${patientConsultationData.firstName} ${patientConsultationData.lastName}`
              : `Temp ${patientConsultationData.temporaryPatient}`}
          </span>
        </p>
      </div>
      <div style={styles.consultationRowDiv}>
        <p style={{ ...styles.consultationParagraph, fontSize: Rpx(25, true) }}>
          Début de la consultation :
          <span style={styles.consultationSpan}>{`${startDateTime.getHours()}h${startDateTime.getMinutes()}`}</span>
        </p>
        <p style={{ ...styles.consultationParagraph, fontSize: Rpx(25, true) }}>
          Fin de la consultation :
          <span style={styles.consultationSpan}>{`${endDateTime.getHours()}h${endDateTime.getMinutes()}`}</span>
        </p>
      </div>
      {stateConnectedUser?.role === 'DOCTOR' && (
        <div style={styles.consultationRowDiv}>
          <div
            style={styles.button}
            onKeyDown={() => {
              showConsultationInformationHandler();
            }}
            tabIndex={0}
            role="button"
            onClick={() => {
              showConsultationInformationHandler();
            }}
          >
            <NoteBookIcon width={Rpx(25, true)} height={Rpx(25, true)} color="white" style={{ margin: 'auto 0' }} />
            <p style={{ ...styles.consultationButtonParagraph, fontSize: Rpx(24, true) }}>
              Afficher le compte rendu de la consultation
            </p>
          </div>
          <div
            style={styles.button}
            onKeyDown={() => {
              setIsConfirmFragmentClosed(false);
            }}
            tabIndex={0}
            role="button"
            onClick={() => {
              setIsConfirmFragmentClosed(false);
            }}
          >
            <NoteBookIcon width={Rpx(25)} height={Rpx(25)} color="white" style={styles.deleteConsultationButtonIcon} />
            <p style={{ ...styles.consultationButtonParagraph, fontSize: Rpx(24, true) }}>Supprimer la consultation</p>
          </div>
        </div>
      )}
    </div>
  );
};

const styles: StyleSheet = {
  container: {
    background: DocColor.LIGHTGRAY,
    borderRadius: Rpx(10),
    margin: `0 2vw ${Rpx(20)} 4vw`,
    padding: `0 2vw`,
  },
  consultationRowDiv: {
    flexDirection: 'row',
    width: '100%',
    padding: `${Rpx(15)} 0`,
    boxSizing: 'border-box',
    justifyContent: 'space-between',
  },
  consultationParagraph: {
    color: DocColor.DARKBLUE,
    fontWeight: 600,
  },
  consultationSpan: {
    color: DocColor.MEDIUMBLUE,
    fontWeight: 500,
  },
  button: {
    flexDirection: 'row',
    height: Rpx(55),
    background: DocColor.MEDIUMBLUE,
    borderRadius: Rpx(18),
    justifyContent: 'center',
    color: 'white',
    fontSize: Rpx(25),
    cursor: 'pointer',
    fontWeight: 500,
    padding: `0 ${Rpx(10)}`,
  },
  deleteConsultationButtonIcon: {
    margin: 'auto 0',
  },
  consultationButtonParagraph: {
    margin: `auto 0 auto ${Rpx(10)}`,
    fontWeight: 700,
  },
};
