import { connect } from 'react-redux';
import { dispatchAsync } from 'react-redux-dispatch-async';
import { Action } from 'redux';
import { CalendarDumb } from './Calendar.dumb';
import { AppState } from '../../../common/configuration/app.states';
import { getCalendarEvents } from '../../usecases/Calendar.selectors';
import {
  deleteCalendarEventRequest,
  insertCalendarEventRequest,
  loadCalendarEventsRequest,
  loadProgramedAppointmentsRequest,
  updateCalendarEventRequest,
} from '../../usecases/Calendar.actions';
import { CalendarEvent } from '../../domain/entities/CalendarEvent';
import { loadPatientsNamesRequest } from '../../../patientsContext/usecases/Patients.actions';
import { NavigationPayload } from '../../../navigationContext/domain/entities/NavigationPayload';
import { navigateToRequest } from '../../../navigationContext/usecases/navigation.actions';
import { getConnectedUser } from '../../../authContext/usecases/Auth.selectors';

const mapState = (state: AppState) => ({
  stateCalendarEvents: getCalendarEvents(state),
  stateConnectedUser: getConnectedUser(state),
});

const mapDispatch = (dispatch: (a: Action) => void) => ({
  loadPatientsNamesAsync: () => dispatchAsync(dispatch, loadPatientsNamesRequest()),
  loadCalendarEventsAsync: () => dispatchAsync(dispatch, loadCalendarEventsRequest()),
  insertCalendarEventAsync: (calendarEvent: CalendarEvent) =>
    dispatchAsync(dispatch, insertCalendarEventRequest(calendarEvent)),
  updateCalendarEventAsync: (calendarEvent: CalendarEvent) =>
    dispatchAsync(dispatch, updateCalendarEventRequest(calendarEvent)),
  deleteCalendarEventAsync: (lookId: string) => dispatchAsync(dispatch, deleteCalendarEventRequest(lookId)),
  loadProgramedAppointmentsAsync: (start: string, end: string) =>
    dispatchAsync(dispatch, loadProgramedAppointmentsRequest({ start, end })),
  navigateToAsync: (nav: NavigationPayload) => dispatch(navigateToRequest(nav)),
});

export const CalendarSmart = connect(mapState, mapDispatch)(CalendarDumb);
