export interface ConsultationNotes {
  consultationId?: string;
  consultationNotes: string;
}
export const hardCodedEmptyConsultationNotes: ConsultationNotes = {
  consultationId: '',
  consultationNotes: '',
};

export const hardCodedConsultationNotesn: ConsultationNotes = {
  consultationId: 'p00111',
  consultationNotes:
    'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Mauris orci ligula, vulputate in bibendum a, imperdiet non magna. Aliquam dolor ipsum, tincidunt sed mi vel, vehicula pretium tellus. Donec in sapien in lectus elementum venenatis in in odio. Integer vulputate mattis finibus. Integer sodales massa ac porta condimentum.',
};
