import * as React from 'react';
import { Color } from '../styles/constantStyles';

export interface ToTopArrowFatLinesIconProps {
  color?: string;
  style?: React.CSSProperties;
  width?: string | number;
  height?: string | number;
}

export const ToTopArrowFatLinesIcon = ({ color, width, height, style }: ToTopArrowFatLinesIconProps) => (
  <svg style={style} width={width} height={height} viewBox="0 0 25 26" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M3.26562 12.2332L12.4656 3.0332L21.6656 12.2332H17.0656V15.2999H7.86562V12.2332H3.26562Z"
      stroke={color || Color.MONOGREY3}
      strokeWidth="1.53333"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M17.0672 21.4336H7.86719"
      stroke={color || Color.MONOGREY3}
      strokeWidth="1.53333"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M17.0672 18.3662H7.86719"
      stroke={color || Color.MONOGREY3}
      strokeWidth="1.53333"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
