import { Action } from 'redux-actions';
import { loadCalendarEventsSuccess } from './Calendar.actions';
import { createTypedHandler, handleTypedActions } from '../../common/usecases/helpers/actions.helper';
import { CalendarState } from '../configuration/Calendar.state';
import { CalendarEvent } from '../domain/entities/CalendarEvent';

const calendarState: CalendarState = {
  calendarEvents: [],
};

const loadCalendarEventsReducer = (state: CalendarState, action: Action<CalendarEvent[]>) => ({
  ...state,
  calendarEvents: action.payload,
});

export const calendarReducer = handleTypedActions(
  [createTypedHandler(loadCalendarEventsSuccess, loadCalendarEventsReducer)],
  calendarState,
);
