import { AuthTokens } from '../../../common/domain/entities/AuthTokens';
import { User } from '../../domain/entities/User';
import { AuthInteractor } from '../../domain/gateways/Auth.interactor';

export class InMemoryAuthInteractor implements AuthInteractor {
  deleteUser(): Promise<User> {
    throw new Error('Method not implemented.');
  }

  sendSignIn(): Promise<AuthTokens> {
    throw new Error('Method not implemented.');
  }

  loadUser(): Promise<User> {
    throw new Error('Method not implemented.');
  }

  updateUsername(): Promise<User> {
    throw new Error('Method not implemented.');
  }

  updatePassword(): Promise<User> {
    throw new Error('Method not implemented.');
  }

  resetPassword(): Promise<User> {
    throw new Error('Method not implemented.');
  }

  sendSignUp(newUser: User): Promise<User> {
    return Promise.resolve(newUser);
  }

  sendAutoSignIn(): Promise<AuthTokens> {
    throw new Error('Method not implemented.');
  }

  sendSignOut(): Promise<undefined> {
    return Promise.resolve(undefined);
  }

  loadUsers(): Promise<User[]> {
    return Promise.resolve([]);
  }

  sendResetPasswordRequest(): Promise<boolean> {
    const isEmailFound = true;
    return Promise.resolve(isEmailFound);
  }
}
