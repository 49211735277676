import React, { useEffect, useState } from 'react';
import { DispatchAsyncResult } from 'react-redux-dispatch-async/lib/dispatchAsync';
import { ExaminationRow } from './ExaminationRow';
import { DocColor, StyleSheet } from '../../../../assets/styles/constantStyles';
import { AddIcon } from '../../../../assets/svg/Addlcon';
import { TrashIcon } from '../../../../assets/svg/TrashIcon';
import { ButtonWithIcon } from '../../../../common/ui/ButtonWithIcon';
import { ConfirmOverlay } from '../../../../common/ui/ConfirmOverlay';
import { Rpx } from '../../../../common/usecases/helpers/DimensionsConverter';
import { Examination, hardCodedEmptyExaminations } from '../../../domain/entities/Examination';
import { ConsultationAdditionFilledDataType } from '../../../domain/entities/ConsultationAdditionFilledDataType';
import { Patient } from '../../../../patientsContext/domain/entities/Patient';

type ExaminationsComponentProps = {
  sendAttchmentByMailAsync: (mail: string, fileName: string, fileContent: string) => Promise<DispatchAsyncResult>;
  patient?: Patient | null;
  examinationData: Examination[] | undefined;
  isOnlyReadable?: boolean;
  setFilledData: React.Dispatch<React.SetStateAction<ConsultationAdditionFilledDataType>>;
};

export const ExaminationsComponent = ({
  sendAttchmentByMailAsync,
  patient,
  examinationData,
  isOnlyReadable,
  setFilledData,
}: ExaminationsComponentProps) => {
  const [examinationDataState, setExaminationDataState] = useState<Examination[]>([...hardCodedEmptyExaminations]);
  const [isConfirmFragmentClosed, setIsConfirmFragmentClosed] = useState<boolean>(true);
  const [onDeleteIndex, setonDeleteIndex] = useState<number>(0);

  useEffect(() => {
    if (isOnlyReadable === true && examinationData && examinationData.length > 0) {
      setExaminationDataState([...examinationData]);
    }
  }, [examinationData]);

  useEffect(() => {
    if (isOnlyReadable !== true) {
      if (examinationDataState[examinationDataState.length - 1].type !== '') {
        setFilledData((prevState) => ({ ...prevState, examinations: examinationDataState }));
      } else if (examinationDataState.length === 1 && examinationDataState[0].type === '') {
        setFilledData((prevState) => ({ ...prevState, examinations: [] }));
      }
    }
  }, [examinationDataState]);
  const addElementHandler = () => {
    setExaminationDataState((objs) => [
      ...objs,
      {
        type: '',
        file: '',
        fileName: '',
      },
    ]);
  };

  const setExamination = (index: number, examination: Examination) => {
    const tempData: Examination[] = examinationDataState;
    tempData[index] = examination;
    setExaminationDataState([...tempData]);
  };

  const onDeleteClickHandler = (index: number) => {
    const tempData: Examination[] = [...examinationDataState];
    if (examinationDataState.length === 1) {
      tempData[0].type = '';
      tempData[0].file = '';
      tempData[0].fileName = '';
    } else {
      tempData.splice(index, 1);
    }
    setExaminationDataState([...tempData]);
  };

  const onConfirmClick = () => {
    onDeleteClickHandler(onDeleteIndex);
    setIsConfirmFragmentClosed(true);
  };

  return (
    <div style={styles.container}>
      <ConfirmOverlay
        onConfirmClick={onConfirmClick}
        message="L’examen sera supprimé
        Êtes-vous sûr ?"
        isClosedState={isConfirmFragmentClosed}
        setIsClosedState={setIsConfirmFragmentClosed}
      />
      <div style={styles.titleDiv}>
        <p key="firstHistoryDateDiv" style={{ ...styles.title, fontSize: Rpx(32, true) }}>
          Examens
        </p>
      </div>
      <div style={{ width: '92%', margin: '0 auto' }}>
        {examinationDataState &&
          examinationDataState.map((obj, index) => {
            const key = `examination ${index}`;
            return (
              <div key={key} style={{ marginTop: Rpx(44), flexDirection: 'row' }}>
                <ExaminationRow
                  patientEmail={patient?.email}
                  sendAttchmentByMailAsync={sendAttchmentByMailAsync}
                  inputName={`Examen ${index + 1}`}
                  examinationData={{ ...obj }}
                  onDataChange={(examinationState) => {
                    setExamination(index, examinationState);
                  }}
                  isOnlyReadable={isOnlyReadable}
                  mailButtonVisibility={patient?.email !== '' && patient?.email !== null}
                />

                <ButtonWithIcon
                  style={{
                    width: Rpx(45, true),
                    height: Rpx(45, true),
                    borderRadius: Rpx(16),
                    margin: `${Rpx(2)} ${Rpx(20)}`,
                    display:
                      (examinationDataState.length === 1 && examinationDataState[0].type === '') || isOnlyReadable
                        ? 'none'
                        : '',
                  }}
                  icon={TrashIcon({
                    width: Rpx(30, true),
                    height: Rpx(30, true),
                    color: 'white',
                    style: { margin: 'auto 0' },
                  })}
                  onClick={() => {
                    setonDeleteIndex(index);
                    setIsConfirmFragmentClosed(false);
                  }}
                />
              </div>
            );
          })}

        <div
          style={{
            ...styles.addButton,
            display: examinationDataState[examinationDataState.length - 1].type === '' || isOnlyReadable ? 'none' : '',
          }}
          onClick={() => {
            addElementHandler();
          }}
          onKeyDown={() => addElementHandler()}
          tabIndex={0}
          role="button"
        >
          <AddIcon width={Rpx(14, true)} height={Rpx(14, true)} color={DocColor.MEDIUMBLUE} style={styles.buttonIcon} />
          <p style={{ ...styles.buttonParagraph, fontSize: Rpx(25, true) }}>Ajouter un autre Examen</p>
        </div>
      </div>
    </div>
  );
};
const styles: StyleSheet = {
  container: {
    position: 'relative',
    margin: `2vw 2vw 0`,
    outline: `${Rpx(10)} solid ${DocColor.DARKBLUE}`,
    borderRadius: Rpx(60),
    minHeight: Rpx(100),
    paddingBottom: Rpx(47),
  },
  titleDiv: {
    width: 'fit-content',
    background: DocColor.DARKBLUE,
    borderRadius: `${Rpx(50)} 0 ${Rpx(20)} 0`,
    minHeight: Rpx(60),
    padding: `0 ${Rpx(40)}`,
    marginBottom: Rpx(15),
  },
  title: {
    color: 'white',
    fontWeight: 600,
    fontStyle: 'italic',
    margin: 'auto',
  },
  addButton: {
    flexDirection: 'row',
    margin: `${Rpx(17)} 0 0 ${Rpx(260)}`,
    cursor: 'pointer',
    marginTop: 0,
  },
  buttonParagraph: {
    fontWeight: 500,
    fontStyle: 'italic',
    color: DocColor.MEDIUMBLUE,
  },
  buttonIcon: {
    outline: `${Rpx(3)} solid ${DocColor.MEDIUMBLUE}`,
    borderRadius: Rpx(20),
    padding: Rpx(3),
    margin: `auto ${Rpx(15)} auto 0`,
  },
};
