import { ENV } from '../../configuration';
import { InMemoryPatientConsultationsInteractor } from '../adapter/inmemory/InMemoryPatientConsultationsInteractor';
import { RealPatientConsultationsInteractor } from '../adapter/real/RealPatientConsultationsInteractor';
import { PatientConsultationsInteractor } from '../domain/gateways/PatientConsultations.interactor';

export class ConsultationsContextFactory {
  static patientConsultationsInteractor(): PatientConsultationsInteractor {
    switch (ENV) {
      case 'prod':
      case 'dev':
        return new RealPatientConsultationsInteractor();
      default:
        return new InMemoryPatientConsultationsInteractor();
    }
  }
}
