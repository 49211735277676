import { appStore } from '../../../configuration/redux/store';

export const Rpx = (px: number, isAdjustable?: boolean, size?: 'S' | 'M' | 'L') => {
  const s =
    size ||
    (appStore.store.getState().userSettings.userSettings
      ? appStore.store.getState().userSettings.userSettings.fontSizeState
      : 'M');
  // appStore.store.getState().patients.userSettings.fontSizeState
  // Calculations based on 1920*1080 dimensions, if you want to change it just change defaultWidth.
  const screenWidth: number = window.innerWidth;
  const defaultWidth = 1920;
  const screenResizeInPercentage: number = (screenWidth * 100) / defaultWidth;
  const responsivePixel: number = (px * screenResizeInPercentage) / 100;
  if (isAdjustable) {
    const adjustedResponsivePixel: number =
      s === 'S'
        ? responsivePixel - (responsivePixel * 10) / 100
        : s === 'L'
        ? responsivePixel + (responsivePixel * 20) / 100
        : responsivePixel;
    return `${adjustedResponsivePixel}px`;
  }
  return `${responsivePixel}px`;
};

export const SRpx = (px: number, isAdjustable?: boolean, size?: 'S' | 'M' | 'L') => {
  const s =
    size ||
    (appStore.store.getState().userSettings.userSettings
      ? appStore.store.getState().userSettings.userSettings.fontSizeState
      : 'M');
  // appStore.store.getState().patients.userSettings.fontSizeState
  // Calculations based on 1920*1080 dimensions, if you want to change it just change defaultWidth.
  const screenWidth: number = window.innerWidth;
  const defaultWidth = 360;
  const screenResizeInPercentage: number = (screenWidth * 100) / defaultWidth;
  const responsivePixel: number = (px * screenResizeInPercentage) / 100;
  if (isAdjustable) {
    const adjustedResponsivePixel: number =
      s === 'S'
        ? responsivePixel - (responsivePixel * 10) / 100
        : s === 'L'
        ? responsivePixel + (responsivePixel * 20) / 100
        : responsivePixel;
    return `${adjustedResponsivePixel}px`;
  }
  return `${responsivePixel}px`;
};
