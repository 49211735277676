import React from 'react';
import { DocColor, StyleSheet } from '../../../../assets/styles/constantStyles';
import { Rpx } from '../../../../common/usecases/helpers/DimensionsConverter';

type TextAreaContainerProps = {
  title: string;
  defaultValue?: string;
  isOnlyReadable?: boolean;
  onChange?: (v: React.ChangeEvent<HTMLTextAreaElement>) => void;
};

export const TextAreaContainer = ({ title, defaultValue, isOnlyReadable, onChange }: TextAreaContainerProps) => {
  const textAreaHandleKeyDown = (e: any) => {
    e.target.style.height = 'inherit';
    e.target.style.height = `${e.target.scrollHeight}px`;
  };

  return (
    <div style={styles.container}>
      <div style={styles.titleDiv}>
        <p key="firstHistoryDateDiv" style={{ ...styles.title, fontSize: Rpx(32, true) }}>
          {title}
        </p>
      </div>
      <textarea
        onKeyDown={(e) => textAreaHandleKeyDown(e)}
        onChange={(v) => {
          if (onChange) {
            onChange(v);
          }
        }}
        disabled={isOnlyReadable}
        style={{ ...styles.textArea, fontSize: Rpx(25, true) }}
        rows={3}
        defaultValue={defaultValue}
      />
    </div>
  );
};
const styles: StyleSheet = {
  container: {
    position: 'relative',
    margin: `2vw 2vw 0`,
    outline: `${Rpx(10)} solid ${DocColor.DARKBLUE}`,
    borderRadius: Rpx(60),
    minHeight: Rpx(100),
  },
  textArea: {
    width: '90%',
    background: DocColor.LIGHTGRAY,
    border: `${Rpx(2)} solid ${DocColor.LIGHTGRAY}`,
    borderRadius: Rpx(9),
    outline: 'none',
    caretColor: DocColor.DARKBLUE,
    overflow: 'auto',
    resize: 'vertical',
    margin: `${Rpx(35)} auto ${Rpx(60)}`,
  },
  titleDiv: {
    width: 'fit-content',
    background: DocColor.DARKBLUE,
    borderRadius: `${Rpx(50)} 0 ${Rpx(20)} 0`,
    minHeight: Rpx(60),
    padding: `0 ${Rpx(40)}`,
    marginBottom: Rpx(15),
  },
  title: {
    color: 'white',
    fontWeight: 600,
    fontStyle: 'italic',
    margin: 'auto',
  },
};
