import { fetchWithToken } from '../../../common/adapter/fetch.helper';
import { BACKEND_CONFIG } from '../../../configuration';
import { Attachment } from '../../domain/entities/Attachments';
import { ConsultationNotes } from '../../domain/entities/ConsultationNotes';
import { ConsultationReason } from '../../domain/entities/ConsultationReason';
import { Examination } from '../../domain/entities/Examination';
import { ExaminationToRequest } from '../../domain/entities/ExaminationToRequest';
import { Interrogation } from '../../domain/entities/Interrogation';
import { MedicalCertificate } from '../../domain/entities/MedicalCertificate';
import { Medicine } from '../../domain/entities/Medicine';
import { Mesures } from '../../domain/entities/Mesures';
import { PatientConsultation } from '../../domain/entities/PatientConsultation';
import { Prescription } from '../../domain/entities/Prescriptions';
import { TodaysConsultationsStats } from '../../domain/entities/TodaysConsultationsStats';
import { PatientConsultationsInteractor } from '../../domain/gateways/PatientConsultations.interactor';

export class RealPatientConsultationsInteractor implements PatientConsultationsInteractor {
  async loadPatientsConsultations(patientId?: string, consultationsLimit?: number, skipConsultations?: number) {
    const url = `${BACKEND_CONFIG.endpoints.consultations}/loadConsultations`;
    const response = await fetchWithToken(
      `${url}?patientId=${patientId}&consultationsLimit=${consultationsLimit}&skipConsultations=${skipConsultations}`,
    );
    const result = await response.json();
    return result;
    // if patientId is undefined it means you have to load all patients consultations
    // if jumpToNextConsultations is undefined it means you have to load without offsets
  }

  async loadPatientConsultation(consultationId: string) {
    const url = `${BACKEND_CONFIG.endpoints.consultations}/loadConsultation`;
    const response = await fetchWithToken(`${url}?consultationId=${consultationId}`); // need to do token stuff first
    const result = await response.json();
    return result;
  }

  async fetchPatientsConsultations(value: string) {
    const url = `${BACKEND_CONFIG.endpoints.consultations}/fetchConsultations`;
    const response = await fetchWithToken(`${url}?value=${value}`); // need to do token stuff first
    const result = await response.json();
    return result;
  }

  async getPatientConsultationNumber() {
    const url = `${BACKEND_CONFIG.endpoints.consultations}/getConsultationNumber`;
    const response = await fetchWithToken(url); // need to do token stuff first
    const result = await response.json();
    return result;
  }

  async getTodaysConsultationsStats(): Promise<TodaysConsultationsStats> {
    const url = `${BACKEND_CONFIG.endpoints.consultations}/getTodaysConsultationsStats`;
    const response = await fetchWithToken(url); // need to do token stuff first
    const result = await response.json();
    return result;
  }

  async insertPatientsConsultation(patientConsultation: PatientConsultation) {
    const url = `${BACKEND_CONFIG.endpoints.consultations}/insertConsultation`;
    const response = await fetchWithToken(url, {
      method: 'POST',
      body: JSON.stringify(patientConsultation),
    });
    const result = await response.json();
    return result;
  }

  async deletePatientConsultation(lookId: string) {
    const url = `${BACKEND_CONFIG.endpoints.consultations}/deleteConsultation`;
    const response = await fetchWithToken(`${url}?lookId=${lookId}`, { method: 'DELETE' });
    const result = await response.json();
    return result;
  }

  async loadAttachments(patientId: string, attachmentsLimit?: number, skipAttachments?: number) {
    const url = `${BACKEND_CONFIG.endpoints.patients}/loadAttachments`;
    const response = await fetchWithToken(
      `${url}?patientId=${patientId}&attachmentsLimit=${attachmentsLimit}&skipAttachments=${skipAttachments}`,
    ); // need to do token stuff first
    const result = await response.json();
    return result;
  }

  async insertAttachment(attachment: Attachment): Promise<Attachment> {
    const url = `${BACKEND_CONFIG.endpoints.patients}/insertAttachment`;
    const response = await fetchWithToken(url, {
      method: 'POST',
      body: JSON.stringify(attachment),
    });
    const result = await response.json();
    return result;
  }

  async sendAttachmentByMail(mail: string, fileName: string, fileContent: string): Promise<boolean> {
    const url = `${BACKEND_CONFIG.endpoints.consultations}/sendAttachmentByMail`;
    const response = await fetchWithToken(url, {
      method: 'POST',
      body: JSON.stringify({ mail, fileName, fileContent }),
    });
    const result = await response.json();
    return result;
  }

  async loadConsultationReason(consultationId: string) {
    const url = `${BACKEND_CONFIG.endpoints.consultations}/loadConsultationReason`;
    const response = await fetchWithToken(`${url}?consultationId=${consultationId}`); // need to do token stuff first
    const result = await response.json();
    return result;
  }

  async insertConsultationReason(consultationReason: ConsultationReason) {
    const url = `${BACKEND_CONFIG.endpoints.consultations}/insertConsultationReason`;
    const response = await fetchWithToken(url, {
      method: 'POST',
      body: JSON.stringify(consultationReason),
    });
    const result = await response.json();
    return result;
  }

  async loadInterrogation(consultationId: string) {
    const url = `${BACKEND_CONFIG.endpoints.consultations}/loadInterrogation`;
    const response = await fetchWithToken(`${url}?consultationId=${consultationId}`); // need to do token stuff first
    const result = await response.json();
    return result;
  }

  async insertInterrogation(interrogation: Interrogation) {
    const url = `${BACKEND_CONFIG.endpoints.consultations}/insertInterrogation`;
    const response = await fetchWithToken(url, {
      method: 'POST',
      body: JSON.stringify(interrogation),
    });
    const result = await response.json();
    return result;
  }

  async loadConsultationNotes(consultationId: string): Promise<ConsultationNotes> {
    const url = `${BACKEND_CONFIG.endpoints.consultations}/loadConsultationNotes`;
    const response = await fetchWithToken(`${url}?consultationId=${consultationId}`); // need to do token stuff first
    const result = await response.json();
    return result;
  }

  async insertConsultationNotes(consultationNotes: ConsultationNotes): Promise<ConsultationNotes> {
    const url = `${BACKEND_CONFIG.endpoints.consultations}/insertConsultationNotes`;
    const response = await fetchWithToken(url, {
      method: 'POST',
      body: JSON.stringify(consultationNotes),
    });
    const result = await response.json();
    return result;
  }

  async loadMesures(consultationId: string) {
    const url = `${BACKEND_CONFIG.endpoints.consultations}/loadMesures`;
    const response = await fetchWithToken(`${url}?consultationId=${consultationId}`); // need to do token stuff first
    const result = await response.json();
    return result;
  }

  async insertMesures(mesures: Mesures) {
    const url = `${BACKEND_CONFIG.endpoints.consultations}/insertMesures`;
    const response = await fetchWithToken(url, {
      method: 'POST',
      body: JSON.stringify(mesures),
    });
    const result = await response.json();
    return result;
  }

  async loadExaminations(consultationId: string) {
    const url = `${BACKEND_CONFIG.endpoints.consultations}/loadExaminations`;
    const response = await fetchWithToken(`${url}?consultationId=${consultationId}`); // need to do token stuff first
    const result = await response.json();
    return result;
  }

  async insertExaminations(patientId: string, consultationId: string, examinations: Examination[]) {
    const url = `${BACKEND_CONFIG.endpoints.consultations}/insertExaminations`;
    const response = await fetchWithToken(url, {
      method: 'POST',
      body: JSON.stringify({ patientId, consultationId, examinations }),
    });
    // const response = await fetchWithToken(url); // need to do token stuff first
    const result = await response.json();
    return result;
  }

  async loadExaminationsToRequest(consultationId: string) {
    const url = `${BACKEND_CONFIG.endpoints.consultations}/loadExaminationsToRequest`;
    const response = await fetchWithToken(`${url}?consultationId=${consultationId}`); // need to do token stuff first
    const result = await response.json();
    return result;
  }

  async insertExaminationsToRequest(consultationId: string, examinationsToRequest: ExaminationToRequest[]) {
    const url = `${BACKEND_CONFIG.endpoints.consultations}/insertExaminationsToRequest`;
    const response = await fetchWithToken(url, {
      method: 'POST',
      body: JSON.stringify({ consultationId, examinationsToRequest }),
    });
    const result = await response.json();
    return result;
  }

  async loadPrescriptions(consultationId: string) {
    const url = `${BACKEND_CONFIG.endpoints.consultations}/loadPrescription`;
    const response = await fetchWithToken(`${url}?consultationId=${consultationId}`); // need to do token stuff first
    const result = await response.json();
    return result;
  }

  async insertPrescriptions(consultationId: string, prescriptions: Prescription[]) {
    const url = `${BACKEND_CONFIG.endpoints.consultations}/insertPrescription`;
    const response = await fetchWithToken(url, {
      method: 'POST',
      body: JSON.stringify({ consultationId, prescriptions }),
    });
    const result = await response.json();
    return result;
  }

  async fetchMedicines(value?: string, composition?: string): Promise<Medicine[]> {
    const url = `${BACKEND_CONFIG.endpoints.medicines}/loadMedicaments`;
    const response = await fetchWithToken(`${url}?product_name=${value || ''}&composition=${composition || ''}`); // need to do token stuff first
    const result = await response.json();
    return result;
  }

  async loadMedicalCertificate(consultationId: string) {
    const url = `${BACKEND_CONFIG.endpoints.consultations}/loadMedicalCertificate`;
    const response = await fetchWithToken(`${url}?consultationId=${consultationId}`); // need to do token stuff first
    const result = await response.json();
    return result;
  }

  async insertMedicalCertificate(medicalCertificate: MedicalCertificate) {
    const url = `${BACKEND_CONFIG.endpoints.consultations}/insertMedicalCertificate`;
    const response = await fetchWithToken(url, {
      method: 'POST',
      body: JSON.stringify(medicalCertificate),
    });
    const result = await response.json();
    return result;
  }
}
