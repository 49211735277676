import React, { useState } from 'react';
import { DispatchAsyncResult } from 'react-redux-dispatch-async/lib/dispatchAsync';
import { DocColor, StyleSheet } from '../../../../assets/styles/constantStyles';
import { User } from '../../../../authContext/domain/entities/User';
import { useNotificationContext } from '../../../../common/configuration/notificationContextProvider';
import { ConfirmOverlay } from '../../../../common/ui/ConfirmOverlay';
import { Rpx } from '../../../../common/usecases/helpers/DimensionsConverter';

interface ChangeUsernameComponentProps {
  connectedUser: User | undefined;
  updateUsernameAsync(id: string, firstName: string, lastName: string): Promise<DispatchAsyncResult<User>>;
  setChosenOption: React.Dispatch<React.SetStateAction<string>>;
}
export const ChangeUsernameComponent = ({
  connectedUser,
  updateUsernameAsync,
  setChosenOption,
}: ChangeUsernameComponentProps) => {
  const notification = useNotificationContext();
  const [isConfirmFragmentClosed, setIsConfirmFragmentClosed] = useState<boolean>(true);
  const [firstName, setFirstName] = useState<string>(connectedUser?.firstName ? connectedUser?.firstName : '');
  const [lastName, setLastName] = useState<string>(connectedUser?.lastName ? connectedUser?.lastName : '');

  const onConfirmClick = () => {
    setIsConfirmFragmentClosed(true);
    if (connectedUser && connectedUser.id) {
      updateUsernameAsync(connectedUser.id, firstName, lastName).then((res) => {
        if (res.success) {
          setChosenOption('');
          notification.setOnSuccess({
            message: `Votre nom d’utilisateur vient d’être modifié.
          `,
          });
        } else {
          notification.setOnError({
            message: `Nous n'avons pas pu mettre à jour le nom d'utilisateur.
          `,
          });
        }
      });
    }
  };

  return (
    <div style={styles.container}>
      <ConfirmOverlay
        onConfirmClick={onConfirmClick}
        message={`Êtes-vous sûr de bien vouloir mettre à jour le nom d'utilisateur ?`}
        isClosedState={isConfirmFragmentClosed}
        setIsClosedState={setIsConfirmFragmentClosed}
      />
      <div style={styles.titleDiv}>
        <p style={{ ...styles.titleParagraph, fontSize: Rpx(23, true) }}>Changer le nom d’utilisateur</p>
      </div>
      <div style={{ margin: 'auto', alignItems: 'center' }}>
        <p style={{ ...styles.explanationParagraph, fontSize: Rpx(25, true) }}>
          Permet de changer le nom qui s’affiche dans l’accueil et celui qui se trouve en haut de votre moniteur.
        </p>
        <div style={styles.horizontalLine} />
        <div style={styles.newUsernameContainer}>
          <p style={{ ...styles.newUsernameTitle, fontSize: Rpx(23, true) }}>Nouveau prénom d’utilisateur</p>
          <input
            style={{ ...styles.input, height: Rpx(70, true), fontSize: Rpx(20, true) }}
            placeholder="Entrez le prénom d'utilisateur"
            defaultValue={connectedUser?.firstName}
            onChange={(v) => {
              setFirstName(v.target.value);
            }}
          />
          <p style={{ ...styles.newUsernameTitle, fontSize: Rpx(23, true) }}>Nouveau nom d’utilisateur</p>
          <input
            style={{ ...styles.input, height: Rpx(70, true), fontSize: Rpx(20, true) }}
            placeholder="Entrez le nom d'utilisateur"
            defaultValue={connectedUser?.lastName}
            onChange={(v) => {
              setLastName(v.target.value);
            }}
          />
          <div style={styles.newUserNameButtonsContainer}>
            <button
              style={{
                ...styles.button,
                height: Rpx(70, true),
                fontSize: Rpx(28, true),
                background:
                  firstName === '' ||
                  lastName === '' ||
                  (lastName === connectedUser?.lastName && firstName === connectedUser?.firstName)
                    ? DocColor.GRAY
                    : DocColor.MEDIUMBLUE,
              }}
              type="button"
              disabled={
                firstName === '' ||
                lastName === '' ||
                (lastName === connectedUser?.lastName && firstName === connectedUser?.firstName)
              }
              onClick={() => setIsConfirmFragmentClosed(false)}
            >
              Valider
            </button>
            <button
              style={{ ...styles.button, height: Rpx(70, true), fontSize: Rpx(28, true) }}
              type="button"
              onClick={() => setChosenOption('')}
            >
              Annuler
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

const styles: StyleSheet = {
  container: {
    position: 'relative',
    width: '52.5%',
    marginLeft: '5%',
    outline: `${Rpx(10)} solid ${DocColor.DARKBLUE}`,
    borderRadius: Rpx(60),
    height: '100%',
    overflow: 'hidden',
    alignItems: 'center',
    transition: 'all 0.3s ease',
  },
  titleDiv: {
    width: 'fit-content',
    background: DocColor.DARKBLUE,
    borderRadius: `0 0 ${Rpx(15)} ${Rpx(15)}`,
    minHeight: Rpx(45),
    padding: `0 ${Rpx(40)}`,
    margin: `0 auto`,
    alignItems: 'center',
    justifyContent: 'center',
  },
  titleParagraph: {
    color: 'white',
    fontWeight: 600,
    marginBottom: Rpx(10),
  },
  explanationParagraph: {
    color: DocColor.DARKBLUE,
    fontWeight: 700,
    padding: `0 ${Rpx(32)}`,
    marginTop: Rpx(65),
    textAlign: 'center',
  },
  horizontalLine: {
    width: '80%',
    background: DocColor.MEDIUMBLUE,
    height: Rpx(8),
    borderRadius: Rpx(4),
    marginTop: Rpx(50),
  },
  newUsernameContainer: {
    width: '90%',
    minHeight: Rpx(50),
    background: DocColor.CLOUDYBLUE,
    marginTop: Rpx(50),
    marginBottom: Rpx(20),
    padding: `${Rpx(30)} 0`,
    borderRadius: Rpx(30),
  },
  newUsernameTitle: {
    color: DocColor.DARKBLUE,
    fontWeight: 700,
    marginLeft: '10%',
    marginTop: Rpx(20),
  },
  input: {
    maxWidth: '100%',
    width: '80%',
    background: 'white',
    border: 'none',
    borderRadius: Rpx(9),
    outline: 'none',
    fontWeight: 700,
    textTransform: 'capitalize',
    textAlign: 'center',
    letterSpacing: Rpx(4),
    margin: `${Rpx(10)} auto 0 auto`,
  },
  newUserNameButtonsContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    width: '70%',
    margin: `${Rpx(50)} auto 0 auto`,
  },
  button: {
    width: 'fit-content',
    backgroundColor: DocColor.DARKBLUE,
    color: 'white',
    fontWeight: 700,
    borderRadius: Rpx(12),
    flexDirection: 'row',
    padding: `0px ${Rpx(40)}`,
  },
};
