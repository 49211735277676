import React, { useEffect, useRef, useState } from 'react';
import { DispatchAsyncResult } from 'react-redux-dispatch-async/lib/dispatchAsync';
import { useMediaQuery } from 'react-responsive';
import { DocColor, StyleSheet } from '../../../../assets/styles/constantStyles';
import { CheckMarkIcon } from '../../../../assets/svg/CheckMarkIcon';
import { EditIcon } from '../../../../assets/svg/EditIcon';
import { useNotificationContext } from '../../../../common/configuration/notificationContextProvider';
import { bigScreenBreakpoint } from '../../../../common/domain/entities/ReactResponsiveBreakpoints';
import { ConfirmOverlay } from '../../../../common/ui/ConfirmOverlay';
import { Rpx, SRpx } from '../../../../common/usecases/helpers/DimensionsConverter';
import { hardCodedEmptyNotes, Notes } from '../../../domain/entities/Notes';

type NotesComponentProps = {
  stateNotes: Notes | undefined;
  updateNotesAsync(notes: Notes): Promise<DispatchAsyncResult>;
};

export const NotesComponent = ({ stateNotes, updateNotesAsync }: NotesComponentProps) => {
  // isBigScreen working as a media breakpoint
  const isBigScreen = useMediaQuery({ query: `(min-width: ${bigScreenBreakpoint}px)` });

  const notification = useNotificationContext();
  const containerRef = useRef<any>(null);
  const [onEdit, setOnEdit] = useState<boolean>(false);
  const [isConfirmFragmentClosed, setIsConfirmFragmentClosed] = useState<boolean>(true);
  const [filledData, setFilledData] = useState<Notes>(hardCodedEmptyNotes);

  useEffect(() => {
    if (stateNotes) {
      setFilledData(stateNotes);
    }
  }, [stateNotes]);

  // this is only for handling click outside the component, to close it
  useEffect(() => {
    function handleClickOutside(event: MouseEvent): void {
      if (
        containerRef.current &&
        !containerRef.current.contains(event.target as Node) &&
        window.innerWidth - window.innerWidth / 100 > event.clientX
      ) {
        if (onEdit) {
          containerRef.current.scrollIntoView({
            behavior: 'smooth',
            block: 'center',
            inline: 'center',
          });
          onEditClickHandler();
        }
      }
    }
    // Bind the event listener
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener('mousedown', handleClickOutside);
    };
  });
  /// /////////////////////////////////////////////////////////////////////

  const onEditClickHandler = () => {
    if (onEdit) {
      if (filledData === stateNotes) {
        setOnEdit(false);
      } else {
        setIsConfirmFragmentClosed(false);
      }
    } else {
      setOnEdit(true);
    }
  };
  const onConfirmClick = () => {
    setOnEdit(false);
    updateNotesAsync(filledData).then((res) => {
      if (res.success) {
        notification.setOnSuccess({
          message: `Les notes sont modifiés avec succès!`,
        });
        containerRef.current?.scrollIntoView({
          behavior: 'smooth',
          block: 'center',
          inline: 'center',
        });
      }
    });
    setIsConfirmFragmentClosed(true);
  };

  const onCloseClick = () => {
    if (stateNotes) {
      setOnEdit(false);
      setIsConfirmFragmentClosed(true);
      setFilledData(stateNotes);
      containerRef.current?.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
        inline: 'center',
      });
    }
  };

  const textAreaHandleKeyDown = (e: any) => {
    e.target.style.height = 'inherit';
    e.target.style.height = `${e.target.scrollHeight}px`;
  };
  return (
    <div
      ref={containerRef}
      style={
        isBigScreen
          ? { ...styles.container, outlineColor: onEdit ? DocColor.MEDIUMBLUE : DocColor.DARKBLUE }
          : { ...responsiveStyles.container }
      }
    >
      <ConfirmOverlay
        message="Êtes-vous sûr de vouloir enregistrer les notes ?"
        onConfirmClick={onConfirmClick}
        onCloseClick={onCloseClick}
        isClosedState={isConfirmFragmentClosed}
        setIsClosedState={setIsConfirmFragmentClosed}
      />
      <div
        style={
          isBigScreen
            ? { ...styles.editDiv, background: onEdit ? DocColor.MEDIUMBLUE : DocColor.DARKBLUE }
            : {
                ...styles.editDiv,
                ...responsiveStyles.editDiv,
                background: onEdit ? DocColor.MEDIUMBLUE : DocColor.DARKBLUE,
              }
        }
        onKeyDown={onEditClickHandler}
        tabIndex={0}
        role="button"
        onClick={onEditClickHandler}
      >
        {!onEdit ? (
          <EditIcon
            style={{ margin: 'auto' }}
            width={isBigScreen ? Rpx(37) : SRpx(17)}
            height={isBigScreen ? Rpx(37) : SRpx(17)}
            color="white"
          />
        ) : (
          <CheckMarkIcon
            style={{ margin: 'auto' }}
            width={isBigScreen ? Rpx(25) : SRpx(13)}
            height={isBigScreen ? Rpx(25) : SRpx(13)}
            color="white"
          />
        )}
      </div>
      <p
        style={
          isBigScreen
            ? { ...styles.title, fontSize: Rpx(25, true), color: onEdit ? DocColor.MEDIUMBLUE : DocColor.DARKBLUE }
            : {
                ...styles.title,
                ...responsiveStyles.title,
                fontSize: SRpx(13, true),
                color: onEdit ? DocColor.MEDIUMBLUE : DocColor.DARKBLUE,
              }
        }
      >
        Notes
      </p>
      <textarea
        disabled={!onEdit}
        onKeyDown={(e) => textAreaHandleKeyDown(e)}
        style={
          isBigScreen
            ? { ...styles.textArea, fontSize: Rpx(25, true) }
            : { ...styles.textArea, ...responsiveStyles.textArea, fontSize: SRpx(16, true) }
        }
        value={filledData.notes}
        rows={3}
        onChange={(v) => {
          setFilledData({ ...filledData, notes: v.target.value });
        }}
      />
    </div>
  );
};

const styles: StyleSheet = {
  container: {
    position: 'relative',
    padding: `${Rpx(50)} ${Rpx(45)}`,
    margin: `2vw 2vw 0`,
    outline: `${Rpx(10)} solid ${DocColor.DARKBLUE}`,
    borderRadius: Rpx(60),
    minHeight: Rpx(100),
  },
  editDiv: {
    position: 'absolute',
    right: 0,
    top: 0,
    margin: 'auto',
    width: Rpx(77),
    height: Rpx(50),
    borderRadius: `0 ${Rpx(39)} 0 ${Rpx(24)}`,
    background: DocColor.DARKBLUE,
    cursor: 'pointer',
  },
  title: {
    color: DocColor.DARKBLUE,
    fontWeight: 800,
  },
  textArea: {
    background: DocColor.LIGHTGRAY,
    border: `${Rpx(2)} solid ${DocColor.LIGHTGRAY}`,
    borderRadius: Rpx(9),
    outline: 'none',
    caretColor: DocColor.DARKBLUE,
    overflow: 'auto',
    resize: 'vertical',
  },
};

const responsiveStyles: StyleSheet = {
  editDiv: {
    position: 'absolute',
    inset: 0,
    margin: '0 0 0 auto',
    width: SRpx(25),
    height: SRpx(25),
    borderRadius: SRpx(5),
    background: DocColor.DARKBLUE,
    cursor: 'pointer',
  },
  container: {
    position: 'relative',
    width: `85%`,
    margin: `0 auto ${SRpx(20)} auto`,
  },
  title: {
    marginBottom: SRpx(15),
  },
  textArea: {
    border: `${SRpx(1)} solid ${DocColor.LIGHTGRAY}`,
    borderRadius: SRpx(5),
  },
};
