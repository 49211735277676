import React from 'react';
import { Calendar2Icon } from '../../../../assets/svg/Calendar2Icon';
import { DocColor, StyleSheet } from '../../../../assets/styles/constantStyles';
import { PlayIcon } from '../../../../assets/svg/Playlcon';
import { CalendarEvent } from '../../../../calendarContext/domain/entities/CalendarEvent';
import CommonFunctions from '../../../../common/usecases/helpers/CommonFunctions';
import { Rpx } from '../../../../common/usecases/helpers/DimensionsConverter';
import { NavigationPayload } from '../../../../navigationContext/domain/entities/NavigationPayload';
import { User } from '../../../../authContext/domain/entities/User';

type NextAppointmentRowProps = {
  stateConnectedUser: User | undefined;
  calendarEvent: CalendarEvent;
  navigateToAsync: (p: NavigationPayload) => void;
};

export const NextAppointmentRow = ({ stateConnectedUser, calendarEvent, navigateToAsync }: NextAppointmentRowProps) => {
  const navigateToConsultationHandler = (
    patientId: string | undefined,
    temporaryPatientName: string | undefined,
    consultationReason: string | undefined,
  ) => {
    if (patientId) {
      navigateToAsync({ routeName: 'Consultation Addition', params: { patientId, consultationReason } });
    } else {
      navigateToAsync({
        routeName: 'Consultation Addition',
        params: { patientId: 'temporaryPatient', temporaryPatientName, consultationReason },
      });
    }
  };

  const navigateToCalendarHandler = () => {
    navigateToAsync({ routeName: 'Mon Calendrier' });
  };

  return (
    <div>
      <div style={styles.informDiv}>
        <p style={{ ...styles.informParagraph, fontSize: Rpx(28, true) }}>
          La prochaine consultation est programmée pour le{' '}
          <span style={styles.informSpan}>
            {calendarEvent.start && CommonFunctions.formatDate(new Date(calendarEvent.start))}
          </span>{' '}
          à{' '}
          <span style={styles.informSpan}>
            {calendarEvent.start &&
              `${CommonFunctions.padTo2Digits(new Date(calendarEvent.start).getHours())}h${CommonFunctions.padTo2Digits(
                new Date(calendarEvent.start).getMinutes(),
              )} `}
          </span>
          avec <span style={styles.informSpan}>{calendarEvent.fullName}</span>
        </p>
      </div>
      <div style={{ flexDirection: 'row', justifyContent: 'center', gap: '5%', marginTop: Rpx(20) }}>
        {stateConnectedUser?.role === 'DOCTOR' && (
          <div
            style={{ ...styles.nextAppointmentButton, height: Rpx(60, true) }}
            onKeyDown={() =>
              navigateToConsultationHandler(
                calendarEvent.patientId,
                calendarEvent.fullName,
                calendarEvent.consultationReason,
              )
            }
            tabIndex={0}
            role="button"
            onClick={() =>
              navigateToConsultationHandler(
                calendarEvent.patientId,
                calendarEvent.fullName,
                calendarEvent.consultationReason,
              )
            }
          >
            <PlayIcon
              style={{ margin: `auto ${Rpx(10)} auto 0` }}
              color="white"
              width={Rpx(32, true)}
              height={Rpx(32, true)}
            />
            <p style={{ ...styles.nextAppointmentButtonParagraph, fontSize: Rpx(26, true) }}>
              Démarrer la consultation
            </p>
          </div>
        )}

        <div
          style={{ ...styles.nextAppointmentButton, height: Rpx(60, true) }}
          onKeyDown={navigateToCalendarHandler}
          tabIndex={0}
          role="button"
          onClick={navigateToCalendarHandler}
        >
          <Calendar2Icon
            style={{ margin: `auto ${Rpx(10)} auto 0` }}
            color="white"
            width={Rpx(32, true)}
            height={Rpx(32, true)}
          />
          <p style={{ ...styles.nextAppointmentButtonParagraph, fontSize: Rpx(26, true) }}>Voir mon Calendrier</p>
        </div>
      </div>
    </div>
  );
};

const styles: StyleSheet = {
  informDiv: {
    background: DocColor.LIGHTGRAY,
    height: 'fit-content',
    padding: `${Rpx(15)} ${Rpx(30)}`,
    marginTop: Rpx(25),
  },
  informParagraph: {
    margin: 'auto',
    fontWeight: 500,
    fontStyle: 'italic',
  },
  informSpan: {
    color: DocColor.MEDIUMBLUE,
    textTransform: 'capitalize',
  },
  nextAppointmentButton: {
    width: 'fit-content',
    backgroundColor: DocColor.DARKBLUE,
    color: 'white',
    borderRadius: Rpx(18),
    flexDirection: 'row',
    cursor: 'pointer',
    padding: `0 ${Rpx(50)}`,
  },
  nextAppointmentButtonParagraph: {
    margin: 'auto 0px auto 10px',
    fontWeight: 700,
  },
};
