import React, { useEffect, useState } from 'react';
import { useMediaQuery } from 'react-responsive';
import { useLocation } from 'react-router-dom';
import { DocColor, StyleSheet } from '../../../assets/styles/constantStyles';
import { ToLeftArrow } from '../../../assets/svg/ToLeftArrow';
import { bigScreenBreakpoint } from '../../../common/domain/entities/ReactResponsiveBreakpoints';
import { Rpx } from '../../../common/usecases/helpers/DimensionsConverter';

export interface TopBarProps {
  name: string;
  onBackClick: () => void;
}
export const TopBar = ({ name, onBackClick }: TopBarProps) => {
  // isBigScreen working as a media breakpoint
  const isBigScreen = useMediaQuery({ query: `(min-width: ${bigScreenBreakpoint}px)` });
  const currentRouteName = useLocation().pathname.replace('/', '');
  const [routeNameState, setRouteNameState] = useState<string>('');

  useEffect(() => {
    if (currentRouteName === 'Patient details' || currentRouteName === 'Patient Addition') {
      setRouteNameState('Mes Patients');
    } else if (currentRouteName === 'Consultation Addition') {
      setRouteNameState('Mes Consultations');
    } else {
      setRouteNameState(currentRouteName);
    }
  }, [currentRouteName]);
  return (
    <div
      style={{
        ...styles.container,
        display: routeNameState === 'Accueil' || '' || !isBigScreen ? 'none' : '',
      }}
    >
      <button
        type="button"
        onClick={onBackClick}
        style={{
          alignItems: 'center',
          gap: Rpx(10),
          display: 'flex',
          flexDirection: 'row',
          margin: `auto ${Rpx(10)} auto 0`,
          cursor: 'pointer',
        }}
      >
        <ToLeftArrow width={Rpx(12, true)} height={Rpx(17, true)} />
        <p style={{ ...styles.paragraph, fontSize: Rpx(27, true) }}>{routeNameState}</p>
      </button>
      <div style={styles.verticalLine} />
      <div style={styles.onlineDot} />
      <p style={{ ...styles.paragraph, fontSize: Rpx(27, true) }}>{name}</p>
    </div>
  );
};

const styles: StyleSheet = {
  container: {
    position: 'absolute',
    inset: 0,
    zIndex: 5,
    width: 'fit-content',
    height: 'fit-content',
    background: DocColor.DARKBLUE,
    flexDirection: 'row',
    borderRadius: `0 0 ${Rpx(40)} ${Rpx(40)}`,
    padding: `${Rpx(10)} ${Rpx(35)}`,
    margin: '0 auto',
    maxWidth: '75%',
  },
  paragraph: {
    margin: 'auto 0',
    fontWeight: 500,
    color: 'white',
    textTransform: 'capitalize',
  },
  verticalLine: {
    height: Rpx(35),
    width: 1,
    background: 'white',
    margin: 'auto 6vw',
  },
  onlineDot: {
    width: Rpx(22),
    height: Rpx(22),
    background: DocColor.LIGHTGREEN,
    margin: `auto ${Rpx(10)} auto 0`,
    borderRadius: 10,
  },
};
