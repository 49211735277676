import React from 'react';
import { useMediaQuery } from 'react-responsive';
import { DocColor, StyleSheet } from '../../assets/styles/constantStyles';
import { ToBottomArrow } from '../../assets/svg/ToBottomArrow';
import { bigScreenBreakpoint } from '../domain/entities/ReactResponsiveBreakpoints';
import { Rpx, SRpx } from '../usecases/helpers/DimensionsConverter';

type NationalitiesSelectorProps = {
  style?: React.CSSProperties;
  onChange?: (v: string) => void;
  width?: string | number;
  value?: string;
  inputNameWidth?: string | number;
  disabled?: boolean;
  OnMistake?: boolean;
};

export const NationalitiesSelector = ({
  style,
  onChange,
  width,
  value,
  inputNameWidth,
  disabled,
  OnMistake,
}: NationalitiesSelectorProps) => {
  // isBigScreen working as a media breakpoint
  const isBigScreen = useMediaQuery({ query: `(min-width: ${bigScreenBreakpoint}px)` });

  return (
    <div style={{ ...styles.inputDiv, width }}>
      <p
        style={
          isBigScreen
            ? {
                ...styles.inputParagraph,
                fontSize: Rpx(27, true),
                width: inputNameWidth,
              }
            : {
                ...styles.inputParagraph,
                ...responsiveStyles.inputParagraph,
                fontSize: SRpx(14, true),
                width: inputNameWidth,
              }
        }
      >
        Nationalité
      </p>
      <div style={isBigScreen ? { ...styles.selectorDiv } : { ...styles.selectorDiv, ...responsiveStyles.selectorDiv }}>
        <ToBottomArrow
          color="black"
          style={
            isBigScreen
              ? {
                  ...styles.selectorIcon,
                  width: Rpx(15, true),
                }
              : {
                  ...styles.selectorIcon,
                  ...responsiveStyles.selectorIcon,
                  width: SRpx(8, true),
                }
          }
        />
        <select
          disabled={disabled}
          value={value && value}
          defaultValue=""
          style={
            isBigScreen
              ? {
                  ...styles.selector,
                  height: Rpx(70, true),
                  fontSize: Rpx(25, true),
                  ...style,
                  borderColor: OnMistake ? DocColor.LIGHTRED : DocColor.CLOUDYWHITE,
                }
              : {
                  ...styles.selector,
                  ...responsiveStyles.selector,
                  height: SRpx(30, true),
                  fontSize: SRpx(13, true),
                  ...style,
                  borderColor: OnMistake ? DocColor.LIGHTRED : DocColor.CLOUDYWHITE,
                }
          }
          onChange={(v) => {
            if (onChange) {
              onChange(v.target.value);
            }
          }}
        >
          <option value="" disabled>
            Nationalités
          </option>
          <option value="">Aucun</option>
          <option value="Afghanistan">Afghanistan</option>
          <option value="Afrique du Sud">Afrique du Sud</option>
          <option value="Albanie">Albanie</option>
          <option value="Algérie">Algérie</option>
          <option value="Allemagne">Allemagne</option>
          <option value="Aller">Aller</option>
          <option value="Andorre">Andorre</option>
          <option value="Angola">Angola</option>
          <option value="Anguilla">Anguilla</option>
          <option value="Antarctique">Antarctique</option>
          <option value="Antigua-et-Barbuda">Antigua-et-Barbuda</option>
          <option value="Antilles néerlandaises">Antilles néerlandaises</option>
          <option value="Arabie Saoudite">Arabie Saoudite</option>
          <option value="Argentine">Argentine</option>
          <option value="Arménie">Arménie</option>
          <option value="Aruba">Aruba</option>
          <option value="Australie">Australie</option>
          <option value="Azerbaïdjan">Azerbaïdjan</option>
          <option value="Bahamas">Bahamas</option>
          <option value="Bahreïn">Bahreïn</option>
          <option value="Barbade">Barbade</option>
          <option value="Belgique">Belgique</option>
          <option value="Bengladesh">Bengladesh</option>
          <option value="Bermudes">Bermudes</option>
          <option value="Bhoutan">Bhoutan</option>
          <option value="Birmanie">Birmanie</option>
          <option value="Biélorussie">Biélorussie</option>
          <option value="Bolivie">Bolivie</option>
          <option value="Bosnie Herzégovine">Bosnie Herzégovine</option>
          <option value="Bostwana">Bostwana</option>
          <option value="Brunei Darussalam">Brunei Darussalam</option>
          <option value="Brésil">Brésil</option>
          <option value="Bulgarie">Bulgarie</option>
          <option value="Burkina Faso">Burkina Faso</option>
          <option value="Burundi">Burundi</option>
          <option value="Bélize">Bélize</option>
          <option value="Bénin">Bénin</option>
          <option value="Cambodge">Cambodge</option>
          <option value="Cameroun">Cameroun</option>
          <option value="Canada">Canada</option>
          <option value="Cap-Vert">Cap-Vert</option>
          <option value="Chili">Chili</option>
          <option value="Chine">Chine</option>
          <option value="Chypre">Chypre</option>
          <option value="Colombie">Colombie</option>
          <option value="Comores">Comores</option>
          <option value="Congo">Congo</option>
          <option value="Costa Rica">Costa Rica</option>
          <option value="Croatie">Croatie</option>
          <option value="Cuba">Cuba</option>
          <option value="Côte D'ivoire">Côte D&apos;ivoire</option>
          <option value="Danemark">Danemark</option>
          <option value="Djibouti">Djibouti</option>
          <option value="Dominique">Dominique</option>
          <option value="Egypte">Egypte</option>
          <option value="Emirats Arabes Unis">Emirats Arabes Unis</option>
          <option value="Equateur">Equateur</option>
          <option value="Espagne">Espagne</option>
          <option value="Estonie">Estonie</option>
          <option value="Ethiopie">Ethiopie</option>
          <option value="Fidji">Fidji</option>
          <option value="Finlande">Finlande</option>
          <option value="France">France</option>
          <option value="Fédération Russe">Fédération Russe</option>
          <option value="Gabon">Gabon</option>
          <option value="Gambie">Gambie</option>
          <option value="Ghana">Ghana</option>
          <option value="Gibraltar">Gibraltar</option>
          <option value="Grenade">Grenade</option>
          <option value="Groenland">Groenland</option>
          <option value="Grèce">Grèce</option>
          <option value="Guadeloupe">Guadeloupe</option>
          <option value="Guam">Guam</option>
          <option value="Guatemala">Guatemala</option>
          <option value="Guernesey">Guernesey</option>
          <option value="Guinée">Guinée</option>
          <option value="Guinée Équatoriale">Guinée Équatoriale</option>
          <option value="Guinée-bissau">Guinée-bissau</option>
          <option value="Guyane">Guyane</option>
          <option value="Guyane Française">Guyane Française</option>
          <option value="Géorgie">Géorgie</option>
          <option value="Géorgie du Sud et îles Sandwich du Sud">Géorgie du Sud et îles Sandwich du Sud</option>
          <option value="Haïti">Haïti</option>
          <option value="Honduras">Honduras</option>
          <option value="Hong Kong">Hong Kong</option>
          <option value="Hongrie">Hongrie</option>
          <option value="Iles Aland">Iles Aland</option>
          <option value="Iles Marshall">Iles Marshall</option>
          <option value="Inde">Inde</option>
          <option value="Indonésie">Indonésie</option>
          <option value="Irak">Irak</option>
          <option value="Irlande">Irlande</option>
          <option value="Islande">Islande</option>
          <option value="Israël">Israël</option>
          <option value="Italie">Italie</option>
          <option value="Jamahiriya arabe libyenne">Jamahiriya arabe libyenne</option>
          <option value="Jamaïque">Jamaïque</option>
          <option value="Japon">Japon</option>
          <option value="Jersey">Jersey</option>
          <option value="Jordan">Jordan</option>
          <option value="Kazakhstan">Kazakhstan</option>
          <option value="Kenya">Kenya</option>
          <option value="Kirghizistan">Kirghizistan</option>
          <option value="Kiribati">Kiribati</option>
          <option value="Koweit">Koweit</option>
          <option value="L'Autriche">L&apos;Autriche</option>
          <option value="L'ancienne république Yugoslave de Macédonie">
            L&apos;ancienne république Yugoslave de Macédonie
          </option>
          <option value="L'île de noël">L&apos;île de noël</option>
          <option value="Le Salvador">Le Salvador</option>
          <option value="Lesotho">Lesotho</option>
          <option value="Lettonie">Lettonie</option>
          <option value="Liban">Liban</option>
          <option value="Libéria">Libéria</option>
          <option value="Liechtenstein">Liechtenstein</option>
          <option value="Lituanie">Lituanie</option>
          <option value="Luxembourg">Luxembourg</option>
          <option value="Macao">Macao</option>
          <option value="Madagascar">Madagascar</option>
          <option value="Malaisie">Malaisie</option>
          <option value="Malawi">Malawi</option>
          <option value="Maldives">Maldives</option>
          <option value="Mali">Mali</option>
          <option value="Malte">Malte</option>
          <option value="Maroc">Maroc</option>
          <option value="Martinique">Martinique</option>
          <option value="Maurice">Maurice</option>
          <option value="Mauritanie">Mauritanie</option>
          <option value="Mayotte">Mayotte</option>
          <option value="Mexique">Mexique</option>
          <option value="Monaco">Monaco</option>
          <option value="Mongolie">Mongolie</option>
          <option value="Montserrat">Montserrat</option>
          <option value="Monténégro">Monténégro</option>
          <option value="Mozambique">Mozambique</option>
          <option value="Namibie">Namibie</option>
          <option value="Nauru">Nauru</option>
          <option value="Nicaragua">Nicaragua</option>
          <option value="Niger">Niger</option>
          <option value="Nigeria">Nigeria</option>
          <option value="Niué">Niué</option>
          <option value="Norvège">Norvège</option>
          <option value="Nouvelle Calédonie">Nouvelle Calédonie</option>
          <option value="Nouvelle-Zélande">Nouvelle-Zélande</option>
          <option value="Népal">Népal</option>
          <option value="Oman">Oman</option>
          <option value="Ouganda">Ouganda</option>
          <option value="Ouzbékistan">Ouzbékistan</option>
          <option value="Pakistan">Pakistan</option>
          <option value="Palaos">Palaos</option>
          <option value="Panama">Panama</option>
          <option value="Papouasie Nouvelle Guinée">Papouasie Nouvelle Guinée</option>
          <option value="Paraguay">Paraguay</option>
          <option value="Pays-Bas">Pays-Bas</option>
          <option value="Philippines">Philippines</option>
          <option value="Pitcairn">Pitcairn</option>
          <option value="Pologne">Pologne</option>
          <option value="Polynésie française">Polynésie française</option>
          <option value="Porto Rico">Porto Rico</option>
          <option value="Pérou">Pérou</option>
          <option value="Qatar">Qatar</option>
          <option value="Roumanie">Roumanie</option>
          <option value="Royaume-Uni">Royaume-Uni</option>
          <option value="Rwanda">Rwanda</option>
          <option value="République Démocratique du Congo">République Démocratique du Congo</option>
          <option value="République Populaire Démocratique de Corée">République Populaire Démocratique de Corée</option>
          <option value="République Tchèque">République Tchèque</option>
          <option value="République arabe syrienne">République arabe syrienne</option>
          <option value="République centrafricaine">République centrafricaine</option>
          <option value="République de Corée">République de Corée</option>
          <option value="République de Moldavie">République de Moldavie</option>
          <option value="République dominicaine">République dominicaine</option>
          <option value="République démocratique populaire lao">République démocratique populaire lao</option>
          <option value="République islamique d'Iran">République islamique d&apos;Iran</option>
          <option value="République-Unie de Tanzanie">République-Unie de Tanzanie</option>
          <option value="Réunion">Réunion</option>
          <option value="Saint Marin">Saint Marin</option>
          <option value="Saint-Christophe-et-Niévès">Saint-Christophe-et-Niévès</option>
          <option value="Saint-Pierre-et-Miquelon">Saint-Pierre-et-Miquelon</option>
          <option value="Saint-Siège (État de la Cité du Vatican)">Saint-Siège (État de la Cité du Vatican)</option>
          <option value="Saint-Vincent-et-les-Grenadines">Saint-Vincent-et-les-Grenadines</option>
          <option value="Sainte-Hélène">Sainte-Hélène</option>
          <option value="Sainte-Lucie">Sainte-Lucie</option>
          <option value="Samoa">Samoa</option>
          <option value="Samoa américaines">Samoa américaines</option>
          <option value="Sao Tomé et Principe">Sao Tomé et Principe</option>
          <option value="Serbie">Serbie</option>
          <option value="Sierra Leone">Sierra Leone</option>
          <option value="Singapour">Singapour</option>
          <option value="Slovaquie">Slovaquie</option>
          <option value="Slovénie">Slovénie</option>
          <option value="Somalie">Somalie</option>
          <option value="Soudan">Soudan</option>
          <option value="Sri Lanka">Sri Lanka</option>
          <option value="Suisse">Suisse</option>
          <option value="Suriname">Suriname</option>
          <option value="Suède">Suède</option>
          <option value="Svalbard et Jan Mayen">Svalbard et Jan Mayen</option>
          <option value="Swaziland">Swaziland</option>
          <option value="Sénégal">Sénégal</option>
          <option value="Tadjikistan">Tadjikistan</option>
          <option value="Taïwan">Taïwan</option>
          <option value="Tchad">Tchad</option>
          <option value="Terres australes françaises">Terres australes françaises</option>
          <option value="Territoire britannique de l'océan Indien">
            Territoire britannique de l&apos;océan Indien
          </option>
          <option value="Territoire palestinien occupé">Territoire palestinien occupé</option>
          <option value="Thaïlande">Thaïlande</option>
          <option value="Timor oriental">Timor oriental</option>
          <option value="Tokélaou">Tokélaou</option>
          <option value="Tonga">Tonga</option>
          <option value="Trinité-et-Tobago">Trinité-et-Tobago</option>
          <option value="Tunisie">Tunisie</option>
          <option value="Turkménistan">Turkménistan</option>
          <option value="Turquie">Turquie</option>
          <option value="Tuvalu">Tuvalu</option>
          <option value="Ukraine">Ukraine</option>
          <option value="Uruguay">Uruguay</option>
          <option value="Vanuatu">Vanuatu</option>
          <option value="Venezuela">Venezuela</option>
          <option value="Vietnam">Vietnam</option>
          <option value="Wallis et Futuna">Wallis et Futuna</option>
          <option value="Yémen">Yémen</option>
          <option value="Zambie">Zambie</option>
          <option value="Zimbabwe">Zimbabwe</option>
          <option value="l'ile de Norfolk">l&apos;ile de Norfolk</option>
          <option value="le Portugal">le Portugal</option>
          <option value="les Seychelles">les Seychelles</option>
          <option value="les Îles Cook">les Îles Cook</option>
          <option value="Érythrée">Érythrée</option>
          <option value="États fédérés de Micronésie">États fédérés de Micronésie</option>
          <option value="États-Unis">États-Unis</option>
          <option value="Île Bouvet">Île Bouvet</option>
          <option value="Île Heard et Îles Mcdonald">Île Heard et Îles Mcdonald</option>
          <option value="Îles Caïmans">Îles Caïmans</option>
          <option value="Îles Cocos (Keeling)">Îles Cocos (Keeling)</option>
          <option value="Îles Falkland (Malouines)">Îles Falkland (Malouines)</option>
          <option value="Îles Féroé">Îles Féroé</option>
          <option value="Îles Mariannes du Nord">Îles Mariannes du Nord</option>
          <option value="Îles Vierges américaines">Îles Vierges américaines</option>
          <option value="Îles Vierges britanniques">Îles Vierges britanniques</option>
          <option value="Îles mineures éloignées des États-Unis">Îles mineures éloignées des États-Unis</option>
          <option value="île de Man">île de Man</option>
          <option value="îles Salomon">îles Salomon</option>
          <option value="îles Turques-et-Caïques">îles Turques-et-Caïques</option>
        </select>
      </div>
    </div>
  );
};

const styles: StyleSheet = {
  container: {
    flexDirection: 'row',
  },
  selectorDiv: {
    width: '100%',
    marginLeft: Rpx(20),
    position: 'relative',
  },
  inputDiv: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  selector: {
    width: '100%',
    padding: `0 ${Rpx(30)}`,
    cursor: 'pointer',
    outline: 'none',
    fontWeight: 500,
    border: `${Rpx(4)} solid`,
    borderRadius: Rpx(9),
    background: DocColor.LIGHTGRAY,
  },
  selectorIcon: {
    position: 'absolute',
    inset: 0,
    margin: `auto ${Rpx(17)} ${Rpx(17)} auto`,
  },
  inputParagraph: {
    fontWeight: 500,
    marginRight: Rpx(20),
    whiteSpace: 'nowrap',
  },
};

const responsiveStyles: StyleSheet = {
  selectorDiv: {
    marginLeft: SRpx(10),
  },
  selectorIcon: {
    margin: `auto ${SRpx(9)} ${SRpx(9)} auto`,
  },
  inputParagraph: {
    fontWeight: 600,
    marginRight: SRpx(10),
  },
  selector: {
    padding: `0 ${SRpx(15)}`,
    border: `${SRpx(2)} solid`,
    borderRadius: SRpx(5),
  },
};
