import React, { useEffect, useState } from 'react';
import { DispatchAsyncResult } from 'react-redux-dispatch-async/lib/dispatchAsync';
import { PatientConsutationRow } from './PatientConsutationRow';
import { DocColor, StyleSheet } from '../../../../assets/styles/constantStyles';
import { AddIcon } from '../../../../assets/svg/Addlcon';
import { Rpx } from '../../../../common/usecases/helpers/DimensionsConverter';
import { NavigationPayload } from '../../../../navigationContext/domain/entities/NavigationPayload';
import { PatientConsultation } from '../../../domain/entities/PatientConsultation';
import { Patient } from '../../../../patientsContext/domain/entities/Patient';

type ConsultationsComponentProps = {
  patient: Patient | null | undefined;
  loadPatientsConsultationsAsync(
    patientId?: string,
    consultationsLimit?: number,
    skipConsultations?: number,
  ): Promise<DispatchAsyncResult>;
  navigateReplace: (p: NavigationPayload) => void;
  currentConsultationNumber: string;
};

export const ConsultationsComponent = ({
  patient,
  loadPatientsConsultationsAsync,
  navigateReplace,
  currentConsultationNumber,
}: ConsultationsComponentProps) => {
  const [filledData, setFilledData] = useState<PatientConsultation[]>([]);
  // the limit of attachments should be loaded
  const consultationsLimit = 2;
  // to know how many skips should be done to attachments on load more.
  const [skipConsultationsCounter, setSkipConsultationsCounter] = useState<number>(0);
  const [loadMoreButtonVisibility, setLoadMoreButtonVisibility] = useState<boolean>(true);

  useEffect(() => {
    loadConsultationsHandler();
  }, []);

  const loadConsultationsHandler = () => {
    if (patient) {
      loadPatientsConsultationsAsync(patient.id, consultationsLimit, skipConsultationsCounter).then((res) => {
        if (res.success) {
          if (res.result.length > 0) {
            const tempData: PatientConsultation[] = filledData;
            tempData.push(...res.result);
            setFilledData([...tempData]);
            setSkipConsultationsCounter((value) => value + consultationsLimit);
          } else {
            setLoadMoreButtonVisibility(false);
          }
        }
      });
    }
  };

  return (
    <div style={styles.container}>
      <div style={styles.titleDiv}>
        <p style={{ ...styles.title, fontSize: Rpx(32, true) }}>Historique des consultations du patient</p>
      </div>
      {filledData && filledData.length > 0 ? (
        <div style={{ overflow: 'auto', margin: '2vw' }}>
          {filledData.map((element) => (
            <PatientConsutationRow
              key={element.id}
              dataState={element}
              navigateReplace={navigateReplace}
              currentConsultationNumber={currentConsultationNumber}
            />
          ))}
        </div>
      ) : (
        <div style={{ ...styles.dataStateRow, height: Rpx(70, true), fontSize: Rpx(25, true) }}>
          <p>La liste des consultations est vide.</p>
        </div>
      )}
      <div
        style={{
          ...styles.loadMoreButton,
          minHeight: Rpx(50, true),
          display: loadMoreButtonVisibility ? '' : 'none',
        }}
        onKeyDown={loadConsultationsHandler}
        tabIndex={0}
        role="button"
        onClick={loadConsultationsHandler}
      >
        <AddIcon style={styles.icon} width={Rpx(15, true)} height={Rpx(15, true)} />
        <p style={{ ...styles.loadMoreButtonParagraph, fontSize: Rpx(28, true) }}>Afficher plus...</p>
      </div>
    </div>
  );
};

const styles: StyleSheet = {
  container: {
    maxHeight: '53vh',
    position: 'relative',
    margin: `2vw 2vw 0`,
    outline: `${Rpx(10)} solid ${DocColor.DARKBLUE}`,
    borderRadius: Rpx(60),
    minHeight: Rpx(100),
    overflow: 'hidden',
  },
  titleDiv: {
    width: 'fit-content',
    background: DocColor.DARKBLUE,
    borderRadius: `${Rpx(50)} 0 ${Rpx(20)} 0`,
    minHeight: Rpx(60),
    padding: `0 ${Rpx(40)}`,
    marginBottom: Rpx(15),
  },
  title: {
    color: 'white',
    fontWeight: 600,
    fontStyle: 'italic',
    margin: 'auto',
  },
  dataStateRow: {
    textAlign: 'center',
    fontWeight: 500,
    whiteSpace: 'nowrap',
    justifyContent: 'center',
    color: DocColor.COALBLACK,
    height: Rpx(70),
    minHeight: Rpx(70),
    background: DocColor.LIGHTGRAY,
    borderRadius: Rpx(5),
    boxSizing: 'border-box',
    margin: '2vw',
  },
  loadMoreButton: {
    flexDirection: 'row',
    width: 'fit-content',
    backgroundColor: DocColor.DARKBLUE,
    color: 'white',
    borderRadius: `${Rpx(20)} ${Rpx(20)} 0 0`,
    cursor: 'pointer',
    padding: `0px 20px`,
    margin: '0 auto',
  },
  loadMoreButtonParagraph: {
    margin: `auto 0px auto ${Rpx(10)}`,
    fontWeight: 500,
  },
  icon: {
    border: '2px solid white',
    borderRadius: Rpx(20),
    padding: Rpx(5),
    margin: 'auto 0',
  },
};
