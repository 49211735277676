import * as React from 'react';
import { Color } from '../styles/constantStyles';

export interface AddIconProps {
  color?: string;
  style?: React.CSSProperties;
  width?: string | number;
  height?: string | number;
}

export const AddIcon = ({ color, width, height, style }: AddIconProps) => (
  <svg style={style} width={width} height={height} viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M1.86914 8.90527H15.3216"
      stroke={color || Color.MONOGREY3}
      strokeWidth="2.6905"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M8.59375 2.17871V15.6312"
      stroke={color || Color.MONOGREY3}
      strokeWidth="2.6905"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
