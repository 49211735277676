import { useLocation } from 'react-router-dom';
import { DispatchAsyncResult } from 'react-redux-dispatch-async/lib/dispatchAsync';
import React, { useEffect, useState } from 'react';
import { useMediaQuery } from 'react-responsive';
import { hardCodedEmptyPatient, Patient } from '../../domain/entities/Patient';
import { DocColor, StyleSheet } from '../../../assets/styles/constantStyles';
import { Rpx, SRpx } from '../../../common/usecases/helpers/DimensionsConverter';
import { bigScreenBreakpoint } from '../../../common/domain/entities/ReactResponsiveBreakpoints';
import { ToRightArrow3 } from '../../../assets/svg/ToRightArrow3';
import { AttachmentsComponent } from '../../../common/ui/AttachmentsComponent';
import { Attachment } from '../../../consultationsContext/domain/entities/Attachments';

type AttachmentsProps = {
  loadPatientAsync(patientId: string): Promise<DispatchAsyncResult>;
  loadAttachmentsAsync(
    patientId: string,
    attachmentsLimit?: number,
    skipAttachments?: number,
  ): Promise<DispatchAsyncResult>;
  insertAttachmentAsync(attachment: Attachment): Promise<DispatchAsyncResult>;
};

export const AttachmentsDumb = ({
  loadPatientAsync,
  loadAttachmentsAsync,
  insertAttachmentAsync,
}: AttachmentsProps) => {
  // isBigScreen working as a media breakpoint
  const isBigScreen = useMediaQuery({ query: `(min-width: ${bigScreenBreakpoint}px)` });

  const recievedPatientId: string = useLocation<string>().state;
  const [patient, setPatient] = useState<Patient>(hardCodedEmptyPatient);
  useEffect(() => {
    if (recievedPatientId) {
      loadPatientAsync(recievedPatientId).then((res) => {
        if (res.success) {
          setPatient(res.result[0]);
        }
      });
    }
  }, [recievedPatientId]);

  return (
    <div style={isBigScreen ? { ...styles.container } : { ...styles.container, ...responsiveStyles.container }}>
      <div style={{ ...responsiveStyles.titleContainer, display: isBigScreen ? 'none' : '' }}>
        <ToRightArrow3 width={SRpx(10)} height={SRpx(10)} color={DocColor.DARKBLUE} />
        <p style={{ ...responsiveStyles.title, fontSize: SRpx(17, true) }}>Pièces jointes</p>
      </div>
      <div style={{ ...responsiveStyles.line, display: isBigScreen ? 'none' : '' }} />
      <AttachmentsComponent
        patientId={patient.id}
        loadAttachmentsAsync={loadAttachmentsAsync}
        insertAttachmentAsync={insertAttachmentAsync}
      />
    </div>
  );
};

const styles: StyleSheet = {
  container: {
    flex: 1,
    margin: `${Rpx(180)} 0`,
  },
};

const responsiveStyles: StyleSheet = {
  container: {
    margin: `${SRpx(30)} 0`,
  },
  titleContainer: {
    flexDirection: 'row',
    width: '80%',
    gap: SRpx(3),
    alignItems: 'center',
    margin: `0 auto ${SRpx(20)} auto`,
  },
  title: {
    color: DocColor.MEDIUMBLUE,
    fontWeight: 700,
    letterSpacing: SRpx(1),
  },
  line: {
    width: '90%',
    height: 2,
    background: DocColor.MEDIUMGRAY,
    margin: `0 auto ${SRpx(20)} auto`,
  },
};
