import * as React from 'react';
import { Color } from '../styles/constantStyles';

export interface NeedleIconProps {
  color?: string;
  style?: React.CSSProperties;
  width?: string | number;
  height?: string | number;
}

export const NeedleIcon = ({ color, width, height, style }: NeedleIconProps) => (
  <svg style={style} width={width} height={height} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M2.68002 13.32L1 15M13.32 2.68002L11.64 4.36003M13.32 2.68002L11.64 1M13.32 2.68002L15 4.36001M5.47999 12.76L3.79997 14.44L1.55995 12.2L3.23997 10.52L5.47999 12.76ZM9.96012 2.68002L13.3202 6.04003L6.04008 13.3201L2.68003 9.96005L9.96012 2.68002Z"
      stroke={color || Color.MONOGREY3}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
