import React from 'react';
import { DocColor, StyleSheet } from '../../assets/styles/constantStyles';
import { Rpx } from '../usecases/helpers/DimensionsConverter';

export interface ToggleButtonProps {
  selected: boolean;
  action?: () => void;
  width: string | number;
  height: string | number;
}
export const ToggleButton = ({ selected, action, width, height }: ToggleButtonProps) => (
  <div
    onKeyDown={action}
    tabIndex={0}
    role="button"
    onClick={action}
    style={{
      ...styles.ToggleButtonContainer,
      width,
      height,
      borderRadius: height,
      background: selected ? DocColor.DARKBLUE : DocColor.GRAY,
      justifyContent: selected ? 'end' : 'start',
    }}
  >
    <div style={{ ...styles.circle, width: height, height }} />
  </div>
);

const styles: StyleSheet = {
  ToggleButtonContainer: {
    position: 'relative',
    cursor: 'pointer',
    flexDirection: 'row',
    alignItems: 'center',
    padding: Rpx(5),
  },
  circle: {
    background: DocColor.CLOUDYBLUE,
    borderRadius: '100%',
  },
};
