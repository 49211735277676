export interface TreatmentsAntecedents {
  patientId: string;
  currentTreatment: string[];
  surgicalOperation: string[];
  toxicAntecedents: string[];
  drugAllergies: string[];
  gynecoObstetrics: string[];
  familyAntecedents: string[];
}
export const hardCodedEmptyTreatmentsAntecedents: TreatmentsAntecedents = {
  patientId: '',
  currentTreatment: [''],
  surgicalOperation: [''],
  toxicAntecedents: [''],
  drugAllergies: [''],
  gynecoObstetrics: [''],
  familyAntecedents: [''],
};

export const hardCodedTreatmentsAntecedents: TreatmentsAntecedents = {
  patientId: '651dqsd65',
  currentTreatment: ['dfsdf', 'éqdsffdq'],
  surgicalOperation: [],
  toxicAntecedents: ['qsdqsd'],
  drugAllergies: [],
  gynecoObstetrics: [],
  familyAntecedents: [],
};
