import React, { useEffect, useState } from 'react';
import { DispatchAsyncResult } from 'react-redux-dispatch-async/lib/dispatchAsync';
import { useMediaQuery } from 'react-responsive';
import { ButtonWithIcon } from './ButtonWithIcon';
import { AttachmentAdditionOverlay } from './AttachmentAdditionOverlay';
import { DocColor, StyleSheet } from '../../assets/styles/constantStyles';
import { AddIcon } from '../../assets/svg/Addlcon';
import { IndicatorIcon } from '../../assets/svg/Indicatorlcon';
import { PaperClipIcon } from '../../assets/svg/PaperClipIcon';
import CommonFunctions from '../usecases/helpers/CommonFunctions';
import { Rpx, SRpx } from '../usecases/helpers/DimensionsConverter';
import { useLoaderContext } from '../configuration/loaderContextProvider';
import { Attachment } from '../../consultationsContext/domain/entities/Attachments';
import { bigScreenBreakpoint } from '../domain/entities/ReactResponsiveBreakpoints';

type AttachmentsComponentProps = {
  patientId: string | undefined;
  onConsultationsContext?: boolean;
  loadAttachmentsAsync(
    patientId: string,
    attachmentsLimit?: number,
    skipAttachments?: number,
  ): Promise<DispatchAsyncResult>;
  insertAttachmentAsync(attachment: Attachment): Promise<DispatchAsyncResult>;
};

export const AttachmentsComponent = ({
  patientId,
  onConsultationsContext,
  loadAttachmentsAsync,
  insertAttachmentAsync,
}: AttachmentsComponentProps) => {
  // isBigScreen working as a media breakpoint
  const isBigScreen = useMediaQuery({ query: `(min-width: ${bigScreenBreakpoint}px)` });

  const loader = useLoaderContext();
  const fileInputRefs = React.useRef<HTMLInputElement>(null);
  const [filledData, setFilledData] = useState<Attachment[]>([]);
  // the limit of attachments should be loaded
  const attachmentsLimit = 3;
  // to know how many skips should be done to attachments on load more.
  const [skipAttachmentsCounter, setSkipAttachmentsCounter] = useState<number>(0);
  const [loadMoreButtonVisibility, setLoadMoreButtonVisibility] = useState<boolean>(true);
  const [isAttachmentAdditionOverlayClosed, setIsAttachmentAdditionOverlayClosed] = useState<boolean>(true);
  const [inputData, setInputData] = useState<Attachment>({
    patientId,
    fileName: '',
    file: '',
  });

  useEffect(() => {
    if (patientId) {
      loadAttachmentsHandler();
      setInputData((prevState) => ({ ...prevState, patientId }));
    }
  }, [patientId]);

  const loadAttachmentsHandler = () => {
    if (patientId) {
      loadAttachmentsAsync(patientId, attachmentsLimit, skipAttachmentsCounter).then((res) => {
        if (res.success) {
          if (res.result.length > 0) {
            const tempData: Attachment[] = filledData;
            tempData.push(...res.result);
            setFilledData([...tempData]);
            setSkipAttachmentsCounter((value) => value + attachmentsLimit);
          } else {
            setLoadMoreButtonVisibility(false);
          }
        }
      });
    }
  };

  const inputOnchangeHandler = (value: React.ChangeEvent<HTMLInputElement>) => {
    if (value.target.files && value.target.files?.length > 0) {
      setIsAttachmentAdditionOverlayClosed(false);
      CommonFunctions.getBase64(value.target.files[0]).then((res) => {
        setInputData({
          ...inputData,
          file: res,
          fileName: value.target.files ? value.target.files[0].name : '',
        });
      });
    }
  };

  const addButtonAttachmentHandler = () => {
    fileInputRefs.current?.click();
  };

  const onConfirmClick = () => {
    loader.setMessage('_');
    insertAttachmentAsync(inputData)
      .then((res) => {
        if (res.success) {
          setFilledData((prevState) => [res.result, ...prevState]);
          setSkipAttachmentsCounter((value) => value + 1);
        }
      })
      .finally(() => loader.setMessage(''));
    setIsAttachmentAdditionOverlayClosed(true);
    if (fileInputRefs.current !== null) {
      fileInputRefs.current.value = '';
    }
  };

  const onCancelClick = () => {
    setIsAttachmentAdditionOverlayClosed(true);
    if (fileInputRefs.current !== null) {
      fileInputRefs.current.value = '';
    }
  };

  return (
    <div style={isBigScreen ? { ...styles.container } : { ...responsiveStyles.container }}>
      <AttachmentAdditionOverlay
        fileName={inputData.fileName}
        isClosedState={isAttachmentAdditionOverlayClosed}
        onBrowseClick={() => {
          fileInputRefs.current?.click();
        }}
        onChangeClick={() => {
          fileInputRefs.current?.click();
        }}
        onValidClick={() => onConfirmClick()}
        onCancelClick={onCancelClick}
      />
      <input
        style={{ display: 'none' }}
        ref={fileInputRefs}
        type="file"
        onChange={(v) => {
          inputOnchangeHandler(v);
        }}
      />
      <div style={{ ...styles.onConsultationTitleDiv, display: onConsultationsContext ? '' : 'none' }}>
        <p key="firstHistoryDateDiv" style={{ ...styles.onConsultationTitle, fontSize: Rpx(32, true) }}>
          Pièces jointes
        </p>
      </div>
      <div
        style={{
          ...styles.headerDiv,
          marginTop: onConsultationsContext ? '0' : '2vw',
          justifyContent: onConsultationsContext ? 'end' : 'space-between',
        }}
      >
        <p
          style={
            isBigScreen
              ? { ...styles.title, fontSize: Rpx(25, true), display: onConsultationsContext ? 'none' : '' }
              : {
                  ...styles.title,
                  ...responsiveStyles.title,
                  fontSize: SRpx(13, true),
                  display: onConsultationsContext ? 'none' : '',
                }
          }
        >
          Pièces jointes
        </p>
        <ButtonWithIcon
          label="Ajouter une pièce jointe"
          style={
            isBigScreen
              ? { ...styles.buttonWithIcon }
              : { ...styles.buttonWithIcon, ...responsiveStyles.buttonWithIcon }
          }
          icon={PaperClipIcon({
            ...styles.icon,
            width: isBigScreen ? Rpx(26, true) : SRpx(15, true),
            height: isBigScreen ? Rpx(26, true) : SRpx(15, true),
            style: { margin: isBigScreen ? `auto 0 auto ${Rpx(10)}` : `auto 0` },
          })}
          onClick={() => addButtonAttachmentHandler()}
        />
      </div>

      {filledData && filledData.length > 0 ? (
        <div style={{ overflow: 'auto', margin: '4vw 2vw' }}>
          {filledData.map((element) => (
            <div
              key={element.id}
              style={isBigScreen ? { ...styles.attachmentContainer } : { ...responsiveStyles.attachmentContainer }}
            >
              <p
                style={{
                  ...responsiveStyles.consultationNumParagraph,
                  fontSize: SRpx(15, true),
                  display: isBigScreen || !element.consultationNumber ? 'none' : '',
                }}
              >
                Consultation N°{element.consultationNumber ? element.consultationNumber : '-'}
              </p>
              <IndicatorIcon
                style={{ margin: `auto ${Rpx(15)} auto ${Rpx(30)}`, display: !isBigScreen ? 'none' : '' }}
                width={Rpx(16, true)}
                height={Rpx(16, true)}
                color={DocColor.MEDIUMBLUE}
              />
              <a
                style={
                  isBigScreen
                    ? { ...styles.attachmentAnchor, fontSize: Rpx(25, true) }
                    : { ...styles.attachmentAnchor, ...responsiveStyles.attachmentAnchor, fontSize: SRpx(13, true) }
                }
                href={element.file}
                download={element.fileName}
              >
                {element.fileName !== '' ? element.fileName : 'Fichier sans nom'}
              </a>
              <p
                style={{
                  ...styles.consultationNumberParagraph,
                  fontSize: Rpx(25, true),
                  display: !isBigScreen ? 'none' : '',
                }}
              >
                {element.consultationNumber ? `Consultation N°${element.consultationNumber}` : ''}
              </p>
            </div>
          ))}
        </div>
      ) : (
        <div
          style={
            isBigScreen
              ? { ...styles.dataStateRow, height: Rpx(70, true), fontSize: Rpx(25, true) }
              : { ...styles.dataStateRow, ...responsiveStyles.dataStateRow, fontSize: SRpx(13, true) }
          }
        >
          <p>La liste des pièces jointes est vide.</p>
        </div>
      )}
      <div
        style={
          isBigScreen
            ? {
                ...styles.loadMoreButton,
                minHeight: Rpx(50, true),
                display: loadMoreButtonVisibility ? '' : 'none',
              }
            : {
                ...styles.loadMoreButton,
                ...responsiveStyles.loadMoreButton,
                minHeight: SRpx(30, true),
                display: loadMoreButtonVisibility ? '' : 'none',
              }
        }
        onKeyDown={loadAttachmentsHandler}
        tabIndex={0}
        role="button"
        onClick={loadAttachmentsHandler}
      >
        <AddIcon
          style={isBigScreen ? { ...styles.icon } : { ...styles.icon, ...responsiveStyles.icon }}
          width={isBigScreen ? Rpx(15, true) : SRpx(10, true)}
          height={isBigScreen ? Rpx(15, true) : SRpx(10, true)}
        />
        <p
          style={
            isBigScreen
              ? { ...styles.loadMoreButtonParagraph, fontSize: Rpx(28, true) }
              : {
                  ...styles.loadMoreButtonParagraph,
                  ...responsiveStyles.loadMoreButtonParagraph,
                  fontSize: SRpx(15, true),
                }
          }
        >
          {isBigScreen ? 'Afficher plus...' : 'Afficher plus'}
        </p>
      </div>
    </div>
  );
};

const styles: StyleSheet = {
  container: {
    maxHeight: '60vh',
    padding: `0 0 0 0`,
    position: 'relative',
    margin: `2vw 2vw 0`,
    outline: `${Rpx(10)} solid ${DocColor.DARKBLUE}`,
    borderRadius: Rpx(60),
    minHeight: Rpx(100),
    overflow: 'hidden',
  },
  onConsultationTitleDiv: {
    width: 'fit-content',
    background: DocColor.DARKBLUE,
    borderRadius: `${Rpx(50)} 0 ${Rpx(20)} 0`,
    minHeight: Rpx(60),
    padding: `0 ${Rpx(40)}`,
  },
  onConsultationTitle: {
    color: 'white',
    fontWeight: 600,
    fontStyle: 'italic',
    margin: 'auto',
  },
  headerDiv: {
    flexDirection: 'row',
    padding: '0 2vw',
  },
  title: {
    color: DocColor.DARKBLUE,
    fontWeight: 800,
    margin: 'auto 0',
  },
  buttonWithIcon: {
    borderRadius: Rpx(13),
    fontSize: Rpx(20, true),
    height: Rpx(46, true),
  },
  attachmentContainer: {
    flexDirection: 'row',
    height: Rpx(70),
    minHeight: Rpx(70),
    background: DocColor.LIGHTGRAY,
    borderRadius: Rpx(5),
    boxSizing: 'border-box',
    marginBottom: Rpx(25),
    marginRight: '1vw',
  },
  attachmentAnchor: {
    fontWeight: 700,
    color: DocColor.DARKBLUE,
    margin: 'auto 0',
  },
  consultationNumberParagraph: {
    fontWeight: 600,
    color: DocColor.MEDIUMBLUE,
    margin: `auto ${Rpx(45)} auto auto`,
  },
  dataStateRow: {
    textAlign: 'center',
    fontWeight: 500,
    whiteSpace: 'nowrap',
    justifyContent: 'center',
    color: DocColor.COALBLACK,
    height: Rpx(70),
    minHeight: Rpx(70),
    background: DocColor.LIGHTGRAY,
    borderRadius: Rpx(5),
    boxSizing: 'border-box',
    margin: '2vw',
  },
  loadMoreButton: {
    flexDirection: 'row',
    width: 'fit-content',
    backgroundColor: DocColor.DARKBLUE,
    color: 'white',
    borderRadius: `${Rpx(20)} ${Rpx(20)} 0 0`,
    cursor: 'pointer',
    padding: `0px 20px`,
    margin: '0 auto',
  },
  loadMoreButtonParagraph: {
    margin: `auto 0px auto ${Rpx(10)}`,
    fontWeight: 500,
  },
  icon: {
    border: '2px solid white',
    borderRadius: Rpx(20),
    padding: Rpx(5),
    margin: 'auto 0',
  },
};

const responsiveStyles: StyleSheet = {
  container: {
    position: 'relative',
    width: `85%`,
    margin: `0 auto ${SRpx(20)} auto`,
  },
  title: {
    margin: `auto 0`,
  },
  buttonWithIcon: {
    borderRadius: SRpx(5),
    fontSize: SRpx(13, true),
    height: SRpx(35, true),
    padding: `0 ${SRpx(10)}`,
  },
  attachmentContainer: {
    background: DocColor.LIGHTGRAY,
    borderRadius: `0 0 ${SRpx(15)} ${SRpx(15)}`,
    margin: `0 0 ${SRpx(10)} 0`,
  },
  attachmentAnchor: {
    color: 'white',
    background: DocColor.MEDIUMBLUE,
    width: '80%',
    padding: `${SRpx(5)} 0`,
    margin: `${SRpx(10)} auto`,
    borderRadius: SRpx(3),
    textAlign: 'center',
  },
  dataStateRow: {
    width: '95%',
    height: SRpx(45),
    margin: `${SRpx(20)} auto 0 auto`,
    fontWeight: 500,
    color: DocColor.DARKBLUE,
    borderRadius: SRpx(5),
  },
  consultationNumParagraph: {
    fontWeight: 600,
    background: DocColor.DARKBLUE,
    width: 'fit-content',
    padding: `${SRpx(2)} ${SRpx(15)}`,
    color: 'white',
    borderRadius: `0 0 ${SRpx(15)} 0`,
  },
  loadMoreButton: {
    borderRadius: SRpx(5),
    padding: `${Rpx(10)} ${Rpx(50)}`,
  },
  loadMoreButtonParagraph: {
    margin: `auto 0px auto ${SRpx(5)}`,
    fontWeight: 600,
  },
  icon: {
    border: `${SRpx(1)} solid white`,
    borderRadius: SRpx(10),
    padding: SRpx(2),
  },
};
