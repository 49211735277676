import React, { useEffect } from 'react';
import { useMediaQuery } from 'react-responsive';
import { CustomInput } from './CustomInput';
import { CustomCheckBox } from './CustomCheckButton';
import { FamilySituationSelector } from './FamilySituationSelector';
import { NationalitiesSelector } from './NationalitiesSelector';
import CommonFunctions from '../usecases/helpers/CommonFunctions';
import { Patient } from '../../patientsContext/domain/entities/Patient';
import { DocColor, StyleSheet } from '../../assets/styles/constantStyles';
import { Rpx, SRpx } from '../usecases/helpers/DimensionsConverter';
import { bigScreenBreakpoint } from '../domain/entities/ReactResponsiveBreakpoints';

type PatientAdditionModificationContainerProps = {
  disabled: boolean;
  onMistakes: boolean;
  mistakesArray?: string[];
  patientData: Patient;
  setPatientData?: React.Dispatch<React.SetStateAction<Patient>>;
  isEmailValid?: boolean;
  setIsEmailValid?: React.Dispatch<React.SetStateAction<boolean>>;
  ThemeColor?: string;
};

export const PatientAdditionModificationContainer = ({
  children,
  disabled,
  onMistakes,
  mistakesArray,
  patientData,
  isEmailValid,
  setPatientData,
  setIsEmailValid,
  ThemeColor,
}: PatientAdditionModificationContainerProps & { children?: React.ReactNode }) => {
  // isBigScreen working as a media breakpoint
  const isBigScreen = useMediaQuery({ query: `(min-width: ${bigScreenBreakpoint}px)` });

  const birthDate: Date | undefined = patientData.birthDate ? new Date(patientData.birthDate) : undefined;
  useEffect(() => {
    if (setIsEmailValid) {
      if (patientData.email === '' || patientData.email === null) {
        setIsEmailValid(true);
      } else {
        setIsEmailValid(CommonFunctions.isValidEmail(patientData.email));
      }
    }
  }, [patientData.email]);

  return (
    <div style={isBigScreen ? { ...styles.container, outlineColor: ThemeColor } : { ...responsiveStyles.container }}>
      {children}
      {isBigScreen && mistakesArray && mistakesArray?.length > 0 && (
        <div>
          {mistakesArray.map((mistake) => (
            <p style={{ ...styles.mistakeParagraph, fontSize: Rpx(24, true) }} key={mistake}>
              • {mistake}
            </p>
          ))}
        </div>
      )}

      <div style={{ ...styles.genderDiv, display: !isBigScreen ? 'none' : '' }}>
        <CustomCheckBox
          checkBoxName="Monsieur"
          checkBoxNameStyle={{ ...styles.radioParagraph, fontSize: Rpx(27, true) }}
          selected={patientData.gender === 'male'}
          width={Rpx(50, true)}
          height={Rpx(50, true)}
          activeColor={ThemeColor || DocColor.DARKBLUE}
          style={{ marginRight: Rpx(20) }}
          onCheckBoxClick={() => setPatientData && !disabled && setPatientData({ ...patientData, gender: 'male' })}
        />
        <CustomCheckBox
          checkBoxName="Madame"
          checkBoxNameStyle={{ ...styles.radioParagraph, fontSize: Rpx(27, true) }}
          selected={patientData.gender === 'female'}
          width={Rpx(50, true)}
          height={Rpx(50, true)}
          activeColor={ThemeColor || DocColor.DARKBLUE}
          style={{ marginRight: Rpx(20) }}
          onCheckBoxClick={() => {
            if (setPatientData && !disabled) {
              setPatientData({ ...patientData, gender: 'female' });
            }
          }}
        />
      </div>
      <div>
        <p
          style={
            isBigScreen
              ? {
                  ...styles.title,
                  fontSize: Rpx(25, true),
                  marginTop: Rpx(8),
                  color: ThemeColor || DocColor.DARKBLUE,
                }
              : {
                  ...styles.title,
                  ...responsiveStyles.title,
                  fontSize: SRpx(13, true),
                  marginTop: SRpx(4),
                  color: ThemeColor || DocColor.DARKBLUE,
                }
          }
        >
          Informations Personnelles
        </p>
        <div
          style={{
            flexDirection: 'row',
            gap: SRpx(25),
            margin: `0 auto ${SRpx(20)} auto`,
            display: isBigScreen ? 'none' : '',
          }}
        >
          <button
            type="button"
            style={{
              ...responsiveStyles.genderButtons,
              fontSize: SRpx(12),
              color: patientData.gender === 'male' ? 'white' : DocColor.DARKBLUE,
              background: patientData.gender === 'male' ? DocColor.MEDIUMBLUE : DocColor.LIGHTGRAY,
            }}
            onClick={() => {
              if (setPatientData && !disabled) {
                setPatientData({ ...patientData, gender: 'male' });
              }
            }}
          >
            Monsieur
          </button>
          <button
            type="button"
            style={{
              ...responsiveStyles.genderButtons,
              fontSize: SRpx(12),
              color: patientData.gender === 'female' ? 'white' : DocColor.DARKBLUE,
              background: patientData.gender === 'female' ? DocColor.MEDIUMBLUE : DocColor.LIGHTGRAY,
            }}
            onClick={() => {
              if (setPatientData && !disabled) {
                setPatientData({ ...patientData, gender: 'female' });
              }
            }}
          >
            Madame
          </button>
        </div>
        <div
          style={
            isBigScreen
              ? {
                  ...styles.division,
                }
              : {
                  ...styles.division,
                  ...responsiveStyles.division,
                }
          }
        >
          <CustomInput
            inputName="Nom"
            inputNameWidth={!isBigScreen ? SRpx(50) : undefined}
            disabled={disabled}
            width={isBigScreen ? '45%' : '100%'}
            OnMistake={(patientData.lastName === '' || patientData.lastName === null) && onMistakes}
            onChange={(v) => {
              if (setPatientData) {
                setPatientData({ ...patientData, lastName: v.target.value });
              }
            }}
            defaultValue={patientData.temporaryPatient === null ? patientData.lastName : ''}
          />
          <CustomInput
            inputName="Prénom"
            inputNameWidth={!isBigScreen ? SRpx(50) : undefined}
            disabled={disabled}
            width={isBigScreen ? '45%' : '100%'}
            OnMistake={(patientData.firstName === '' || patientData.firstName === null) && onMistakes}
            onChange={(v) => {
              if (setPatientData) {
                setPatientData({ ...patientData, firstName: v.target.value });
              }
            }}
            defaultValue={patientData.temporaryPatient === null ? patientData.firstName : ''}
          />
        </div>

        <div
          style={
            isBigScreen
              ? {
                  ...styles.division,
                }
              : {
                  ...styles.division,
                  ...responsiveStyles.division,
                }
          }
        >
          <CustomInput
            inputName="Date de naissance"
            inputNameWidth={!isBigScreen ? SRpx(124) : undefined}
            disabled={disabled}
            width={isBigScreen ? '45%' : '100%'}
            type="date"
            OnMistake={(patientData.birthDate === undefined || patientData.birthDate === null) && onMistakes}
            onChange={(v) => {
              if (setPatientData) {
                setPatientData({ ...patientData, birthDate: v.target.valueAsDate || undefined });
              }
            }}
            defaultValue={birthDate && CommonFunctions.dateToDateInputValue(birthDate)}
          />
          <FamilySituationSelector
            value={patientData.temporaryPatient === null ? patientData.familySituation : undefined}
            disabled={disabled}
            width={isBigScreen ? '45%' : '100%'}
            OnMistake={(patientData.familySituation === '' || patientData.familySituation === null) && onMistakes}
            onChange={(v) => {
              if (setPatientData) {
                setPatientData({ ...patientData, familySituation: v });
              }
            }}
          />
        </div>
      </div>
      <div style={{ marginTop: isBigScreen ? Rpx(35) : SRpx(15) }}>
        <p
          style={
            isBigScreen
              ? {
                  ...styles.title,
                  fontSize: Rpx(25, true),
                  marginTop: Rpx(8),
                  color: ThemeColor || DocColor.DARKBLUE,
                }
              : {
                  ...styles.title,
                  ...responsiveStyles.title,
                  fontSize: SRpx(13, true),
                  marginTop: SRpx(4),
                  color: ThemeColor || DocColor.DARKBLUE,
                }
          }
        >
          Contact
        </p>
        <div
          style={
            isBigScreen
              ? {
                  ...styles.division,
                }
              : {
                  ...styles.division,
                  ...responsiveStyles.division,
                }
          }
        >
          <CustomInput
            inputName="Adresse"
            inputNameWidth={!isBigScreen ? SRpx(50) : Rpx(120)}
            disabled={disabled}
            width="100%"
            onChange={(v) => {
              if (setPatientData) {
                setPatientData({ ...patientData, address: v.target.value });
              }
            }}
            defaultValue={patientData.temporaryPatient === null ? patientData.address : ''}
          />
        </div>

        <div
          style={
            isBigScreen
              ? {
                  ...styles.division,
                }
              : {
                  ...styles.division,
                  ...responsiveStyles.division,
                }
          }
        >
          <CustomInput
            inputName="Email"
            inputNameWidth={!isBigScreen ? SRpx(50) : Rpx(120)}
            disabled={disabled}
            width={isBigScreen ? '45%' : '100%'}
            OnMistake={!isEmailValid && onMistakes}
            onChange={(v) => {
              if (setPatientData) {
                setPatientData({ ...patientData, email: v.target.value });
              }
            }}
            defaultValue={patientData.email !== null ? patientData.email : ''}
          />
          <CustomInput
            inputName="Mobile"
            inputNameWidth={!isBigScreen ? SRpx(50) : undefined}
            disabled={disabled}
            width={isBigScreen ? '45%' : '100%'}
            OnMistake={(patientData.phone === '' || patientData.phone === null) && onMistakes}
            type="number"
            placeholder="06-00-00-00-00"
            onChange={(v) => {
              if (setPatientData) {
                setPatientData({ ...patientData, phone: v.target.value });
              }
            }}
            defaultValue={patientData.phone && patientData.phone.replace('+212', '0')}
          />
        </div>
      </div>
      <div style={{ marginTop: isBigScreen ? Rpx(35) : SRpx(15) }}>
        <p
          style={
            isBigScreen
              ? {
                  ...styles.title,
                  fontSize: Rpx(25, true),
                  marginTop: Rpx(8),
                  color: ThemeColor || DocColor.DARKBLUE,
                }
              : {
                  ...styles.title,
                  ...responsiveStyles.title,
                  fontSize: SRpx(13, true),
                  marginTop: SRpx(4),
                  color: ThemeColor || DocColor.DARKBLUE,
                }
          }
        >
          Informations Complémentaires
        </p>
        <div
          style={
            isBigScreen
              ? {
                  ...styles.division,
                }
              : {
                  ...styles.division,
                  ...responsiveStyles.division,
                }
          }
        >
          <CustomInput
            inputName="CIN / N° Passeport"
            inputNameWidth={!isBigScreen ? SRpx(120) : undefined}
            disabled={disabled}
            width={isBigScreen ? '45%' : '100%'}
            OnMistake={(patientData.identityCode === '' || patientData.identityCode === null) && onMistakes}
            onChange={(v) => {
              if (setPatientData) {
                setPatientData({ ...patientData, identityCode: v.target.value });
              }
            }}
            defaultValue={patientData.temporaryPatient === null ? patientData.identityCode : ''}
          />

          <CustomInput
            inputName="Profession"
            inputNameWidth={!isBigScreen ? SRpx(120) : Rpx(160)}
            disabled={disabled}
            width={isBigScreen ? '45%' : '100%'}
            onChange={(v) => {
              if (setPatientData) {
                setPatientData({ ...patientData, profession: v.target.value });
              }
            }}
            defaultValue={patientData.temporaryPatient === null ? patientData.profession : ''}
          />
        </div>

        <div
          style={
            isBigScreen
              ? {
                  ...styles.division,
                }
              : {
                  ...styles.division,
                  ...responsiveStyles.division,
                }
          }
        >
          <CustomInput
            inputName="Assurance Maladie"
            inputNameWidth={!isBigScreen ? SRpx(120) : undefined}
            disabled={disabled}
            width={isBigScreen ? '45%' : '100%'}
            OnMistake={(patientData.assurance === '' || patientData.assurance === null) && onMistakes}
            onChange={(v) => {
              if (setPatientData) {
                setPatientData({ ...patientData, assurance: v.target.value });
              }
            }}
            defaultValue={patientData.temporaryPatient === null ? patientData.assurance : ''}
          />

          <NationalitiesSelector
            value={patientData.temporaryPatient === null ? patientData.nationality : undefined}
            inputNameWidth={!isBigScreen ? SRpx(100) : Rpx(140)}
            disabled={disabled}
            width={isBigScreen ? '45%' : '100%'}
            onChange={(v) => {
              if (setPatientData) {
                setPatientData({ ...patientData, nationality: v });
              }
            }}
          />
        </div>
      </div>
      {!isBigScreen && mistakesArray && mistakesArray?.length > 0 && (
        <div style={{ alignItems: 'center', marginTop: SRpx(15) }}>
          {mistakesArray.map((mistake) => (
            <p style={{ ...styles.mistakeParagraph, fontSize: SRpx(10, true) }} key={mistake}>
              • {mistake}
            </p>
          ))}
        </div>
      )}
    </div>
  );
};
const styles: StyleSheet = {
  container: {
    position: 'relative',
    padding: `${Rpx(50)} ${Rpx(45)}`,
    margin: `2vw 2vw 0`,
    outline: `${Rpx(10)} solid ${DocColor.DARKBLUE}`,
    borderRadius: Rpx(60),
    minHeight: Rpx(100),
  },
  mistakeParagraph: {
    width: 'fit-content',
    marginBottom: Rpx(10),
    color: 'red',
    fontWeight: 700,
    letterSpacing: Rpx(3),
  },
  division: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginBottom: Rpx(20),
  },
  genderDiv: {
    flexDirection: 'row',
    justifyContent: 'end',
    alignItems: 'center',
  },
  radioParagraph: {
    marginRight: Rpx(50),
    fontWeight: 600,
    color: DocColor.DARKBLUE,
    alignItems: 'center',
  },
  title: {
    fontWeight: 800,
    marginBottom: Rpx(25),
  },
};

const responsiveStyles: StyleSheet = {
  container: {
    position: 'relative',
    width: `85%`,
    margin: '0 auto',
  },
  division: {
    flexDirection: 'column',
    marginBottom: SRpx(5),
    gap: SRpx(5),
  },
  genderButtons: {
    fontWeight: 500,
    cursor: 'pointer',
    width: 'fit-content',
    height: SRpx(23),
    borderRadius: SRpx(6),
    padding: `0 ${SRpx(20)}`,
  },
  title: {
    marginBottom: SRpx(10),
  },
};
