import React, { useEffect, useState } from 'react';
import { NotesTemplateOverlay } from './NotesTemplateOverlay';
import { DocColor, StyleSheet } from '../../../../assets/styles/constantStyles';
import { useLoaderContext } from '../../../../common/configuration/loaderContextProvider';
import { Rpx } from '../../../../common/usecases/helpers/DimensionsConverter';
import { EyeIcon } from '../../../../assets/svg/EyeIcon';
import { ButtonWithIcon } from '../../../../common/ui/ButtonWithIcon';
import { ConsultationAdditionFilledDataType } from '../../../domain/entities/ConsultationAdditionFilledDataType';
import { ConsultationNotes, hardCodedEmptyConsultationNotes } from '../../../domain/entities/ConsultationNotes';
import { Patient } from '../../../../patientsContext/domain/entities/Patient';

type NotesComponentProps = {
  patient?: Patient | null;
  temporaryPatientName: string;
  defaultValue?: string;
  isOnlyReadable?: boolean;
  disabled?: boolean;
  setFilledData: React.Dispatch<React.SetStateAction<ConsultationAdditionFilledDataType>>;
};

export const NotesComponent = ({
  patient,
  temporaryPatientName,
  defaultValue,
  isOnlyReadable,
  disabled,
  setFilledData,
}: NotesComponentProps) => {
  const loader = useLoaderContext();
  const [consultationNotesDataState, setConsultationNotesDataState] = useState<ConsultationNotes>(
    hardCodedEmptyConsultationNotes,
  );
  const [isNotesTemplateOverlayClosed, setIsNotesTemplateOverlayClosed] = useState<boolean>(true);

  useEffect(() => {
    if (isOnlyReadable !== true) {
      setFilledData((prevState) => ({ ...prevState, consultationNotes: consultationNotesDataState.consultationNotes }));
    }
  }, [consultationNotesDataState]);

  const textAreaHandleKeyDown = (e: any) => {
    e.target.style.height = 'inherit';
    e.target.style.height = `${e.target.scrollHeight}px`;
  };

  return (
    <div style={{ ...styles.container, outlineColor: disabled ? DocColor.GRAY : DocColor.DARKBLUE }}>
      {!isNotesTemplateOverlayClosed && (
        <NotesTemplateOverlay
          onSuccess={() => {
            setIsNotesTemplateOverlayClosed(true);
            loader.setMessage('');
          }}
          onFailure={() => {
            setIsNotesTemplateOverlayClosed(true);
            loader.setMessage('');
          }}
          patient={patient}
          temporaryPatientName={temporaryPatientName}
        />
      )}
      <div style={{ ...styles.titleDiv, background: disabled ? DocColor.GRAY : DocColor.DARKBLUE }}>
        <p key="firstHistoryDateDiv" style={{ ...styles.title, fontSize: Rpx(32, true) }}>
          Notes
        </p>
      </div>
      <textarea
        disabled={disabled || isOnlyReadable}
        onKeyDown={(e) => textAreaHandleKeyDown(e)}
        style={{ ...styles.textArea, fontSize: Rpx(25, true) }}
        defaultValue={defaultValue}
        rows={3}
        onChange={(v) => {
          setConsultationNotesDataState((prevConsultationNotesData) => ({
            ...prevConsultationNotesData,
            consultationNotes: v.target.value,
          }));
        }}
      />
      <div style={{ alignItems: 'end', margin: `${Rpx(20)} auto 0 auto`, width: '90%' }}>
        <ButtonWithIcon
          disabled={disabled}
          label="Afficher un aperçu"
          style={{ ...styles.button, fontSize: Rpx(20, true), height: Rpx(46, true) }}
          icon={EyeIcon({
            ...styles.icon,
            width: Rpx(26, true),
            height: Rpx(26, true),
            isClosed: false,
            style: { margin: `auto 0 auto ${Rpx(10)}` },
          })}
          onClick={() => {
            loader.setMessage('Ouverture du fichier en cours');
            setIsNotesTemplateOverlayClosed(false);
          }}
        />
      </div>
    </div>
  );
};

const styles: StyleSheet = {
  container: {
    position: 'relative',
    margin: `2vw 2vw 0`,
    outline: `${Rpx(10)} solid ${DocColor.DARKBLUE}`,
    borderRadius: Rpx(60),
    minHeight: Rpx(100),
    paddingBottom: Rpx(47),
  },
  titleDiv: {
    width: 'fit-content',
    background: DocColor.DARKBLUE,
    borderRadius: `${Rpx(50)} 0 ${Rpx(20)} 0`,
    minHeight: Rpx(60),
    padding: `0 ${Rpx(40)}`,
    marginBottom: Rpx(15),
  },
  title: {
    color: 'white',
    fontWeight: 600,
    fontStyle: 'italic',
    margin: 'auto',
  },
  textArea: {
    background: DocColor.LIGHTGRAY,
    border: `${Rpx(2)} solid ${DocColor.LIGHTGRAY}`,
    borderRadius: Rpx(9),
    outline: 'none',
    caretColor: DocColor.DARKBLUE,
    overflow: 'auto',
    resize: 'vertical',
    width: '90%',
    margin: `${Rpx(20)} auto 0 auto`,
  },
  button: {
    borderRadius: Rpx(13),
  },
};
