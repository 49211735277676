import React, { useEffect, useState } from 'react';
import { DispatchAsyncResult } from 'react-redux-dispatch-async/lib/dispatchAsync';
import { useLocation } from 'react-router-dom';
import { PatientConsultationsComponent } from './components/PatientConsultationsComponent';
import { NextAppointmentComponent } from './components/NextAppointmentComponent';
import { ConsultationsStatsComponent } from './components/ConsultationsStatsComponent';
import { PatientConsultation } from '../../domain/entities/PatientConsultation';
import { DocColor, StyleSheet } from '../../../assets/styles/constantStyles';
import { AddIcon } from '../../../assets/svg/Addlcon';
import { Rpx } from '../../../common/usecases/helpers/DimensionsConverter';
import { CustomSearchInput } from '../../../common/ui/CustomSearchInput';
import { NavigationPayload } from '../../../navigationContext/domain/entities/NavigationPayload';
import { useNotificationContext } from '../../../common/configuration/notificationContextProvider';
import { CalendarEvent } from '../../../calendarContext/domain/entities/CalendarEvent';
import { User } from '../../../authContext/domain/entities/User';
import { TodaysConsultationsStats } from '../../domain/entities/TodaysConsultationsStats';

interface PatientConsultationProps {
  stateConnectedUser: User | undefined;
  fetchUserAsync(id: string): Promise<DispatchAsyncResult>;
  statePatientsConsultations: PatientConsultation[];
  stateTodaysConsultationsStats: TodaysConsultationsStats | undefined;
  loadPatientsConsultationsAsync(
    patientId?: string,
    consultationsLimit?: number,
    skipConsultations?: number,
  ): Promise<DispatchAsyncResult>;
  fetchPatientsConsultationsAsync(value: string): Promise<DispatchAsyncResult>;
  getTodaysConsultationsStatsAsync(): Promise<DispatchAsyncResult>;
  deletePatientConsultationAsync(lookId: string): Promise<DispatchAsyncResult>;
  loadNextAppointmentAsync: () => Promise<DispatchAsyncResult>;
  navigateToAsync: (p: NavigationPayload) => void;
  // navigateBack: () => void;
}

export const PatientConsultationsDumb = ({
  stateConnectedUser,
  fetchUserAsync,
  statePatientsConsultations,
  stateTodaysConsultationsStats,
  loadPatientsConsultationsAsync,
  fetchPatientsConsultationsAsync,
  getTodaysConsultationsStatsAsync,
  deletePatientConsultationAsync,
  loadNextAppointmentAsync,
  navigateToAsync,
}: PatientConsultationProps) => {
  const notification = useNotificationContext();
  const [recievedData] = useState<any>(useLocation().state);
  const [linkedUser, setLinkedUser] = useState<User>();
  const [dataState, setDataState] = useState<string>('Chargement en cours...');
  // the limit of attachments should be loaded
  const consultationsLimit = 3;
  // to know how many skips should be done to attachments on load more.
  const [skipConsultationsCounter, setSkipConsultationsCounter] = useState<number>(0);
  // this state should not be affected by the searchbar
  const [patientsConsultationsData, setPatientsConsultationsData] = useState<PatientConsultation[]>([]);
  const [isOnlyPatientConsultations, setIsOnlyPatientConsultations] = useState<boolean>(
    recievedData?.patient !== undefined,
  );
  const [searchOnChange, setSearchOnChange] = useState<{ value: string; result: string[] }>({ value: '', result: [] });
  // next appointment usestate
  const [nextAppointmentData, setNextAppointmentData] = useState<CalendarEvent[]>([]);

  useEffect(() => {
    if (stateConnectedUser?.linkedTo) {
      fetchUserAsync(stateConnectedUser?.linkedTo).then((res) => {
        if (res.success) {
          setLinkedUser(res.result);
        }
      });
    }
    loadNextAppointmentAsync().then((res) => {
      if (res.success) {
        setNextAppointmentData([...res.result]);
      }
    });
    getTodaysConsultationsStats();
  }, []);

  const getTodaysConsultationsStats = () => {
    getTodaysConsultationsStatsAsync();
  };

  useEffect(() => {
    const tempData: any[] = [];
    patientsConsultationsData.forEach((obj: any) => {
      tempData.push({
        id: obj.id,
        firstName: obj.firstName,
        lastName: obj.lastName,
        temporaryPatient: obj.temporaryPatient ? `temp ${obj.temporaryPatient}` : null,
      });
    });
  }, [patientsConsultationsData]);

  useEffect(() => {
    if (isOnlyPatientConsultations) {
      loadPatientConsultations();
    } else {
      loadConsultations();
    }
  }, [isOnlyPatientConsultations]);

  const loadConsultations = () => {
    loadPatientsConsultationsAsync(undefined, consultationsLimit, skipConsultationsCounter).then((res) => {
      if (res.success) {
        const tempData: PatientConsultation[] = patientsConsultationsData;
        tempData.push(...res.result);
        setPatientsConsultationsData([...tempData]);
        setSkipConsultationsCounter((value) => value + consultationsLimit);
        if (tempData.length === 0) {
          setDataState('la liste des consultations est vide.');
        }
      } else {
        setDataState('Il y a un problème dans le chargement des données, Veuillez réessayer plus tard...');
      }
    });
  };

  const loadPatientConsultations = () => {
    loadPatientsConsultationsAsync(recievedData?.patient.id).then((res) => {
      if (res.success) {
        setPatientsConsultationsData(res.result);
        if (res.result.length === 0) {
          setDataState('la liste des consultations est vide.');
        }
      } else {
        setDataState('Il y a un problème dans le chargement des données, Veuillez réessayer plus tard...');
      }
    });
  };

  const loadMoreOnClickHandler = () => {
    loadConsultations();
  };

  const onDeleteClickHandler = (consultationId: string) => {
    deletePatientConsultationAsync(consultationId).then((res) => {
      if (res.success) {
        const tempData: PatientConsultation[] = patientsConsultationsData.filter((obj) => obj.id !== consultationId);
        setPatientsConsultationsData([...tempData]);
        notification.setOnSuccess({
          message: `La consultation N°${res.result.number} est supprimé.`,
        });
      }
    });
    getTodaysConsultationsStats();
  };

  const navigateToConsultationHandler = () => {
    navigateToAsync({ routeName: 'Consultation Addition' });
  };

  const scheduleAppointmentHandler = () => {
    navigateToAsync({ routeName: 'Mon Calendrier', params: { scheduleAppointment: true } });
  };

  return (
    <div style={styles.container}>
      {stateConnectedUser?.role === 'DOCTOR' && (
        <p
          style={{
            ...styles.title,
            fontSize: Rpx(48, true),
          }}
        >
          Mes Consultations
        </p>
      )}
      {stateConnectedUser?.role === 'NURSE' && (
        <div style={{ flexDirection: 'row', margin: `0 ${Rpx(90)}`, alignItems: 'center', gap: Rpx(15) }}>
          <div style={styles.linkedDoctorDiv} />
          <p
            style={{
              ...styles.linkedDoctorTitle,
              fontSize: Rpx(35, true),
            }}
          >
            {linkedUser?.firstName && `Les consultations du Dr.${linkedUser?.firstName} ${linkedUser?.lastName}`}
          </p>
        </div>
      )}

      <div style={styles.headerDiv}>
        {isOnlyPatientConsultations ? (
          <div style={{ width: '23vw' }}>
            <p style={{ ...styles.patientNameParagraph, fontSize: Rpx(30, true) }}>
              {recievedData.patient.temporaryPatient === null
                ? `${recievedData.patient.firstName} ${recievedData.patient.lastName}`
                : `Temp ${recievedData.patient.temporaryPatient}`}
            </p>
            <p style={{ ...styles.codePatientParagraph, fontSize: Rpx(24, true) }}>
              Code Patient{' '}
              <span style={styles.codePatientSpan}>
                {recievedData?.patient.id ? recievedData?.patient.id.slice(-5).toUpperCase() : '-'}
              </span>
            </p>
            <div
              style={{ ...styles.loadConsultationsButton, fontSize: Rpx(20, true), height: Rpx(45, true) }}
              onKeyDown={() => {
                setPatientsConsultationsData([]);
                setIsOnlyPatientConsultations(false);
              }}
              tabIndex={0}
              role="button"
              onClick={() => {
                setPatientsConsultationsData([]);
                setIsOnlyPatientConsultations(false);
              }}
            >
              <p style={{ margin: `auto 0`, fontSize: Rpx(21, true), whiteSpace: 'nowrap' }}>
                Afficher toutes les consultations du patient
              </p>
            </div>
          </div>
        ) : (
          <CustomSearchInput
            fetchDataAsync={fetchPatientsConsultationsAsync}
            onSearchChange={(v, res) => {
              setSearchOnChange({ value: v, result: res });
              if (res.length === 0) {
                setDataState('La liste des consultations est vide.');
              }
            }}
            filterDisbled
          />
        )}

        <div
          style={{ ...styles.button, height: Rpx(70, true) }}
          onClick={() => {
            if (stateConnectedUser?.role === 'DOCTOR') {
              navigateToConsultationHandler();
            } else {
              scheduleAppointmentHandler();
            }
          }}
          onKeyDown={() => {
            if (stateConnectedUser?.role === 'DOCTOR') {
              navigateToConsultationHandler();
            } else {
              scheduleAppointmentHandler();
            }
          }}
          tabIndex={0}
          role="button"
        >
          <AddIcon style={styles.icon} width={Rpx(15, true)} height={Rpx(15, true)} />
          <p style={{ ...styles.buttonParagraph, fontSize: Rpx(28, true) }}>
            {' '}
            {stateConnectedUser?.role === 'DOCTOR' ? `Nouvelle consultation` : `Programmer un RDV`}
          </p>
        </div>
      </div>

      <ConsultationsStatsComponent
        isVisible={isOnlyPatientConsultations}
        stateTodaysConsultationsStats={stateTodaysConsultationsStats}
        linkedUser={linkedUser}
      />

      <div style={{ flexDirection: stateConnectedUser?.role === 'DOCTOR' ? 'column' : 'column-reverse' }}>
        <PatientConsultationsComponent
          stateConnectedUser={stateConnectedUser}
          patientsConsultationsData={patientsConsultationsData}
          searchOnChange={searchOnChange}
          dataState={dataState}
          loadMoreButtonVisibility={
            !(
              isOnlyPatientConsultations ||
              patientsConsultationsData.length === 0 ||
              statePatientsConsultations.length === 0 ||
              searchOnChange.value !== ''
            )
          }
          onDeleteClick={(consultationId) => onDeleteClickHandler(consultationId)}
          onLoadMoreClick={() => loadMoreOnClickHandler()}
          navigateToAsync={navigateToAsync}
        />
        <NextAppointmentComponent
          stateConnectedUser={stateConnectedUser}
          nextAppointmentData={nextAppointmentData}
          navigateToAsync={navigateToAsync}
        />
      </div>
    </div>
  );
};
const styles: StyleSheet = {
  container: {
    flex: 1,
    margin: `${Rpx(130)} 0`,
  },
  linkedDoctorDiv: {
    width: Rpx(25),
    height: Rpx(25),
    borderRadius: Rpx(5),
    background: DocColor.MEDIUMBLUE,
    transform: 'rotate(45deg)',
  },
  linkedDoctorTitle: {
    fontWeight: 600,
    color: DocColor.DARKBLUE,
  },
  title: {
    color: DocColor.DARKBLUE,
    fontWeight: 800,
    letterSpacing: Rpx(3),
    margin: '0 auto',
  },
  headerDiv: {
    flexDirection: 'row',
    width: '85%',
    margin: `${Rpx(90)} auto 0 auto`,
  },
  button: {
    width: 'fit-content',
    backgroundColor: DocColor.DARKBLUE,
    color: 'white',
    borderRadius: Rpx(8),
    flexDirection: 'row',
    cursor: 'pointer',
    padding: `0px ${Rpx(20)}`,
    margin: 'auto 0 0 auto',
  },
  icon: {
    border: '2px solid white',
    borderRadius: Rpx(20),
    padding: Rpx(5),
    margin: 'auto 0',
  },
  buttonParagraph: {
    margin: `auto 0px auto ${Rpx(10)}`,
    fontWeight: 500,
  },
  patientNameParagraph: {
    padding: `${Rpx(10)} 0`,
    background: DocColor.DARKBLUE,
    borderRadius: `${Rpx(30)} ${Rpx(30)} 0 0`,
    color: 'white',
    fontWeight: 700,
    textAlign: 'center',
    textTransform: 'capitalize',
  },
  codePatientSpan: {
    fontWeight: 800,
    color: DocColor.MEDIUMBLUE,
    letterSpacing: Rpx(3),
    marginLeft: Rpx(20),
  },
  codePatientParagraph: {
    border: `${Rpx(6)} solid ${DocColor.DARKBLUE}`,
    borderRadius: `0 0 ${Rpx(10)} ${Rpx(10)}`,
    textAlign: 'center',
    padding: `${Rpx(10)} 0`,
    fontWeight: 800,
    color: DocColor.DARKBLUE,
  },
  loadConsultationsButton: {
    flexDirection: 'row',
    width: '22vw',
    background: DocColor.DARKBLUE,
    borderRadius: `0 0 ${Rpx(21)} ${Rpx(21)}`,
    margin: '0 auto',
    justifyContent: 'center',
    color: 'white',
    cursor: 'pointer',
    fontWeight: 500,
  },
};
