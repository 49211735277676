import { all } from 'redux-saga/effects';
import { authSagas } from '../../authContext/usecases/Auth.sagas';
import { calendarSagas } from '../../calendarContext/usecases/Calendar.sagas';
import { applicationSagas } from '../../common/usecases/application/application.sagas';
import { consultationsSagas } from '../../consultationsContext/usecases/Consultations.sagas';
import { navigationSagas } from '../../navigationContext/usecases/navigation.sagas';
import { patientsSagas } from '../../patientsContext/usecases/Patients.sagas';
import { userSettingsSagas } from '../../settingsContext/usecases/UserSettings.sagas';

export function* sagas() {
  yield all([
    calendarSagas(),
    patientsSagas(),
    consultationsSagas(),
    userSettingsSagas(),
    navigationSagas(),
    authSagas(),
    applicationSagas(),
  ]);
}
