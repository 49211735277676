import { User } from '../../authContext/domain/entities/User';
import { createAsyncActions } from '../../common/usecases/helpers/actions.helper';
import { UserSettings } from '../domain/entities/UserSettings';

export const {
  request: updateUsernameRequest,
  success: updateUsernameSuccess,
  failure: updateUsernameFailure,
} = createAsyncActions<{ id: string; firstName: string; lastName: string }, User, unknown>('UPDATE_USERNAME');

export const {
  request: updatePasswordRequest,
  success: updatePasswordSuccess,
  failure: updatePasswordFailure,
} = createAsyncActions<{ id: string; currentPassword: string; newPassword: string }, User, unknown>('UPDATE_PASSWORD');

export const {
  request: loadUserSettingsRequest,
  success: loadUserSettingsSuccess,
  failure: loadUserSettingsFailure,
} = createAsyncActions<void, UserSettings, unknown>('LOAD_USER_SETTINGS');

export const {
  request: updateUserSettingsRequest,
  success: updateUserSettingsSuccess,
  failure: updateUserSettingsFailure,
} = createAsyncActions<UserSettings, UserSettings, unknown>('UPDATE_USER_SETTINGS');
