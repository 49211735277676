import * as React from 'react';
import { Color } from '../styles/constantStyles';

export interface PasswordInputProps {
  color?: string;
  style?: React.CSSProperties;
  width?: string | number;
  height?: string | number;
}

export const PasswordInputIcon = ({ color, style, width, height }: PasswordInputProps) => (
  <svg style={style} viewBox="0 0 29 30" width={width} height={height} fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M20.6667 9V7.66667C20.6667 6.5826 20.6667 6.04056 20.583 5.58882C20.2057 3.55343 18.6133 1.96096 16.5779 1.58373C16.1261 1.5 15.5841 1.5 14.5 1.5V1.5C13.416 1.5 12.8739 1.5 12.4222 1.58373C10.3868 1.96096 8.79434 3.55343 8.4171 5.58882C8.33337 6.04056 8.33337 6.5826 8.33337 7.66667V9"
      stroke={color || Color.MONOGREY3}
      strokeWidth="2"
      strokeLinecap="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M1.50368 8.37868C0.625 9.25736 0.625 10.6716 0.625 13.5V22C0.625 25.7712 0.625 27.6569 1.79657 28.8284C2.96815 30 4.85376 30 8.625 30H20.375C24.1462 30 26.0319 30 27.2034 28.8284C28.375 27.6569 28.375 25.7712 28.375 22V13.5C28.375 10.6716 28.375 9.25736 27.4963 8.37868C26.6176 7.5 25.2034 7.5 22.375 7.5H6.625C3.79657 7.5 2.38236 7.5 1.50368 8.37868ZM14.5 20.5C16.0018 20.5 17.125 19.3294 17.125 18C17.125 16.6706 16.0018 15.5 14.5 15.5C12.9982 15.5 11.875 16.6706 11.875 18C11.875 19.3294 12.9982 20.5 14.5 20.5ZM19.125 18C19.125 20.1512 17.5736 21.9499 15.5 22.3945V25.5H13.5V22.3945C11.4264 21.9499 9.875 20.1512 9.875 18C9.875 15.5147 11.9457 13.5 14.5 13.5C17.0543 13.5 19.125 15.5147 19.125 18Z"
      fill={color || Color.MONOGREY3}
    />
  </svg>
);
