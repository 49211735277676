import * as React from 'react';
import { Color } from '../styles/constantStyles';

export interface EditIconProps {
  color?: string;
  style?: React.CSSProperties;
  width?: string | number;
  height?: string | number;
}

export const EditIcon = ({ color, width, height, style }: EditIconProps) => (
  <svg style={style} width={width} height={height} viewBox="0 0 37 37" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M18.5 23.125H13.875V18.5L27.75 4.625L32.375 9.25L18.5 23.125Z"
      stroke={color || Color.MONOGREY3}
      strokeWidth="2.44021"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M24.2812 8.09375L28.9062 12.7187"
      stroke={color || Color.MONOGREY3}
      strokeWidth="1.62681"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M31.2188 17.3438V30.0625C31.2188 30.3692 31.0969 30.6633 30.8801 30.8801C30.6633 31.0969 30.3692 31.2188 30.0625 31.2188H6.9375C6.63084 31.2188 6.33675 31.0969 6.11991 30.8801C5.90307 30.6633 5.78125 30.3692 5.78125 30.0625V6.9375C5.78125 6.63084 5.90307 6.33675 6.11991 6.11991C6.33675 5.90307 6.63084 5.78125 6.9375 5.78125H19.6562"
      stroke={color || Color.MONOGREY3}
      strokeWidth="2.44021"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
