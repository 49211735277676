import React, { useCallback, useEffect } from 'react';
import { useMediaQuery } from 'react-responsive';
import { Color, DocColor, StyleSheet } from '../../assets/styles/constantStyles';
import { CheckMarkIcon } from '../../assets/svg/CheckMarkIcon';
import { CloseIcon } from '../../assets/svg/CloseIcon';
import { bigScreenBreakpoint } from '../domain/entities/ReactResponsiveBreakpoints';
import { Rpx, SRpx } from '../usecases/helpers/DimensionsConverter';

export interface ConfirmOverlayProps {
  message: string;
  highlightedMessage?: string;
  onConfirmClick: () => void;
  onCloseClick?: () => void;
  width?: string | number;
  isClosedState: boolean;
  setIsClosedState: React.Dispatch<React.SetStateAction<boolean>>;
}
export const ConfirmOverlay = ({
  message,
  highlightedMessage,
  isClosedState,
  setIsClosedState,
  onConfirmClick,
  onCloseClick,
  width,
}: ConfirmOverlayProps) => {
  // isBigScreen working as a media breakpoint
  const isBigScreen = useMediaQuery({ query: `(min-width: ${bigScreenBreakpoint}px)` });

  useEffect(() => {
    document.addEventListener('keydown', escFunction, false);

    return () => {
      document.removeEventListener('keydown', escFunction, false);
    };
  }, []);

  const escFunction = useCallback((event) => {
    if (event.key === 'Escape') {
      setIsClosedState(true);
    }
  }, []);

  const containerOnClickHandler = () => {
    setIsClosedState(true);
  };
  const onCancelClickHandler = () => {
    setIsClosedState(true);
  };
  return (
    <div
      style={{
        ...styles.container,
        display: isClosedState ? 'none' : '',
      }}
      onClick={(e) => {
        if (e.target !== e.currentTarget) {
          return;
        }
        containerOnClickHandler();
      }}
      onKeyDown={(e) => {
        if (e.target !== e.currentTarget) {
          return;
        }
        containerOnClickHandler();
      }}
      tabIndex={0}
      role="button"
    >
      <div
        style={
          isBigScreen
            ? { ...styles.confirmContainer, width }
            : { ...styles.confirmContainer, ...responsiveStyles.confirmContainer, width }
        }
      >
        <div
          style={isBigScreen ? { ...styles.closeButton } : { ...styles.closeButton, ...responsiveStyles.closeButton }}
          onKeyDown={() => {
            setIsClosedState(true);
          }}
          tabIndex={0}
          role="button"
          onClick={() => {
            setIsClosedState(true);
          }}
        >
          <CloseIcon color={DocColor.DARKBLUE} />
        </div>
        <p style={isBigScreen ? { ...styles.paragraph } : { ...styles.paragraph, ...responsiveStyles.paragraph }}>
          {message}
          <br />
          <span style={styles.paragraphSpan}>{highlightedMessage}</span>
        </p>
        <div style={isBigScreen ? { ...styles.buttonsDiv } : { ...styles.buttonsDiv, ...responsiveStyles.buttonsDiv }}>
          <div
            onKeyDown={onConfirmClick}
            tabIndex={0}
            role="button"
            onClick={onConfirmClick}
            style={
              isBigScreen
                ? { ...styles.button }
                : {
                    ...styles.button,
                    ...responsiveStyles.button,
                  }
            }
          >
            <CheckMarkIcon
              style={
                isBigScreen
                  ? { ...styles.checkMarkIcon }
                  : { ...styles.checkMarkIcon, ...responsiveStyles.checkMarkIcon }
              }
              width={isBigScreen ? Rpx(20) : SRpx(10)}
              height={isBigScreen ? Rpx(20) : SRpx(10)}
              color="white"
            />
            <p
              style={
                isBigScreen
                  ? { ...styles.buttonParagraph }
                  : { ...styles.buttonParagraph, ...responsiveStyles.buttonParagraph }
              }
            >
              Valider
            </p>
          </div>
          <div
            onKeyDown={onCloseClick || onCancelClickHandler}
            tabIndex={0}
            role="button"
            onClick={onCloseClick || onCancelClickHandler}
            style={
              isBigScreen
                ? { ...styles.button, ...styles.cancelButton }
                : { ...styles.button, ...responsiveStyles.button, ...styles.cancelButton }
            }
          >
            <CloseIcon
              style={isBigScreen ? { ...styles.closeIcon } : { ...styles.closeIcon, ...responsiveStyles.closeIcon }}
              width={isBigScreen ? Rpx(20) : SRpx(10)}
              height={isBigScreen ? Rpx(20) : SRpx(10)}
              color="white"
            />
            <p
              style={
                isBigScreen
                  ? { ...styles.buttonParagraph }
                  : { ...styles.buttonParagraph, ...responsiveStyles.buttonParagraph }
              }
            >
              Annuler
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

const styles: StyleSheet = {
  container: {
    position: 'fixed',
    inset: 0,
    width: '100%',
    height: '100%',
    backgroundColor: DocColor.HALFTRANSPARENTBLACK,
    zIndex: 7,
  },
  confirmContainer: {
    position: 'relative',
    margin: 'auto',
    backgroundColor: DocColor.CLOUDYBLUE,
    maxWidth: '50%',
    width: 'fit-content',
    height: 'fit-content',
    padding: `${Rpx(60)} 0 ${Rpx(45)} 0`,
    borderRadius: Rpx(15),
    zIndex: 3,
  },
  closeButton: {
    position: 'absolute',
    inset: 0,
    margin: `${Rpx(20)} ${Rpx(20)} 0 auto`,
    background: 'white',
    width: Rpx(30),
    height: Rpx(30),
    borderRadius: Rpx(7),
    padding: Rpx(10),
    cursor: 'pointer',
    zIndex: 2,
  },
  paragraph: {
    fontSize: Rpx(30),
    fontWeight: 700,
    textAlign: 'center',
    margin: `${Rpx(15)} auto 0 auto`,
    width: '90%',
  },
  paragraphSpan: {
    color: DocColor.MEDIUMBLUE,
  },
  buttonsDiv: {
    width: 'fit-content',
    flexDirection: 'row',
    margin: `${Rpx(35)} auto 0 auto`,
    padding: '0 3vw',
  },
  button: {
    width: 'fit-content',
    height: Rpx(75),
    backgroundColor: DocColor.MEDIUMBLUE,
    color: Color.APPWHITE,
    borderRadius: Rpx(10),
    flexDirection: 'row',
    cursor: 'pointer',
    padding: `0 ${Rpx(20)}`,
    fontSize: Rpx(30),
    fontWeight: 500,
    letterSpacing: Rpx(5),
    textTransform: 'uppercase',
  },
  checkMarkIcon: {
    border: `${Rpx(5)} solid white`,
    borderRadius: Rpx(20),
    padding: Rpx(5),
    margin: 'auto 0',
  },
  closeIcon: {
    border: `${Rpx(5)} solid white`,
    borderRadius: Rpx(20),
    padding: Rpx(5),
    margin: 'auto 0',
  },
  buttonParagraph: {
    margin: `auto 0 auto ${Rpx(10)}`,
  },
  cancelButton: {
    marginLeft: Rpx(95),
    backgroundColor: DocColor.DARKBLUE,
  },
};

const responsiveStyles: StyleSheet = {
  confirmContainer: {
    maxWidth: '90%',
    padding: `${SRpx(30)} 0 ${SRpx(23)} 0`,
    borderRadius: SRpx(8),
  },
  closeButton: {
    margin: `${SRpx(10)} ${SRpx(10)} 0 auto`,
    width: SRpx(15),
    height: SRpx(15),
    borderRadius: SRpx(4),
    padding: SRpx(5),
  },
  paragraph: {
    fontSize: SRpx(15),
    margin: `${SRpx(15)} auto 0 auto`,
    width: '90%',
  },
  buttonsDiv: {
    margin: `${SRpx(18)} auto 0 auto`,
  },
  button: {
    height: SRpx(48),
    borderRadius: SRpx(5),
    padding: `0 ${SRpx(10)}`,
    fontSize: SRpx(15),
    letterSpacing: SRpx(3),
  },
  checkMarkIcon: {
    border: `${SRpx(2)} solid white`,
    borderRadius: SRpx(10),
    padding: SRpx(3),
  },
  closeIcon: {
    border: `${SRpx(2)} solid white`,
    borderRadius: SRpx(10),
    padding: SRpx(3),
  },
  buttonParagraph: {
    margin: `auto 0 auto ${SRpx(5)}`,
  },
};
