import { all, call, put, takeLatest } from 'redux-saga/effects';
import { Action } from 'redux-actions';
import {
  loadUserSettingsFailure,
  loadUserSettingsRequest,
  loadUserSettingsSuccess,
  updatePasswordFailure,
  updatePasswordRequest,
  updatePasswordSuccess,
  updateUsernameFailure,
  updateUsernameRequest,
  updateUsernameSuccess,
  updateUserSettingsFailure,
  updateUserSettingsRequest,
  updateUserSettingsSuccess,
} from './UserSettings.actions';
import { getDependencies } from '../../common/usecases/common.sagas';
import { DependenciesType } from '../../configuration/dependencies';
import { UserSettings } from '../domain/entities/UserSettings';
import { User } from '../../authContext/domain/entities/User';

function* updateUsernameSaga(action: Action<{ id: string; firstName: string; lastName: string }>) {
  try {
    const props: { id: string; firstName: string; lastName: string } = action.payload;
    const dep: DependenciesType = yield getDependencies();
    const res: User = yield call(dep.userSettingsInteractor.updateUsername, props.id, props.firstName, props.lastName);
    yield put(updateUsernameSuccess(res));
  } catch (error) {
    yield put(updateUsernameFailure(error));
  }
}

function* updatePasswordSaga(action: Action<{ id: string; currentPassword: string; newPassword: string }>) {
  try {
    const props: { id: string; currentPassword: string; newPassword: string } = action.payload;
    const dep: DependenciesType = yield getDependencies();
    const res: User = yield call(
      dep.userSettingsInteractor.updatePassword,
      props.id,
      props.currentPassword,
      props.newPassword,
    );
    yield put(updatePasswordSuccess(res));
  } catch (error) {
    yield put(updatePasswordFailure(error));
  }
}

function* loadUserSettingsSaga() {
  try {
    const dep: DependenciesType = yield getDependencies();
    const res: UserSettings = yield call(dep.userSettingsInteractor.loadUserSettings);
    yield put(loadUserSettingsSuccess(res));
  } catch (error) {
    yield put(loadUserSettingsFailure(error));
  }
}

function* updateUserSettingsSaga(action: Action<UserSettings>) {
  try {
    const userSettings: UserSettings = action.payload;
    const dep: DependenciesType = yield getDependencies();
    const res: UserSettings = yield call(dep.userSettingsInteractor.updateUserSettings, userSettings);
    yield put(updateUserSettingsSuccess(res));
  } catch (error) {
    yield put(updateUserSettingsFailure(error));
  }
}

export function* userSettingsSagas() {
  yield all([
    takeLatest(loadUserSettingsRequest, loadUserSettingsSaga),
    takeLatest(updateUsernameRequest, updateUsernameSaga),
    takeLatest(updatePasswordRequest, updatePasswordSaga),
    takeLatest(updateUserSettingsRequest, updateUserSettingsSaga),
    // takeEvery(loadFBDitemsRequest, loadFBDitemsSaga),
  ]);
}
