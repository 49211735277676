import * as React from 'react';
import { Color } from '../styles/constantStyles';

export interface SearchProps {
  color?: string;
  style?: React.CSSProperties;
}

export const SearchIcon = ({ color, style }: SearchProps) => (
  <svg viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" style={style}>
    <path
      stroke={color || Color.MONOGREY3}
      d="M9 17C13.4183 17 17 13.4183 17 9C17 4.58172 13.4183 1 9 1C4.58172 1 1 4.58172 1 9C1 13.4183 4.58172 17 9 17Z"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      stroke={color || Color.MONOGREY3}
      d="M19 19L14.65 14.65"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
