import { DispatchAsyncResult } from 'react-redux-dispatch-async/lib/dispatchAsync';
import React, { useEffect, useState } from 'react';
import { defaultPassword, hardcodedUsers, User } from '../../domain/entities/User';
import { Color, Padds, StyleSheet } from '../../../assets/styles/constantStyles';

interface AdminDevToolsProps {
  loadUsersAsync(): Promise<DispatchAsyncResult<User[]>>;
  sendSignUpAsync(user: User): Promise<DispatchAsyncResult<User>>;
}

export const AdminDevToolsDumb: React.FC<AdminDevToolsProps> = ({ loadUsersAsync, sendSignUpAsync }) => {
  const [usersList, setUsersList] = useState<User[]>([]);

  useEffect(() => {
    reFreshUsers();
  }, []);

  const reFreshUsers = () => {
    loadUsersAsync()
      .then((res) => {
        if (!res.success) {
          alert(`Error while executing loadUsersAsync\n${res.error}`);
          return;
        }
        setUsersList(res.result);
      })
      .catch((err) => {
        alert(`Error while executing loadUsersAsync\n${err}`);
      });
  };

  const sendSignUp = (userToRegister: User) => {
    sendSignUpAsync({ ...userToRegister })
      .then((res) => {
        if (!res.success) {
          alert(`Error while executing sendSignUpAsync\n${res.error}`);
          return;
        }
        reFreshUsers();
      })
      .catch((err) => {
        alert(`Error while executing sendSignUpAsync\n${err}`);
      });
  };

  const isUserRegistered = (email: string) => {
    return usersList.some((user) => user.email === email);
  };

  return (
    <div style={styles.container}>
      <h2 style={styles.title}>Admin Dev Tools</h2>
      <div style={styles.section}>
        <h3 style={styles.sectionTitle}>Users to Register</h3>
        <p style={styles.note}>Default password: {defaultPassword}</p>
        <div style={styles.userGrid}>
          {hardcodedUsers.map((user, i) => (
            <div key={`user-${i + 1}`} style={styles.userRow}>
              <div style={styles.userInfo}>
                <span style={styles.userEmail}>{user.email}</span>
                <span style={styles.userRole}>{user.role}</span>
              </div>
              <button
                type="button"
                onClick={() => sendSignUp(user)}
                style={{ ...styles.button, ...(isUserRegistered(user.email) ? { backgroundColor: Color.DANGER } : {}) }}
                disabled={isUserRegistered(user.email)}
              >
                {isUserRegistered(user.email) ? 'Registered' : 'Register User'}
              </button>
            </div>
          ))}
        </div>
      </div>
      <div style={styles.section}>
        <h3 style={styles.sectionTitle}>Registered Users</h3>
        <button type="button" onClick={reFreshUsers} style={styles.refreshButton}>
          Refresh Users
        </button>
        <div style={styles.userList}>
          {usersList.map((user) => (
            <pre key={user.id} style={styles.userJson}>
              {JSON.stringify(user, null, 2)}
            </pre>
          ))}
        </div>
      </div>
    </div>
  );
};

const styles: StyleSheet = {
  container: {
    flex: 1,
    margin: '2em',
    fontFamily: 'Arial, sans-serif',
  },
  title: {
    color: Color.THEMECOLOR,
    marginBottom: '1em',
  },
  section: {
    marginBottom: '2em',
  },
  sectionTitle: {
    color: Color.DANGER,
    marginBottom: '0.5em',
  },
  note: {
    fontStyle: 'italic',
    marginBottom: '1em',
  },
  userGrid: {
    display: 'grid',
    gridTemplateColumns: 'repeat(4, 1fr)',
    gap: '1em',
  },
  userRow: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '0.5em',
    backgroundColor: '#f5f5f5',
    borderRadius: '4px',
  },
  userInfo: {
    display: 'flex',
    flexDirection: 'column',
    marginBottom: '0.5em',
  },
  userEmail: {
    fontWeight: 'bold',
  },
  userRole: {
    color: Color.DANGER,
  },
  button: {
    background: Color.THEMECOLOR,
    color: 'white',
    border: 'none',
    padding: Padds.PADD1,
    borderRadius: '4px',
    cursor: 'pointer',
  },
  refreshButton: {
    background: Color.DANGER,
    color: 'white',
    border: 'none',
    padding: Padds.PADD1,
    borderRadius: '4px',
    cursor: 'pointer',
    marginBottom: '1em',
  },
  userList: {
    maxHeight: '300px',
    overflowY: 'auto',
  },
  userJson: {
    backgroundColor: '#f5f5f5',
    padding: '0.5em',
    borderRadius: '4px',
    whiteSpace: 'pre-wrap',
    wordBreak: 'break-all',
  },
};
