import { createAsyncActions } from '../../common/usecases/helpers/actions.helper';
import { CalendarEvent } from '../domain/entities/CalendarEvent';

export const {
  request: loadCalendarEventsRequest,
  success: loadCalendarEventsSuccess,
  failure: loadCalendarEventsFailure,
} = createAsyncActions<void, CalendarEvent[], unknown>('LOAD_CALENDAR_EVENTS');

export const {
  request: insertCalendarEventRequest,
  success: insertCalendarEventSuccess,
  failure: insertCalendarEventFailure,
} = createAsyncActions<CalendarEvent, CalendarEvent, unknown>('INSERT_CALENDAR_EVENT');

export const {
  request: updateCalendarEventRequest,
  success: updateCalendarEventSuccess,
  failure: updateCalendarEventFailure,
} = createAsyncActions<CalendarEvent, CalendarEvent, unknown>('UPDATE_CALENDAR_EVENT');

export const {
  request: deleteCalendarEventRequest,
  success: deleteCalendarEventSuccess,
  failure: deleteCalendarEventFailure,
} = createAsyncActions<string, boolean, unknown>('DELETE_CALENDAR_EVENT');

export const {
  request: loadNextAppointmentRequest,
  success: loadNextAppointmentSuccess,
  failure: loadNextAppointmentFailure,
} = createAsyncActions<void, CalendarEvent[], unknown>('LOAD_NEXT_APPOINTMENT');

export const {
  request: loadProgramedAppointmentsRequest,
  success: loadProgramedAppointmentsSuccess,
  failure: loadProgramedAppointmentsFailure,
} = createAsyncActions<{ start: string; end: string }, CalendarEvent[], unknown>('LOAD_PROGRAMED_APPOINTMENT');
