import * as React from 'react';
import { Color } from '../styles/constantStyles';

export interface ToRighttArrow2Props {
  color?: string;
  style?: React.CSSProperties;
  width?: string | number;
  height?: string | number;
}

export const ToRightArrow2 = ({ color, width, height, style }: ToRighttArrow2Props) => (
  <svg style={style} width={width} height={height} viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M18.0913 10.4139L4.89598 3.02324C4.78483 2.96184 4.65761 2.93575 4.53127 2.94844C4.40492 2.96114 4.28544 3.01202 4.18873 3.09431C4.09202 3.1766 4.02267 3.28639 3.98991 3.40908C3.95715 3.53176 3.96254 3.66151 4.00535 3.78106L6.48973 10.7498C6.54446 10.8851 6.54446 11.0364 6.48973 11.1717L4.00535 18.1404C3.96254 18.26 3.95715 18.3897 3.98991 18.5124C4.02267 18.6351 4.09202 18.7449 4.18873 18.8272C4.28544 18.9095 4.40492 18.9604 4.53127 18.973C4.65761 18.9857 4.78483 18.9596 4.89598 18.8982L18.0913 11.5076C18.189 11.4536 18.2704 11.3743 18.3271 11.2782C18.3838 11.182 18.4137 11.0724 18.4137 10.9607C18.4137 10.8491 18.3838 10.7395 18.3271 10.6433C18.2704 10.5471 18.189 10.4679 18.0913 10.4139V10.4139Z"
      stroke={color || Color.MONOGREY3}
      strokeWidth="1.875"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M6.57031 10.9609H11.5703"
      stroke={color || Color.MONOGREY3}
      strokeWidth="1.875"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
