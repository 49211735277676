import * as React from 'react';
import { Color } from '../styles/constantStyles';

export interface CrossIconProps {
  color?: string;
  style?: React.CSSProperties;
  width?: string | number;
  height?: string | number;
}

export const CrossIcon = ({ color, width, height, style }: CrossIconProps) => (
  <svg style={style} width={width} height={height} viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M2 2L11.5 11.5" stroke={color || Color.MONOGREY3} strokeWidth="4" strokeLinecap="round" />
    <path d="M11.5 2L2 11.5" stroke={color || Color.MONOGREY3} strokeWidth="4" strokeLinecap="round" />
  </svg>
);
