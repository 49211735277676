import React, { useEffect, useState } from 'react';
import { DispatchAsyncResult } from 'react-redux-dispatch-async/lib/dispatchAsync';
import { useMediaQuery } from 'react-responsive';
import { DocColor, StyleSheet } from '../../../assets/styles/constantStyles';
import { CheckMarkIcon } from '../../../assets/svg/CheckMarkIcon';
import { CloseIcon } from '../../../assets/svg/CloseIcon';
import { ToRightArrow3 } from '../../../assets/svg/ToRightArrow3';
import { useNotificationContext } from '../../../common/configuration/notificationContextProvider';
import { bigScreenBreakpoint } from '../../../common/domain/entities/ReactResponsiveBreakpoints';
import { ConfirmOverlay } from '../../../common/ui/ConfirmOverlay';
import { PatientAdditionModificationContainer } from '../../../common/ui/PatientAdditionModificationContainer';
import { Rpx, SRpx } from '../../../common/usecases/helpers/DimensionsConverter';
import { hardCodedEmptyPatient, Patient } from '../../domain/entities/Patient';

type PatientAdditionProps = {
  insertPatientAsync(newPatient: Patient): Promise<DispatchAsyncResult>;
  navigateBack: () => void;
};
export const PatientAdditionDumb = ({ insertPatientAsync, navigateBack }: PatientAdditionProps) => {
  // isBigScreen working as a media breakpoint
  const isBigScreen = useMediaQuery({ query: `(min-width: ${bigScreenBreakpoint}px)` });

  const notification = useNotificationContext();
  const [patientData, setPatientData] = useState<Patient>(hardCodedEmptyPatient);
  const [mistakesArray, setMistakesArray] = useState<string[]>([]);
  const [isSubmitClicked, setIsSubmitClicked] = useState<boolean>(false);
  const [isEmailValid, setIsEmailValid] = useState<boolean>(false);
  const [initRender, setInitRender] = useState<boolean>(true);
  const [isConfirmFragmentClosed, setIsConfirmFragmentClosed] = useState<boolean>(true);

  useEffect(() => {
    if (
      isSubmitClicked &&
      isEmailValid &&
      patientData.firstName !== '' &&
      patientData.lastName !== '' &&
      patientData.birthDate !== null &&
      patientData.familySituation !== '' &&
      patientData.phone !== '' &&
      patientData.identityCode !== '' &&
      patientData.assurance !== ''
    ) {
      insertPatient(patientData);
    } else {
      setIsSubmitClicked(false);
    }
  }, [isSubmitClicked]);
  const insertPatient = (newPatient: Patient) => {
    insertPatientAsync(newPatient)
      .then((res) => {
        setIsSubmitClicked(false);
        setMistakesArray([]);
        if (res.success && res.result) {
          if (res.result.email === 'exists') {
            setMistakesArray((element) => [...element, 'Cette adresse email existe déjà.']);
          }
          if (res.result.phone === 'exists') {
            setMistakesArray((element) => [...element, 'Cette numéro de téléphone est existe déjà.']);
          }
          if (res.result.identityCode === 'exists') {
            setMistakesArray((element) => [...element, 'Cette CIN / Numéro passeport Maladie est existe déjà. ']);
          }
          if (res.result.email !== 'exists' && res.result.phone !== 'exists' && res.result.identityCode !== 'exists') {
            navigateBack();
            notification.setOnSuccess({
              message: `Le patient ${newPatient.firstName} ${newPatient.lastName} est ajouté avec succès!`,
            });
          }
        }
      })
      .catch((error) => {
        alert(`An error detected through the process of adding: ${error}`);
        setIsSubmitClicked(false);
      });
  };
  const saveButtonOnClickHandler = () => {
    setIsSubmitClicked(true);
    setInitRender(false);
  };
  const discardButtonOnClickHandler = () => {
    navigateBack();
  };

  const onConfirmClick = () => {
    discardButtonOnClickHandler();
  };

  return (
    <div style={styles.container}>
      <ConfirmOverlay
        message="Les données renseignées seront perdues.
        Êtes-vous sûr de bien vouloir quitter
        l’enregistrement du patient ?"
        onConfirmClick={onConfirmClick}
        isClosedState={isConfirmFragmentClosed}
        setIsClosedState={setIsConfirmFragmentClosed}
      />
      <div style={{ ...responsiveStyles.titleContainer, display: isBigScreen ? 'none' : '' }}>
        <ToRightArrow3 width={SRpx(10)} height={SRpx(10)} color={DocColor.DARKBLUE} />
        <p style={{ ...responsiveStyles.title, fontSize: SRpx(17, true) }}>Ajouter un patient</p>
      </div>
      <div style={{ ...responsiveStyles.line, display: isBigScreen ? 'none' : '' }} />
      <div style={{ ...styles.headerContainer, display: !isBigScreen ? 'none' : '' }}>
        <p style={{ ...styles.title, fontSize: Rpx(32, true) }}>Ajouter un patient</p>
        <div
          style={{ ...styles.button, background: DocColor.MEDIUMBLUE, margin: '0 0 0 auto', height: Rpx(63, true) }}
          onKeyDown={saveButtonOnClickHandler}
          tabIndex={0}
          role="button"
          onClick={saveButtonOnClickHandler}
        >
          <CheckMarkIcon
            style={{
              margin: `auto ${Rpx(19)} auto 0`,
            }}
            width={Rpx(20, true)}
            color="white"
          />
          <p style={{ ...styles.buttonParagraph, fontSize: Rpx(27, true) }}>Enregistrer</p>
        </div>
        <div
          style={{ ...styles.button, height: Rpx(63, true) }}
          onKeyDown={discardButtonOnClickHandler}
          tabIndex={0}
          role="button"
          onClick={discardButtonOnClickHandler}
        >
          <CloseIcon
            style={{
              margin: 'auto 19px auto 0',
            }}
            width={Rpx(20, true)}
            color="white"
          />
          <p style={{ ...styles.buttonParagraph, fontSize: Rpx(27, true) }}>Annuler</p>
        </div>
      </div>
      <PatientAdditionModificationContainer
        disabled={false}
        patientData={patientData}
        setPatientData={setPatientData}
        onMistakes={!initRender}
        mistakesArray={mistakesArray}
        isEmailValid={isEmailValid}
        setIsEmailValid={setIsEmailValid}
        ThemeColor={DocColor.DARKBLUE}
      />
      <div style={{ ...responsiveStyles.buttons, display: isBigScreen ? 'none' : '' }}>
        <div
          style={{
            ...styles.button,
            ...responsiveStyles.button,
            background: DocColor.MEDIUMBLUE,
            margin: '0 0 0 auto',
            height: SRpx(30, true),
          }}
          onKeyDown={saveButtonOnClickHandler}
          tabIndex={0}
          role="button"
          onClick={saveButtonOnClickHandler}
        >
          <CheckMarkIcon
            style={{
              margin: `auto ${SRpx(10)} auto 0`,
            }}
            width={SRpx(10, true)}
            color="white"
          />
          <p style={{ ...styles.buttonParagraph, fontSize: SRpx(15, true) }}>Enregistrer</p>
        </div>
        <div
          style={{ ...styles.button, ...responsiveStyles.button, height: SRpx(30, true) }}
          onKeyDown={() => setIsConfirmFragmentClosed(false)}
          tabIndex={0}
          role="button"
          onClick={() => setIsConfirmFragmentClosed(false)}
        >
          <CloseIcon
            style={{
              margin: `auto ${SRpx(10)} auto 0`,
            }}
            width={SRpx(10, true)}
            color="white"
          />
          <p style={{ ...styles.buttonParagraph, fontSize: SRpx(15, true) }}>Annuler</p>
        </div>
      </div>
    </div>
  );
};

const styles: StyleSheet = {
  container: {
    flex: 1,
    margin: `${Rpx(125)} 0`,
  },
  headerContainer: {
    flexDirection: 'row',
    margin: `0 ${Rpx(95)}`,
    alignItems: 'center',
    gap: Rpx(20),
  },
  inputsContainer: {
    position: 'relative',
    margin: Rpx(30),
    outline: `${Rpx(10)} solid ${DocColor.DARKBLUE}`,
    borderRadius: Rpx(60),
    minHeight: Rpx(100),
    padding: `${Rpx(50)} ${Rpx(45)}`,
  },
  title: {
    color: DocColor.DARKBLUE,
    fontWeight: 700,
  },
  button: {
    flexDirection: 'row',
    background: DocColor.DARKBLUE,
    padding: `0 ${Rpx(30)}`,
    borderRadius: Rpx(12),
    marginLeft: Rpx(15),
    cursor: 'pointer',
  },
  buttonParagraph: {
    color: 'white',
    fontWeight: 500,
    margin: 'auto 0',
  },
};

const responsiveStyles: StyleSheet = {
  titleContainer: {
    flexDirection: 'row',
    width: '80%',
    gap: SRpx(3),
    alignItems: 'center',
    margin: `0 auto ${SRpx(20)} auto`,
  },
  title: {
    color: DocColor.MEDIUMBLUE,
    fontWeight: 700,
    letterSpacing: SRpx(1),
  },
  line: {
    width: '90%',
    height: 2,
    background: DocColor.MEDIUMGRAY,
    margin: `0 auto ${SRpx(20)} auto`,
  },
  buttons: {
    flexDirection: 'row',
    margin: `${SRpx(15)} auto 0 auto`,
    gap: SRpx(20),
  },
  button: {
    padding: `0 ${SRpx(15)}`,
    borderRadius: SRpx(6),
  },
};
