import { ENV } from '../../configuration';
import { InMemoryPatientsInteractor } from '../adapter/inmemory/InMemoryPatientsInteractor';
import { RealPatientsInteractor } from '../adapter/real/RealPatientsInteractor';
import { PatientsInteractor } from '../domain/gateways/Patients.interactor';

export class PatientsContextFactory {
  static patientsInteractor(): PatientsInteractor {
    switch (ENV) {
      case 'prod':
      case 'dev':
        return new RealPatientsInteractor();
      default:
        return new InMemoryPatientsInteractor();
    }
  }
}
