import React, { useEffect, useState } from 'react';
import { DispatchAsyncResult } from 'react-redux-dispatch-async/lib/dispatchAsync';
import { useMediaQuery } from 'react-responsive';
import { PatientConsutationRow } from './PatientConsutationRow';
import { DocColor, StyleSheet } from '../../../../assets/styles/constantStyles';
import { AddIcon } from '../../../../assets/svg/Addlcon';
import { User } from '../../../../authContext/domain/entities/User';
import { Rpx, SRpx } from '../../../../common/usecases/helpers/DimensionsConverter';
import { NavigationPayload } from '../../../../navigationContext/domain/entities/NavigationPayload';
import { PatientConsultation } from '../../../../consultationsContext/domain/entities/PatientConsultation';
import { bigScreenBreakpoint } from '../../../../common/domain/entities/ReactResponsiveBreakpoints';

type ConsultationsProps = {
  stateConnectedUser: User | undefined;
  patientId: string | undefined;
  loadPatientsConsultationsAsync(
    patientId?: string,
    consultationsLimit?: number,
    skipConsultations?: number,
  ): Promise<DispatchAsyncResult>;
  navigateToAsync: (p: NavigationPayload) => void;
};

export const Consultations = ({
  stateConnectedUser,
  patientId,
  loadPatientsConsultationsAsync,
  navigateToAsync,
}: ConsultationsProps) => {
  // isBigScreen working as a media breakpoint
  const isBigScreen = useMediaQuery({ query: `(min-width: ${bigScreenBreakpoint}px)` });

  const [filledData, setFilledData] = useState<PatientConsultation[]>([]);
  // the limit of attachments should be loaded
  const consultationsLimit = 3;
  // to know how many skips should be done to attachments on load more.
  const [skipConsultationsCounter, setSkipConsultationsCounter] = useState<number>(0);
  const [loadMoreButtonVisibility, setLoadMoreButtonVisibility] = useState<boolean>(true);

  useEffect(() => {
    if (patientId) {
      loadConsultationsHandler();
    }
  }, [patientId]);

  const loadConsultationsHandler = () => {
    if (patientId) {
      loadPatientsConsultationsAsync(patientId, consultationsLimit, skipConsultationsCounter).then((res) => {
        if (res.success) {
          if (res.result.length > 0) {
            const tempData: PatientConsultation[] = filledData;
            tempData.push(...res.result);
            setFilledData([...tempData]);
            setSkipConsultationsCounter((value) => value + consultationsLimit);
          } else {
            setLoadMoreButtonVisibility(false);
          }
        }
      });
    }
  };

  const showConsultationInformationButtonHandler = (consultation: PatientConsultation) => {
    navigateToAsync({
      routeName: 'Consultation Addition',
      params: {
        patientId: consultation.patientId,
        consultationId: consultation.id,
        consultationNumber: consultation.number,
        isOnlyReadableConsultation: true,
      },
    });
  };

  return (
    <div style={isBigScreen ? { ...styles.container } : { ...responsiveStyles.container }}>
      <p
        style={
          isBigScreen
            ? { ...styles.title, fontSize: Rpx(25, true) }
            : {
                ...styles.title,
                ...responsiveStyles.title,
                fontSize: SRpx(13, true),
              }
        }
      >
        Historique des consultations du patient
      </p>
      {filledData && filledData.length > 0 ? (
        <div style={{ overflow: 'auto', margin: '2vw' }}>
          {filledData.map((element) => (
            <PatientConsutationRow
              key={element.id}
              stateConnectedUser={stateConnectedUser}
              dataState={element}
              onShowConsultationInformationClick={() => showConsultationInformationButtonHandler(element)}
            />
          ))}
        </div>
      ) : (
        <div
          style={
            isBigScreen
              ? { ...styles.dataStateRow, height: Rpx(70, true), fontSize: Rpx(25, true) }
              : { ...styles.dataStateRow, ...responsiveStyles.dataStateRow, fontSize: SRpx(13, true) }
          }
        >
          <p>La liste des consultations est vide.</p>
        </div>
      )}
      <div
        style={
          isBigScreen
            ? {
                ...styles.loadMoreButton,
                minHeight: Rpx(50, true),
                display: loadMoreButtonVisibility ? '' : 'none',
              }
            : {
                ...styles.loadMoreButton,
                ...responsiveStyles.loadMoreButton,
                minHeight: SRpx(30, true),
                display: loadMoreButtonVisibility ? '' : 'none',
              }
        }
        onKeyDown={loadConsultationsHandler}
        tabIndex={0}
        role="button"
        onClick={loadConsultationsHandler}
      >
        <AddIcon
          style={isBigScreen ? { ...styles.icon } : { ...styles.icon, ...responsiveStyles.icon }}
          width={isBigScreen ? Rpx(15, true) : SRpx(10, true)}
          height={isBigScreen ? Rpx(15, true) : SRpx(10, true)}
        />
        <p
          style={
            isBigScreen
              ? { ...styles.loadMoreButtonParagraph, fontSize: Rpx(28, true) }
              : {
                  ...styles.loadMoreButtonParagraph,
                  ...responsiveStyles.loadMoreButtonParagraph,
                  fontSize: SRpx(15, true),
                }
          }
        >
          {isBigScreen ? 'Afficher plus...' : 'Afficher plus'}
        </p>
      </div>
    </div>
  );
};

const styles: StyleSheet = {
  container: {
    maxHeight: '75vh',
    position: 'relative',
    margin: `2vw 2vw 0`,
    outline: `${Rpx(10)} solid ${DocColor.DARKBLUE}`,
    borderRadius: Rpx(60),
    minHeight: Rpx(100),
    overflow: 'hidden',
  },
  title: {
    color: DocColor.DARKBLUE,
    fontWeight: 800,
    margin: '2vw 2vw 0 2vw',
  },
  dataStateRow: {
    textAlign: 'center',
    fontWeight: 500,
    whiteSpace: 'nowrap',
    justifyContent: 'center',
    color: DocColor.COALBLACK,
    height: Rpx(70),
    minHeight: Rpx(70),
    background: DocColor.LIGHTGRAY,
    borderRadius: Rpx(5),
    boxSizing: 'border-box',
    margin: '2vw',
  },
  loadMoreButton: {
    flexDirection: 'row',
    width: 'fit-content',
    backgroundColor: DocColor.DARKBLUE,
    color: 'white',
    borderRadius: `${Rpx(20)} ${Rpx(20)} 0 0`,
    cursor: 'pointer',
    padding: `0px ${Rpx(20)}`,
    margin: '0 auto',
  },
  loadMoreButtonParagraph: {
    margin: `auto 0px auto ${Rpx(10)}`,
    fontWeight: 500,
  },
  icon: {
    border: `${Rpx(2)} solid white`,
    borderRadius: Rpx(20),
    padding: Rpx(5),
    margin: 'auto 0',
  },
};

const responsiveStyles: StyleSheet = {
  container: {
    position: 'relative',
    width: `85%`,
    margin: `0 auto ${SRpx(20)} auto`,
  },
  title: {
    marginBottom: SRpx(15),
  },
  dataStateRow: {
    width: '95%',
    height: SRpx(45),
    margin: `${SRpx(20)} auto 0 auto`,
    fontWeight: 500,
    color: DocColor.DARKBLUE,
    borderRadius: SRpx(5),
  },
  loadMoreButton: {
    borderRadius: SRpx(5),
    padding: `${Rpx(10)} ${Rpx(50)}`,
  },
  loadMoreButtonParagraph: {
    margin: `auto 0px auto ${SRpx(5)}`,
    fontWeight: 600,
  },
  icon: {
    border: `${SRpx(1)} solid white`,
    borderRadius: SRpx(10),
    padding: SRpx(2),
  },
};
