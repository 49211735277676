import React, { useEffect, useRef, useState } from 'react';
import { useMediaQuery } from 'react-responsive';
import { DocColor, StyleSheet } from '../../assets/styles/constantStyles';
import { bigScreenBreakpoint } from '../domain/entities/ReactResponsiveBreakpoints';
import { Rpx, SRpx } from '../usecases/helpers/DimensionsConverter';

type CustomInputProps = {
  inputName?: string;
  inputNameWidth?: string | number;
  inputNameStyle?: React.CSSProperties;
  unit?: string;
  type?: string;
  disabled?: boolean;
  style?: React.CSSProperties;
  width?: string | number;
  height?: string | number;
  OnMistake?: boolean;
  min?: string | number;
  onChange?: (val: React.ChangeEvent<HTMLInputElement>) => void;
  defaultValue?: string;
  placeholder?: string;
  options?: { id: string; value: string }[];
  loadOptionsMinLength?: number;
  onOptionChange?: (id: string, value: string) => void;
  dropDownMaxHeight?: string | number;
};

export const CustomInput = ({
  inputName,
  inputNameStyle,
  inputNameWidth,
  unit,
  disabled,
  width,
  height,
  type,
  style,
  placeholder,
  OnMistake,
  defaultValue,
  min,
  onChange,
  options,
  loadOptionsMinLength,
  onOptionChange,
  dropDownMaxHeight,
}: CustomInputProps) => {
  // isBigScreen working as a media breakpoint
  const isBigScreen = useMediaQuery({ query: `(min-width: ${bigScreenBreakpoint}px)` });

  const inputRef = useRef<HTMLInputElement>(null);
  const containerRef = useRef<HTMLDivElement>(null);
  const [isDropDownClosed, setIsDropDownClosed] = useState<boolean>(true);
  const [optionsState, setOptionsState] = useState<{ id: string; value: string }[]>();

  useEffect(() => {
    function handleClickOutside(event: MouseEvent): void {
      if (
        options &&
        containerRef.current &&
        !containerRef.current.contains(event.target as Node) &&
        window.innerWidth - window.innerWidth / 100 > event.clientX
      ) {
        setIsDropDownClosed(true);
      }
    }
    // Bind the event listener
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener('mousedown', handleClickOutside);
    };
  });
  /// /////////////////////////////////////////////////////////////////////

  useEffect(() => {
    if (options) {
      setOptionsState([...options]);
    }
  }, [options]);

  useEffect(() => {
    if (inputRef.current !== null) {
      if (defaultValue) {
        inputRef.current.value = defaultValue;
        const event = new Event('change', { bubbles: true });
        inputRef.current.dispatchEvent(event);
      } else {
        inputRef.current.value = '';
      }
    }
  }, [defaultValue]);

  const onOptionClickHandler = (obj: string) => {
    if (inputRef.current !== null) {
      inputRef.current.value = obj;
      setIsDropDownClosed(true);
    }
  };
  return (
    <div ref={containerRef} style={{ width }}>
      <div style={styles.inputDiv}>
        {inputName && (
          <p
            style={
              isBigScreen
                ? {
                    ...styles.inputParagraph,
                    fontSize: Rpx(27, true),
                    ...inputNameStyle,
                    minWidth: inputNameWidth && inputNameWidth,
                  }
                : {
                    ...styles.inputParagraph,
                    ...responsiveStyles.inputParagraph,
                    fontSize: SRpx(14, true),
                    ...inputNameStyle,
                    minWidth: inputNameWidth && inputNameWidth,
                  }
            }
          >
            {inputName}
          </p>
        )}
        <div style={{ position: 'relative', width: '100%' }}>
          <div style={{ flexDirection: 'row', gap: isBigScreen ? Rpx(5) : SRpx(3) }}>
            <input
              onWheel={(e) => (e.target as HTMLElement).blur()}
              ref={inputRef}
              onClick={(val) => {
                if (val.detail === 1) {
                  setIsDropDownClosed((v) => !v);
                }
              }}
              min={min}
              placeholder={placeholder}
              spellCheck="false"
              disabled={disabled}
              type={unit ? 'number' : type || 'text'}
              style={
                isBigScreen
                  ? {
                      ...styles.input,
                      height: height || Rpx(70, true),
                      fontSize: Rpx(25, true),
                      ...style,
                      borderColor: OnMistake ? DocColor.LIGHTRED : DocColor.CLOUDYWHITE,
                    }
                  : {
                      ...styles.input,
                      ...responsiveStyles.input,
                      height: height || SRpx(30, true),
                      fontSize: SRpx(13, true),
                      ...style,
                      borderColor: OnMistake ? DocColor.LIGHTRED : DocColor.CLOUDYWHITE,
                    }
              }
              onChange={(v) => {
                if (optionsState) {
                  if (onOptionChange) {
                    onOptionChange('', v.target.value);
                  }
                  const tempFilteredOptions = optionsState?.filter((obj) =>
                    obj.value.toLowerCase().includes(v.target.value.toLowerCase()),
                  );
                  if (v.target.value === '' || tempFilteredOptions.length === 0) {
                    setIsDropDownClosed(true);
                  } else if (
                    tempFilteredOptions.length === 1 &&
                    tempFilteredOptions[0].value === v.target.value.toLowerCase()
                  ) {
                    setIsDropDownClosed(true);
                    if (onOptionChange) {
                      onOptionChange(tempFilteredOptions[0].id, tempFilteredOptions[0].value);
                    }
                  } else {
                    setIsDropDownClosed(false);
                  }
                }
                if (onChange) {
                  onChange(v);
                }
              }}
            />
            <div
              style={{
                ...style,
                ...styles.inputUnit,
                display: unit ? '' : 'none',
              }}
            >
              <p>{unit}</p>
            </div>
          </div>

          {optionsState &&
            (loadOptionsMinLength && inputRef.current !== null
              ? inputRef.current.value.length >= loadOptionsMinLength
              : true) && (
              <div
                style={{
                  ...styles.dropDownSelector,
                  border: `${isDropDownClosed || disabled ? '0' : Rpx(10)} solid ${DocColor.MEDIUMBLUE}`,
                  ...style,
                  maxHeight: isDropDownClosed || disabled ? Rpx(0) : dropDownMaxHeight || Rpx(140),
                  width,
                  height: 'fit-content',
                  marginTop: height || Rpx(70, true),
                  padding: !isDropDownClosed ? Rpx(20) : '0',
                }}
              >
                <div style={{ overflow: 'auto' }}>
                  {optionsState
                    .filter((obj) =>
                      obj.value
                        .toLowerCase()
                        .includes(inputRef.current !== null ? inputRef.current.value.toLowerCase() : ''),
                    )
                    .map(({ id, value }) => (
                      <div key={id}>
                        <div style={styles.optionLine} />
                        <div
                          style={{ ...styles.optionSelector, height: height || Rpx(70, true) }}
                          onKeyDown={() => {
                            if (onOptionChange) {
                              onOptionChange(id, value);
                            }
                            onOptionClickHandler(value);
                          }}
                          tabIndex={0}
                          role="button"
                          onClick={() => {
                            if (onOptionChange) {
                              onOptionChange(id, value);
                            }
                            onOptionClickHandler(value);
                          }}
                        >
                          <p style={{ fontSize: 'inherit', fontWeight: 700 }}>{value}</p>
                        </div>
                      </div>
                    ))}
                </div>
              </div>
            )}
        </div>
      </div>
    </div>
  );
};

const styles: StyleSheet = {
  inputDiv: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  inputParagraph: {
    fontWeight: 500,
    marginRight: Rpx(20),
    whiteSpace: 'nowrap',
  },
  input: {
    maxWidth: '100%',
    background: DocColor.LIGHTGRAY,
    border: `${Rpx(4)} solid ${DocColor.LIGHTGRAY}`,
    borderRadius: Rpx(9),
    outline: 'none',
    caretColor: DocColor.DARKBLUE,
    width: '100%',
    WebkitAppearance: 'none',
  },
  inputUnit: {
    fontSize: Rpx(20, true),
    fontWeight: 500,
    width: '45%',
    color: DocColor.DARKBLUE,
    justifyContent: 'center',
    textAlign: 'center',
    background: DocColor.LIGHTGRAY,
    borderRadius: Rpx(9),
  },
  dropDownSelector: {
    height: 'fit-content',
    background: DocColor.LIGHTGRAY,
    position: 'absolute',
    inset: 0,
    overflow: 'hidden',
    transition: 'all .3s ease',
    borderRadius: `0 0 ${Rpx(30)} ${Rpx(30)}`,
    zIndex: 3,
  },
  optionLine: {
    width: '80%',
    height: Rpx(2),
    margin: '0 auto',
    background: DocColor.GRAY,
  },
  optionSelector: {
    flexDirection: 'row',
    margin: '0 auto',
    alignItems: 'center',
    cursor: 'pointer',
  },
};

const responsiveStyles: StyleSheet = {
  inputParagraph: {
    fontWeight: 600,
    marginRight: SRpx(10),
  },
  input: {
    border: `${SRpx(2)} solid ${DocColor.LIGHTGRAY}`,
    borderRadius: SRpx(5),
  },
};
