import React, { useEffect, useState } from 'react';
import { DocColor, StyleSheet } from '../../assets/styles/constantStyles';
import { RectangleIcon } from '../../assets/svg/RectangleIcon';
import { ToBottomArrow2 } from '../../assets/svg/ToBottomArrow2';
import { Rpx } from '../usecases/helpers/DimensionsConverter';

type CustomSelectorProps = {
  optionsArray: string[];
  optionsColor?: string;
  optionsSize?: number;
  inputName?: string;
  OnMistake?: boolean;
  inputNameWidth?: string | number;
  containerDisabled?: boolean;
  disabled?: boolean;
  style?: React.CSSProperties;
  width?: string | number;
  height?: string | number;
  placeholder: string;
  value?: string;
  onChange?: (value: string) => void;
};

export const CustomSelector = ({
  optionsArray,
  optionsColor,
  optionsSize,
  inputName,
  inputNameWidth,
  OnMistake,
  containerDisabled,
  disabled,
  width,
  height,
  style,
  placeholder,
  value,
  onChange,
}: CustomSelectorProps) => {
  const [inputValue, setInputValue] = useState<string>('');
  const [isDropDownClosed, setIsDropDownClosed] = useState<boolean>(true);

  useEffect(() => {
    if (value === '' || value === undefined) {
      setInputValue(placeholder);
    } else {
      setInputValue(value);
    }
  }, [value]);

  const onOptionClickHandler = (obj: string) => {
    if (onChange) {
      onChange(obj);
    }
    setInputValue(obj);
    setIsDropDownClosed(true);
  };
  return (
    <div style={{ width }}>
      <div style={styles.selectorDiv}>
        {inputName && (
          <p
            style={{
              ...styles.selectorName,
              fontSize: Rpx(27, true),
              minWidth: inputNameWidth && inputNameWidth,
              color: containerDisabled ? DocColor.GRAY : 'unset',
            }}
          >
            {inputName}
          </p>
        )}

        <div style={{ width: '100%', position: 'relative' }}>
          <div
            style={{
              ...styles.selector,
              height: height || Rpx(70, true),
              fontSize: Rpx(25, true),
              ...style,
              borderColor: OnMistake ? DocColor.LIGHTRED : DocColor.LIGHTGRAY,
              color: containerDisabled || inputValue === placeholder ? DocColor.GRAY : optionsColor,
            }}
            onKeyDown={() => setIsDropDownClosed((v) => !v)}
            tabIndex={0}
            role="button"
            onClick={() => setIsDropDownClosed((v) => !v)}
          >
            <p>{inputValue}</p>
            <ToBottomArrow2
              style={{ ...styles.arrowIcon, display: disabled ? 'none' : '' }}
              width={Rpx(16, true)}
              height={Rpx(16, true)}
              color={containerDisabled || inputValue === placeholder ? DocColor.GRAY : DocColor.MEDIUMBLUE}
            />
          </div>
          <div
            style={{
              ...styles.dropDownSelector,
              maxHeight: isDropDownClosed || disabled ? Rpx(0) : Rpx(600),
              margin: `${height || Rpx(70)} auto 0 auto`,
              border: `${isDropDownClosed || disabled ? '0' : Rpx(10)} solid ${DocColor.MEDIUMBLUE}`,
            }}
          >
            {optionsArray.map((obj) => (
              <div key={obj}>
                <div style={styles.optionLine} />
                <div
                  style={styles.optionSelector}
                  onKeyDown={() => {
                    onOptionClickHandler(obj);
                  }}
                  tabIndex={0}
                  role="button"
                  onClick={() => {
                    onOptionClickHandler(obj);
                  }}
                >
                  <RectangleIcon
                    style={{ marginRight: Rpx(10) }}
                    color={DocColor.MEDIUMBLUE}
                    width={Rpx(10, true)}
                    height={Rpx(10, true)}
                  />
                  <p
                    style={{
                      color: optionsColor,
                      fontSize: optionsSize ? Rpx(optionsSize, true) : Rpx(25, true),
                      fontWeight: 700,
                    }}
                  >
                    {obj}
                  </p>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

const styles: StyleSheet = {
  selectorDiv: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  selectorName: {
    fontWeight: 500,
    marginRight: Rpx(20),
    whiteSpace: 'nowrap',
  },
  selector: {
    position: 'relative',
    flexDirection: 'row',
    maxWidth: '100%',
    background: DocColor.LIGHTGRAY,
    border: `${Rpx(4)} solid ${DocColor.LIGHTGRAY}`,
    borderRadius: Rpx(9),
    outline: 'none',
    caretColor: DocColor.DARKBLUE,
    fontWeight: 700,
    width: '100%',
    cursor: 'pointer',
    alignItems: 'center',
    justifyContent: 'center',
  },
  dropDownSelector: {
    height: 'fit-content',
    width: '95%',
    background: DocColor.LIGHTGRAY,
    position: 'absolute',
    inset: 0,
    overflow: 'hidden',
    transition: 'all .3s ease',
    borderRadius: `0 0 ${Rpx(30)} ${Rpx(30)}`,
    zIndex: 3,
  },
  optionLine: {
    width: '80%',
    height: Rpx(2),
    margin: '0 auto',
    background: DocColor.GRAY,
  },
  optionSelector: {
    flexDirection: 'row',
    margin: '0 auto',
    alignItems: 'center',
    height: Rpx(70),
    cursor: 'pointer',
  },
  arrowIcon: {
    position: 'absolute',
    inset: 0,
    margin: `auto ${Rpx(10)} ${Rpx(10)} auto`,
  },
};
