import { Attachment, hardCodedAttachments } from '../../domain/entities/Attachments';
import { ConsultationNotes } from '../../domain/entities/ConsultationNotes';
import { ConsultationReason } from '../../domain/entities/ConsultationReason';
import { Examination } from '../../domain/entities/Examination';
import { ExaminationToRequest } from '../../domain/entities/ExaminationToRequest';
import { Interrogation } from '../../domain/entities/Interrogation';
import { MedicalCertificate } from '../../domain/entities/MedicalCertificate';
import { Medicine } from '../../domain/entities/Medicine';
import { Mesures } from '../../domain/entities/Mesures';
import {
  hardCodedEmptyPatientConsultation,
  hardCodedPatientsConsultations,
  hardCodedPatientsConsultations1,
  hardCodedSpecificPatientConsultations,
  PatientConsultation,
} from '../../domain/entities/PatientConsultation';
import { Prescription } from '../../domain/entities/Prescriptions';
import { TodaysConsultationsStats } from '../../domain/entities/TodaysConsultationsStats';
import { PatientConsultationsInteractor } from '../../domain/gateways/PatientConsultations.interactor';

export class InMemoryPatientConsultationsInteractor implements PatientConsultationsInteractor {
  loadPatientsConsultations(patientId?: string, jumpToNextConsultations?: number): Promise<PatientConsultation[]> {
    if (patientId !== undefined) {
      return Promise.resolve(hardCodedSpecificPatientConsultations);
    }
    if (jumpToNextConsultations !== undefined && jumpToNextConsultations === 0) {
      return Promise.resolve(hardCodedPatientsConsultations);
    }
    if (jumpToNextConsultations !== undefined && jumpToNextConsultations === 1) {
      return Promise.resolve(hardCodedPatientsConsultations1);
    }
    if (jumpToNextConsultations !== undefined && jumpToNextConsultations > 1) {
      return Promise.resolve([]);
    }
    return Promise.resolve(hardCodedPatientsConsultations);

    // if patientId is undefined it means you have to load all patients consultations
    // if jumpToNextConsultations is undefined it means you have to load without offsets
  }

  loadPatientConsultation(): Promise<PatientConsultation> {
    return Promise.resolve(hardCodedEmptyPatientConsultation);
  }

  fetchPatientsConsultations(): Promise<PatientConsultation[]> {
    throw new Error('Method not implemented.');
  }

  getPatientConsultationNumber(): Promise<number> {
    throw new Error('Method not implemented.');
  }

  getTodaysConsultationsStats(): Promise<TodaysConsultationsStats> {
    throw new Error('Method not implemented.');
  }

  insertPatientsConsultation(): Promise<PatientConsultation> {
    throw new Error('Method not implemented.');
  }

  deletePatientConsultation(): Promise<PatientConsultation> {
    throw new Error('Method not implemented.');
  }

  loadAttachments(): Promise<Attachment[]> {
    return Promise.resolve(hardCodedAttachments);
  }

  insertAttachment(): Promise<Attachment> {
    return Promise.resolve(hardCodedAttachments[0]);
  }

  sendAttachmentByMail(): Promise<boolean> {
    throw new Error('Method not implemented.');
  }

  loadConsultationReason(): Promise<ConsultationReason> {
    throw new Error('Method not implemented.');
  }

  insertConsultationReason(): Promise<ConsultationReason> {
    throw new Error('Method not implemented.');
  }

  loadInterrogation(): Promise<Interrogation> {
    throw new Error('Method not implemented.');
  }

  insertInterrogation(): Promise<Interrogation> {
    throw new Error('Method not implemented.');
  }

  loadConsultationNotes(): Promise<ConsultationNotes> {
    throw new Error('Method not implemented.');
  }

  insertConsultationNotes(): Promise<ConsultationNotes> {
    throw new Error('Method not implemented.');
  }

  loadMesures(): Promise<Mesures> {
    throw new Error('Method not implemented.');
  }

  insertMesures(): Promise<Mesures> {
    throw new Error('Method not implemented.');
  }

  loadExaminations(): Promise<Examination[]> {
    throw new Error('Method not implemented.');
  }

  insertExaminations(): Promise<Examination[]> {
    throw new Error('Method not implemented.');
  }

  loadExaminationsToRequest(): Promise<ExaminationToRequest[]> {
    throw new Error('Method not implemented.');
  }

  insertExaminationsToRequest(): Promise<ExaminationToRequest[]> {
    throw new Error('Method not implemented.');
  }

  loadPrescriptions(): Promise<Prescription[]> {
    throw new Error('Method not implemented.');
  }

  insertPrescriptions(): Promise<Prescription[]> {
    throw new Error('Method not implemented.');
  }

  fetchMedicines(): Promise<Medicine[]> {
    throw new Error('Method not implemented.');
  }

  loadMedicalCertificate(): Promise<MedicalCertificate> {
    throw new Error('Method not implemented.');
  }

  insertMedicalCertificate(): Promise<MedicalCertificate> {
    throw new Error('Method not implemented.');
  }
}
