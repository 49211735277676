import React, { useEffect, useRef } from 'react';
import { useMediaQuery } from 'react-responsive';
import { DocColor, StyleSheet } from '../../assets/styles/constantStyles';
import { useLoaderContext } from '../configuration/loaderContextProvider';
import { bigScreenBreakpoint } from '../domain/entities/ReactResponsiveBreakpoints';
import { Rpx, SRpx } from '../usecases/helpers/DimensionsConverter';

export const LoaderOverlay = () => {
  // isBigScreen working as a media breakpoint
  const isBigScreen = useMediaQuery({ query: `(min-width: ${bigScreenBreakpoint}px)` });

  const loaderRef = useRef<HTMLDivElement>(null);
  const loader = useLoaderContext();

  useEffect(() => {
    const loaderEffect = new KeyframeEffect(
      loaderRef.current,
      [{ transform: 'rotate(0deg)' }, { transform: 'rotate(360deg)' }],
      {
        duration: 1000,
        fill: 'forwards',
        iterations: Infinity,
      },
    );
    const loaderAnimation = new Animation(loaderEffect, document.timeline);
    loaderAnimation.play();
  }, []);

  return (
    <div
      style={{
        ...styles.container,
        display: loader.message === '' ? 'none' : '',
      }}
    >
      <div
        ref={loaderRef}
        style={isBigScreen ? { ...styles.loader } : { ...styles.loader, ...responsiveStyles.loader }}
      />
      <p style={isBigScreen ? { ...styles.paragraph } : { ...styles.paragraph, ...responsiveStyles.paragraph }}>
        {loader.message === '_' ? 'Chargement en cours' : loader.message}
      </p>
    </div>
  );
};

const styles: StyleSheet = {
  container: {
    position: 'fixed',
    inset: 0,
    width: '100%',
    height: '100%',
    backgroundColor: DocColor.QUARTERTRANSPARENTBLACK,
    zIndex: 6,
    justifyContent: 'center',
    alignItems: 'center',
  },
  loader: {
    width: Rpx(120),
    height: Rpx(120),
    borderRadius: '50%',
    display: 'inline-block',
    border: `${Rpx(10)} solid white`,
    boxSizing: 'border-box',
    borderBottomColor: 'transparent',
  },
  paragraph: {
    fontSize: Rpx(50),
    fontWeight: 500,
    textAlign: 'center',
    marginTop: Rpx(40),
    width: '90%',
    color: 'white',
    cursor: 'default',
    letterSpacing: Rpx(2),
  },
};

const responsiveStyles: StyleSheet = {
  loader: {
    width: SRpx(60),
    height: SRpx(60),
    border: `${SRpx(5)} solid white`,
  },
  paragraph: {
    fontSize: SRpx(25),
    marginTop: SRpx(20),
    letterSpacing: SRpx(1),
  },
};
