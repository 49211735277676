import React from 'react';

export const IESupport = () => (
  <div
    style={{
      width: '100%',
      margin: '0 auto',
      textAlign: 'center',
      marginTop: '5%',
    }}
  >
    <h3>Nicht unterstützter Browser</h3>
    <p>Vielen Dank für Ihr Interesse an der Fayonation Plattform.</p>
    <p style={{ marginLeft: '7em', marginRight: '7em' }}>
      Es scheint als würden Sie Internet Explorer benutzen. Um die Sicherheit Ihrer Daten zu gewährleisten ist es leider
      nicht möglich sich mit dem Internet Explorer bei unserer Platttform anzumelden.
    </p>
    <p>Um fortzufahren bitten wir Sie auf einen sicheren und unterstützten Browser zu wechseln.</p>
    <div
      style={{
        display: 'flex',
        marginTop: '1em',
      }}
    >
      <div style={{ flex: 1 }} />
      <a style={{ flex: 1 }} rel="noreferrer" target="_blank" href="https://www.google.com/chrome/">
        <img
          alt="chromeIcon"
          style={{ backgroundSize: 'cover', width: 100, height: 100 }}
          src={require('../../assets/images/browsers/chrome.svg').default}
        />
        <div>Chrome</div>
      </a>
      <a style={{ flex: 1 }} rel="noreferrer" target="_blank" href="https://www.mozilla.org/en-US/firefox/new/">
        <img
          alt="firefox"
          style={{ width: 100, height: 100 }}
          src={require('../../assets/images/browsers/firefox.svg').default}
        />
        <div>Firefox</div>
      </a>
      <a style={{ flex: 1 }} rel="noreferrer" target="_blank" href="https://www.microsoft.com/en-us/edge">
        <img
          alt="edge"
          style={{ backgroundSize: 'cover', width: 100, height: 100 }}
          src={require('../../assets/images/browsers/edge.svg').default}
        />
        <div>Edge</div>
      </a>
      <div style={{ flex: 1 }} />
    </div>
  </div>
);
