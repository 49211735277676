import { Action } from 'redux-actions';
import {
  loadUserSuccess,
  sendAutoSignInRequest,
  sendAutoSignInSuccess,
  sendSignInSuccess,
  sendSignOutFailure,
  sendSignOutSuccess,
} from './Auth.actions';
import { createTypedHandler, handleTypedActions } from '../../common/usecases/helpers/actions.helper';
import { AuthState } from '../configuration/Auth.state';
import { User } from '../domain/entities/User';
import { updatePasswordSuccess, updateUsernameSuccess } from '../../settingsContext/usecases/UserSettings.actions';

const defaultState: AuthState = {
  connectedUser: undefined,
  tryingToSignIn: undefined,
  // authTokens: undefined,
};
const updateConnectedUserReducer = (state: AuthState, action: Action<User | undefined>) => ({
  ...state,
  connectedUser: action.payload,
});

const sendSignInReducer = (state: AuthState) => {
  const newState: AuthState = { ...state, /* authTokens: action.payload, */ tryingToSignIn: false };
  return newState;
};

const tryAutoSignInReducer = (state: AuthState) => {
  const newState: AuthState = { ...state, tryingToSignIn: true };
  return newState;
};

const sendSignOutReducer = () => ({ ...defaultState });
export const authReducer = handleTypedActions(
  [
    createTypedHandler(loadUserSuccess, updateConnectedUserReducer),
    createTypedHandler(updateUsernameSuccess, updateConnectedUserReducer),
    createTypedHandler(updatePasswordSuccess, updateConnectedUserReducer),
    createTypedHandler(sendSignInSuccess, sendSignInReducer),
    createTypedHandler(sendAutoSignInSuccess, sendSignInReducer),
    createTypedHandler(sendAutoSignInRequest, tryAutoSignInReducer),
    createTypedHandler(sendSignOutSuccess, sendSignOutReducer),
    createTypedHandler(sendSignOutFailure, sendSignOutReducer),
  ],
  defaultState,
);
