import React, { useEffect, useRef, useState } from 'react';
import { DispatchAsyncResult } from 'react-redux-dispatch-async/lib/dispatchAsync';
import { useMediaQuery } from 'react-responsive';
import { DocColor, StyleSheet } from '../../../../assets/styles/constantStyles';
import { CheckMarkIcon } from '../../../../assets/svg/CheckMarkIcon';
import { EditIcon } from '../../../../assets/svg/EditIcon';
import { useNotificationContext } from '../../../../common/configuration/notificationContextProvider';
import { bigScreenBreakpoint } from '../../../../common/domain/entities/ReactResponsiveBreakpoints';
import { ConfirmOverlay } from '../../../../common/ui/ConfirmOverlay';
import { PatientAdditionModificationContainer } from '../../../../common/ui/PatientAdditionModificationContainer';
import { Rpx, SRpx } from '../../../../common/usecases/helpers/DimensionsConverter';
import { Patient } from '../../../domain/entities/Patient';

type PersonalInformationComponentProps = {
  recievedPatient: Patient;
  setPatient: React.Dispatch<React.SetStateAction<Patient>>;
  updatePatientAsync(newPatient: Patient): Promise<DispatchAsyncResult>;
};

export const PersonalInformationComponent = ({
  recievedPatient,
  setPatient,
  updatePatientAsync,
}: PersonalInformationComponentProps) => {
  // isBigScreen working as a media breakpoint
  const isBigScreen = useMediaQuery({ query: `(min-width: ${bigScreenBreakpoint}px)` });

  const notification = useNotificationContext();
  const containerRef = useRef<HTMLDivElement>(null);
  const [onEdit, setOnEdit] = useState<boolean>(false);
  const [isConfirmFragmentClosed, setIsConfirmFragmentClosed] = useState<boolean>(true);
  const [filledData, setFilledData] = useState<Patient>(recievedPatient);
  const [isEmailValid, setIsEmailValid] = useState<boolean>(false);
  const [initRender, setInitRender] = useState<boolean>(true);
  const [mistakesArray, setmistakesArray] = useState<string[]>([]);

  // this is only for handling click outside the component, to close it
  useEffect(() => {
    function handleClickOutside(event: MouseEvent): void {
      if (
        containerRef.current &&
        !containerRef.current.contains(event.target as Node) &&
        window.innerWidth - window.innerWidth / 100 > event.clientX
      ) {
        if (onEdit) {
          containerRef.current.scrollIntoView({
            behavior: 'smooth',
            block: 'center',
            inline: 'center',
          });
          onEditClickHandler();
        }
      }
    }
    // Bind the event listener
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener('mousedown', handleClickOutside);
    };
  });
  /// /////////////////////////////////////////////////////////////////////

  useEffect(() => {
    if (recievedPatient) {
      setFilledData({ ...recievedPatient });
    }
  }, [recievedPatient]);

  const onEditClickHandler = () => {
    if (onEdit) {
      if (
        isEmailValid &&
        filledData.firstName !== '' &&
        filledData.lastName !== '' &&
        filledData.birthDate !== null &&
        filledData.familySituation !== null &&
        filledData.phone !== '' &&
        filledData.identityCode !== '' &&
        filledData.assurance !== ''
      ) {
        if (
          recievedPatient.gender === filledData.gender &&
          recievedPatient.firstName === filledData.firstName &&
          recievedPatient.lastName === filledData.lastName &&
          recievedPatient.birthDate === filledData.birthDate &&
          recievedPatient.familySituation === filledData.familySituation &&
          recievedPatient.address === filledData.address &&
          recievedPatient.email === filledData.email &&
          recievedPatient.phone === filledData.phone &&
          recievedPatient.identityCode === filledData.identityCode &&
          recievedPatient.profession === filledData.profession &&
          recievedPatient.assurance === filledData.assurance &&
          recievedPatient.nationality === filledData.nationality
        ) {
          setOnEdit(false);
        } else {
          setIsConfirmFragmentClosed(false);
        }
      }
    } else {
      setOnEdit(true);
      setInitRender(false);
    }
  };

  const onConfirmClick = () => {
    updatePatientAsync(filledData).then((res) => {
      setmistakesArray([]);
      if (res.success) {
        if (res.result.email === 'exists') {
          setmistakesArray((element) => [...element, 'Cette adresse email existe déjà.']);
        }
        if (res.result.phone === 'exists') {
          setmistakesArray((element) => [...element, 'Cette numéro de téléphone est existe déjà.']);
        }
        if (res.result.identityCode === 'exists') {
          setmistakesArray((element) => [...element, 'Cette CIN / Numéro passeport Maladie est existe déjà. ']);
        }
        if (res.result.email !== 'exists' && res.result.phone !== 'exists' && res.result.identityCode !== 'exists') {
          setPatient(res.result);
          setOnEdit(false);
          notification.setOnSuccess({
            message: `Le patient est modifié avec succès!`,
          });
          containerRef.current?.scrollIntoView({
            behavior: 'smooth',
            block: 'center',
            inline: 'center',
          });
        }
      }
    });
    setIsConfirmFragmentClosed(true);
  };

  const onCloseClick = () => {
    if (recievedPatient) {
      setOnEdit(false);
      setIsConfirmFragmentClosed(true);
      setFilledData(recievedPatient);
      containerRef.current?.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
        inline: 'center',
      });
    }
  };
  return (
    <div style={!isBigScreen ? { ...responsiveStyles.container } : {}} ref={containerRef}>
      <ConfirmOverlay
        message="Êtes-vous sûr de vouloir mettre à jour les informations personnelles du patient ?"
        onConfirmClick={onConfirmClick}
        onCloseClick={onCloseClick}
        isClosedState={isConfirmFragmentClosed}
        setIsClosedState={setIsConfirmFragmentClosed}
      />
      <PatientAdditionModificationContainer
        isEmailValid={isEmailValid}
        setIsEmailValid={setIsEmailValid}
        disabled={!onEdit}
        patientData={filledData}
        setPatientData={setFilledData}
        ThemeColor={onEdit ? DocColor.MEDIUMBLUE : DocColor.DARKBLUE}
        onMistakes={!initRender}
        mistakesArray={mistakesArray}
      >
        <div
          style={
            isBigScreen
              ? { ...styles.editDiv, background: onEdit ? DocColor.MEDIUMBLUE : DocColor.DARKBLUE }
              : {
                  ...styles.editDiv,
                  ...responsiveStyles.editDiv,
                  background: onEdit ? DocColor.MEDIUMBLUE : DocColor.DARKBLUE,
                }
          }
          onKeyDown={onEditClickHandler}
          tabIndex={0}
          role="button"
          onClick={onEditClickHandler}
        >
          {!onEdit ? (
            <EditIcon
              style={{ margin: 'auto' }}
              width={isBigScreen ? Rpx(37) : SRpx(17)}
              height={isBigScreen ? Rpx(37) : SRpx(17)}
              color="white"
            />
          ) : (
            <CheckMarkIcon
              style={{ margin: 'auto' }}
              width={isBigScreen ? Rpx(25) : SRpx(13)}
              height={isBigScreen ? Rpx(25) : SRpx(13)}
              color="white"
            />
          )}
        </div>
      </PatientAdditionModificationContainer>
    </div>
  );
};

const styles: StyleSheet = {
  editDiv: {
    position: 'absolute',
    right: 0,
    top: 0,
    margin: 'auto',
    width: Rpx(77),
    height: Rpx(50),
    borderRadius: `0 ${Rpx(39)} 0 ${Rpx(24)}`,
    background: DocColor.DARKBLUE,
    cursor: 'pointer',
  },
};

const responsiveStyles: StyleSheet = {
  container: {
    margin: `0 auto ${SRpx(20)} auto`,
  },
  editDiv: {
    position: 'absolute',
    inset: 0,
    margin: '0 0 0 auto',
    width: SRpx(25),
    height: SRpx(25),
    borderRadius: SRpx(5),
    background: DocColor.DARKBLUE,
    cursor: 'pointer',
  },
};
