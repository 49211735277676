import { DispatchAsyncResult } from 'react-redux-dispatch-async/lib/dispatchAsync';
import { Route, Switch, useLocation } from 'react-router-dom';
import React, { useEffect } from 'react';
import { useMediaQuery } from 'react-responsive';
import { User } from '../../../authContext/domain/entities/User';
import { authNavScreens, unAuthScreens } from '../../configuration/NavScreens';
import { NavigationScreen } from '../../domain/entities/NavigationScreen';
import { SideBar } from '../components/SideBar';
import { TopBar } from '../components/TopBar';
import { NavigationPayload } from '../../domain/entities/NavigationPayload';
import { useLoaderContext } from '../../../common/configuration/loaderContextProvider';
import { getTokensToFetch } from '../../../common/adapter/fetch.helper';
import { bigScreenBreakpoint } from '../../../common/domain/entities/ReactResponsiveBreakpoints';
import { FooterSignature } from '../../../common/ui/FooterSignature';

interface NavigatorProps {
  stateTryingToSignIn: boolean | undefined;
  stateConnectedUser: User | undefined;
  navigateTo: (p: NavigationPayload) => void;
  sendSignOutAsync(): Promise<DispatchAsyncResult<void>>;
  navigateBack: () => void;
  // sendAutoSignInAsync(): Promise<DispatchAsyncResult<AuthTokens>>;
  loadUserSettingsAsync(): Promise<DispatchAsyncResult<void>>;
  loadUserAsync(userId: string): Promise<DispatchAsyncResult<User>>;
}
export const NavigatorDumb = ({
  stateTryingToSignIn,
  stateConnectedUser,
  sendSignOutAsync,
  navigateTo,
  navigateBack,
  loadUserSettingsAsync,
  loadUserAsync,
}: NavigatorProps) => {
  // isBigScreen working as a media breakpoint
  const isBigScreen = useMediaQuery({ query: `(min-width: ${bigScreenBreakpoint}px)` });

  const location = useLocation();
  const loader = useLoaderContext();

  useEffect(() => {
    getTokensToFetch().then((val: any) => {
      if (val?.userId && !stateConnectedUser) {
        loader.setMessage('En cours de connexion');
        loadUserSettingsAsync().finally(() => {
          loader.setMessage('');
        });
        loadUserAsync(val?.userId);
      }
    });
  }, []);

  useEffect(() => {
    if (stateTryingToSignIn) {
      loader.setMessage('En cours de connexion');
    } else {
      loader.setMessage('');
    }
  }, [stateTryingToSignIn]);

  useEffect(() => {
    if (
      stateConnectedUser &&
      authNavScreens.filter((e) => e.routeName === location.pathname.replace('/', '')).length === 0
    ) {
      loader.setMessage('');
      navigateTo({ routeName: 'Accueil' });
    } else if (!stateConnectedUser) {
      navigateTo({ routeName: 'SignIn' });
    }
  }, [stateConnectedUser, location.pathname]);

  const getRoutesRender = (screens: NavigationScreen[]) => (
    <div style={{ overflow: 'auto', flex: 1 }}>
      <Switch>
        {screens.map((src) => (
          <Route exact key={src.routeName} path={`/${src.routeName}`} component={src.component} />
        ))}
      </Switch>
    </div>
  );

  const AuthorizedView = React.useCallback(
    () => (
      <div
        style={{
          flexDirection: isBigScreen ? 'row' : 'column',
          position: 'relative',
          width: '100vw',
          height: '100vh',
        }}
      >
        <SideBar
          stateConnectedUser={stateConnectedUser}
          sendSignOutAsync={sendSignOutAsync}
          navigateTo={navigateTo}
          onBackClick={navigateBack}
        />
        <div style={{ position: 'relative', flex: 1, paddingTop: !isBigScreen ? '25vw' : 'unset' }}>
          <TopBar
            name={`${stateConnectedUser?.role === 'DOCTOR' ? 'Dr.' : ''}${stateConnectedUser?.firstName} ${
              stateConnectedUser?.lastName
            }`}
            onBackClick={navigateBack}
          />
          {getRoutesRender(authNavScreens)}
          <FooterSignature />
        </div>
      </div>
    ),
    [stateConnectedUser, location, isBigScreen],
  );
  const UnauthorizedView = React.useCallback(
    () => (
      <div
        style={{
          flexDirection: isBigScreen ? 'row' : 'column',
          position: 'relative',
          width: '100vw',
          height: '100vh',
        }}
      >
        <SideBar
          stateConnectedUser={stateConnectedUser}
          navigateTo={navigateTo}
          onUnauthorizedView
          onBackClick={navigateBack}
        />
        <div style={{ flex: 1, paddingTop: !isBigScreen ? '25vw' : 'unset' }}>
          {getRoutesRender(unAuthScreens)}
          <FooterSignature />
        </div>
      </div>
    ),
    [isBigScreen],
  );

  if (stateConnectedUser) {
    return AuthorizedView();
  }
  if (!stateConnectedUser) {
    return UnauthorizedView();
  }

  return <div>Chargement...</div>;
};
