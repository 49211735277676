import { connect } from 'react-redux';
import { dispatchAsync } from 'react-redux-dispatch-async';
import { Action } from 'redux';
import { PatientsListDumb } from './PatientsList.dumb';
import { fetchPatientsRequest, loadAllPatientsRequest } from '../../usecases/Patients.actions';
import { NavigationPayload } from '../../../navigationContext/domain/entities/NavigationPayload';
import { navigateToRequest } from '../../../navigationContext/usecases/navigation.actions';
import { AppState } from '../../../common/configuration/app.states';
import { getAllPatients } from '../../usecases/Patients.selectors';

const mapState = (state: AppState) => ({
  statePatients: getAllPatients(state),
});

const mapDispatch = (dispatch: (a: Action) => void) => ({
  loadAllPatientsAsync: () => dispatchAsync(dispatch, loadAllPatientsRequest()),
  fetchPatientsAsync: (value: string) => dispatchAsync(dispatch, fetchPatientsRequest(value)),
  navigateToAsync: (nav: NavigationPayload) => dispatch(navigateToRequest(nav)),
});

export const PatientsListSmart = connect(mapState, mapDispatch)(PatientsListDumb);
