import React from 'react';
import { useMediaQuery } from 'react-responsive';
import { DocColor, StyleSheet } from '../../../../assets/styles/constantStyles';
import { Rpx, SRpx } from '../../../../common/usecases/helpers/DimensionsConverter';
import CommonFunctions from '../../../../common/usecases/helpers/CommonFunctions';
import { CardIcon } from '../../../../assets/svg/CardIcon';
import { User } from '../../../../authContext/domain/entities/User';
import { PatientConsultation } from '../../../../consultationsContext/domain/entities/PatientConsultation';
import { bigScreenBreakpoint } from '../../../../common/domain/entities/ReactResponsiveBreakpoints';

interface PatientConsultationRowProps {
  stateConnectedUser: User | undefined;
  dataState: PatientConsultation;
  onShowConsultationInformationClick: () => void;
}

export const PatientConsutationRow = ({
  stateConnectedUser,
  dataState,
  onShowConsultationInformationClick,
}: PatientConsultationRowProps) => {
  // isBigScreen working as a media breakpoint
  const isBigScreen = useMediaQuery({ query: `(min-width: ${bigScreenBreakpoint}px)` });

  const startDateTime = new Date(dataState.startDateTime);
  const endDateTime = new Date(dataState.endDateTime);
  return (
    <div style={isBigScreen ? { ...styles.container } : { ...styles.container, ...responsiveStyles.container }}>
      <p
        style={
          isBigScreen
            ? { ...styles.consultationNumParagraph, fontSize: Rpx(26, true) }
            : {
                ...styles.consultationNumParagraph,
                ...responsiveStyles.consultationNumParagraph,
                fontSize: SRpx(15, true),
              }
        }
      >
        Consultation N°{dataState.number ? dataState.number : '-'}
      </p>
      <div
        style={
          isBigScreen
            ? { ...styles.consultationDatesDiv }
            : { ...styles.consultationDatesDiv, ...responsiveStyles.consultationDatesDiv }
        }
      >
        <p
          style={{
            ...styles.consultationParagraph,
            fontSize: isBigScreen ? Rpx(25, true) : SRpx(15, true),
            width: '100%',
          }}
        >
          Date :<span style={styles.consultationSpan}>{CommonFunctions.formatDate(startDateTime)}</span>
        </p>
        <p style={{ ...styles.consultationParagraph, fontSize: isBigScreen ? Rpx(25, true) : SRpx(15, true) }}>
          {isBigScreen ? 'Début de la consultation :' : 'Début :'}
          <span style={styles.consultationSpan}>{`${startDateTime.getHours()}h${startDateTime.getMinutes()}`}</span>
        </p>
        <p style={{ ...styles.consultationParagraph, fontSize: isBigScreen ? Rpx(25, true) : SRpx(15, true) }}>
          {isBigScreen ? 'Fin de la consultation :' : 'Fin :'}
          <span style={styles.consultationSpan}>{`${endDateTime.getHours()}h${endDateTime.getMinutes()}`}</span>
        </p>
      </div>
      {stateConnectedUser?.role === 'DOCTOR' && (
        <div
          style={
            isBigScreen
              ? { ...styles.consultationButton }
              : { ...styles.consultationButton, ...responsiveStyles.consultationButton }
          }
          onClick={() => {
            onShowConsultationInformationClick();
          }}
          onKeyDown={() => {
            onShowConsultationInformationClick();
          }}
          tabIndex={0}
          role="button"
        >
          <CardIcon
            width={isBigScreen ? Rpx(40, true) : SRpx(20, true)}
            height={isBigScreen ? Rpx(40, true) : SRpx(20, true)}
            color={isBigScreen ? DocColor.MEDIUMBLUE : 'white'}
          />
          <p
            style={
              isBigScreen
                ? { ...styles.consultationButtonParagraph, fontSize: Rpx(25, true) }
                : {
                    ...styles.consultationButtonParagraph,
                    ...responsiveStyles.consultationButtonParagraph,
                    fontSize: SRpx(13, true),
                  }
            }
          >
            {isBigScreen ? 'Afficher le compte rendu de la consultation' : 'Afficher le compte rendu'}
          </p>
        </div>
      )}
    </div>
  );
};

const styles: StyleSheet = {
  container: {
    background: DocColor.LIGHTGRAY,
    borderRadius: `0 0 ${Rpx(50)} ${Rpx(50)}`,
    margin: `0 ${Rpx(50)} ${Rpx(20)} 0`,
  },
  consultationNumParagraph: {
    fontWeight: 600,
    background: DocColor.DARKBLUE,
    width: 'fit-content',
    padding: `${Rpx(5)} ${Rpx(25)}`,
    color: 'white',
    borderRadius: `0 0 ${Rpx(30)} 0`,
  },
  consultationDatesDiv: {
    flexFlow: 'row wrap',
    width: '100%',
    padding: `${Rpx(15)} 5%`,
    boxSizing: 'border-box',
    justifyContent: 'space-between',
  },
  consultationParagraph: {
    fontWeight: 600,
  },
  consultationSpan: {
    color: DocColor.MEDIUMBLUE,
  },
  consultationButton: {
    flexDirection: 'row',
    margin: `${Rpx(20)} auto`,
    cursor: 'pointer',
  },
  consultationButtonParagraph: {
    color: DocColor.MEDIUMBLUE,
    fontWeight: 700,
    marginLeft: Rpx(12),
  },
};

const responsiveStyles: StyleSheet = {
  container: {
    borderRadius: `0 0 ${SRpx(15)} ${SRpx(15)}`,
    margin: `0 0 ${SRpx(10)} 0`,
  },
  consultationNumParagraph: {
    padding: `${SRpx(2)} ${SRpx(15)}`,
    color: 'white',
    borderRadius: `0 0 ${SRpx(15)} 0`,
  },
  consultationDatesDiv: {
    padding: `${SRpx(8)} 8% 0 8%`,
  },
  consultationButton: {
    margin: `${SRpx(5)} auto ${SRpx(10)} auto`,
    borderRadius: SRpx(5),
    padding: `${SRpx(5)} ${SRpx(10)}`,
    background: DocColor.MEDIUMBLUE,
  },
  consultationButtonParagraph: {
    color: 'white',
    marginLeft: SRpx(6),
  },
};
