import React from 'react';
import { useMediaQuery } from 'react-responsive';
import { DocColor, StyleSheet } from '../../assets/styles/constantStyles';
import { ToBottomArrow } from '../../assets/svg/ToBottomArrow';
import { bigScreenBreakpoint } from '../domain/entities/ReactResponsiveBreakpoints';
import { Rpx, SRpx } from '../usecases/helpers/DimensionsConverter';

type FamilySituationSelectorProps = {
  style?: React.CSSProperties;
  onChange?: (value: string) => void;
  OnMistake?: boolean;
  width?: string | number;
  disabled?: boolean;
  value?: string;
};

export const FamilySituationSelector = ({
  style,
  onChange,
  disabled,
  width,
  OnMistake,
  value,
}: FamilySituationSelectorProps) => {
  // isBigScreen working as a media breakpoint
  const isBigScreen = useMediaQuery({ query: `(min-width: ${bigScreenBreakpoint}px)` });

  return (
    <div style={{ ...styles.inputDiv, width }}>
      <p
        style={
          isBigScreen
            ? {
                ...styles.inputParagraph,
                fontSize: Rpx(27, true),
              }
            : {
                ...styles.inputParagraph,
                ...responsiveStyles.inputParagraph,
                fontSize: SRpx(14, true),
              }
        }
      >
        Situation Familiale
      </p>
      <div style={isBigScreen ? { ...styles.selectorDiv } : { ...styles.selectorDiv, ...responsiveStyles.selectorDiv }}>
        <ToBottomArrow
          color="black"
          style={
            isBigScreen
              ? {
                  ...styles.selectorIcon,
                  width: Rpx(15, true),
                }
              : {
                  ...styles.selectorIcon,
                  ...responsiveStyles.selectorIcon,
                  width: SRpx(8, true),
                }
          }
        />
        <select
          value={value && value}
          defaultValue=""
          disabled={disabled}
          style={
            isBigScreen
              ? {
                  ...styles.selector,
                  height: Rpx(70, true),
                  fontSize: Rpx(25, true),
                  ...style,
                  borderColor: OnMistake ? DocColor.LIGHTRED : DocColor.CLOUDYWHITE,
                }
              : {
                  ...styles.selector,
                  ...responsiveStyles.selector,
                  height: SRpx(30, true),
                  fontSize: SRpx(13, true),
                  ...style,
                  borderColor: OnMistake ? DocColor.LIGHTRED : DocColor.CLOUDYWHITE,
                }
          }
          onChange={(v) => {
            if (onChange) {
              onChange(v.target.value);
            }
          }}
        >
          <option value="" disabled>
            choisir
          </option>
          <option value="married">marié</option>
          <option value="divorced">divorcé</option>
          <option value="single">célibataire</option>
        </select>
      </div>
    </div>
  );
};

const styles: StyleSheet = {
  container: {
    flexDirection: 'row',
  },
  selectorDiv: {
    width: '100%',
    marginLeft: Rpx(20),
    position: 'relative',
  },
  selector: {
    padding: `0 ${Rpx(30)}`,
    cursor: 'pointer',
    outline: 'none',
    fontWeight: 500,
    border: `${Rpx(4)} solid`,
    borderRadius: Rpx(9),
    background: DocColor.LIGHTGRAY,
  },
  selectorIcon: {
    position: 'absolute',
    inset: 0,
    margin: `auto ${Rpx(17)} ${Rpx(17)} auto`,
  },
  inputDiv: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  inputParagraph: {
    fontWeight: 500,
    marginRight: Rpx(20),
    whiteSpace: 'nowrap',
  },
};

const responsiveStyles: StyleSheet = {
  selectorDiv: {
    marginLeft: SRpx(10),
  },
  selectorIcon: {
    margin: `auto ${SRpx(9)} ${SRpx(9)} auto`,
  },
  inputParagraph: {
    fontWeight: 600,
    marginRight: SRpx(10),
  },
  selector: {
    padding: `0 ${SRpx(15)}`,
    border: `${SRpx(2)} solid`,
    borderRadius: SRpx(5),
  },
};
