import * as React from 'react';
import { Color } from '../styles/constantStyles';

export interface ToLefttArrowProps {
  color?: string;
  style?: React.CSSProperties;
  width?: string | number;
  height?: string | number;
}

export const ToLeftArrow = ({ color, width, height, style }: ToLefttArrowProps) => (
  <svg style={style} width={width} height={height} viewBox="0 0 12 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M11.2172 2.55088C11.7054 2.06272 11.7054 1.27126 11.2172 0.783109C10.7291 0.294953 9.93761 0.294953 9.44945 0.783109L11.2172 2.55088ZM2 10.0003L1.11612 9.11644C0.627961 9.6046 0.627961 10.3961 1.11612 10.8842L2 10.0003ZM9.44945 19.2175C9.93761 19.7057 10.7291 19.7057 11.2172 19.2175C11.7054 18.7294 11.7054 17.9379 11.2172 17.4498L9.44945 19.2175ZM9.44945 0.783109L1.11612 9.11644L2.88388 10.8842L11.2172 2.55088L9.44945 0.783109ZM1.11612 10.8842L9.44945 19.2175L11.2172 17.4498L2.88388 9.11644L1.11612 10.8842Z"
      fill={color || Color.MONOGREY3}
    />
  </svg>
);
