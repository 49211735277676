import * as React from 'react';
import { Color } from '../styles/constantStyles';

export interface ToRighttArrowProps {
  color?: string;
  style?: React.CSSProperties;
  width?: string | number;
  height?: string | number;
}

export const ToRightArrow = ({ color, width, height, style }: ToRighttArrowProps) => (
  <svg style={style} width={width} height={height} viewBox="0 0 12 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M0.782783 17.4491C0.294627 17.9373 0.294627 18.7287 0.782782 19.2169C1.27094 19.705 2.06239 19.705 2.55055 19.2169L0.782783 17.4491ZM10 9.99967L10.8839 10.8836C11.372 10.3954 11.372 9.60395 10.8839 9.11579L10 9.99967ZM2.55055 0.782457C2.0624 0.294301 1.27094 0.294301 0.782784 0.782457C0.294629 1.27061 0.294629 2.06207 0.782784 2.55022L2.55055 0.782457ZM2.55055 19.2169L10.8839 10.8836L9.11612 9.11579L0.782783 17.4491L2.55055 19.2169ZM10.8839 9.11579L2.55055 0.782457L0.782784 2.55022L9.11612 10.8836L10.8839 9.11579Z"
      fill={color || Color.MONOGREY3}
    />
  </svg>
);
