import React, { useEffect, useRef, useState } from 'react';
import { DispatchAsyncResult } from 'react-redux-dispatch-async/lib/dispatchAsync';
import { DocColor, StyleSheet } from '../../assets/styles/constantStyles';
import { SearchIcon } from '../../assets/svg/SearchIcon';
import { ToBottomArrow } from '../../assets/svg/ToBottomArrow';
import { Rpx } from '../usecases/helpers/DimensionsConverter';

export interface CustomSearchInputProps {
  fetchDataAsync(value: string): Promise<DispatchAsyncResult>;
  filterDisbled?: boolean;
  filterSelectorWidth?: string | number;
  onSearchChange?: (v: string, res: string[]) => void;
  style?: React.CSSProperties;
}
export const CustomSearchInput = ({
  fetchDataAsync,
  filterDisbled,
  filterSelectorWidth,
  onSearchChange,
  style,
}: CustomSearchInputProps) => {
  let timer: any = null;
  const loaderRef = useRef<HTMLDivElement>(null);
  const [onLoad, setOnLoad] = useState<boolean>(false);

  useEffect(() => {
    if (onLoad) {
      loaderAnimate();
    }
  }, [onLoad]);

  const loaderAnimate = () => {
    const loaderEffect = new KeyframeEffect(
      loaderRef.current,
      [{ transform: 'rotate(0deg)' }, { transform: 'rotate(360deg)' }],
      {
        duration: 1000,
        fill: 'forwards',
        iterations: Infinity,
      },
    );
    const loaderAnimation = new Animation(loaderEffect, document.timeline);
    loaderAnimation.play();
  };

  const fetchData = (value: string) => {
    setOnLoad(true);
    fetchDataAsync(value).then((res) => {
      if (res.success) {
        if (onSearchChange) {
          onSearchChange(value, [...res.result]);
          setOnLoad(false);
        }
      }
    });
  };

  const handleSearch = (value: string) => {
    window.clearTimeout(timer);
    timer = setTimeout(() => {
      fetchData(value);
    }, 1000);
    /* const splitValue = value.split(' ').filter((x) => x !== '');
    // filteredSearchPool var is for filtering the searchPool with the splitValue array
     let filteredSearchPool: any = searchPool;
    splitValue.forEach((obj: string) => {
      filteredSearchPool = filteredSearchPool.filter((spObj: any) => {
        let allowedFieldsSpObj = {};
        if (allowedFields) {
          allowedFields.forEach((allowedFieldObj: any) => {
            allowedFieldsSpObj = {
              ...allowedFieldsSpObj,
              [allowedFieldObj.key]: `${allowedFieldObj.preValue}${spObj[allowedFieldObj.key]}`,
            };
          });
        } else {
          allowedFieldsSpObj = { ...spObj };
        }

        return JSON.stringify(Object.values(allowedFieldsSpObj))
          .replace(/['"]+/g, '')
          .replace(/[,]+/g, ' ')
          .toLowerCase()
          .includes(obj);
      });
    }); */
    /* const arr: any = searchPool.filter((obj) => {
      const searchPoolObj = { ...obj };
      delete searchPoolObj.id;
      return JSON.stringify(Object.values(searchPoolObj))
        .replace(/['"]+/g, '')
        .replace(/[,]+/g, ' ')
        .toLowerCase()
        .includes(value);
    }); 
    const tempData: string[] = [];
    filteredSearchPool.forEach((obj: any) => {
      tempData.push(obj.id);
    }); */
    /* if (onSearchChange) {
      onSearchChange(value, [...filteredSearchPool]);
    } */
  };

  return (
    <div style={{ ...styles.container, height: Rpx(70), fontSize: Rpx(28, true), ...style }}>
      <div style={{ ...styles.searchDiv }}>
        <input
          autoComplete="off"
          style={{
            ...styles.inputAndSelector,
            ...styles.searchInput,
          }}
          type="text"
          placeholder="Recherche"
          onChange={(v) => {
            handleSearch(v.target.value);
          }}
        />
        <div style={{ ...styles.searchIconDiv }}>
          {onLoad ? (
            <div ref={loaderRef} style={styles.loader} />
          ) : (
            <SearchIcon
              color={DocColor.GRAY}
              style={{
                width: '50%',
                height: '50%',
              }}
            />
          )}
        </div>
      </div>

      <div style={{ ...styles.selectorDiv, display: filterDisbled ? 'none' : '' }}>
        <ToBottomArrow
          color={DocColor.GRAY}
          style={{
            ...styles.selectorIcon,
            width: Rpx(15, true),
          }}
        />
        <select
          style={{
            ...styles.inputAndSelector,
            height: Rpx(70, true),
            ...styles.selector,
            fontSize: Rpx(28, true),
            width: filterSelectorWidth || '10vw',
          }}
        >
          <option value="0" disabled>
            Filtre
          </option>
          <option value="1">Option 1</option>
          <option value="2">Option 2</option>
          <option value="3">Option 3</option>
        </select>
      </div>
    </div>
  );
};

const styles: StyleSheet = {
  container: {
    flexDirection: 'row',
    width: '16vw',
  },
  searchDiv: {
    flexDirection: 'row',
    cursor: 'pointer',
    width: 'inherit',
  },
  inputAndSelector: {
    maxWidth: '100%',
    outline: 'none',
    fontWeight: 500,
    border: 'none',
    borderRadius: `${Rpx(9)} 0 0 ${Rpx(9)}`,
    background: DocColor.LIGHTGRAY,
  },
  searchInput: {
    color: 'black',
    caretColor: DocColor.DARKBLUE,
    padding: '0.5em 0 0.5em 1em',
  },
  searchIconDiv: {
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: `0 ${Rpx(9)} ${Rpx(9)} 0`,
    background: DocColor.LIGHTGRAY,
    width: '20%',
    height: 'inherit',
  },
  selectorDiv: {
    marginLeft: Rpx(20),
    position: 'relative',
  },
  selector: {
    color: DocColor.GRAY,
    padding: '0 30px',
    cursor: 'pointer',
  },
  selectorIcon: {
    position: 'absolute',
    inset: 0,
    margin: `auto ${Rpx(17)} ${Rpx(17)} auto`,
  },
  loader: {
    width: '50%',
    height: '50%',
    display: 'inline-block',
    boxSizing: 'border-box',
    border: `${Rpx(2)} solid ${DocColor.GRAY}`,
    borderRadius: '50%',
    borderBottomColor: 'transparent',
  },
};
