import React, { ReactNode, useContext, useMemo, useState } from 'react';
import { LoaderContextType } from '../domain/entities/LoaderContextType';

export const LoaderContext = React.createContext<LoaderContextType | null>(null);

export const LoaderContextProvider = ({ children }: { children: ReactNode }) => {
  const [message, setMessage] = useState<string>('');
  const props = useMemo(() => ({ message, setMessage }), [message]);
  return <LoaderContext.Provider value={props}>{children}</LoaderContext.Provider>;
};

export const useLoaderContext = () => {
  const context = useContext(LoaderContext);

  if (!context) {
    throw new Error('<useLoaderState> must be provided');
  }
  return context;
};
