import { authContextDependencies } from '../authContext/configuration/AuthContextDependencies';
import { calendarContextDependencies } from '../calendarContext/configuration/CalendarContextDependencies';
import { navigationContextDependencies } from '../navigationContext/configuration/navigationContextDependencies';
import { patientsContextDependencies } from '../patientsContext/configuration/PatientsContextDependencies';
import { consultationsContextDependencies } from '../consultationsContext/configuration/ConsultationsContextDependencies';
import { userSettingsContextDependencies } from '../settingsContext/configuration/UserSettingsContextDependencies';

export const dependencies = {
  ...patientsContextDependencies,
  ...calendarContextDependencies,
  ...navigationContextDependencies,
  ...authContextDependencies,
  ...consultationsContextDependencies,
  ...userSettingsContextDependencies,
};

export type DependenciesType = typeof dependencies;
