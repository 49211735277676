import React from 'react';
import { Color } from '../styles/constantStyles';

export interface ReturnIcon2Props {
  style?: React.CSSProperties;
  width?: string | number;
  height?: string | number;
  color?: string;
}

export const ReturnIcon2 = ({ width, height, style, color }: ReturnIcon2Props) => (
  <svg width={width} height={height} style={style} viewBox="0 0 13 5" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M11.3312 4.33949C11.5187 4.70884 11.9701 4.85626 12.3395 4.66876C12.7088 4.48126 12.8563 4.02985 12.6688 3.66051L11.3312 4.33949ZM9.53335 1.63331L9.86208 0.959185V0.959185L9.53335 1.63331ZM5.94939 1.04949L6.04176 1.79378L5.94939 1.04949ZM0.534482 3.23057C0.209716 3.48767 0.15486 3.95936 0.411958 4.28413C0.669057 4.6089 1.14075 4.66375 1.46552 4.40666L0.534482 3.23057ZM1.75 1C1.75 0.585786 1.41421 0.25 1 0.25C0.585786 0.25 0.25 0.585786 0.25 1H1.75ZM1 4H0.25C0.25 4.41421 0.585786 4.75 1 4.75L1 4ZM4 4.75C4.41421 4.75 4.75 4.41421 4.75 4C4.75 3.58579 4.41421 3.25 4 3.25V4.75ZM12.6688 3.66051C12.0793 2.49934 11.0879 1.55695 9.86208 0.959185L9.20463 2.30743C10.1614 2.774 10.9014 3.49285 11.3312 4.33949L12.6688 3.66051ZM9.86208 0.959185C8.63677 0.361673 7.23224 0.134522 5.85702 0.305198L6.04176 1.79378C7.13617 1.65795 8.2473 1.8406 9.20463 2.30743L9.86208 0.959185ZM5.85702 0.305198C3.57962 0.587841 1.98175 2.08486 0.534482 3.23057L1.46552 4.40666C3.0676 3.13838 4.28292 2.01206 6.04176 1.79378L5.85702 0.305198ZM0.25 1V4H1.75V1H0.25ZM1 4.75H4V3.25H1V4.75Z"
      fill={color || Color.MONOGREY3}
    />
  </svg>
);
