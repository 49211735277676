import * as React from 'react';
import { Color } from '../styles/constantStyles';

export interface ToRighttArrow3Props {
  color?: string;
  style?: React.CSSProperties;
  width?: string | number;
  height?: string | number;
}

export const ToRightArrow3 = ({ color, width, height, style }: ToRighttArrow3Props) => (
  <svg style={style} width={width} height={height} viewBox="0 0 9 12" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M9 6L4.64275e-07 11.1962L9.18537e-07 0.803847L9 6Z" fill={color || Color.MONOGREY3} />
  </svg>
);
