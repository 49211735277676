import * as React from 'react';
import { Color } from '../styles/constantStyles';

export interface WarningIconProps {
  color?: string;
  style?: React.CSSProperties;
  width?: string | number;
  height?: string | number;
}

export const WarningIcon = ({ color, width, height, style }: WarningIconProps) => (
  <svg style={style} width={width} height={height} viewBox="0 0 42 42" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M23 31.3958C23 30.2913 22.1046 29.3958 21 29.3958C19.8954 29.3958 19 30.2913 19 31.3958H23ZM19 31.4167C19 32.5212 19.8954 33.4167 21 33.4167C22.1046 33.4167 23 32.5212 23 31.4167H19ZM23 10.5833C23 9.47876 22.1046 8.58333 21 8.58333C19.8954 8.58333 19 9.47876 19 10.5833H23ZM19 25.1667C19 26.2712 19.8954 27.1667 21 27.1667C22.1046 27.1667 23 26.2712 23 25.1667H19ZM19 31.3958V31.4167H23V31.3958H19ZM19 10.5833V25.1667H23V10.5833H19ZM37.75 21C37.75 30.2508 30.2508 37.75 21 37.75V41.75C32.4599 41.75 41.75 32.4599 41.75 21H37.75ZM21 37.75C11.7492 37.75 4.25 30.2508 4.25 21H0.25C0.25 32.4599 9.54009 41.75 21 41.75V37.75ZM4.25 21C4.25 11.7492 11.7492 4.25 21 4.25V0.25C9.54009 0.25 0.25 9.54009 0.25 21H4.25ZM21 4.25C30.2508 4.25 37.75 11.7492 37.75 21H41.75C41.75 9.54009 32.4599 0.25 21 0.25V4.25Z"
      fill={color || Color.MONOGREY3}
    />
  </svg>
);
