import React, { useEffect, useState } from 'react';
import { DispatchAsyncResult } from 'react-redux-dispatch-async/lib/dispatchAsync';
import { useMediaQuery } from 'react-responsive';
import { PatientRow } from './components/PatientRow';
import { DocColor, StyleSheet } from '../../../assets/styles/constantStyles';
import { Patient } from '../../domain/entities/Patient';
import { AddPatientIcon } from '../../../assets/svg/AddPatientIcon';
import { NavigationPayload } from '../../../navigationContext/domain/entities/NavigationPayload';
import { CustomSearchInput } from '../../../common/ui/CustomSearchInput';
import { Rpx, SRpx } from '../../../common/usecases/helpers/DimensionsConverter';
import { bigScreenBreakpoint } from '../../../common/domain/entities/ReactResponsiveBreakpoints';
import { ToRightArrow3 } from '../../../assets/svg/ToRightArrow3';

interface PatientListProps {
  statePatients: Patient[];
  loadAllPatientsAsync(): Promise<DispatchAsyncResult>;
  fetchPatientsAsync(value: string): Promise<DispatchAsyncResult>;
  navigateToAsync: (p: NavigationPayload) => void;
}

export const PatientsListDumb = ({
  statePatients,
  loadAllPatientsAsync,
  fetchPatientsAsync,
  navigateToAsync,
}: PatientListProps) => {
  // isBigScreen working as a media breakpoint
  const isBigScreen = useMediaQuery({ query: `(min-width: ${bigScreenBreakpoint}px)` });

  const [dataState, setDataState] = useState<string>('Chargement en cours...');
  const [searchOnChange, setSearchOnChange] = useState<{ value: string; result: any[] }>({ value: '', result: [] });

  useEffect(() => {
    loadAllPatients();
    setTimeout(() => {
      setDataState('La liste de vos patients est vide.');
    }, 10000);
  }, []);

  useEffect(() => {
    const tempData: any[] = [];
    statePatients.forEach((obj: any) => {
      tempData.push({
        id: obj.id,
        firstName: obj.firstName,
        lastName: obj.lastName,
        temporaryPatient: obj.temporaryPatient ? `temp ${obj.temporaryPatient}` : null,
      });
    });
  }, [statePatients]);

  const loadAllPatients = () => {
    loadAllPatientsAsync().then((res) => {
      if (res.success) {
        if (res.result.length === 0) {
          setDataState('La liste de vos patients est vide.');
        }
      } else {
        setDataState('Il y a un problème dans le chargement des données, en essayant de recharger...');
        setTimeout(() => {
          loadAllPatients();
        }, 2000);
      }
    });
  };
  const addPatientHandler = () => {
    navigateToAsync({ routeName: 'Patient Addition' });
  };

  return (
    <div style={styles.container}>
      <div style={{ ...responsiveStyles.titleContainer, display: isBigScreen ? 'none' : '' }}>
        <ToRightArrow3 width={SRpx(10)} height={SRpx(10)} color={DocColor.DARKBLUE} />
        <p style={{ ...responsiveStyles.title, fontSize: SRpx(17, true) }}>Mes Patients</p>
      </div>
      <div style={{ ...responsiveStyles.line, display: isBigScreen ? 'none' : '' }} />
      <div
        style={
          isBigScreen ? { ...styles.divComponents } : { ...styles.divComponents, ...responsiveStyles.divComponents }
        }
      >
        <CustomSearchInput
          filterDisbled
          onSearchChange={(v, res) => {
            setSearchOnChange({ value: v, result: res });
            if (res.length === 0) {
              setDataState('La liste de vos patients est vide.');
            }
          }}
          fetchDataAsync={fetchPatientsAsync}
          style={!isBigScreen ? { ...responsiveStyles.customSearchInput, fontSize: SRpx(13, true) } : undefined}
        />
        <div
          role="button"
          tabIndex={0}
          onClick={addPatientHandler}
          onKeyPress={addPatientHandler}
          style={{ ...styles.button, marginLeft: 'auto', height: Rpx(63, true), display: !isBigScreen ? 'none' : '' }}
        >
          <AddPatientIcon style={styles.fieldIcon} width={Rpx(27, true)} />
          <p style={{ ...styles.buttonParagraph, fontSize: Rpx(27, true) }}>Ajouter un patient</p>
        </div>
      </div>
      {(searchOnChange.value !== '' && searchOnChange.result.length > 0) ||
      (searchOnChange.value === '' && statePatients.length > 0) ? (
        <div style={{ marginTop: Rpx(40) }}>
          {(searchOnChange.value !== '' ? searchOnChange.result : statePatients).map((patient) => (
            <PatientRow key={patient.id} data={patient} navigateToAsync={navigateToAsync} />
          ))}
        </div>
      ) : (
        <div
          style={
            isBigScreen
              ? { ...styles.dataStateRow, fontSize: Rpx(25, true) }
              : { ...styles.dataStateRow, ...responsiveStyles.dataStateRow, fontSize: SRpx(13, true) }
          }
        >
          <p>{dataState}</p>
        </div>
      )}
      <div
        role="button"
        tabIndex={0}
        onClick={addPatientHandler}
        onKeyPress={addPatientHandler}
        style={{
          ...styles.button,
          ...responsiveStyles.button,
          marginLeft: 'auto',
          height: SRpx(35, true),
          display: isBigScreen ? 'none' : '',
        }}
      >
        <AddPatientIcon style={{ ...styles.fieldIcon, ...responsiveStyles.fieldIcon }} width={SRpx(15, true)} />
        <p style={{ ...styles.buttonParagraph, ...responsiveStyles.buttonParagraph, fontSize: SRpx(15, true) }}>
          Ajouter un patient
        </p>
      </div>
    </div>
  );
};

const styles: StyleSheet = {
  container: {
    flex: 1,
    marginBottom: Rpx(100),
    marginTop: Rpx(220),
  },
  button: {
    width: 'fit-content',
    backgroundColor: DocColor.DARKBLUE,
    color: 'white',
    borderRadius: Rpx(18),
    flexDirection: 'row',
    cursor: 'pointer',
    padding: `0 ${Rpx(20)}`,
  },
  fieldIcon: {
    margin: `auto ${Rpx(10)} auto 0`,
  },
  buttonParagraph: {
    width: 'fit-content',
    height: 'fit-content',
    margin: `auto 0 auto ${Rpx(5)}`,
    fontWeight: 500,
  },
  paragraph: {
    letterSpacing: 1,
    width: 'fit-content',
    height: 'fit-content',
    margin: `auto 0 auto ${Rpx(20)}`,
    fontSize: Rpx(15),
  },

  divComponents: {
    flexDirection: 'row',
    width: '80%',
    margin: '0 auto',
  },

  dataStateRow: {
    width: '85%',
    height: Rpx(70),
    backgroundColor: DocColor.LIGHTGRAY,
    margin: `${Rpx(40)} auto 0 auto`,
    borderRadius: Rpx(9),
    textAlign: 'center',
    fontWeight: 400,
    whiteSpace: 'nowrap',
    justifyContent: 'center',
    color: DocColor.COALBLACK,
  },
};

const responsiveStyles: StyleSheet = {
  titleContainer: {
    flexDirection: 'row',
    width: '90%',
    gap: SRpx(3),
    alignItems: 'center',
    margin: `0 auto ${SRpx(20)} auto`,
  },
  title: {
    color: DocColor.MEDIUMBLUE,
    fontWeight: 700,
    letterSpacing: SRpx(1),
  },
  line: {
    width: '90%',
    height: 2,
    background: DocColor.MEDIUMGRAY,
    margin: `0 auto ${SRpx(40)} auto`,
  },
  customSearchInput: {
    width: '100%',
    height: 'fit-content',
  },
  button: {
    backgroundColor: DocColor.MEDIUMBLUE,
    borderRadius: SRpx(9),
    padding: `0 ${SRpx(20)}`,
    margin: `${SRpx(20)} auto`,
  },
  fieldIcon: {
    margin: `auto ${SRpx(5)} auto 0`,
  },
  buttonParagraph: {
    margin: `auto 0 auto ${SRpx(5)}`,
  },
  divComponents: {
    width: '90%',
  },
  dataStateRow: {
    width: '90%',
    height: SRpx(45),
    margin: `${SRpx(20)} auto 0 auto`,
    fontWeight: 500,
    color: DocColor.DARKBLUE,
    borderRadius: SRpx(5),
  },
};
