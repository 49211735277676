import * as React from 'react';
import { Color } from '../styles/constantStyles';

export interface AppointmentIconProps {
  color?: string;
  style?: React.CSSProperties;
  width?: string | number;
  height?: string | number;
}

export const AppointmentIcon = ({ color, width, height, style }: AppointmentIconProps) => (
  <svg style={style} width={width} height={height} viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M16 10V16"
      stroke={color || Color.MONOGREY3}
      strokeWidth="3"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M21.2 19L16 16"
      stroke={color || Color.MONOGREY3}
      strokeWidth="3"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M23.0254 12.4629H28.0254V7.46289"
      stroke={color || Color.MONOGREY3}
      strokeWidth="3"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M23.7755 23.775C22.2375 25.3143 20.2775 26.3628 18.1434 26.788C16.0094 27.2132 13.7972 26.9958 11.7867 26.1635C9.77619 25.3312 8.05769 23.9213 6.8486 22.1122C5.63951 20.3031 4.99414 18.176 4.99414 16C4.99414 13.824 5.63951 11.6969 6.8486 9.8878C8.05769 8.07866 9.77619 6.66877 11.7867 5.83647C13.7972 5.00417 16.0094 4.78686 18.1434 5.21202C20.2775 5.63719 22.2375 6.68573 23.7755 8.22501L28.0255 12.4625"
      stroke={color || Color.MONOGREY3}
      strokeWidth="3"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
