import React from 'react';
import { StyleSheet } from '../../../../assets/styles/constantStyles';
import { MedicineIcon } from '../../../../assets/svg/MedicineIcon';
import { NeedleIcon } from '../../../../assets/svg/NeedleIcon';
import CommonFunctions from '../../../../common/usecases/helpers/CommonFunctions';
import { Rpx } from '../../../../common/usecases/helpers/DimensionsConverter';

export const EventComponent = (data: any) => {
  const { event } = data;
  return (
    <div
      style={styles.container}
      title={`${event.RDVType === 'consultation' ? '💉' : '💊'} ${event.fullName} | ${CommonFunctions.padTo2Digits(
        event.start.getHours(),
      )}:${CommonFunctions.padTo2Digits(event.start.getMinutes())} - ${CommonFunctions.padTo2Digits(
        event.end.getHours(),
      )}:${CommonFunctions.padTo2Digits(event.end.getMinutes())}`}
    >
      <div style={styles.eventContainer}>
        {event.RDVType === 'consultation' ? (
          <NeedleIcon color="white" width={Rpx(25, true)} height={Rpx(25, true)} />
        ) : (
          <MedicineIcon color="white" width={Rpx(25, true)} height={Rpx(25, true)} />
        )}
        <div style={styles.verticalLine} />
        <p style={{ ...styles.fullNameParagraph, fontSize: Rpx(20, true) }}>{event.fullName}</p>
        <div style={styles.verticalLine} />
      </div>
    </div>
  );
};

const styles: StyleSheet = {
  container: {
    width: '100%',
    height: '100%',
  },
  eventContainer: {
    flexDirection: 'row',
    padding: Rpx(5),
    gap: Rpx(5),
    alignItems: 'center',
  },
  verticalLine: {
    width: Rpx(2),
    height: '100%',
    background: 'white',
  },
  fullNameParagraph: {
    color: 'white',
    fontWeight: 600,
    textTransform: 'capitalize',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
  },
};
