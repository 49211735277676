import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { DispatchAsyncResult } from 'react-redux-dispatch-async/lib/dispatchAsync';
import { v4 as uuidv4 } from 'uuid';
import io from 'socket.io-client';
import { NameEntranceOverlay } from './components/NameEntranceOverlay';
import { PersonalInformationComponent } from './components/PersonalInformationComponent';
import { TextAreaContainer } from './components/TextAreaContainer';
import { ExaminationsComponent } from './components/ExaminationsComponent';
import { ExaminationsToRequestComponent } from './components/ExaminationsToRequestComponent';
import { PrescriptionComponent } from './components/PrescriptionComponent';
import { MedicalCertificateComponent } from './components/MedicalCertificateComponent';
import { MesuresComponent } from './components/MesuresComponent';
import { ConsultationsComponent } from './components/ConsultationsComponent';
import { OtherCertificatesComponent } from './components/OtherCertificatesComponent';
import { NotesComponent } from './components/NotesComponent';
import { DocColor, StyleSheet } from '../../../assets/styles/constantStyles';
import { Rpx } from '../../../common/usecases/helpers/DimensionsConverter';
import { BodyIcon } from '../../../assets/svg/BodyIcon';
import { ButtonWithIcon } from '../../../common/ui/ButtonWithIcon';
import { CheckMarkIcon } from '../../../assets/svg/CheckMarkIcon';
import { CloseIcon } from '../../../assets/svg/CloseIcon';
import CommonFunctions from '../../../common/usecases/helpers/CommonFunctions';
import { NavigationPayload } from '../../../navigationContext/domain/entities/NavigationPayload';
import { PatientConsultation } from '../../domain/entities/PatientConsultation';
import { useNotificationContext } from '../../../common/configuration/notificationContextProvider';
import { ConsultationReason } from '../../domain/entities/ConsultationReason';
import { Interrogation } from '../../domain/entities/Interrogation';
import { hardCodedEmptyMesures, Mesures } from '../../domain/entities/Mesures';
import { ConsultationAdditionFilledDataType } from '../../domain/entities/ConsultationAdditionFilledDataType';
import { Examination, hardCodedEmptyExaminations } from '../../domain/entities/Examination';
import { ExaminationToRequest, hardCodedEmptyExaminationsToRequest } from '../../domain/entities/ExaminationToRequest';
import { Prescription } from '../../domain/entities/Prescriptions';
import { MedicalCertificate } from '../../domain/entities/MedicalCertificate';
import { useLoaderContext } from '../../../common/configuration/loaderContextProvider';
import { AttachmentsComponent } from '../../../common/ui/AttachmentsComponent';
import { Attachment } from '../../domain/entities/Attachments';
import { ConfirmOverlay } from '../../../common/ui/ConfirmOverlay';
import { ConsultationNotes } from '../../domain/entities/ConsultationNotes';
import { User } from '../../../authContext/domain/entities/User';
import basicInfo from '../../../env.json';
import { Patient } from '../../../patientsContext/domain/entities/Patient';

interface ConsultationAdditionProps {
  stateConnectedUser: User | undefined;

  loadPatientAsync: (id: string) => Promise<DispatchAsyncResult>;
  getTemporaryPatientNumberAsync: () => Promise<DispatchAsyncResult>;
  loadPatientsNamesAsync: () => Promise<DispatchAsyncResult>;
  insertPatientAsync: (newPatient: Patient) => Promise<DispatchAsyncResult>;

  loadAttachmentsAsync(
    patientId: string,
    attachmentsLimit?: number,
    skipAttachments?: number,
  ): Promise<DispatchAsyncResult>;
  insertAttachmentAsync(attachment: Attachment): Promise<DispatchAsyncResult>;
  getConsultationNumberAsync: () => Promise<DispatchAsyncResult>;
  loadPatientsConsultationsAsync(
    patientId?: string,
    consultationsLimit?: number,
    skipConsultations?: number,
  ): Promise<DispatchAsyncResult>;
  insertPatientConsultationAsync: (patientConsultation: PatientConsultation) => Promise<DispatchAsyncResult>;
  loadConsultationReasonAsync: (consultationId: string) => Promise<DispatchAsyncResult>;
  insertConsultationReasonAsync: (consultationReason: ConsultationReason) => Promise<DispatchAsyncResult>;
  loadInterrogationAsync: (consultationId: string) => Promise<DispatchAsyncResult>;
  insertInterrogationAsync: (interrogation: Interrogation) => Promise<DispatchAsyncResult>;
  loadConsultationNotesAsync: (consultationId: string) => Promise<DispatchAsyncResult>;
  insertConsultationNotesAsync: (consultationNotes: ConsultationNotes) => Promise<DispatchAsyncResult>;
  loadMesuresAsync: (consultationId: string) => Promise<DispatchAsyncResult>;
  insertMesuresAsync: (mesures: Mesures) => Promise<DispatchAsyncResult>;
  loadExaminationsAsync: (consultationId: string) => Promise<DispatchAsyncResult>;
  insertExaminationsAsync: (
    patientId: string,
    consultationId: string,
    examinations: Examination[],
  ) => Promise<DispatchAsyncResult>;
  sendAttchmentByMailAsync: (mail: string, fileName: string, fileContent: string) => Promise<DispatchAsyncResult>;
  loadExaminationsToRequestAsync: (consultationId: string) => Promise<DispatchAsyncResult>;
  insertExaminationsToRequestAsync: (
    consultationId: string,
    examinationsToRequest: ExaminationToRequest[],
  ) => Promise<DispatchAsyncResult>;
  loadPrescriptionsAsync: (consultationId: string) => Promise<DispatchAsyncResult>;
  insertPrescriptionsAsync: (consultationId: string, prescriptions: Prescription[]) => Promise<DispatchAsyncResult>;
  fetchMedicinesAsync: (value?: string, composition?: string) => Promise<DispatchAsyncResult>;
  loadMedicalCertificateAsync: (consultationId: string) => Promise<DispatchAsyncResult>;
  insertMedicalCertificateAsync: (mesures: MedicalCertificate) => Promise<DispatchAsyncResult>;
  navigateToAsync: (p: NavigationPayload) => void;
  navigateReplace: (p: NavigationPayload) => void;
  navigateBack: () => void;
}

export const ConsultationAdditionDumb = ({
  stateConnectedUser,
  loadPatientAsync,
  getTemporaryPatientNumberAsync,
  loadPatientsNamesAsync,
  insertPatientAsync,
  loadAttachmentsAsync,
  insertAttachmentAsync,
  getConsultationNumberAsync,
  loadPatientsConsultationsAsync,
  insertPatientConsultationAsync,
  loadConsultationReasonAsync,
  insertConsultationReasonAsync,
  loadInterrogationAsync,
  insertInterrogationAsync,
  loadConsultationNotesAsync,
  insertConsultationNotesAsync,
  loadMesuresAsync,
  insertMesuresAsync,
  loadExaminationsAsync,
  insertExaminationsAsync,
  sendAttchmentByMailAsync,
  loadExaminationsToRequestAsync,
  insertExaminationsToRequestAsync,
  loadPrescriptionsAsync,
  insertPrescriptionsAsync,
  fetchMedicinesAsync,
  loadMedicalCertificateAsync,
  insertMedicalCertificateAsync,
  navigateToAsync,
  navigateReplace,
  navigateBack,
}: ConsultationAdditionProps) => {
  const [consultationStartDate] = useState(new Date());
  const notification = useNotificationContext();
  const [isConfirmFragmentClosed, setIsConfirmFragmentClosed] = useState<boolean>(true);
  const [isNameEntranceOverlayClosed, setIsNameEntranceOverlayClosed] = useState<boolean>(true);
  const loader = useLoaderContext();
  const recievedData: any = useLocation().state;
  const [patient, setPatient] = useState<Patient | undefined | null>(undefined);
  const [consultationNumber, setConsultationNumber] = useState<string>('');
  const [temporaryPatientName, setTemporaryPatientName] = useState<string>('');
  const [filledData, setFilledData] = useState<ConsultationAdditionFilledDataType>({
    consultationReasons: '',
    interrogation: '',
    mesures: { ...hardCodedEmptyMesures },
    examinations: [...hardCodedEmptyExaminations],
    examinationsToRequest: [...hardCodedEmptyExaminationsToRequest],
  });

  useEffect(() => {
    const socket = io(basicInfo.backendConfig.endpoints.ws, {
      query: { userId: stateConnectedUser?.id },
    });
    return () => {
      socket.disconnect();
    };
  }, []);

  useEffect(() => {
    if (recievedData?.isOnlyReadableConsultation) {
      setConsultationNumber(recievedData?.consultationNumber);
    } else {
      getConsultationNumberAsync().then((res) => {
        if (res.success) {
          const todaysDate: Date = new Date();
          setConsultationNumber(
            `${`0${todaysDate.getDate()}`.slice(-2)}${`0${todaysDate.getMonth() + 1}`.slice(-2)}${todaysDate
              .getFullYear()
              .toString()
              .slice(-2)}${res.result < 10 ? `0${res.result + 1}`.slice(-2) : res.result + 1}`,
          );
        }
      });
    }
  }, [recievedData]);

  useEffect(() => {
    setIsNameEntranceOverlayClosed(recievedData?.isOnlyReadableConsultation);
    if (recievedData?.patient) {
      setPatient(recievedData.patient);
    } else if (recievedData?.patientId) {
      loader.setMessage('_');
      setFilledData((prevState) => ({ ...prevState, consultationReasons: recievedData?.consultationReason }));
      if (recievedData?.patientId === 'temporaryPatient') {
        getTemporaryPatientNumberAsync()
          .then((res) => {
            if (res.success) {
              const temporaryPatientUuid = uuidv4();
              setPatient({
                id: temporaryPatientUuid,
                temporaryPatient: res.result,
                temporaryPatientName: recievedData?.temporaryPatientName,
              });
            } else {
              setPatient(null);
            }
          })
          .finally(() => loader.setMessage(''));
      } else {
        loadPatientAsync(recievedData.patientId)
          .then((res) => {
            if (res.success) {
              setPatient(res.result[0]);
            } else {
              setPatient(null);
            }
          })
          .finally(() => loader.setMessage(''));
      }
    } else {
      setPatient(null);
    }
    if (recievedData?.isOnlyReadableConsultation) {
      loadConsultationReasonAsync(recievedData?.consultationId).then((res) => {
        if (res.success && res.result) {
          setFilledData((prevState) => ({ ...prevState, consultationReasons: res.result.reasons }));
        }
      });

      loadInterrogationAsync(recievedData?.consultationId).then((res) => {
        if (res.success && res.result) {
          setFilledData((prevState) => ({ ...prevState, interrogation: res.result.interrogation }));
        }
      });

      loadConsultationNotesAsync(recievedData?.consultationId).then((res) => {
        if (res.success && res.result) {
          setFilledData((prevState) => ({ ...prevState, consultationNotes: res.result.consultationNotes }));
        }
      });

      loadMesuresAsync(recievedData?.consultationId).then((res) => {
        if (res.success && res.result) {
          setFilledData((prevState) => ({ ...prevState, mesures: res.result }));
        }
      });

      loadExaminationsAsync(recievedData?.consultationId).then((res) => {
        if (res.success && res.result) {
          setFilledData((prevState) => ({ ...prevState, examinations: res.result }));
        }
      });

      loadExaminationsToRequestAsync(recievedData?.consultationId).then((res) => {
        if (res.success && res.result) {
          setFilledData((prevState) => ({ ...prevState, examinationsToRequest: res.result }));
        }
      });

      loadPrescriptionsAsync(recievedData?.consultationId).then((res) => {
        if (res.success && res.result) {
          setFilledData((prevState) => ({ ...prevState, prescriptions: res.result }));
        }
      });

      loadMedicalCertificateAsync(recievedData?.consultationId).then((res) => {
        if (res.success && res.result) {
          setFilledData((prevState) => ({ ...prevState, medicalCertificate: { ...res.result } }));
        }
      });
    }
  }, [recievedData]);

  useEffect(() => {
    setIsNameEntranceOverlayClosed(patient !== null);
    if (patient) {
      setTemporaryPatientName(
        patient?.temporaryPatientName && patient?.temporaryPatient !== null
          ? patient?.temporaryPatientName
          : patient.firstName && patient.lastName
          ? `${patient.firstName} ${patient.lastName}`
          : '',
      );
    }
  }, [patient]);

  const onSubmitClickHandler = () => {
    loader.setMessage('Sauvegarde des données');
    if (patient && patient.temporaryPatient === null) {
      insertConsultation(patient);
    } else if (patient && patient.temporaryPatient !== null) {
      insertPatientAsync({ ...patient, temporaryPatientName }).then((res) => {
        if (res.success) {
          insertConsultation(res.result);
        }
      });
    }
  };

  const insertConsultation = (p: Patient) => {
    insertPatientConsultationAsync({
      number: consultationNumber,
      patientId: p.id,
      startDateTime: consultationStartDate,
      endDateTime: new Date(),
    }).then((res) => {
      if (res.success) {
        insertConsultationReasonAsync({
          consultationId: res.result.id,
          reasons: filledData.consultationReasons ? filledData.consultationReasons : '',
        });
        insertInterrogationAsync({
          consultationId: res.result.id,
          interrogation: filledData.interrogation ? filledData.interrogation : '',
        });
        insertConsultationNotesAsync({
          consultationId: res.result.id,
          consultationNotes: filledData.consultationNotes ? filledData.consultationNotes : '',
        });
        if (filledData.mesures) {
          insertMesuresAsync({
            ...filledData.mesures,
            consultationId: res.result.id,
          });
        }
        if (filledData.examinations) {
          if (p.id) {
            insertExaminationsAsync(p.id, res.result.id, filledData.examinations);
          }
        }
        if (filledData.examinationsToRequest) {
          insertExaminationsToRequestAsync(res.result.id, filledData.examinationsToRequest);
        }
        if (filledData.prescriptions) {
          insertPrescriptionsAsync(res.result.id, filledData.prescriptions);
        }
        if (filledData.medicalCertificate) {
          insertMedicalCertificateAsync({
            ...filledData.medicalCertificate,
            consultationId: res.result.id,
          });
        }

        navigateBack();
        notification.setOnSuccess({
          message: `la consultation N°${consultationNumber} a été ajoutée avec succès!`,
        });
      }
      loader.setMessage('');
    });
  };

  const onConfirmClick = () => {
    navigateBack();
    setIsConfirmFragmentClosed(true);
  };

  return (
    <div style={styles.container}>
      <ConfirmOverlay
        message="Êtes vous sûr de bien vouloir annuler la consultation ?
          Les données renseignées seront perdues."
        onConfirmClick={onConfirmClick}
        isClosedState={isConfirmFragmentClosed}
        setIsClosedState={setIsConfirmFragmentClosed}
      />
      {!isNameEntranceOverlayClosed && (
        <NameEntranceOverlay
          loadPatientAsync={loadPatientAsync}
          getTemporaryPatientNumberAsync={getTemporaryPatientNumberAsync}
          loadPatientsNamesAsync={loadPatientsNamesAsync}
          setPatientState={setPatient}
          setIsClosedState={setIsNameEntranceOverlayClosed}
          navigateBack={navigateBack}
        />
      )}

      <p style={{ ...styles.title, fontSize: Rpx(48, true) }}>
        Consultation <span style={styles.titleSpan}>N° {consultationNumber}</span>
      </p>
      <div style={{ ...styles.codePatientDiv, height: Rpx(47, true) }}>
        <div style={{ ...styles.codePatientInnerDivs, background: DocColor.DARKBLUE }}>
          <p style={{ ...styles.codePatientTitle, fontSize: Rpx(24, true) }}>Code du Patient</p>
        </div>
        <div style={styles.codePatientInnerDivs}>
          <p style={{ ...styles.codePatientParagraph, fontSize: Rpx(24, true) }}>
            {patient ? patient.id?.slice(-5).toUpperCase() : '-'}
          </p>
        </div>
      </div>

      <PersonalInformationComponent navigateToAsync={navigateToAsync} patient={patient} />
      {!(recievedData?.patient === undefined && patient?.temporaryPatient !== null) && patient && patient.id !== '' && (
        <ConsultationsComponent
          currentConsultationNumber={consultationNumber}
          patient={patient}
          loadPatientsConsultationsAsync={loadPatientsConsultationsAsync}
          navigateReplace={navigateReplace}
        />
      )}

      {!(recievedData?.patient === undefined && patient?.temporaryPatient !== null) && patient && patient.id !== '' && (
        <AttachmentsComponent
          patientId={patient.id}
          onConsultationsContext
          loadAttachmentsAsync={loadAttachmentsAsync}
          insertAttachmentAsync={insertAttachmentAsync}
        />
      )}

      <TextAreaContainer
        onChange={(v) => {
          setFilledData((prevState) => ({ ...prevState, consultationReasons: v.target.value }));
        }}
        defaultValue={filledData.consultationReasons}
        isOnlyReadable={recievedData?.isOnlyReadableConsultation}
        title="Motif de Consultation"
      />
      <TextAreaContainer
        onChange={(v) => {
          setFilledData((prevState) => ({ ...prevState, interrogation: v.target.value }));
        }}
        defaultValue={filledData.interrogation}
        isOnlyReadable={recievedData?.isOnlyReadableConsultation}
        title="Interrogatoire"
      />
      <MesuresComponent
        setFilledData={setFilledData}
        isOnlyReadable={recievedData?.isOnlyReadableConsultation}
        mesuresData={filledData.mesures}
      />
      <ExaminationsComponent
        sendAttchmentByMailAsync={sendAttchmentByMailAsync}
        patient={patient}
        examinationData={filledData.examinations}
        isOnlyReadable={recievedData?.isOnlyReadableConsultation}
        setFilledData={setFilledData}
      />
      {patient?.temporaryPatient !== null && (
        <div style={styles.inputNameDiv}>
          <p style={{ ...styles.subTitle, fontSize: Rpx(32, true) }}>
            Examens, ordonnance et certificat sous le nom de{' '}
          </p>
          <div style={{ position: 'relative', width: Rpx(450, true), flexDirection: 'row' }}>
            <div style={{ ...styles.inputIcon, height: Rpx(48, true) }}>
              <BodyIcon
                style={{ margin: `auto` }}
                width={Rpx(25, true)}
                height={Rpx(25, true)}
                color={DocColor.MEDIUMBLUE}
              />
            </div>
            <input
              style={{ ...styles.input, height: Rpx(48, true), fontSize: Rpx(26, true) }}
              type="text"
              onChange={(v) => {
                setTemporaryPatientName(v.target.value);
              }}
              disabled={recievedData?.isOnlyReadableConsultation === true}
              defaultValue={temporaryPatientName}
              placeholder="Entrez le nom du patient"
            />
          </div>
        </div>
      )}

      <ExaminationsToRequestComponent
        patient={patient}
        temporaryPatientName={temporaryPatientName}
        examinationsToRequestData={filledData.examinationsToRequest}
        isOnlyReadable={recievedData?.isOnlyReadableConsultation}
        setFilledData={setFilledData}
        disabled={CommonFunctions.isEmptyOrSpaces(temporaryPatientName)}
      />
      <PrescriptionComponent
        patient={patient}
        fetchMedicinesAsync={fetchMedicinesAsync}
        temporaryPatientName={temporaryPatientName}
        prescriptionData={filledData.prescriptions}
        isOnlyReadable={recievedData?.isOnlyReadableConsultation}
        setFilledData={setFilledData}
        disabled={CommonFunctions.isEmptyOrSpaces(temporaryPatientName)}
      />
      <MedicalCertificateComponent
        patient={patient}
        temporaryPatientName={temporaryPatientName}
        medicalCertificateData={filledData.medicalCertificate}
        isOnlyReadable={recievedData?.isOnlyReadableConsultation}
        setFilledData={setFilledData}
        disabled={CommonFunctions.isEmptyOrSpaces(temporaryPatientName)}
      />
      <OtherCertificatesComponent
        patient={patient}
        temporaryPatientName={temporaryPatientName}
        disabled={CommonFunctions.isEmptyOrSpaces(temporaryPatientName)}
      />
      <NotesComponent
        patient={patient}
        temporaryPatientName={temporaryPatientName}
        isOnlyReadable={recievedData?.isOnlyReadableConsultation}
        setFilledData={setFilledData}
        disabled={CommonFunctions.isEmptyOrSpaces(temporaryPatientName)}
        defaultValue={filledData.consultationNotes}
      />

      <div style={{ alignItems: 'end', marginTop: Rpx(66), width: '90%' }}>
        {recievedData?.isOnlyReadableConsultation !== true && (
          <div style={{ flexDirection: 'row', gap: Rpx(30) }}>
            <ButtonWithIcon
              label="Fin de la consultation"
              style={{ fontSize: Rpx(27, true), height: Rpx(63, true), padding: `0 ${Rpx(20)}` }}
              icon={CheckMarkIcon({
                width: Rpx(15, true),
                height: Rpx(15, true),
                color: 'white',
                style: { margin: `auto 0 auto ${Rpx(10)}` },
              })}
              onClick={onSubmitClickHandler}
            />
            <ButtonWithIcon
              label="Annuler"
              style={{
                fontSize: Rpx(27, true),
                height: Rpx(63, true),
                background: DocColor.DARKBLUE,
                padding: `0 ${Rpx(20)}`,
              }}
              icon={CloseIcon({
                width: Rpx(15, true),
                height: Rpx(15, true),
                color: 'white',
                style: { margin: `auto 0 auto ${Rpx(10)}` },
              })}
              onClick={() => setIsConfirmFragmentClosed(false)}
            />
          </div>
        )}
      </div>
    </div>
  );
};

const styles: StyleSheet = {
  container: {
    flex: 1,
    marginTop: Rpx(130),
    marginBottom: Rpx(75),
  },
  title: {
    color: DocColor.DARKBLUE,
    fontWeight: 800,
    letterSpacing: Rpx(3),
    margin: `0 auto ${Rpx(35)} auto`,
  },
  titleSpan: {
    color: DocColor.MEDIUMBLUE,
  },
  codePatientDiv: {
    flexDirection: 'row',
    width: '25vw',
    outline: `${Rpx(5)} solid ${DocColor.DARKBLUE}`,
    borderRadius: Rpx(5),
    marginLeft: '3vw',
  },
  codePatientInnerDivs: {
    width: '50%',
    justifyContent: 'center',
    alignItems: 'center',
  },
  codePatientTitle: {
    color: 'white',
    fontWeight: 600,
  },
  codePatientParagraph: {
    color: DocColor.MEDIUMBLUE,
    fontWeight: 800,
    letterSpacing: Rpx(2),
  },
  subTitle: {
    fontWeight: 700,
    color: DocColor.MEDIUMBLUE,
    marginBottom: Rpx(44),
  },
  inputNameDiv: { justifyContent: 'space-between', alignItems: 'center', margin: `${Rpx(62)} 0 2vw 0` },
  input: {
    maxWidth: '100%',
    background: DocColor.LIGHTGRAY,
    borderRadius: `0 ${Rpx(5)} ${Rpx(5)} 0`,
    border: `${Rpx(2)} solid ${DocColor.LIGHTGRAY}`,
    outline: 'none',
    color: DocColor.DARKBLUE,
    caretColor: DocColor.DARKBLUE,
    fontWeight: 700,
    width: '100%',
    textTransform: 'capitalize',
  },
  inputIcon: {
    width: 'fit-content',
    paddingLeft: Rpx(30),
    borderRadius: `${Rpx(5)} 0 0 ${Rpx(5)}`,
    background: DocColor.LIGHTGRAY,
    justifyContent: 'center',
    alignItems: 'center',
  },
};
