import React from 'react';
import { connect } from 'react-redux';
import { Navigator } from './navigationContext/index';
import './App.css';
import { NotificationDiv } from './common/ui/NotificationOverLay';
import { AppState } from './common/configuration/app.states';
import { getUserSettingsState } from './settingsContext/usecases/UserSettings.selectors';
import { LoaderOverlay } from './common/ui/LoaderOverlay';

const mapStateToProps = (state: AppState) => ({
  userSettingsState: getUserSettingsState(state),
});

const mapDispatchToProps = {};

type Props = ReturnType<typeof mapStateToProps> & typeof mapDispatchToProps;

const PlainApp: React.FC<Props> = ({ userSettingsState }: Props) => (
  <>
    <LoaderOverlay />
    {userSettingsState && (
      <NotificationDiv
        notificationsActionsState={userSettingsState.notificationsActionsState}
        notificationsActionsDelaiState={userSettingsState.notificationsActionsDelai}
      />
    )}

    <Navigator />
  </>
);

export const App = connect(mapStateToProps, mapDispatchToProps)(PlainApp);
