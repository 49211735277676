import { Action } from 'redux';
import { connect } from 'react-redux';
import { dispatchAsync } from 'react-redux-dispatch-async';
import { ForgotPasswordDumb } from './ForgotPassword.dumb';
import { navigateBackRequest } from '../../../navigationContext/usecases/navigation.actions';
import { sendResetPasswordRequestRequest } from '../../usecases/Auth.actions';

const mapState = () => ({});

const mapDispatch = (dispatch: (a: Action) => void) => ({
  sendResetPasswordRequestAsync: (mail: string) => dispatchAsync(dispatch, sendResetPasswordRequestRequest(mail)),
  navigateBack: () => dispatch(navigateBackRequest()),
});

export const ForgotPasswordSmart = connect(mapState, mapDispatch)(ForgotPasswordDumb);
