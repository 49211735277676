import * as React from 'react';
import { Color } from '../styles/constantStyles';

export interface RectangleIconProps {
  color?: string;
  style?: React.CSSProperties;
  width?: string | number;
  height?: string | number;
}

export const RectangleIcon = ({ color, width, height, style }: RectangleIconProps) => (
  <svg style={style} width={width} height={height} viewBox="0 0 14 14" xmlns="http://www.w3.org/2000/svg">
    <rect
      x="0.00390625"
      y="6.99805"
      width="9.89693"
      height="9.89693"
      transform="rotate(-45 0.00390625 6.99805)"
      fill={color || Color.MONOGREY3}
    />
  </svg>
);
