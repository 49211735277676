import React, { useEffect, useState } from 'react';
import { useMediaQuery } from 'react-responsive';
import { ProgressBar } from './ProgressBar';
import { DocColor, StyleSheet } from '../../assets/styles/constantStyles';
import { CloseIcon } from '../../assets/svg/CloseIcon';
import { useNotificationContext } from '../configuration/notificationContextProvider';
import { Rpx, SRpx } from '../usecases/helpers/DimensionsConverter';
import { bigScreenBreakpoint } from '../domain/entities/ReactResponsiveBreakpoints';

interface NotificationDivProps {
  notificationsActionsState: boolean;
  notificationsActionsDelaiState: number;
}

export const NotificationDiv = ({
  notificationsActionsState,
  notificationsActionsDelaiState,
}: NotificationDivProps) => {
  // isBigScreen working as a media breakpoint
  const isBigScreen = useMediaQuery({ query: `(min-width: ${bigScreenBreakpoint}px)` });

  const notification = useNotificationContext();
  const [notificationState, setNotificationState] = useState<number | string>(0);
  const [message, setMessage] = useState<string>('');
  const [progressPercentage, setProgressPercentage] = useState<number>(100);
  let staticProgressDelai = 0;
  let progressDelai = 0;
  let counter: ReturnType<typeof setInterval>;

  useEffect(() => {
    if (notification.onSuccess.message !== '') {
      setNotificationState(1);
      setMessage(notification.onSuccess.message);
    } else if (notification.onError.message !== '') {
      setNotificationState(2);
      setMessage(notification.onError.message);
    }
    if (notification.onSuccess.message !== '' || notification.onError.message !== '') {
      staticProgressDelai = notification.onSuccess.duration || notificationsActionsDelaiState;
      progressDelai = staticProgressDelai;
      counter = setInterval(() => {
        progressDelai -= 20;
        setProgressPercentage((progressDelai * 100) / staticProgressDelai);
        if (progressDelai === 0) {
          closeNotification();
        }
      }, 20);
    }

    return () => {
      clearInterval(counter);
    };
  }, [notification.onSuccess, notification.onError]);

  const closeNotification = () => {
    setNotificationState(0);
    clearInterval(counter);
  };

  const closeNotificationHandler = () => {
    closeNotification();
  };

  return (
    <div
      style={
        isBigScreen
          ? {
              ...styles.Container,
              backgroundColor:
                notificationState === 0
                  ? DocColor.GRAY
                  : notificationState === 1
                  ? DocColor.TEALISHGREEN
                  : DocColor.FLAMINGOPINK,
              marginTop: notificationState === 0 ? Rpx(-150) : Rpx(10),
              opacity: notificationState === 0 ? 0 : 1,
              visibility: !notificationsActionsState || notificationState === 0 ? 'collapse' : 'visible',
            }
          : {
              ...styles.Container,
              ...responsiveStyles.Container,
              backgroundColor:
                notificationState === 0
                  ? DocColor.GRAY
                  : notificationState === 1
                  ? DocColor.AZUREISHWHITE
                  : DocColor.FLAMINGOPINK,
              marginTop: notificationState === 0 ? SRpx(-75) : SRpx(5),
              opacity: notificationState === 0 ? 0 : 1,
              visibility: !notificationsActionsState || notificationState === 0 ? 'collapse' : 'visible',
            }
      }
    >
      <div style={{ flexDirection: 'row' }}>
        <p
          style={
            isBigScreen
              ? { ...styles.paragraph, fontSize: Rpx(25, true) }
              : { ...styles.paragraph, ...responsiveStyles.paragraph, fontSize: SRpx(13, true) }
          }
        >
          {message}
        </p>
        <div
          onKeyDown={closeNotificationHandler}
          tabIndex={0}
          role="button"
          onClick={closeNotificationHandler}
          style={isBigScreen ? { ...styles.closeIcon } : { ...styles.closeIcon, ...responsiveStyles.closeIcon }}
        >
          <CloseIcon
            style={isBigScreen ? { ...styles.icon } : { ...styles.icon, ...responsiveStyles.icon }}
            color="black"
            width={isBigScreen ? Rpx(20) : SRpx(10)}
            height={isBigScreen ? Rpx(20) : SRpx(10)}
          />
        </div>
      </div>

      <div>
        {notification.onSuccess.customElement}
        {notification.onError.customElement}
      </div>
      <ProgressBar progressPercentage={`${progressPercentage}%`} duration="20ms" />
    </div>
  );
};

const styles: StyleSheet = {
  Container: {
    borderRadius: Rpx(5),
    width: '22vw',
    height: 'fit-content',
    margin: `${Rpx(10)} ${Rpx(10)} 0 auto`,
    position: 'fixed',
    inset: 0,
    boxShadow: `rgba(0, 0, 0, 0.05) 0 ${Rpx(6)} ${Rpx(24)} 0, rgba(0, 0, 0, 0.08) 0 0 0 ${Rpx(1)}`,
    transition: 'all .5s ease',
    zIndex: 7,
  },
  paragraph: {
    padding: `5% 10%`,
    textAlign: 'center',
    fontWeight: 600,
  },
  icon: {
    background: 'white',
    padding: Rpx(5),
    borderRadius: Rpx(7),
  },
  closeIcon: {
    margin: `${Rpx(10)} ${Rpx(10)} auto auto`,
    cursor: 'pointer',
  },
};

const responsiveStyles: StyleSheet = {
  Container: {
    borderRadius: SRpx(3),
    width: '90%',
    margin: `5% auto 0 auto`,
    inset: 0,
    boxShadow: `rgba(0, 0, 0, 0.05) 0 ${SRpx(3)} ${SRpx(12)} 0, rgba(0, 0, 0, 0.08) 0 0 0 ${SRpx(1)}`,
  },
  paragraph: {
    padding: `5%`,
  },
  icon: {
    padding: SRpx(3),
    borderRadius: SRpx(4),
  },
  closeIcon: {
    margin: `${SRpx(5)} ${SRpx(5)} auto auto`,
  },
};
