import React, { useEffect, useState } from 'react';
import { Calendar, momentLocalizer, Views } from 'react-big-calendar';
import moment from 'moment';
import 'moment/locale/fr';
import { DispatchAsyncResult } from 'react-redux-dispatch-async/lib/dispatchAsync';
import { useLocation } from 'react-router-dom';
import { CustomDateSelector } from './components/CustomDateSelector';
import { FullEventComponent } from './components/FullEventComponent';
import { EventComponent } from './components/EventComponent';
import { EventAdditionModificationFragment } from './components/EventAdditionModificationFragment';
import { DocColor, StyleSheet } from '../../../assets/styles/constantStyles';
import { BellIcon } from '../../../assets/svg/BellIcon';
import { Rpx } from '../../../common/usecases/helpers/DimensionsConverter';
import { ToLeftArrow } from '../../../assets/svg/ToLeftArrow';
import { ToRightArrow } from '../../../assets/svg/ToRightArrow';
import { CalendarEvent } from '../../domain/entities/CalendarEvent';
import { NavigationPayload } from '../../../navigationContext/domain/entities/NavigationPayload';
import { User } from '../../../authContext/domain/entities/User';

interface CalendarProps {
  stateConnectedUser: User | undefined;
  stateCalendarEvents: CalendarEvent[];
  loadPatientsNamesAsync: () => Promise<DispatchAsyncResult>;
  loadCalendarEventsAsync: () => Promise<DispatchAsyncResult>;
  insertCalendarEventAsync: (calendarEvent: CalendarEvent) => Promise<DispatchAsyncResult>;
  updateCalendarEventAsync: (calendarEvent: CalendarEvent) => Promise<DispatchAsyncResult>;
  deleteCalendarEventAsync: (lookId: string) => Promise<DispatchAsyncResult>;
  loadProgramedAppointmentsAsync: (start: string, end: string) => Promise<DispatchAsyncResult>;
  navigateToAsync: (p: NavigationPayload) => void;
}

export const CalendarDumb = ({
  stateConnectedUser,
  stateCalendarEvents,
  loadPatientsNamesAsync,
  loadCalendarEventsAsync,
  insertCalendarEventAsync,
  updateCalendarEventAsync,
  deleteCalendarEventAsync,
  loadProgramedAppointmentsAsync,
  navigateToAsync,
}: CalendarProps) => {
  const recievedData: any = useLocation().state;
  const localizer = momentLocalizer(moment);
  const [date, setDate] = useState(new Date());
  const [calendarEvents, setCalendarEvents] = useState<
    {
      id?: number;
      patientId?: string;
      RDVType?: 'consultation' | 'control';
      consultationReason?: string;
      fullName?: string;
      start?: Date;
      end?: Date;
    }[]
  >();
  const [viewMode, setViewMode] = useState<'month' | 'week' | 'work_week' | 'day' | 'agenda'>(Views.MONTH);
  const [calendarFirstDayOfWeek, setCalendarFirstDayOfWeek] = useState<Date>(moment(date).startOf('week').toDate());
  const [isEventAdditionModificationFragmentClosed, setIsEventAdditionModificationFragmentClosed] =
    useState<boolean>(true);
  const [fragmentTask, setfragmentTask] = useState<'add' | 'edit'>('add');
  const [fragmentDataState, setFragmentDataState] = useState<CalendarEvent>({});
  useEffect(() => {
    loadCalendarEventsAsync();
  }, []);

  useEffect(() => {
    if (recievedData && recievedData.scheduleAppointment) {
      setfragmentTask('add');
      setFragmentDataState({ start: new Date().toLocaleString('en-US', { hourCycle: 'h23' }) });
      setIsEventAdditionModificationFragmentClosed(false);
    }
  }, [recievedData]);

  useEffect(() => {
    if (stateCalendarEvents) {
      const tempObjArray: any[] = [];
      stateCalendarEvents.map((element: CalendarEvent) =>
        tempObjArray.push({
          id: element.id,
          patientId: element.patientId,
          fullName: element.fullName,
          RDVType: element.RDVType,
          consultationReason: element.consultationReason,
          start: element.start ? new Date(element.start) : undefined,
          end: element.end ? new Date(element.end) : undefined,
        }),
      );
      setCalendarEvents(tempObjArray);
    }
  }, [stateCalendarEvents]);

  const getUnitType = (vm: 'month' | 'week' | 'work_week' | 'day' | 'agenda') => {
    let unitType = null;
    switch (vm) {
      case Views.WEEK:
      case Views.WORK_WEEK:
        unitType = Views.WEEK;
        break;
      case Views.MONTH:
      case Views.AGENDA:
        unitType = Views.MONTH;
        break;
      default:
        unitType = Views.DAY;
    }
    return unitType;
  };

  useEffect(() => {
    setCalendarFirstDayOfWeek(moment(date).startOf('week').toDate());
  }, [date]);
  useEffect(() => {}, [calendarFirstDayOfWeek]);

  // Change view mode
  const handleViewMode = (e: moment.MomentInput) => {
    setDate(moment(e).toDate());
    setViewMode(Views.DAY);
  };

  const eventStyleGetter = (event: any) => {
    const style = {
      backgroundColor: event.RDVType === 'consultation' ? DocColor.DARKBLUE : DocColor.MEDIUMBLUE,
    };
    return {
      style,
    };
  };

  return (
    <div style={styles.container}>
      {!isEventAdditionModificationFragmentClosed && (
        <EventAdditionModificationFragment
          stateConnectedUser={stateConnectedUser}
          data={fragmentDataState}
          fragmentTask={fragmentTask}
          setIsClosedState={setIsEventAdditionModificationFragmentClosed}
          isClosedState={isEventAdditionModificationFragmentClosed}
          loadPatientsNamesAsync={loadPatientsNamesAsync}
          loadCalendarEventsAsync={loadCalendarEventsAsync}
          insertCalendarEventAsync={insertCalendarEventAsync}
          updateCalendarEventAsync={updateCalendarEventAsync}
          deleteCalendarEventAsync={deleteCalendarEventAsync}
          loadProgramedAppointmentsAsync={loadProgramedAppointmentsAsync}
          navigateToAsync={navigateToAsync}
        />
      )}

      <div style={styles.calendarHeader}>
        <div
          style={{ ...styles.addRDVButton, fontSize: Rpx(28, true) }}
          onClick={() => {
            setfragmentTask('add');
            setFragmentDataState({ start: new Date().toLocaleString('en-US', { hourCycle: 'h23' }) });
            setIsEventAdditionModificationFragmentClosed(false);
          }}
          onKeyDown={() => {
            setfragmentTask('add');
            setFragmentDataState({});
            setIsEventAdditionModificationFragmentClosed(false);
          }}
          tabIndex={0}
          role="button"
        >
          <BellIcon style={styles.addRDVIcon} width={Rpx(35, true)} height={Rpx(35, true)} />
          <p style={styles.addRDVButtonParagraph}>Nouveau RDV</p>
        </div>
        <div style={{ ...styles.calendarControllerContainer, height: Rpx(70, true) }}>
          <div style={styles.calendarControllerNavigator}>
            <div
              style={{ cursor: 'pointer' }}
              onClick={() => setDate(moment(date).add(-1, getUnitType(viewMode)).toDate())}
              onKeyDown={() => setDate(moment(date).add(-1, getUnitType(viewMode)).toDate())}
              tabIndex={0}
              role="button"
            >
              <ToLeftArrow height={Rpx(20, true)} />
            </div>

            <button
              style={{ margin: `0 ${Rpx(20)}`, color: 'white', fontSize: Rpx(32, true), fontWeight: 600 }}
              type="button"
              onClick={() => setDate(moment().toDate())}
            >
              {viewMode === 'month' ? 'Ce mois' : viewMode === 'week' ? 'Cette semaine' : 'Ce jour'}
            </button>
            <div
              style={{ cursor: 'pointer' }}
              onClick={() => setDate(moment(date).add(1, getUnitType(viewMode)).toDate())}
              onKeyDown={() => setDate(moment(date).add(1, getUnitType(viewMode)).toDate())}
              tabIndex={0}
              role="button"
            >
              <ToRightArrow height={Rpx(20, true)} color="white" />
            </div>
          </div>

          <p style={{ ...styles.calendarControllerDate, fontSize: Rpx(40, true) }}>
            {date.toLocaleDateString('fr-FR', { year: 'numeric', month: 'short' })}
          </p>

          <div>
            <CustomDateSelector
              onChange={(value) => {
                setViewMode(value);
              }}
              value={viewMode}
            />
          </div>
        </div>
      </div>
      <div style={styles.calendarContainer}>
        {viewMode === 'week' ? (
          <div style={styles.calendarWeekHeader}>
            <div style={styles.calendarWeekHeaderColumn}>
              <p style={styles.calendarWeekHeaderNumber}>
                {moment(calendarFirstDayOfWeek).add(0, 'day').toDate().getDate()}
              </p>
              <p style={styles.calendarWeekHeaderDay}>Lundi</p>
            </div>
            <div style={styles.calendarWeekHeaderColumn}>
              <p style={styles.calendarWeekHeaderNumber}>
                {moment(calendarFirstDayOfWeek).add(1, 'day').toDate().getDate()}
              </p>
              <p style={styles.calendarWeekHeaderDay}>Mardi</p>
            </div>
            <div style={styles.calendarWeekHeaderColumn}>
              <p style={styles.calendarWeekHeaderNumber}>
                {moment(calendarFirstDayOfWeek).add(2, 'day').toDate().getDate()}
              </p>
              <p style={styles.calendarWeekHeaderDay}>Mercredi</p>
            </div>
            <div style={styles.calendarWeekHeaderColumn}>
              <p style={styles.calendarWeekHeaderNumber}>
                {moment(calendarFirstDayOfWeek).add(3, 'day').toDate().getDate()}
              </p>
              <p style={styles.calendarWeekHeaderDay}>Jeudi</p>
            </div>
            <div style={styles.calendarWeekHeaderColumn}>
              <p style={styles.calendarWeekHeaderNumber}>
                {moment(calendarFirstDayOfWeek).add(4, 'day').toDate().getDate()}
              </p>
              <p style={styles.calendarWeekHeaderDay}>Vendredi</p>
            </div>
            <div style={styles.calendarWeekHeaderColumn}>
              <p style={styles.calendarWeekHeaderNumber}>
                {moment(calendarFirstDayOfWeek).add(5, 'day').toDate().getDate()}
              </p>
              <p style={styles.calendarWeekHeaderDay}>Samedi</p>
            </div>
            <div style={styles.calendarWeekHeaderColumn}>
              <p style={styles.calendarWeekHeaderNumber}>
                {moment(calendarFirstDayOfWeek).add(6, 'day').toDate().getDate()}
              </p>
              <p style={styles.calendarWeekHeaderDay}>Dimanche</p>
            </div>
          </div>
        ) : viewMode === 'work_week' ? (
          <div style={styles.calendarWeekHeader}>
            <div style={{ ...styles.calendarWeekHeaderColumn, width: '20%' }}>
              <p style={styles.calendarWeekHeaderNumber}>
                {moment(calendarFirstDayOfWeek).add(0, 'day').toDate().getDate()}
              </p>
              <p style={styles.calendarWeekHeaderDay}>Lundi</p>
            </div>
            <div style={{ ...styles.calendarWeekHeaderColumn, width: '20%' }}>
              <p style={styles.calendarWeekHeaderNumber}>
                {moment(calendarFirstDayOfWeek).add(1, 'day').toDate().getDate()}
              </p>
              <p style={styles.calendarWeekHeaderDay}>Mardi</p>
            </div>
            <div style={{ ...styles.calendarWeekHeaderColumn, width: '20%' }}>
              <p style={styles.calendarWeekHeaderNumber}>
                {moment(calendarFirstDayOfWeek).add(2, 'day').toDate().getDate()}
              </p>
              <p style={styles.calendarWeekHeaderDay}>Mercredi</p>
            </div>
            <div style={{ ...styles.calendarWeekHeaderColumn, width: '20%' }}>
              <p style={styles.calendarWeekHeaderNumber}>
                {moment(calendarFirstDayOfWeek).add(3, 'day').toDate().getDate()}
              </p>
              <p style={styles.calendarWeekHeaderDay}>Jeudi</p>
            </div>
            <div style={{ ...styles.calendarWeekHeaderColumn, width: '20%' }}>
              <p style={styles.calendarWeekHeaderNumber}>
                {moment(calendarFirstDayOfWeek).add(4, 'day').toDate().getDate()}
              </p>
              <p style={styles.calendarWeekHeaderDay}>Vendredi</p>
            </div>
          </div>
        ) : (
          viewMode === 'day' && (
            <div
              style={{ ...styles.calendarDayHeader, fontSize: Rpx(26, true), height: Rpx(60, true) }}
            >{`${date.toLocaleDateString('fr-FR', {
              weekday: 'long',
            })} ${date.getDate()} ${date.toLocaleDateString('fr-FR', {
              month: 'long',
            })}`}</div>
          )
        )}
        <Calendar
          localizer={localizer}
          events={calendarEvents}
          selectable
          toolbar={false}
          scrollToTime={new Date(1970, 1, 1, 6)}
          defaultDate={new Date()}
          min={new Date(2010, 10, 0, 7, 0, 0)}
          max={new Date(2050, 10, 0, 20, 0, 0)}
          date={date}
          onNavigate={(d) => setDate(d)}
          view={viewMode}
          views={['month', 'week', 'day', 'work_week', 'agenda']}
          onView={handleViewMode}
          onDrillDown={handleViewMode}
          messages={{
            showMore: (number) => `${number} autres...`,
            event: 'événement',
            time: 'temps',
            noEventsInRange: "Il n'y a aucun événement dans cette mois.",
          }}
          onSelectSlot={(slotInfo) => {
            setfragmentTask('add');
            setFragmentDataState({
              start: slotInfo.start.toLocaleString('en-US', { hourCycle: 'h23' }),
              end: slotInfo.end.toLocaleString('en-US', { hourCycle: 'h23' }),
            });
            setIsEventAdditionModificationFragmentClosed(false);
          }}
          onSelectEvent={(eventInfo) => {
            setfragmentTask('edit');
            setFragmentDataState({
              ...eventInfo,
              start: eventInfo.start?.toLocaleString('en-US', { hourCycle: 'h23' }),
              end: eventInfo.end?.toLocaleString('en-US', { hourCycle: 'h23' }),
            });
            setIsEventAdditionModificationFragmentClosed(false);
          }}
          components={{
            event: EventComponent, // used by each view (Month, Day, Week)
            day: { event: FullEventComponent },
          }}
          eventPropGetter={eventStyleGetter}
        />
      </div>
    </div>
  );
};
const styles: StyleSheet = {
  container: {
    flex: 1,
    marginBottom: Rpx(200),
  },
  calendarHeader: {
    width: '95%',
    margin: `${Rpx(165)} auto ${Rpx(20)} auto`,
  },
  addRDVButton: {
    width: 'fit-content',
    height: Rpx(70),
    backgroundColor: DocColor.DARKBLUE,
    color: 'white',
    borderRadius: Rpx(20),
    flexDirection: 'row',
    cursor: 'pointer',
    padding: `0px ${Rpx(30)}`,
    margin: `auto 0 ${Rpx(18)} auto`,
  },
  addRDVIcon: {
    margin: 'auto 0',
  },
  addRDVButtonParagraph: {
    margin: `auto 0px auto ${Rpx(10)}`,
    fontWeight: 500,
  },
  calendarControllerContainer: {
    position: 'relative',
    flexDirection: 'row',
    background: DocColor.MEDIUMBLUE,
    borderRadius: Rpx(15),
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: `0 ${Rpx(30)}`,
  },
  calendarControllerNavigator: {
    flexDirection: 'row',
    alignItems: 'center',
  },

  calendarControllerDate: {
    fontWeight: 900,
    color: 'white',
    letterSpacing: Rpx(5),
    textTransform: 'capitalize',
  },

  calendarContainer: {
    width: '95%',
    margin: `0 auto ${Rpx(70)} auto`,
    height: Rpx(1200),
  },
  calendarWeekHeader: {
    marginLeft: '4rem',
    flexDirection: 'row',
    width: 'initial',
    border: `1px solid ${DocColor.GRAY}`,
    borderBottom: 'none',
    borderRadius: `${Rpx(20)} ${Rpx(20)} 0 0`,
    paddingRight: '1vw',
  },
  calendarWeekHeaderColumn: {
    position: 'relative',
    borderRight: `1px solid ${DocColor.GRAY}`,
    width: '14.28%',
    justifyContent: 'center',
    alignItems: 'center',
    height: Rpx(60),
  },
  calendarWeekHeaderNumber: {
    position: 'absolute',
    fontSize: Rpx(20),
    fontWeight: 700,
    color: DocColor.MEDIUMBLUE,
    inset: 0,
    margin: `0 0 auto auto`,
    width: 'fit-content',
    height: 'fit-content',
    padding: Rpx(2),
  },
  calendarWeekHeaderDay: {
    fontSize: '70%',
    fontWeight: 600,
    color: DocColor.DARKBLUE,
  },
  calendarDayHeader: {
    width: '60%',
    border: `1px solid ${DocColor.GRAY}`,
    background: DocColor.CLOUDYBLUE,
    borderBottom: 'none',
    borderRadius: `${Rpx(20)} ${Rpx(20)} 0 0`,
    margin: `0 auto`,
    justifyContent: 'center',
    alignItems: 'center',
    color: DocColor.MEDIUMBLUE,
    fontWeight: 700,
    letterSpacing: Rpx(2),
    textTransform: 'capitalize',
  },
};
