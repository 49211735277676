import React from 'react';
import { DocColor, StyleSheet } from '../../assets/styles/constantStyles';
import { PaperClipIcon } from '../../assets/svg/PaperClipIcon';
import { Rpx } from '../usecases/helpers/DimensionsConverter';

type CustomFileInputProps = {
  inputName: string;
  inputNameWidth?: string | number;
  color?: string;
  disabled?: boolean;
  style?: React.CSSProperties;
  width?: string | number;
  height?: string | number;
  onChange?: (val: React.ChangeEvent<HTMLInputElement>) => void;
  placeholder?: string;
  fileName?: string;
};

export const CustomFileInput = React.forwardRef<HTMLInputElement, CustomFileInputProps>(
  (
    {
      inputName,
      inputNameWidth,
      disabled,
      color,
      width,
      height,
      style,
      placeholder,
      fileName,
      onChange,
    }: CustomFileInputProps,
    ref,
  ) => {
    CustomFileInput.displayName = 'CustomFileInput';

    const changeHandler = (event: any) => {
      if (onChange) {
        onChange(event);
      }
    };
    return (
      <div style={{ width }}>
        <div style={styles.inputDiv}>
          <p style={{ ...styles.inputName, fontSize: Rpx(27, true), minWidth: inputNameWidth && inputNameWidth }}>
            {inputName}
          </p>
          <div
            style={{
              ...styles.input,
              height: height || Rpx(70, true),
              fontSize: Rpx(20, true),
              ...style,
              color: fileName !== '' ? color : DocColor.GRAY,
            }}
          >
            {fileName || placeholder}
          </div>
          <div
            style={{
              position: 'relative',
              minWidth: Rpx(40, true),
              height: Rpx(40, true),
              background: disabled ? DocColor.LIGHTGRAY : DocColor.MEDIUMBLUE,
              borderRadius: Rpx(13),
              margin: `auto ${Rpx(10)}`,
              cursor: 'pointer',
            }}
          >
            <PaperClipIcon
              style={{ margin: 'auto', cursor: 'pointer' }}
              color="white"
              width={Rpx(35, true)}
              height={Rpx(35, true)}
            />
            <input
              ref={ref}
              onChange={(event) => changeHandler(event)}
              disabled={disabled}
              type="file"
              style={{ position: 'absolute', width: '100%', height: '100%', opacity: 0, cursor: 'inherit' }}
            />
          </div>
        </div>
      </div>
    );
  },
);

const styles: StyleSheet = {
  inputDiv: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  inputName: {
    fontWeight: 500,
    marginRight: Rpx(20),
    whiteSpace: 'nowrap',
  },
  input: {
    maxWidth: '100%',
    background: DocColor.LIGHTGRAY,
    border: `${Rpx(2)} solid ${DocColor.LIGHTGRAY}`,
    borderRadius: Rpx(9),
    outline: 'none',
    caretColor: DocColor.DARKBLUE,
    fontWeight: 700,
    whiteSpace: 'nowrap',
    padding: `0 ${Rpx(10)}`,
    textAlign: 'center',
    justifyContent: 'center',
    width: '100%',
    cursor: 'default',
  },
};
