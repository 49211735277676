import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';
import persistReducer from 'redux-persist/es/persistReducer';
import localforage from 'localforage';
import { authReducer } from '../../authContext/usecases/Auth.reducers';
import { patientsReducer } from '../../patientsContext/usecases/Patients.reducers';
import { calendarReducer } from '../../calendarContext/usecases/Calendar.reducers';
import { consultationsReducer } from '../../consultationsContext/usecases/Consultations.reducers';
import { userSettingsReducer } from '../../settingsContext/usecases/UserSettings.reducers';

const persistConfig = {
  key: 'doctorcms',
  storage: localforage,
  whitelist: ['authTokens'],
  blacklist: ['connectedUser'],
};

export const createRootReducer = (history: any) =>
  combineReducers({
    router: connectRouter(history),
    auth: persistReducer(persistConfig, authReducer),
    patients: patientsReducer,
    consultations: consultationsReducer,
    // persistedPatients: persistReducer(persistConfig, persistedPatientsReducer), // to be uncommented when we have data to persist + put back <persistgate in index
    calendar: calendarReducer,
    userSettings: userSettingsReducer,
  });
