import React, { useEffect, useRef } from 'react';
import { ButtonWithIcon } from './ButtonWithIcon';
import { DocColor, StyleSheet } from '../../assets/styles/constantStyles';
import { Calendar2Icon } from '../../assets/svg/Calendar2Icon';
import CommonFunctions from '../usecases/helpers/CommonFunctions';
import { Rpx } from '../usecases/helpers/DimensionsConverter';

type CustomDateInputProps = {
  dateInputName?: string;
  disabled?: boolean;
  onChange?: (v: React.ChangeEvent<HTMLInputElement>) => void;
  valueAsDate?: Date;
};

export const CustomDateInput = ({ dateInputName, onChange, disabled, valueAsDate }: CustomDateInputProps) => {
  const dateInputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (dateInputRef.current && valueAsDate) {
      dateInputRef.current.value = CommonFunctions.dateToDateInputValue(valueAsDate);
    }
  }, [valueAsDate]);

  return (
    <div style={styles.dateInputDiv}>
      <p style={{ ...styles.dateNameParagraph, fontSize: Rpx(27, true) }}>{dateInputName}</p>
      <div style={{ position: 'relative', flexDirection: 'row' }}>
        <input
          style={{ position: 'absolute', opacity: '0', width: '100%', height: '100%' }}
          disabled={disabled}
          ref={dateInputRef}
          type="date"
          onChange={(v) => onChange && onChange(v)}
        />
        <div style={{ ...styles.dateInput, height: Rpx(50, true), fontSize: Rpx(25, true) }}>
          <p>{valueAsDate ? `${CommonFunctions.formatDate(valueAsDate)}` : `jj\\mm\\yyyy`}</p>
        </div>
        <ButtonWithIcon
          style={{
            ...styles.calendarButtons,
            width: Rpx(35, true),
            height: Rpx(35, true),
            display: disabled ? 'none' : '',
          }}
          icon={Calendar2Icon({
            ...styles.icon,
            width: Rpx(26, true),
            height: Rpx(26, true),
            style: { margin: 'auto 0' },
          })}
        />
      </div>
    </div>
  );
};

const styles: StyleSheet = {
  dateInputDiv: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  dateInput: {
    fontWeight: 700,
    color: DocColor.DARKBLUE,
    borderRadius: Rpx(5),
    background: DocColor.LIGHTGRAY,
    width: 'fit-content',
    padding: `0 ${Rpx(70)}`,
    marginRight: '5px',
    justifyContent: 'center',
  },
  calendarButtons: {
    borderRadius: Rpx(13),
    margin: `auto 0`,
  },
  dateNameParagraph: {
    fontWeight: 500,
    marginRight: Rpx(20),
    whiteSpace: 'nowrap',
  },
  icon: {
    color: 'white',
  },
};
