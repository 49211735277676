import { Action } from 'redux';
import { connect } from 'react-redux';
import { dispatchAsync } from 'react-redux-dispatch-async';
import { PatientAdditionDumb } from './PatientAddition.dumb';
import { Patient } from '../../domain/entities/Patient';
import { insertPatientRequest } from '../../usecases/Patients.actions';
import { navigateBackRequest } from '../../../navigationContext/usecases/navigation.actions';

const mapState = () => ({});

const mapDispatch = (dispatch: (a: Action) => void) => ({
  insertPatientAsync: (newPatient: Patient) => dispatchAsync(dispatch, insertPatientRequest(newPatient)),
  navigateBack: () => dispatch(navigateBackRequest()),
});

export const PatientAdditionSmart = connect(mapState, mapDispatch)(PatientAdditionDumb);
