import React from 'react';
import 'react-app-polyfill/stable';
import ReactDOM from 'react-dom';
import './assets/styles/index.scss';
import { Provider } from 'react-redux';
import { ConnectedRouter } from 'connected-react-router';
import { App } from './App';
import { IESupport } from './common/ui/IESupport';
import { appStore, history } from './configuration/redux/store';
import { ErrorBoundary } from './common/ui/ErrorBoundary';
import { NotificationContextProvider } from './common/configuration/notificationContextProvider';
import { LoaderContextProvider } from './common/configuration/loaderContextProvider';

// @ts-ignore // gotta take care of those IE users
const isIE = /* @cc_on!@ */ false || !!document.documentMode;

let ShowingApp = (
  <ErrorBoundary>
    <Provider store={appStore.store}>
      <ConnectedRouter history={history}>
        <LoaderContextProvider>
          <NotificationContextProvider>
            <App />
          </NotificationContextProvider>
        </LoaderContextProvider>
      </ConnectedRouter>
    </Provider>
  </ErrorBoundary>
);

if (isIE) {
  ShowingApp = <IESupport />;
}

// TODO: Update loading screen
ReactDOM.render(<React.StrictMode>{ShowingApp}</React.StrictMode>, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
