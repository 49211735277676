import React, { useState } from 'react';
import { ExaminationsToRequestOverlay } from './ExaminationsToRequestOverlay';
import { DocColor, StyleSheet } from '../../../../assets/styles/constantStyles';
import { Rpx } from '../../../../common/usecases/helpers/DimensionsConverter';
import { ExaminationToRequest } from '../../../domain/entities/ExaminationToRequest';
import { CustomSelector } from '../../../../common/ui/CustomSelector';
import { ButtonWithIcon } from '../../../../common/ui/ButtonWithIcon';
import { EditIcon } from '../../../../assets/svg/EditIcon';

type ExaminationToRequestRowProps = {
  inputName: string;
  examinationToRequestData: ExaminationToRequest;
  onDataChange: (examinationToRequestState: ExaminationToRequest) => void;
  isOnlyReadable?: boolean;
  disabled?: boolean;
};

export const ExaminationToRequestRow = ({
  inputName,
  examinationToRequestData,
  onDataChange,
  isOnlyReadable,
  disabled,
}: ExaminationToRequestRowProps) => {
  const [isExaminationsToRequestOverlayClosed, setIsExaminationsToRequestOverlayClosed] = useState<boolean>(true);
  const examinationsToRequestOptionsList = [
    {
      type: 'Bilans Biologiques',
      optionsList: [
        'Acide Urique',
        'Potassium',
        'Cholestérol total',
        'Vitamine D',
        'T4',
        'Plaquettes',
        'Vitamine B12',
        'Examen Ostéo Articulaire',
        'Testostérone',
      ],
    },
    {
      type: 'Bilans Radiologiques',
      optionsList: [
        'Acide Urique',
        'Potassium',
        'Cholestérol total',
        'Calcémie',
        'Protéine C',
        'Cortisol',
        'Cholestérol HDL',
        'Protéine S',
        'Ferritine',
      ],
    },
    {
      type: 'Bilans Anatomopathologiques',
      optionsList: ['Acide Urique', 'Potassium', 'Cholestérol total', 'Calcémie', 'Protéine C'],
    },
    {
      type: 'Avis spécialisé',
      optionsList: ['Potassium', 'Acide Urique', 'Cholestérol total'],
    },
  ];

  return (
    <div style={{ width: '100%' }}>
      <ExaminationsToRequestOverlay
        ExaminationToRequestType={examinationToRequestData.type}
        optionsList={
          examinationsToRequestOptionsList.find((o) => o.type === examinationToRequestData.type)?.optionsList
        }
        setIsClosedState={setIsExaminationsToRequestOverlayClosed}
        onCancelClick={() => {
          setIsExaminationsToRequestOverlayClosed(true);
        }}
        isClosedState={isExaminationsToRequestOverlayClosed}
        dataState={[...examinationToRequestData.examinationsList]}
        onValidClick={(arrayList) => {
          onDataChange({ ...examinationToRequestData, examinationsList: arrayList });
        }}
      />

      <div style={{ width: '100%' }}>
        <div style={{ flexDirection: 'row' }}>
          <CustomSelector
            disabled={disabled || isOnlyReadable}
            containerDisabled={disabled}
            onChange={(value) => {
              if (examinationToRequestData.type !== value) {
                onDataChange({ ...examinationToRequestData, type: value, examinationsList: [] });
                setIsExaminationsToRequestOverlayClosed(false);
              }
            }}
            value={examinationToRequestData.type}
            optionsArray={[
              'Bilans Biologiques',
              'Bilans Radiologiques',
              'Bilans Anatomopathologiques',
              'Avis spécialisé',
            ]}
            inputName={inputName}
            inputNameWidth={Rpx(150)}
            style={{ borderRadius: Rpx(5) }}
            optionsColor={DocColor.MEDIUMBLUE}
            placeholder="Choisir un Bilan"
            width="100%"
            height={Rpx(48, true)}
          />
        </div>
        <div
          style={{
            flexDirection: 'row',
            justifyContent: 'center',
            marginTop: Rpx(10),
            marginLeft: Rpx(150),
            display: examinationToRequestData.type === '' || disabled ? 'none' : '',
          }}
        >
          <div style={styles.examinationsToRequestDiv}>
            {examinationToRequestData.examinationsList.length !== 0 ? (
              examinationToRequestData.examinationsList.map((object, i) => (
                <div key={object}>
                  <p style={{ ...styles.examinationsToRequestParagraph, fontSize: Rpx(26, true) }}>
                    {i === 0 ? object : `- ${object}`}
                  </p>
                </div>
              ))
            ) : (
              <div>
                <p style={{ ...styles.examinationsToRequestParagraph, fontSize: Rpx(26, true) }}>vide</p>
              </div>
            )}
          </div>
          <ButtonWithIcon
            style={{
              width: Rpx(35, true),
              height: Rpx(35, true),
              borderRadius: Rpx(13),
              margin: `0 ${Rpx(20)}`,
              display: isOnlyReadable ? 'none' : '',
            }}
            icon={EditIcon({
              width: Rpx(26, true),
              height: Rpx(26, true),
              color: 'white',
              style: { margin: 'auto 0' },
            })}
            onClick={() => {
              setIsExaminationsToRequestOverlayClosed(false);
            }}
          />
        </div>
      </div>
    </div>
  );
};

const styles: StyleSheet = {
  examinationsToRequestDiv: {
    flexFlow: 'row wrap',
    gap: Rpx(10),
    maxWidth: '80%',
    justifyContent: 'center',
  },
  examinationsToRequestParagraph: {
    fontWeight: 600,
    color: DocColor.MEDIUMBLUE,
  },
};
