import { UserRole } from './UserRole';

export type User = {
  id?: string;
  email: string;
  firstName: string;
  lastName: string;
  gender: string;
  role: 'DOCTOR' | 'NURSE' | 'ACCOUNTANT' | '';
  linkedTo?: string;
};

export const emptyUser: User = {
  email: '',
  firstName: '',
  lastName: '',
  role: '',
  gender: '',
};

export const defaultPassword = 'password123';
export const hardcodedUsers: User[] = [
  {
    email: 'john.doe@fayonation.com',
    firstName: 'john',
    lastName: 'doe',
    gender: 'Male',
    role: UserRole.DOCTOR,
  },
  {
    email: 'jane.doe@fayonation.com',
    firstName: 'sofia',
    lastName: 'Benabdallah',
    gender: 'Female',
    role: UserRole.NURSE,
    linkedTo: '76676d42-a617-4b43-982b-75bd92899d84',
  },
  {
    email: 'zineb.boumedian@example.com',
    firstName: 'Zineb',
    lastName: 'Boumedian',
    gender: 'Male',
    role: UserRole.DOCTOR,
  },
  {
    email: 'douae.boumedian@example.com',
    firstName: 'Douae',
    lastName: 'Boumedian',
    gender: 'Female',
    role: UserRole.NURSE,
    linkedTo: '1a6ba869-af74-46a3-aeb1-62364cba7738',
  },
  {
    email: 'ziad.hafidi@example.com',
    firstName: 'Ziad',
    lastName: 'Hafidi',
    gender: 'Male',
    role: UserRole.DOCTOR,
  },
  {
    email: 'chaimae.hafidi@example.com',
    firstName: 'Chaimae',
    lastName: 'Hafidi',
    gender: 'Female',
    role: UserRole.NURSE,
    linkedTo: '132d37d6-f02f-42de-94c9-ccf524818b7f',
  },
  {
    email: 'adil.tayaa@example.com',
    firstName: 'Adil',
    lastName: 'Tayaa',
    gender: 'Male',
    role: UserRole.DOCTOR,
  },
  {
    email: 'salma.tayaa@example.com',
    firstName: 'Salma',
    lastName: 'Tayaa',
    gender: 'Female',
    role: UserRole.NURSE,
    linkedTo: '69905384-0ef2-43b2-885e-851d5905d5e8',
  },
  {
    email: 'raisshassan@example.com',
    firstName: 'Hassan',
    lastName: 'Raiss',
    gender: 'Male',
    role: UserRole.DOCTOR,
  },
  {
    email: 'amina.laarbi@example.com',
    firstName: 'Amina',
    lastName: 'Laarbi',
    gender: 'Female',
    role: UserRole.NURSE,
    linkedTo: '978e2709-37fa-4a90-95e8-e3af5105d8b2',
  },
  {
    email: 'sara.mountasir@gmail.com',
    firstName: 'Sara',
    lastName: 'Mountasir',
    gender: 'Female',
    role: UserRole.DOCTOR,
  },
  {
    email: 'amira.salim@gmail.com',
    firstName: 'amira',
    lastName: 'salim',
    gender: 'Female',
    role: UserRole.NURSE,
    linkedTo: '827d469c-acf8-4f9b-80fd-1d3b68ec92e5',
  },
  {
    email: 'aliixsaml996@gmail.com',
    firstName: 'ali salem',
    lastName: 'essouiah',
    gender: 'Male',
    role: UserRole.DOCTOR,
  },
  {
    email: 'mohamed.motawakil@gmail.com',
    firstName: 'mohamed',
    lastName: 'motawakil',
    gender: 'Male',
    role: UserRole.DOCTOR,
  },
  {
    email: 'fathi.badda@example.com',
    firstName: 'fathi',
    lastName: 'badda',
    gender: 'Male',
    role: UserRole.DOCTOR,
  },
  {
    email: 'driss.bakhouch@gmail.com',
    firstName: 'driss',
    lastName: 'bakhouch',
    gender: 'Male',
    role: UserRole.NURSE,
  },
  {
    email: 'nihad.baqali@nurse.com',
    firstName: 'nihad',
    lastName: 'baqali',
    gender: 'Female',
    role: UserRole.NURSE,
  },
];
