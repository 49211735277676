import React, { useCallback, useEffect } from 'react';
import { useMediaQuery } from 'react-responsive';
import { Color, DocColor, StyleSheet } from '../../assets/styles/constantStyles';
import { CloseIcon } from '../../assets/svg/CloseIcon';
import { bigScreenBreakpoint } from '../domain/entities/ReactResponsiveBreakpoints';
import { Rpx, SRpx } from '../usecases/helpers/DimensionsConverter';

export interface AttachmentAdditionOverlayProps {
  fileName: string;
  examinationType?: string;
  onBrowseClick: () => void;
  onChangeClick: () => void;
  onValidClick: () => void;
  onCancelClick: () => void;
  isClosedState: boolean;
}
export const AttachmentAdditionOverlay = ({
  fileName,
  examinationType,
  isClosedState,
  onBrowseClick,
  onChangeClick,
  onValidClick,
  onCancelClick,
}: AttachmentAdditionOverlayProps) => {
  // isBigScreen working as a media breakpoint
  const isBigScreen = useMediaQuery({ query: `(min-width: ${bigScreenBreakpoint}px)` });

  useEffect(() => {
    document.addEventListener('keydown', escFunction, false);

    return () => {
      document.removeEventListener('keydown', escFunction, false);
    };
  }, []);

  const escFunction = useCallback((event) => {
    if (event.key === 'Escape') {
      onCancelClick();
    }
  }, []);

  return (
    <div
      style={{
        ...styles.container,
        display: isClosedState ? 'none' : '',
      }}
    >
      <div
        style={
          isBigScreen
            ? { ...styles.confirmContainer }
            : { ...styles.confirmContainer, ...responsiveStyles.confirmContainer }
        }
      >
        <div
          style={isBigScreen ? { ...styles.closeButton } : { ...styles.closeButton, ...responsiveStyles.closeButton }}
          onKeyDown={() => onCancelClick()}
          tabIndex={0}
          role="button"
          onClick={() => onCancelClick()}
        >
          <CloseIcon color={DocColor.DARKBLUE} />
        </div>
        <p
          style={
            isBigScreen
              ? {
                  ...styles.paragraph,
                  fontSize: Rpx(30, true),
                  display: fileName !== '' ? 'none' : ' ',
                }
              : {
                  ...styles.paragraph,
                  ...responsiveStyles.paragraph,
                  fontSize: SRpx(18, true),
                  display: fileName !== '' ? 'none' : ' ',
                }
          }
        >
          Ajouter une pièce jointe pour
        </p>
        <p
          style={
            isBigScreen
              ? { ...styles.paragraph, fontSize: Rpx(30, true), color: DocColor.DARKBLUE, fontWeight: 700 }
              : {
                  ...styles.paragraph,
                  ...responsiveStyles.paragraph,
                  fontSize: SRpx(18, true),
                  color: DocColor.DARKBLUE,
                  fontWeight: 700,
                }
          }
        >
          {examinationType || ''}
        </p>
        <p
          style={
            isBigScreen
              ? {
                  ...styles.paragraph,
                  fontSize: Rpx(30, true),
                  display: fileName === '' ? 'none' : ' ',
                }
              : {
                  ...styles.paragraph,
                  ...responsiveStyles.paragraph,
                  fontSize: SRpx(18, true),
                  display: fileName === '' ? 'none' : ' ',
                }
          }
        >
          Fichier sélectionné
        </p>
        <p
          style={
            isBigScreen
              ? {
                  ...styles.paragraph,
                  fontSize: Rpx(30, true),
                  color: DocColor.MEDIUMBLUE,
                  fontWeight: 700,
                  display: fileName === '' ? 'none' : ' ',
                }
              : {
                  ...styles.paragraph,
                  ...responsiveStyles.paragraph,
                  fontSize: SRpx(18, true),
                  color: DocColor.MEDIUMBLUE,
                  fontWeight: 700,
                  display: fileName === '' ? 'none' : ' ',
                }
          }
        >
          {fileName}
        </p>
        <div style={isBigScreen ? { ...styles.buttonsDiv } : { ...styles.buttonsDiv, ...responsiveStyles.buttonsDiv }}>
          <button
            style={
              isBigScreen
                ? {
                    ...styles.button,
                    height: Rpx(75, true),
                    fontSize: Rpx(30, true),
                    display: fileName !== '' ? 'none' : ' ',
                  }
                : {
                    ...styles.button,
                    ...responsiveStyles.button,
                    width: '60%',
                    height: SRpx(40, true),
                    fontSize: SRpx(15, true),
                    display: fileName !== '' ? 'none' : ' ',
                  }
            }
            type="button"
            onClick={onBrowseClick}
          >
            Parcourir
          </button>
          <button
            style={
              isBigScreen
                ? {
                    ...styles.button,
                    height: Rpx(75, true),
                    fontSize: Rpx(30, true),
                    display: fileName === '' ? 'none' : ' ',
                  }
                : {
                    ...styles.button,
                    ...responsiveStyles.button,
                    width: '100%',
                    height: SRpx(40, true),
                    fontSize: SRpx(15, true),
                    display: fileName === '' ? 'none' : ' ',
                  }
            }
            type="button"
            onClick={onChangeClick}
          >
            Changer le fichier
          </button>
          <button
            style={
              isBigScreen
                ? {
                    ...styles.button,
                    height: Rpx(75, true),
                    fontSize: Rpx(30, true),
                    display: fileName === '' ? 'none' : ' ',
                  }
                : {
                    ...styles.button,
                    ...responsiveStyles.button,
                    width: '60%',
                    height: SRpx(40, true),
                    fontSize: SRpx(15, true),
                    display: fileName === '' ? 'none' : ' ',
                  }
            }
            type="button"
            onClick={onValidClick}
          >
            Valider
          </button>
          <button
            style={
              isBigScreen
                ? { ...styles.button, height: Rpx(75, true), fontSize: Rpx(30, true), background: DocColor.DARKBLUE }
                : {
                    ...styles.button,
                    ...responsiveStyles.button,
                    width: '35%',
                    height: SRpx(40, true),
                    fontSize: SRpx(15, true),
                    background: DocColor.DARKBLUE,
                  }
            }
            type="button"
            onClick={onCancelClick}
          >
            Annuler
          </button>
        </div>
      </div>
    </div>
  );
};

const styles: StyleSheet = {
  container: {
    position: 'fixed',
    inset: 0,
    width: '100%',
    height: '100%',
    backgroundColor: DocColor.HALFTRANSPARENTBLACK,
    zIndex: 6,
  },
  confirmContainer: {
    position: 'relative',
    margin: 'auto',
    backgroundColor: DocColor.CLOUDYBLUE,
    minWidth: '35%',
    width: 'fit-content',
    height: 'fit-content',
    padding: `${Rpx(70)} ${Rpx(50)}`,
    borderRadius: Rpx(33),
    zIndex: 2,
    justifyContent: 'center',
    alignItems: 'center',
  },
  closeButton: {
    position: 'absolute',
    inset: 0,
    margin: `${Rpx(20)} ${Rpx(20)} 0 auto`,
    background: 'white',
    width: Rpx(30),
    height: Rpx(30),
    borderRadius: Rpx(7),
    padding: Rpx(10),
    cursor: 'pointer',
    zIndex: 2,
  },
  buttonsDiv: {
    marginTop: Rpx(65),
    flexDirection: 'row',
    justifyContent: 'space-between',
    gap: Rpx(50),
  },
  paragraph: {
    fontWeight: 500,
    marginBottom: Rpx(25),
    width: 'fit-content',
  },
  button: {
    width: 'fit-content',
    backgroundColor: DocColor.MEDIUMBLUE,
    color: Color.APPWHITE,
    borderRadius: Rpx(15),
    flexDirection: 'row',
    cursor: 'pointer',
    padding: `0 ${Rpx(40)}`,
    fontWeight: 500,
    whiteSpace: 'nowrap',
    letterSpacing: Rpx(5),
    textTransform: 'uppercase',
  },
};

const responsiveStyles: StyleSheet = {
  confirmContainer: {
    width: '90%',
    maxWidth: '90%',
    padding: `${SRpx(30)} 0 ${SRpx(23)} 0`,
    borderRadius: SRpx(8),
  },
  closeButton: {
    margin: `${SRpx(10)} ${SRpx(10)} 0 auto`,
    width: SRpx(15),
    height: SRpx(15),
    borderRadius: SRpx(4),
    padding: SRpx(5),
  },
  buttonsDiv: {
    width: '90%',
    flexFlow: 'row wrap',
    marginTop: SRpx(20),
    gap: SRpx(10),
  },
  paragraph: {
    marginBottom: SRpx(15),
  },
  button: {
    height: SRpx(48),
    borderRadius: SRpx(5),
    padding: `0 ${SRpx(10)}`,
    fontSize: SRpx(15),
    letterSpacing: SRpx(3),
  },
};
