import { hardCodedNotes, Notes } from '../../domain/entities/Notes';
import { hardCodedPatients, Patient } from '../../domain/entities/Patient';
import { hardCodedTreatmentsAntecedents, TreatmentsAntecedents } from '../../domain/entities/TreatmentsAntecedents';
import { PatientsInteractor } from '../../domain/gateways/Patients.interactor';

export class InMemoryPatientsInteractor implements PatientsInteractor {
  loadAllPatients(): Promise<Patient[]> {
    return Promise.resolve(hardCodedPatients);
  }

  loadPatient(): Promise<Patient> {
    throw new Error('Method not implemented.');
  }

  fetchPatients(): Promise<Patient[]> {
    throw new Error('Method not implemented.');
  }

  getTemporaryPatientNumber(): Promise<number> {
    return Promise.resolve(0);
  }

  loadPatientsNames(): Promise<{ patientId: string; fullName: string }[]> {
    return Promise.resolve([]);
  }

  insertPatient(newPatient: Patient): Promise<Patient> {
    return Promise.resolve({ ...newPatient, newPatient });
  }

  updatePatient(updatePatient: Patient): Promise<Patient> {
    return Promise.resolve({ ...updatePatient, updatePatient });
  }

  deletePatient(): Promise<boolean> {
    return Promise.resolve(true);
  }

  loadNotes(): Promise<Notes> {
    return Promise.resolve(hardCodedNotes);
  }

  updateNotes(notes: Notes): Promise<Notes> {
    return Promise.resolve(notes);
  }

  loadTreatmentsAntecedents(): Promise<TreatmentsAntecedents> {
    return Promise.resolve(hardCodedTreatmentsAntecedents);
  }

  updateTreatmentsAntecedents(): Promise<TreatmentsAntecedents> {
    throw new Error('Method not implemented.');
  }
}
