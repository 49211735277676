import React from 'react';
import { Color } from '../styles/constantStyles';

export interface CheckMarkIconProps {
  color?: string;
  style?: React.CSSProperties;
  width?: string | number;
  height?: string | number;
}

export const CheckMarkIcon = ({ color, width, height, style }: CheckMarkIconProps) => (
  <svg style={style} width={width} height={height} viewBox="0 0 18 14" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M17.384 2.38388C17.8721 1.89573 17.8721 1.10427 17.384 0.616117C16.8958 0.127961 16.1044 0.127961 15.6162 0.616116L17.384 2.38388ZM6.00006 12L5.11618 12.8839C5.60434 13.3721 6.39579 13.3721 6.88395 12.8839L6.00006 12ZM2.38388 6.61611C1.89572 6.12796 1.10427 6.12796 0.616113 6.61612C0.12796 7.10428 0.127963 7.89573 0.61612 8.38389L2.38388 6.61611ZM15.6162 0.616116L5.11618 11.1161L6.88395 12.8839L17.384 2.38388L15.6162 0.616116ZM0.61612 8.38389L5.11618 12.8839L6.88394 11.1161L2.38388 6.61611L0.61612 8.38389Z"
      fill={color || Color.MONOGREY3}
    />
  </svg>
);
