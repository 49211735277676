import { fetchWithToken } from '../../../common/adapter/fetch.helper';
import { BACKEND_CONFIG } from '../../../configuration';
import { Notes } from '../../domain/entities/Notes';
import { Patient } from '../../domain/entities/Patient';
import { TreatmentsAntecedents } from '../../domain/entities/TreatmentsAntecedents';
import { PatientsInteractor } from '../../domain/gateways/Patients.interactor';

export class RealPatientsInteractor implements PatientsInteractor {
  async loadAllPatients() {
    const url = `${BACKEND_CONFIG.endpoints.patients}/loadAllPatients`;
    const response = await fetchWithToken(url); // need to do token stuff first
    const result = await response.json();
    return result;
  }

  async loadPatient(id: string) {
    const url = `${BACKEND_CONFIG.endpoints.patients}/loadPatient`;
    const response = await fetchWithToken(`${url}?id=${id}`); // need to do token stuff first
    const result = await response.json();
    return result;
  }

  async fetchPatients(value: string) {
    const url = `${BACKEND_CONFIG.endpoints.patients}/fetchPatients`;
    const response = await fetchWithToken(`${url}?value=${value}`); // need to do token stuff first
    const result = await response.json();
    return result;
  }

  async getTemporaryPatientNumber() {
    const url = `${BACKEND_CONFIG.endpoints.patients}/getTemporaryPatientNumber`;
    const response = await fetchWithToken(url); // need to do token stuff first
    const result = await response.json();
    return result;
  }

  async loadPatientsNames() {
    const url = `${BACKEND_CONFIG.endpoints.patients}/loadPatientsNames`;
    const response = await fetchWithToken(url); // need to do token stuff first
    const result = await response.json();
    return result;
  }

  async insertPatient(newPatient: Patient): Promise<Patient> {
    const url = `${BACKEND_CONFIG.endpoints.patients}/insertPatient`;
    const response = await fetchWithToken(url, {
      method: 'POST',
      body: JSON.stringify(newPatient),
    });
    // const response = await fetchWithToken(url); // need to do token stuff first
    const result = await response.json();
    return result;
  }

  async updatePatient(updatePatient: Patient) {
    const url = `${BACKEND_CONFIG.endpoints.patients}/updatePatient`;
    const response = await fetchWithToken(url, {
      method: 'PUT',
      body: JSON.stringify(updatePatient),
    });
    // const response = await fetchWithToken(url); // need to do token stuff first
    const result = await response.json();
    return result;
  }

  async deletePatient(lookId: string) {
    const url = `${BACKEND_CONFIG.endpoints.patients}/deletePatient`;
    const response = await fetchWithToken(`${url}?lookId=${lookId}`, { method: 'DELETE' });
    // const response = await fetchWithToken(url); // need to do token stuff first
    const result = await response.json();
    return result;
  }

  async loadNotes(patientId: string) {
    const url = `${BACKEND_CONFIG.endpoints.patients}/loadNotes`;
    const response = await fetchWithToken(`${url}?patientId=${patientId}`); // need to do token stuff first
    const result = await response.json();
    return result;
  }

  async updateNotes(notes: Notes) {
    const url = `${BACKEND_CONFIG.endpoints.patients}/updateNotes`;
    const response = await fetchWithToken(url, {
      method: 'PUT',
      body: JSON.stringify(notes),
    });
    // const response = await fetchWithToken(url); // need to do token stuff first
    const result = await response.json();
    return result;
  }

  async loadTreatmentsAntecedents(patientId: string) {
    const url = `${BACKEND_CONFIG.endpoints.patients}/loadTreatmentsAndAntecedents`;
    const response = await fetchWithToken(`${url}?patientId=${patientId}`); // need to do token stuff first
    const result = await response.json();
    return result;
  }

  async updateTreatmentsAntecedents(treatmentsAndAntecedents: TreatmentsAntecedents) {
    const url = `${BACKEND_CONFIG.endpoints.patients}/updateTreatmentsAndAntecedents`;
    const response = await fetchWithToken(url, {
      method: 'PUT',
      body: JSON.stringify(treatmentsAndAntecedents),
    });
    // const response = await fetchWithToken(url); // need to do token stuff first
    const result = await response.json();
    return result;
  }
}
