import * as React from 'react';
import { Color } from '../styles/constantStyles';

export interface CircledToRightArrowProps {
  color?: string;
  style?: React.CSSProperties;
  width?: string | number;
  height?: string | number;
}

export const CircledToRightArrow = ({ color, style, width, height }: CircledToRightArrowProps) => (
  <svg width={width} height={height} viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg" style={style}>
    <path
      d="M9.43934 18.1893C8.85355 18.7751 8.85355 19.7249 9.43934 20.3107C10.0251 20.8964 10.9749 20.8964 11.5607 20.3107L9.43934 18.1893ZM16.75 13L17.8107 14.0607C18.3964 13.4749 18.3964 12.5251 17.8107 11.9393L16.75 13ZM11.5607 5.68934C10.9749 5.10355 10.0251 5.10355 9.43934 5.68934C8.85355 6.27513 8.85355 7.22487 9.43934 7.81066L11.5607 5.68934ZM11.5607 20.3107L17.8107 14.0607L15.6893 11.9393L9.43934 18.1893L11.5607 20.3107ZM17.8107 11.9393L11.5607 5.68934L9.43934 7.81066L15.6893 14.0607L17.8107 11.9393ZM22.75 13C22.75 18.3848 18.3848 22.75 13 22.75V25.75C20.0416 25.75 25.75 20.0416 25.75 13H22.75ZM13 22.75C7.61522 22.75 3.25 18.3848 3.25 13H0.25C0.25 20.0416 5.95837 25.75 13 25.75V22.75ZM3.25 13C3.25 7.61522 7.61522 3.25 13 3.25V0.25C5.95837 0.25 0.25 5.95837 0.25 13H3.25ZM13 3.25C18.3848 3.25 22.75 7.61522 22.75 13H25.75C25.75 5.95837 20.0416 0.25 13 0.25V3.25Z"
      fill={color || Color.MONOGREY3}
    />
  </svg>
);
