import { Action } from 'redux';
import { connect } from 'react-redux';
import { dispatchAsync } from 'react-redux-dispatch-async';
import { PatientConsultationsDumb } from './PatientConsultations.dumb';
import { AppState } from '../../../common/configuration/app.states';
import { NavigationPayload } from '../../../navigationContext/domain/entities/NavigationPayload';
import { navigateBackRequest, navigateToRequest } from '../../../navigationContext/usecases/navigation.actions';
import { getPatientsConsultations, getTodaysConsultationsStats } from '../../usecases/Consultations.selectors';
import {
  deletePatientConsultationRequest,
  fetchPatientsConsultationsRequest,
  getTodaysConsultationsStatsRequest,
  loadPatientsConsultationsRequest,
} from '../../usecases/Consultations.actions';
import { loadNextAppointmentRequest } from '../../../calendarContext/usecases/Calendar.actions';
import { getConnectedUser } from '../../../authContext/usecases/Auth.selectors';
import { fetchUserRequest } from '../../../authContext/usecases/Auth.actions';

const mapState = (state: AppState) => ({
  stateConnectedUser: getConnectedUser(state),
  statePatientsConsultations: getPatientsConsultations(state),
  stateTodaysConsultationsStats: getTodaysConsultationsStats(state),
});

const mapDispatch = (dispatch: (a: Action) => void) => ({
  // navigateTo: (nav: NavigationPayload) => dispatch(navigateTo(nav)),
  // loadUserAsync: (id: string) => dispatchAsync(dispatch, loadUserRequest(id)),
  fetchUserAsync: (id: string) => dispatchAsync(dispatch, fetchUserRequest(id)),
  loadPatientsConsultationsAsync: (patientId?: string, consultationsLimit?: number, skipConsultations?: number) =>
    dispatchAsync(
      dispatch,
      loadPatientsConsultationsRequest({
        patientId,
        consultationsLimit,
        skipConsultations,
      }),
    ),
  fetchPatientsConsultationsAsync: (value: string) => dispatchAsync(dispatch, fetchPatientsConsultationsRequest(value)),
  getTodaysConsultationsStatsAsync: () => dispatchAsync(dispatch, getTodaysConsultationsStatsRequest()),
  deletePatientConsultationAsync: (lookId: string) => dispatchAsync(dispatch, deletePatientConsultationRequest(lookId)),
  loadNextAppointmentAsync: () => dispatchAsync(dispatch, loadNextAppointmentRequest()),
  navigateToAsync: (nav: NavigationPayload) => dispatch(navigateToRequest(nav)),
  navigateBack: () => dispatch(navigateBackRequest()),
});

export const PatientConsultationsSmart = connect(mapState, mapDispatch)(PatientConsultationsDumb);
