import React, { useEffect, useState } from 'react';
import { MedicalCertificateTemplateOverlay } from './MedicalCertificateTemplateOverlay';
import { DocColor, StyleSheet } from '../../../../assets/styles/constantStyles';
import { EyeIcon } from '../../../../assets/svg/EyeIcon';
import { useLoaderContext } from '../../../../common/configuration/loaderContextProvider';
import { ButtonWithIcon } from '../../../../common/ui/ButtonWithIcon';
import { CustomDateInput } from '../../../../common/ui/CustomDateInput';
import { CustomInput } from '../../../../common/ui/CustomInput';
import { Rpx } from '../../../../common/usecases/helpers/DimensionsConverter';
import { ConsultationAdditionFilledDataType } from '../../../domain/entities/ConsultationAdditionFilledDataType';
import { MedicalCertificate } from '../../../domain/entities/MedicalCertificate';
import { Patient } from '../../../../patientsContext/domain/entities/Patient';

type MedicalCertificateComponentProps = {
  patient?: Patient | null;
  temporaryPatientName: string;
  medicalCertificateData: MedicalCertificate | undefined;
  isOnlyReadable?: boolean;
  setFilledData: React.Dispatch<React.SetStateAction<ConsultationAdditionFilledDataType>>;
  disabled?: boolean;
};

export const MedicalCertificateComponent = ({
  patient,
  temporaryPatientName,
  isOnlyReadable,
  medicalCertificateData,
  setFilledData,
  disabled,
}: MedicalCertificateComponentProps) => {
  const loader = useLoaderContext();
  const [numberOfDays, setNumberOfDays] = useState<string | undefined>(undefined);
  const [startDate, setStartDate] = useState<Date | undefined>(new Date());
  const [endDate, setEndDate] = useState<Date | undefined>(undefined);
  const [isMedicalCertificateTemplateOverlayClosed, setIsMedicalCertificateTemplateOverlayClosed] =
    useState<boolean>(true);

  useEffect(() => {
    if (isOnlyReadable === true && medicalCertificateData) {
      const startDateTime: Date | undefined = medicalCertificateData.startDateTime
        ? new Date(medicalCertificateData.startDateTime)
        : undefined;
      const endDateTime: Date | undefined = medicalCertificateData.endDateTime
        ? new Date(medicalCertificateData.endDateTime)
        : undefined;
      setStartDate(startDateTime);
      setEndDate(endDateTime);
    }
  }, [medicalCertificateData]);

  useEffect(() => {
    if (isOnlyReadable !== true) {
      setFilledData((prevState) => ({
        ...prevState,
        medicalCertificate: { startDateTime: startDate, endDateTime: endDate },
      }));
    }
  }, [numberOfDays, startDate, endDate]);

  useEffect(() => {
    if (isOnlyReadable !== true) {
      generateEndDate();
    }
  }, [numberOfDays, startDate]);

  useEffect(() => {
    generateNumberOfDays();
  }, [endDate]);

  const generateEndDate = () => {
    if (startDate && numberOfDays) {
      const generatedEndDate: Date | undefined = new Date(startDate);
      if (generatedEndDate) {
        generatedEndDate.setDate(generatedEndDate.getDate() + parseInt(numberOfDays));
        setEndDate(generatedEndDate);
      }
    }
  };
  const generateNumberOfDays = () => {
    if (startDate && endDate) {
      const differenceInTime: number = (endDate.getTime() - startDate.getTime()) / (1000 * 3600 * 24);
      setNumberOfDays(!Number.isNaN(differenceInTime) ? differenceInTime.toString() : '');
    }
  };

  const showMedicalCertificatePdfHandler = () => {
    loader.setMessage('Ouverture du fichier en cours');
    setIsMedicalCertificateTemplateOverlayClosed(false);
  };

  return (
    <div style={{ ...styles.container, outlineColor: disabled ? DocColor.GRAY : DocColor.DARKBLUE }}>
      {!isMedicalCertificateTemplateOverlayClosed && (
        <MedicalCertificateTemplateOverlay
          onSuccess={() => {
            setIsMedicalCertificateTemplateOverlayClosed(true);
            loader.setMessage('');
          }}
          onFailure={() => {
            setIsMedicalCertificateTemplateOverlayClosed(true);
            loader.setMessage('');
          }}
          patient={patient}
          temporaryPatientName={temporaryPatientName}
        />
      )}
      <div style={{ ...styles.titleDiv, background: disabled ? DocColor.GRAY : DocColor.DARKBLUE }}>
        <p key="firstHistoryDateDiv" style={{ ...styles.title, fontSize: Rpx(32, true) }}>
          Certificat Médical
        </p>
      </div>
      <div style={{ width: '90%', margin: '0 auto' }}>
        <div>
          <CustomInput
            disabled={disabled || isOnlyReadable}
            style={{
              ...styles.customInput,
              height: Rpx(50, true),
              fontSize: Rpx(25, true),
              color: disabled ? DocColor.GRAY : DocColor.DARKBLUE,
            }}
            min={0}
            inputNameStyle={{ color: disabled ? DocColor.GRAY : DocColor.DARKBLUE, fontWeight: 700 }}
            inputName="Nombre de jours de repos"
            type="number"
            width="100%"
            defaultValue={numberOfDays}
            onChange={(v) => {
              setNumberOfDays(v.target.value);
            }}
          />
          <div
            style={{
              ...styles.inputsDiv,
              display: !numberOfDays || disabled ? 'none' : '',
            }}
          >
            <CustomDateInput
              dateInputName="Du"
              disabled={disabled || isOnlyReadable}
              onChange={(v) => {
                setStartDate(v.target.valueAsDate ? v.target.valueAsDate : undefined);
              }}
              valueAsDate={startDate}
            />
            <CustomDateInput
              dateInputName="Au"
              disabled={disabled || isOnlyReadable}
              onChange={(v) => setEndDate(v.target.valueAsDate ? v.target.valueAsDate : undefined)}
              valueAsDate={endDate}
            />
          </div>
        </div>

        <div style={{ alignItems: 'end', marginTop: Rpx(40) }}>
          <div
            style={{
              flexDirection: 'row',
              gap: Rpx(30),
              display: !numberOfDays ? 'none' : '',
            }}
          >
            <ButtonWithIcon
              disabled={disabled}
              label="Afficher un aperçu du certificat"
              style={{ ...styles.button, fontSize: Rpx(20, true), height: Rpx(46, true) }}
              icon={EyeIcon({
                ...styles.icon,
                width: Rpx(26, true),
                height: Rpx(26, true),
                isClosed: false,
                style: { margin: `auto 0 auto ${Rpx(10)}` },
              })}
              onClick={() => showMedicalCertificatePdfHandler()}
            />
            {/* <ButtonWithIcon
              disabled={disabled}
              label="Imprimer l’ordonnance"
              style={{ ...styles.button, fontSize: Rpx(20, true), height: Rpx(46, true) }}
              icon={PrinterIcon({
                ...styles.icon,
                width: Rpx(26, true),
                height: Rpx(26, true),
                style: { margin: `auto 0 auto ${Rpx(10)}` },
              })}
              onClick={() => {
                const dd: Date = new Date();
                dd.setDate(dd.getDate() + 1);
              }}
            /> */}
          </div>
        </div>
      </div>
    </div>
  );
};
const styles: StyleSheet = {
  container: {
    position: 'relative',
    margin: `2vw 2vw 0`,
    outline: `${Rpx(10)} solid ${DocColor.DARKBLUE}`,
    borderRadius: Rpx(60),
    minHeight: Rpx(100),
    paddingBottom: Rpx(47),
  },
  inputsDiv: {
    flexDirection: 'row',
    justifyContent: 'center',
    gap: '10%',
    marginTop: Rpx(20),
  },
  titleDiv: {
    width: 'fit-content',
    background: DocColor.DARKBLUE,
    borderRadius: `${Rpx(50)} 0 ${Rpx(20)} 0`,
    minHeight: Rpx(60),
    padding: `0 ${Rpx(40)}`,
    marginBottom: Rpx(15),
  },
  title: {
    color: 'white',
    fontWeight: 600,
    fontStyle: 'italic',
    margin: 'auto',
  },
  customInput: {
    textAlign: 'center',
    fontWeight: 700,
    color: DocColor.DARKBLUE,
    borderRadius: Rpx(5),
  },
  button: {
    borderRadius: Rpx(13),
  },
  icon: {
    color: 'white',
  },
};
