import * as React from 'react';
import { Color } from '../styles/constantStyles';

export interface PenIconProps {
  color?: string;
  style?: React.CSSProperties;
  width?: string | number;
  height?: string | number;
}

export const PenIcon = ({ color, width, height, style }: PenIconProps) => (
  <svg style={style} width={width} height={height} viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M14.2353 26.75C13.5449 26.75 12.9853 27.3096 12.9853 28C12.9853 28.6904 13.5449 29.25 14.2353 29.25V26.75ZM27.2353 29.25C27.9256 29.25 28.4853 28.6904 28.4853 28C28.4853 27.3096 27.9256 26.75 27.2353 26.75V29.25ZM2 28H0.75C0.75 28.6904 1.30964 29.25 2 29.25L2 28ZM2 21.8824L1.11612 20.9985C0.881696 21.2329 0.75 21.5508 0.75 21.8824H2ZM21.8824 2L22.7662 1.11612C22.2781 0.627961 21.4866 0.627961 20.9985 1.11612L21.8824 2ZM28 8.11765L28.8839 9.00153C29.372 8.51338 29.372 7.72192 28.8839 7.23376L28 8.11765ZM8.11765 28V29.25C8.44917 29.25 8.76711 29.1183 9.00153 28.8839L8.11765 28ZM14.2353 29.25H27.2353V26.75H14.2353V29.25ZM3.25 28V21.8824H0.75V28H3.25ZM20.9985 2.88388L27.1161 9.00153L28.8839 7.23376L22.7662 1.11612L20.9985 2.88388ZM8.11765 26.75H2V29.25H8.11765V26.75ZM27.1161 7.23376L22.5279 11.822L24.2956 13.5898L28.8839 9.00153L27.1161 7.23376ZM22.5279 11.822L7.23376 27.1161L9.00153 28.8839L24.2956 13.5898L22.5279 11.822ZM2.88388 22.7662L18.178 7.47212L16.4102 5.70435L1.11612 20.9985L2.88388 22.7662ZM18.178 7.47212L22.7662 2.88388L20.9985 1.11612L16.4102 5.70435L18.178 7.47212ZM24.2956 11.822L18.178 5.70435L16.4102 7.47212L22.5279 13.5898L24.2956 11.822Z"
      fill={color || Color.MONOGREY3}
    />
  </svg>
);
