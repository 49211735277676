import React, { useState } from 'react';
import { DispatchAsyncResult } from 'react-redux-dispatch-async/lib/dispatchAsync';
import { SubstancedMedicinesOverlay } from './SubstancedMedicinesOverlay';
import { DocColor, StyleSheet } from '../../../../assets/styles/constantStyles';
import { Rpx } from '../../../../common/usecases/helpers/DimensionsConverter';
import { Prescription } from '../../../domain/entities/Prescriptions';
import { CustomInput } from '../../../../common/ui/CustomInput';
import CommonFunctions from '../../../../common/usecases/helpers/CommonFunctions';
import { Medicine } from '../../../domain/entities/Medicine';
import { CustomCheckBox } from '../../../../common/ui/CustomCheckButton';
import { ConfirmOverlay } from '../../../../common/ui/ConfirmOverlay';

interface PrescriptionRowProps {
  medicinesData: Medicine[];
  medicineInputOptionsState: {
    id: string;
    value: string;
  }[];
  dataState: Prescription;
  prescriptionDataState: Prescription[];
  index: number;
  isOnlyReadable?: boolean;
  disabled?: boolean;
  medicineInputChosenOption: {
    id: string;
    value: string;
  };
  setMedicineInputChosenOption: React.Dispatch<
    React.SetStateAction<{
      id: string;
      value: string;
    }>
  >;
  setPrescriptionDataState: React.Dispatch<React.SetStateAction<Prescription[]>>;
  fetchMedicinesAsync: (value?: string, composition?: string) => Promise<DispatchAsyncResult>;
}

export const PrescriptionRow = ({
  medicinesData,
  medicineInputOptionsState,
  dataState,
  prescriptionDataState,
  index,
  isOnlyReadable,
  disabled,
  medicineInputChosenOption,
  setMedicineInputChosenOption,
  setPrescriptionDataState,
  fetchMedicinesAsync,
}: PrescriptionRowProps) => {
  const [isConfirmFragmentClosed, setIsConfirmFragmentClosed] = useState<boolean>(true);
  const [isSubstancedMedicinesOverlayClosed, setIsSubstancedMedicinesOverlayClosed] = useState<boolean>(true);

  const updatePrescriptionHandler = (
    id: number,
    medicineName?: string,
    medicineForm?: string,
    medicineComposition?: string,
    quantity?: string,
    measure?: 'càs' | 'càc' | 'càm' | '',
    doseWeight?: number,
    numberOfUses?: number,
    frequency?: 'daily' | 'weekly' | '',
    duration?: string,
    consumptionTime?: ('before meal' | 'during meal' | 'after meal' | 'morning' | 'noon' | 'Evening' | '')[],
    note?: string,
  ) => {
    const tempData: Prescription[] = prescriptionDataState;
    if (medicineName !== undefined) {
      tempData[id].medicineName = medicineName;
    }
    if (medicineForm !== undefined) {
      tempData[id].medicineForm = medicineForm;
    }
    if (medicineComposition !== undefined) {
      tempData[id].medicineComposition = medicineComposition;
    }
    if (quantity !== undefined) {
      tempData[id].quantity = quantity;
    }
    if (measure !== undefined) {
      tempData[id].measure = measure;
    }
    if (doseWeight !== undefined) {
      tempData[id].doseWeight = doseWeight;
    }
    if (numberOfUses !== undefined) {
      tempData[id].numberOfUses = numberOfUses;
    }
    if (frequency !== undefined) {
      tempData[id].frequency = frequency;
    }
    if (duration !== undefined) {
      tempData[id].duration = duration;
    }
    if (consumptionTime !== undefined) {
      tempData[id].consumptionTime = consumptionTime;
    }
    if (note !== undefined) {
      tempData[id].note = note;
    }
    setPrescriptionDataState([...tempData]);
  };

  const onDeleteClickHandler = (id: number) => {
    const tempData: Prescription[] = prescriptionDataState;
    if (prescriptionDataState.length === 1) {
      tempData[0].medicineName = '';
      tempData[0].medicineForm = '';
      tempData[0].medicineComposition = '';
      tempData[0].quantity = '';
      tempData[0].measure = '';
      tempData[0].doseWeight = 0;
      tempData[0].numberOfUses = 0;
      tempData[0].frequency = '';
      tempData[0].duration = '';
      tempData[0].consumptionTime = [];
      tempData[0].note = '';
    } else {
      tempData.splice(id, 1);
    }
    setPrescriptionDataState([...tempData]);
  };

  const onConfirmClick = () => {
    onDeleteClickHandler(index);
    setIsConfirmFragmentClosed(true);
  };

  return (
    <div>
      <ConfirmOverlay
        onConfirmClick={onConfirmClick}
        message="L’examen sera supprimé
        Êtes-vous sûr ?"
        isClosedState={isConfirmFragmentClosed}
        setIsClosedState={setIsConfirmFragmentClosed}
      />
      {!isSubstancedMedicinesOverlayClosed && (
        <SubstancedMedicinesOverlay
          setIsSubstancedMedicinesOverlayClosed={setIsSubstancedMedicinesOverlayClosed}
          substance={prescriptionDataState[index].medicineComposition}
          fetchMedicinesAsync={fetchMedicinesAsync}
          onOptionClick={(medicine) => {
            if (medicineInputChosenOption.id !== medicine.uuid) {
              updatePrescriptionHandler(
                index,
                medicine.product_name,
                medicine.product_form,
                medicine.composition,
                '',
                '',
                0,
                0,
                '',
                '',
                [],
                '',
              );
            }
          }}
        />
      )}
      <div style={{ marginTop: Rpx(44) }}>
        <CustomInput
          disabled={disabled || isOnlyReadable}
          onOptionChange={(id, value) => {
            setMedicineInputChosenOption({ id, value });
            updatePrescriptionHandler(
              index,
              value,
              id ? medicinesData.filter((e) => e.uuid === id)[0].product_form : '',
              id ? medicinesData.filter((e) => e.uuid === id)[0].composition : '',
            );
            if (index !== 0 && CommonFunctions.isEmptyOrSpaces(value)) {
              setIsConfirmFragmentClosed(false);
            }
          }}
          options={[...medicineInputOptionsState]}
          loadOptionsMinLength={1}
          dropDownMaxHeight={Rpx(350)}
          style={{
            ...styles.customInput,
            height: Rpx(48, true),
            fontSize: Rpx(25, true),
            color: disabled ? DocColor.GRAY : DocColor.MEDIUMBLUE,
          }}
          height={Rpx(48, true)}
          inputNameStyle={{ color: disabled ? DocColor.GRAY : DocColor.DARKBLUE, fontWeight: 700 }}
          inputName={`Médicament ${index + 1}`}
          inputNameWidth={Rpx(160)}
          defaultValue={prescriptionDataState[index].medicineName || ''}
          placeholder="Entrer un médicament"
        />
        <div
          style={{
            display: CommonFunctions.isEmptyOrSpaces(dataState.medicineName) || disabled ? 'none' : '',
          }}
        >
          <div style={styles.substanceDiv}>
            <p style={{ color: DocColor.DARKBLUE, fontSize: Rpx(24, true), fontWeight: 700 }}>
              Substance active :{' '}
              <span style={{ color: DocColor.MEDIUMBLUE }}>
                {prescriptionDataState[index].medicineComposition !== ''
                  ? prescriptionDataState[index].medicineComposition
                  : '-'}
              </span>{' '}
            </p>
            <button
              type="button"
              disabled={prescriptionDataState[index].medicineComposition === ''}
              style={{
                ...styles.showSubstancedMedsButton,
                background: prescriptionDataState[index].medicineComposition !== '' ? DocColor.DARKBLUE : DocColor.GRAY,
                fontSize: Rpx(24, true),
                display: disabled || isOnlyReadable ? 'none' : '',
              }}
              onClick={() => setIsSubstancedMedicinesOverlayClosed(false)}
            >
              Afficher les médicaments ayant la même substance active
            </button>
          </div>

          <div
            style={{
              flexDirection: 'row',
              gap: Rpx(18),
              alignItems: 'center',
              marginTop: Rpx(30),
              marginLeft: Rpx(180),
            }}
          >
            <CustomInput
              disabled={disabled || isOnlyReadable}
              min={0}
              type={
                !(
                  dataState.medicineForm.toLowerCase().includes('gélule') ||
                  dataState.medicineForm.toLowerCase().includes('gelule') ||
                  dataState.medicineForm.toLowerCase().includes('gél') ||
                  dataState.medicineForm.toLowerCase().includes('gel') ||
                  dataState.medicineForm.toLowerCase().includes('crèm') ||
                  dataState.medicineForm.toLowerCase().includes('collyre') ||
                  dataState.medicineForm.toLowerCase().includes('sachet') ||
                  dataState.medicineForm.toLowerCase().includes('pulvéris') ||
                  dataState.medicineForm.toLowerCase().includes('suppositoire') ||
                  dataState.medicineForm.toLowerCase().includes('comprim') ||
                  dataState.medicineForm.toLowerCase().includes('inject') ||
                  dataState.medicineForm.toLowerCase().includes('sirop') ||
                  dataState.medicineForm.toLowerCase().includes('buvable')
                )
                  ? 'text'
                  : 'number'
              }
              style={{ ...styles.customInput, height: Rpx(48, true), fontSize: Rpx(20, true) }}
              inputName="Quantité"
              inputNameWidth={Rpx(220, true)}
              inputNameStyle={{ fontSize: Rpx(26, true), fontWeight: 600 }}
              width={
                !(
                  dataState.medicineForm.toLowerCase().includes('gélule') ||
                  dataState.medicineForm.toLowerCase().includes('gelule') ||
                  dataState.medicineForm.toLowerCase().includes('gél') ||
                  dataState.medicineForm.toLowerCase().includes('gel') ||
                  dataState.medicineForm.toLowerCase().includes('crèm') ||
                  dataState.medicineForm.toLowerCase().includes('collyre') ||
                  dataState.medicineForm.toLowerCase().includes('sachet') ||
                  dataState.medicineForm.toLowerCase().includes('pulvéris') ||
                  dataState.medicineForm.toLowerCase().includes('suppositoire') ||
                  dataState.medicineForm.toLowerCase().includes('comprim') ||
                  dataState.medicineForm.toLowerCase().includes('inject') ||
                  dataState.medicineForm.toLowerCase().includes('sirop') ||
                  dataState.medicineForm.toLowerCase().includes('buvable')
                )
                  ? '50%'
                  : '35%'
              }
              defaultValue={prescriptionDataState[index].quantity.toString() || '0'}
              onChange={(v) => {
                updatePrescriptionHandler(index, undefined, undefined, undefined, v.target.value);
              }}
            />
            <p style={{ fontSize: Rpx(24, true), fontWeight: 600 }}>
              {dataState.medicineForm.toLowerCase().includes('gélule') ||
              dataState.medicineForm.toLowerCase().includes('gelule')
                ? 'Gélule (s)'
                : dataState.medicineForm.toLowerCase().includes('gél') ||
                  dataState.medicineForm.toLowerCase().includes('gel') ||
                  dataState.medicineForm.toLowerCase().includes('crèm') ||
                  dataState.medicineForm.toLowerCase().includes('collyre')
                ? 'Application (s)'
                : dataState.medicineForm.toLowerCase().includes('sachet')
                ? 'Sachet (s)'
                : dataState.medicineForm.toLowerCase().includes('pulvéris')
                ? 'Pulvirisation (s)'
                : dataState.medicineForm.toLowerCase().includes('suppositoire')
                ? 'Suppositoire (s)'
                : dataState.medicineForm.toLowerCase().includes('comprim')
                ? 'Comprimé (s)'
                : dataState.medicineForm.toLowerCase().includes('inject')
                ? 'Injection (s)'
                : ''}
            </p>
            {(dataState.medicineForm.toLowerCase().includes('sirop') ||
              dataState.medicineForm.toLowerCase().includes('buvable')) &&
              dataState.doseWeight === 0 && (
                <div style={{ flexDirection: 'row', gap: Rpx(30) }}>
                  <CustomCheckBox
                    checkBoxName="càs"
                    checkBoxNameStyle={{ ...styles.checkbox, fontSize: Rpx(24, true) }}
                    selected={prescriptionDataState[index].measure === 'càs'}
                    width={Rpx(24, true)}
                    height={Rpx(24, true)}
                    activeColor={DocColor.DARKBLUE}
                    checkBoxStyle="CROSS"
                    onCheckBoxClick={() =>
                      !disabled &&
                      !isOnlyReadable &&
                      updatePrescriptionHandler(index, undefined, undefined, undefined, undefined, 'càs')
                    }
                  />
                  <CustomCheckBox
                    checkBoxName="càc"
                    checkBoxNameStyle={{ ...styles.checkbox, fontSize: Rpx(24, true) }}
                    selected={prescriptionDataState[index].measure === 'càc'}
                    width={Rpx(24, true)}
                    height={Rpx(24, true)}
                    activeColor={DocColor.DARKBLUE}
                    checkBoxStyle="CROSS"
                    onCheckBoxClick={() =>
                      !disabled &&
                      !isOnlyReadable &&
                      updatePrescriptionHandler(index, undefined, undefined, undefined, undefined, 'càc')
                    }
                  />
                  <CustomCheckBox
                    checkBoxName="càm"
                    checkBoxNameStyle={{ ...styles.checkbox, fontSize: Rpx(24, true) }}
                    selected={prescriptionDataState[index].measure === 'càm'}
                    width={Rpx(24, true)}
                    height={Rpx(24, true)}
                    activeColor={DocColor.DARKBLUE}
                    checkBoxStyle="CROSS"
                    onCheckBoxClick={() =>
                      !disabled &&
                      !isOnlyReadable &&
                      updatePrescriptionHandler(index, undefined, undefined, undefined, undefined, 'càm')
                    }
                  />
                </div>
              )}
          </div>

          {(dataState.medicineForm.toLowerCase().includes('sirop') ||
            dataState.medicineForm.toLowerCase().includes('buvable')) && (
            <div
              style={{
                flexDirection: 'row',
                gap: Rpx(18),
                alignItems: 'center',
                marginLeft: Rpx(420),
              }}
            >
              <CustomInput
                disabled={disabled || isOnlyReadable}
                min={0}
                type="number"
                style={{ ...styles.customInput, height: Rpx(48, true), fontSize: Rpx(20, true) }}
                width="15%"
                defaultValue={prescriptionDataState[index].doseWeight.toString() || '0'}
                onChange={(v) => {
                  updatePrescriptionHandler(
                    index,
                    undefined,
                    undefined,
                    undefined,
                    undefined,
                    undefined,
                    !Number.isNaN(v.target.valueAsNumber) ? v.target.valueAsNumber : 0,
                  );
                }}
              />
              <p style={{ fontSize: Rpx(24, true), fontWeight: 600 }}>Dose / Poids</p>
            </div>
          )}

          <div
            style={{
              flexDirection: 'row',
              gap: Rpx(18),
              alignItems: 'center',
              marginTop: Rpx(30),
              marginLeft: Rpx(180),
            }}
          >
            <CustomInput
              disabled={disabled || isOnlyReadable}
              min={0}
              type="number"
              style={{ ...styles.customInput, height: Rpx(48, true), fontSize: Rpx(20, true) }}
              inputName="Nombre de prises"
              inputNameWidth={Rpx(220, true)}
              inputNameStyle={{ fontSize: Rpx(26, true), fontWeight: 600 }}
              width="35%"
              defaultValue={prescriptionDataState[index].numberOfUses.toString() || '0'}
              onChange={(v) => {
                updatePrescriptionHandler(
                  index,
                  undefined,
                  undefined,
                  undefined,
                  undefined,
                  undefined,
                  undefined,
                  !Number.isNaN(v.target.valueAsNumber) ? v.target.valueAsNumber : 0,
                );
              }}
            />
            <p style={{ fontSize: Rpx(24, true), fontWeight: 600, marginRight: Rpx(75) }}>fois par </p>
            <div style={{ flexDirection: 'row', gap: Rpx(30) }}>
              <CustomCheckBox
                checkBoxName="Jour"
                checkBoxNameStyle={{ ...styles.checkbox, fontSize: Rpx(24, true) }}
                selected={prescriptionDataState[index].frequency === 'daily'}
                width={Rpx(24, true)}
                height={Rpx(24, true)}
                activeColor={DocColor.DARKBLUE}
                checkBoxStyle="CROSS"
                onCheckBoxClick={() =>
                  !disabled &&
                  !isOnlyReadable &&
                  updatePrescriptionHandler(
                    index,
                    undefined,
                    undefined,
                    undefined,
                    undefined,
                    undefined,
                    undefined,
                    undefined,
                    'daily',
                  )
                }
              />
              <CustomCheckBox
                checkBoxName="Semaine"
                checkBoxNameStyle={{ ...styles.checkbox, fontSize: Rpx(24, true) }}
                selected={prescriptionDataState[index].frequency === 'weekly'}
                width={Rpx(24, true)}
                height={Rpx(24, true)}
                activeColor={DocColor.DARKBLUE}
                checkBoxStyle="CROSS"
                onCheckBoxClick={() =>
                  !disabled &&
                  !isOnlyReadable &&
                  updatePrescriptionHandler(
                    index,
                    undefined,
                    undefined,
                    undefined,
                    undefined,
                    undefined,
                    undefined,
                    undefined,
                    'weekly',
                  )
                }
              />
            </div>
          </div>

          <div
            style={{
              flexDirection: 'row',
              gap: Rpx(18),
              alignItems: 'center',
              marginTop: Rpx(30),
              marginLeft: Rpx(180),
            }}
          >
            <CustomInput
              disabled={disabled || isOnlyReadable}
              style={{ ...styles.customInput, height: Rpx(48, true), fontSize: Rpx(20, true), textAlign: 'unset' }}
              inputName="Durée"
              inputNameWidth={Rpx(220, true)}
              inputNameStyle={{ fontSize: Rpx(26, true), fontWeight: 600 }}
              width="80%"
              defaultValue={prescriptionDataState[index].duration || ''}
              onChange={(v) => {
                updatePrescriptionHandler(
                  index,
                  undefined,
                  undefined,
                  undefined,
                  undefined,
                  undefined,
                  undefined,
                  undefined,
                  undefined,
                  v.target.value,
                );
              }}
            />
          </div>
          <div
            style={{
              flexDirection: 'row',
              gap: Rpx(18),
              marginLeft: Rpx(420),
              marginTop: Rpx(20),
            }}
          >
            <p style={{ fontSize: Rpx(20, true), fontWeight: 700, fontStyle: 'italic' }}>Facultatif</p>
            <div
              style={{
                flexFlow: 'row wrap',
                rowGap: Rpx(5),
              }}
            >
              <CustomCheckBox
                style={{ flexBasis: '30%' }}
                checkBoxName="Avant le repas"
                checkBoxNameStyle={{ ...styles.checkbox, fontSize: Rpx(24, true) }}
                selected={prescriptionDataState[index].consumptionTime.includes('before meal')}
                width={Rpx(24, true)}
                height={Rpx(24, true)}
                activeColor={DocColor.DARKBLUE}
                checkBoxStyle="CROSS"
                onCheckBoxClick={() =>
                  !disabled &&
                  !isOnlyReadable &&
                  updatePrescriptionHandler(
                    index,
                    undefined,
                    undefined,
                    undefined,
                    undefined,
                    undefined,
                    undefined,
                    undefined,
                    undefined,
                    undefined,
                    prescriptionDataState[index].consumptionTime.includes('before meal')
                      ? prescriptionDataState[index].consumptionTime.filter((e) => e !== 'before meal')
                      : [...prescriptionDataState[index].consumptionTime, 'before meal'],
                  )
                }
              />
              <CustomCheckBox
                style={{ flexBasis: '30%' }}
                checkBoxName="Pendant le repas"
                checkBoxNameStyle={{ ...styles.checkbox, fontSize: Rpx(24, true) }}
                selected={prescriptionDataState[index].consumptionTime.includes('during meal')}
                width={Rpx(24, true)}
                height={Rpx(24, true)}
                activeColor={DocColor.DARKBLUE}
                checkBoxStyle="CROSS"
                onCheckBoxClick={() =>
                  !disabled &&
                  !isOnlyReadable &&
                  updatePrescriptionHandler(
                    index,
                    undefined,
                    undefined,
                    undefined,
                    undefined,
                    undefined,
                    undefined,
                    undefined,
                    undefined,
                    undefined,
                    prescriptionDataState[index].consumptionTime.includes('during meal')
                      ? prescriptionDataState[index].consumptionTime.filter((e) => e !== 'during meal')
                      : [...prescriptionDataState[index].consumptionTime, 'during meal'],
                  )
                }
              />
              <CustomCheckBox
                style={{ flexBasis: '30%' }}
                checkBoxName="Après le repas"
                checkBoxNameStyle={{ ...styles.checkbox, fontSize: Rpx(24, true) }}
                selected={prescriptionDataState[index].consumptionTime.includes('after meal')}
                width={Rpx(24, true)}
                height={Rpx(24, true)}
                activeColor={DocColor.DARKBLUE}
                checkBoxStyle="CROSS"
                onCheckBoxClick={() =>
                  !disabled &&
                  !isOnlyReadable &&
                  updatePrescriptionHandler(
                    index,
                    undefined,
                    undefined,
                    undefined,
                    undefined,
                    undefined,
                    undefined,
                    undefined,
                    undefined,
                    undefined,
                    prescriptionDataState[index].consumptionTime.includes('after meal')
                      ? prescriptionDataState[index].consumptionTime.filter((e) => e !== 'after meal')
                      : [...prescriptionDataState[index].consumptionTime, 'after meal'],
                  )
                }
              />
              <CustomCheckBox
                style={{ flexBasis: '30%' }}
                checkBoxName="Matin"
                checkBoxNameStyle={{ ...styles.checkbox, fontSize: Rpx(24, true) }}
                selected={prescriptionDataState[index].consumptionTime.includes('morning')}
                width={Rpx(24, true)}
                height={Rpx(24, true)}
                activeColor={DocColor.DARKBLUE}
                checkBoxStyle="CROSS"
                onCheckBoxClick={() =>
                  !disabled &&
                  !isOnlyReadable &&
                  updatePrescriptionHandler(
                    index,
                    undefined,
                    undefined,
                    undefined,
                    undefined,
                    undefined,
                    undefined,
                    undefined,
                    undefined,
                    undefined,
                    prescriptionDataState[index].consumptionTime.includes('morning')
                      ? prescriptionDataState[index].consumptionTime.filter((e) => e !== 'morning')
                      : [...prescriptionDataState[index].consumptionTime, 'morning'],
                  )
                }
              />
              <CustomCheckBox
                style={{ flexBasis: '30%' }}
                checkBoxName="Midi"
                checkBoxNameStyle={{ ...styles.checkbox, fontSize: Rpx(24, true) }}
                selected={prescriptionDataState[index].consumptionTime.includes('noon')}
                width={Rpx(24, true)}
                height={Rpx(24, true)}
                activeColor={DocColor.DARKBLUE}
                checkBoxStyle="CROSS"
                onCheckBoxClick={() =>
                  !disabled &&
                  !isOnlyReadable &&
                  updatePrescriptionHandler(
                    index,
                    undefined,
                    undefined,
                    undefined,
                    undefined,
                    undefined,
                    undefined,
                    undefined,
                    undefined,
                    undefined,
                    prescriptionDataState[index].consumptionTime.includes('noon')
                      ? prescriptionDataState[index].consumptionTime.filter((e) => e !== 'noon')
                      : [...prescriptionDataState[index].consumptionTime, 'noon'],
                  )
                }
              />
              <CustomCheckBox
                style={{ flexBasis: '30%' }}
                checkBoxName="Soir"
                checkBoxNameStyle={{ ...styles.checkbox, fontSize: Rpx(24, true) }}
                selected={prescriptionDataState[index].consumptionTime.includes('Evening')}
                width={Rpx(24, true)}
                height={Rpx(24, true)}
                activeColor={DocColor.DARKBLUE}
                checkBoxStyle="CROSS"
                onCheckBoxClick={() =>
                  !disabled &&
                  !isOnlyReadable &&
                  updatePrescriptionHandler(
                    index,
                    undefined,
                    undefined,
                    undefined,
                    undefined,
                    undefined,
                    undefined,
                    undefined,
                    undefined,
                    undefined,
                    prescriptionDataState[index].consumptionTime.includes('Evening')
                      ? prescriptionDataState[index].consumptionTime.filter((e) => e !== 'Evening')
                      : [...prescriptionDataState[index].consumptionTime, 'Evening'],
                  )
                }
              />
            </div>
          </div>
          <div
            style={{
              width: '100%',
              marginTop: Rpx(20),
            }}
          >
            <CustomInput
              disabled={disabled || isOnlyReadable}
              style={{
                height: Rpx(48, true),
                fontSize: Rpx(24, true),
                fontWeight: 700,
                color: disabled ? DocColor.GRAY : DocColor.DARKBLUE,
              }}
              height={Rpx(48, true)}
              inputNameStyle={{ color: disabled ? DocColor.GRAY : 'black', fontWeight: 600, fontStyle: 'italic' }}
              inputName="Note (Facultatif)"
              inputNameWidth={Rpx(200)}
              defaultValue={prescriptionDataState[index].note || ''}
              onChange={(v) => {
                updatePrescriptionHandler(
                  index,
                  undefined,
                  undefined,
                  undefined,
                  undefined,
                  undefined,
                  undefined,
                  undefined,
                  undefined,
                  undefined,
                  undefined,
                  v.target.value,
                );
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

const styles: StyleSheet = {
  customInput: {
    textAlign: 'center',
    fontWeight: 700,
    color: DocColor.DARKBLUE,
    borderRadius: Rpx(5),
    marginTop: Rpx(10),
  },
  substanceDiv: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginTop: Rpx(30),
    marginLeft: Rpx(180),
  },
  showSubstancedMedsButton: {
    color: 'white',
    fontWeight: 700,
    width: '50%',
    padding: `${Rpx(10)} ${Rpx(25)}`,
    borderRadius: Rpx(15),
  },
  checkbox: {
    fontWeight: 600,
    marginLeft: Rpx(10),
  },
};
