import React from 'react';
import { useMediaQuery } from 'react-responsive';
import { DocColor } from '../../assets/styles/constantStyles';
import { bigScreenBreakpoint } from '../domain/entities/ReactResponsiveBreakpoints';
import { Rpx, SRpx } from '../usecases/helpers/DimensionsConverter';

export const FooterSignature = () => {
  const isBigScreen = useMediaQuery({ query: `(min-width: ${bigScreenBreakpoint}px)` });

  return (
    <div
      style={{
        color: 'white',
        fontSize: isBigScreen ? Rpx(15, false, 'M') : SRpx(15, false, 'M'),
        alignSelf: 'center',
        background: !isBigScreen ? DocColor.DARKBLUE : 'transparent',
        width: '100%',
        alignItems: 'center',
        justifyContent: 'center',
        fontFamily: 'Barlow',
      }}
    >
      <span>
        Powered by{' '}
        <a style={{ color: 'white' }} href="https://fayonation.com/" target="_blank" rel="noreferrer">
          fayonation.com
        </a>
      </span>
    </div>
  );
};
