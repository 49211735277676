import { all, call, put, takeLatest } from 'redux-saga/effects';
import { Action } from 'redux-actions';
import {
  fetchUserFailure,
  fetchUserRequest,
  fetchUserSuccess,
  loadUserFailure,
  loadUserRequest,
  loadUsersFailure,
  loadUsersRequest,
  loadUsersSuccess,
  loadUserSuccess,
  resetPasswordFailure,
  resetPasswordRequest,
  resetPasswordSuccess,
  sendResetPasswordRequestFailure,
  sendResetPasswordRequestRequest,
  sendResetPasswordRequestSuccess,
  sendSignInFailure,
  sendSignInRequest,
  sendSignInSuccess,
  sendSignOutFailure,
  sendSignOutRequest,
  sendSignOutSuccess,
  sendSignUpFailure,
  sendSignUpRequest,
  sendSignUpSuccess,
} from './Auth.actions';
import { getDependencies } from '../../common/usecases/common.sagas';
import { DependenciesType } from '../../configuration/dependencies';
import { User } from '../domain/entities/User';
import { AuthTokens } from '../../common/domain/entities/AuthTokens';
import { UserAccount } from '../domain/entities/UserAccount';

function* loadUsersSaga() {
  try {
    const dep: DependenciesType = yield getDependencies();
    const res: User[] = yield call(dep.authInteractor.loadUsers);
    yield put(loadUsersSuccess(res));
  } catch (error) {
    yield put(loadUsersFailure(error));
  }
}

function* loadUserSaga(action: Action<string>) {
  try {
    const dep: DependenciesType = yield getDependencies();
    const res: User = yield call(dep.authInteractor.loadUser, action.payload);
    yield put(loadUserSuccess(res));
  } catch (error) {
    yield put(loadUserFailure(error));
  }
}

function* fetchUserSaga(action: Action<string>) {
  try {
    const dep: DependenciesType = yield getDependencies();
    const res: User = yield call(dep.authInteractor.loadUser, action.payload);
    yield put(fetchUserSuccess(res));
  } catch (error) {
    yield put(fetchUserFailure(error));
  }
}

function* resetPasswordSaga(action: Action<{ id: string; accessToken: string; newPassword: string }>) {
  try {
    const props: { id: string; accessToken: string; newPassword: string } = action.payload;
    const dep: DependenciesType = yield getDependencies();
    const res: User = yield call(dep.authInteractor.resetPassword, props.id, props.accessToken, props.newPassword);
    yield put(resetPasswordSuccess(res));
  } catch (error) {
    yield put(resetPasswordFailure(error));
  }
}

function* sendSignUpSaga(action: Action<User>) {
  try {
    const dep: DependenciesType = yield getDependencies();
    const res: User = yield call(dep.authInteractor.sendSignUp, action.payload);
    yield put(sendSignUpSuccess(res));
  } catch (error) {
    yield put(sendSignUpFailure(error));
  }
}

function* sendSignInSaga(action: Action<UserAccount>) {
  try {
    const dep: DependenciesType = yield getDependencies();
    const res: AuthTokens = yield call(dep.authInteractor.sendSignIn, action.payload);
    if (res) {
      yield put(sendSignInSuccess(res));
      if (res.userId) {
        yield put(loadUserRequest(res.userId));
      }
    } else {
      yield put(sendSignInFailure(undefined));
    }
  } catch (error) {
    yield put(sendSignInFailure(error));
  }
}

/* function* sendAutoSignInSaga() {
  try {
    const dep: DependenciesType = yield getDependencies();
    const res: AuthTokens = yield call(dep.authInteractor.sendAutoSignIn);
    if (res) {
      setTokensToFetch(res);
      yield put(sendAutoSignInSuccess(res));
    } else {
      yield put(sendAutoSignInFailure(undefined));
      yield put(sendSignOutRequest());
    }
  } catch (error) {
    yield put(sendSignOutRequest());
    yield put(sendAutoSignInFailure(error));
  }
} */

function* sendResetPasswordRequestSaga(action: Action<string>) {
  try {
    const dep: DependenciesType = yield getDependencies();
    const res: boolean = yield call(dep.authInteractor.sendResetPasswordRequest, action.payload);
    yield put(sendResetPasswordRequestSuccess(res));
  } catch (error) {
    yield put(sendResetPasswordRequestFailure(error));
  }
}

function* sendSignOutSaga() {
  try {
    const dep: DependenciesType = yield getDependencies();
    const res: undefined = yield call(dep.authInteractor.sendSignOut);
    yield put(sendSignOutSuccess(res));
  } catch (error) {
    yield put(sendSignOutFailure(error));
  }
}

export function* authSagas() {
  yield all([
    takeLatest(loadUsersRequest, loadUsersSaga),
    takeLatest(loadUserRequest, loadUserSaga),
    takeLatest(fetchUserRequest, fetchUserSaga),
    takeLatest(resetPasswordRequest, resetPasswordSaga),
    takeLatest(sendSignUpRequest, sendSignUpSaga),
    takeLatest(sendSignInRequest, sendSignInSaga),
    takeLatest(sendResetPasswordRequestRequest, sendResetPasswordRequestSaga),
    // takeLatest(sendAutoSignInRequest, sendAutoSignInSaga),
    takeLatest(sendSignOutRequest, sendSignOutSaga),
    // takeEvery(loadFBDitemsRequest, loadFBDitemsSaga),
  ]);
}
