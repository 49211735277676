import React, { useEffect, useState } from 'react';
import { DispatchAsyncResult } from 'react-redux-dispatch-async/lib/dispatchAsync';
import { useMediaQuery } from 'react-responsive';
import { DocColor, StyleSheet } from '../../../assets/styles/constantStyles';
import { EyeIcon } from '../../../assets/svg/EyeIcon';
import { useLoaderContext } from '../../../common/configuration/loaderContextProvider';
import { bigScreenBreakpoint } from '../../../common/domain/entities/ReactResponsiveBreakpoints';
import CommonFunctions from '../../../common/usecases/helpers/CommonFunctions';
import { Rpx, SRpx } from '../../../common/usecases/helpers/DimensionsConverter';
import { NavigationPayload } from '../../../navigationContext/domain/entities/NavigationPayload';
import { emptyUser, User } from '../../domain/entities/User';

interface ResetPasswordProps {
  loadUsersAsync(userId: string): Promise<DispatchAsyncResult<User>>;
  sendSignInAsync(email: string, password: string): Promise<DispatchAsyncResult<User | undefined>>;
  loadUserSettingsAsync(): Promise<DispatchAsyncResult<void>>;
  navigateToAsync: (p: NavigationPayload) => void;
  resetPasswordAsync(id: string, accessToken: string, newPassword: string): Promise<DispatchAsyncResult<User>>;
}
export const ResetPasswordDumb = ({
  loadUsersAsync,
  sendSignInAsync,
  loadUserSettingsAsync,
  navigateToAsync,
  resetPasswordAsync,
}: ResetPasswordProps) => {
  // isBigScreen working as a media breakpoint
  const isBigScreen = useMediaQuery({ query: `(min-width: ${bigScreenBreakpoint}px)` });
  const loader = useLoaderContext();
  const queryParams = new URLSearchParams(window.location.search);
  const [user, setUser] = useState<User>(emptyUser);
  const [newPasswordShown, setNewPasswordShown] = useState<boolean>(false);
  const [matchPasswordShown, setMatchPasswordShown] = useState<boolean>(false);
  const [InputMistakes, setInputMistakes] = useState<{
    isPasswordMatched: boolean;
    isNewPasswordWeak: boolean;
  }>({
    isPasswordMatched: true,
    isNewPasswordWeak: false,
  });
  const [initStateRender, setInitStateRender] = useState<boolean>(true);
  const [newPasswordInput, setNewPasswordInput] = useState<string>('');
  const [matchNewPasswordInput, setMatchNewPasswordInput] = useState<string>('');

  useEffect(() => {
    const userId: string | null = queryParams.get('userId');
    if (userId !== null) {
      loader.setMessage('_');
      loadUsersAsync(userId)
        .then((res) => {
          if (res.success) {
            setUser({ ...res.result });
          }
        })
        .finally(() => loader.setMessage(''));
    }
  }, []);

  useEffect(() => {
    if (newPasswordInput !== '' || matchNewPasswordInput !== '') {
      setInputMistakes((prevState) => ({
        ...prevState,
        isPasswordMatched: newPasswordInput === matchNewPasswordInput,
      }));
    }
  }, [newPasswordInput, matchNewPasswordInput]);

  useEffect(() => {
    setInputMistakes((prevState) => ({
      ...prevState,
      isNewPasswordWeak: CommonFunctions.isPasswordWeak(newPasswordInput),
    }));
  }, [newPasswordInput]);

  const onSubmitButtonClickHandler = () => {
    const userId: string | null = queryParams.get('userId');
    const accessToken: string | null = queryParams.get('accessToken');
    if (
      userId !== null &&
      accessToken !== null &&
      InputMistakes.isNewPasswordWeak === false &&
      InputMistakes.isPasswordMatched === true
    ) {
      loader.setMessage('_');
      resetPasswordAsync(userId, accessToken, newPasswordInput).then((res) => {
        if (res.success) {
          loader.setMessage('En cours de connexion');
          sendSignInAsync(user.email, newPasswordInput)
            .then((ress) => {
              if (ress.success) {
                loadUserSettingsAsync();
                navigateToAsync({
                  routeName: 'Accueil',
                });
              }
            })
            .finally(() => {
              loader.setMessage('');
            });
        } else {
          loader.setMessage('');
          alert("Une erreur s'est produite");
        }
      });
    }
    setInitStateRender(false);
  };

  return (
    <div style={styles.container}>
      <div style={styles.resetPasswordContainer}>
        <p style={isBigScreen ? { ...styles.title } : { ...styles.title, ...responsiveStyles.title }}>
          Réinitialiser le mot de passe
        </p>
        <div style={{ ...responsiveStyles.titleLine, display: isBigScreen ? 'none' : '' }} />
        <p
          style={
            isBigScreen
              ? {
                  ...styles.paragraph,
                }
              : {
                  ...styles.paragraph,
                  ...responsiveStyles.paragraph,
                }
          }
        >{`Bonjour ${user.role === 'DOCTOR' ? 'Dr.' : ''}${user.firstName} ${
          user.lastName
        }, entrez votre nouveau mot de passe :`}</p>
        <div
          style={
            isBigScreen
              ? { ...styles.inputContainer }
              : { ...styles.inputContainer, ...responsiveStyles.inputContainer }
          }
        >
          <div
            style={
              isBigScreen ? { ...styles.inputTitleDiv } : { ...styles.inputTitleDiv, ...responsiveStyles.inputTitleDiv }
            }
          >
            <p
              style={
                isBigScreen
                  ? {
                      ...styles.inputParagraph,
                      color:
                        !initStateRender && (!InputMistakes.isPasswordMatched || InputMistakes.isNewPasswordWeak)
                          ? 'red'
                          : 'black',
                    }
                  : {
                      ...styles.inputParagraph,
                      ...responsiveStyles.inputParagraph,
                      color:
                        !initStateRender && (!InputMistakes.isPasswordMatched || InputMistakes.isNewPasswordWeak)
                          ? 'red'
                          : 'black',
                    }
              }
            >
              Nouveau mot de passe
            </p>
          </div>
          <div style={{ position: 'relative' }}>
            <input
              autoComplete="on"
              style={
                isBigScreen
                  ? {
                      ...styles.input,
                      borderColor:
                        !initStateRender && (!InputMistakes.isPasswordMatched || InputMistakes.isNewPasswordWeak)
                          ? DocColor.LIGHTRED
                          : DocColor.CLOUDYWHITE,
                    }
                  : {
                      ...styles.input,
                      ...responsiveStyles.input,
                      borderColor:
                        !initStateRender && (!InputMistakes.isPasswordMatched || InputMistakes.isNewPasswordWeak)
                          ? DocColor.LIGHTRED
                          : DocColor.CLOUDYWHITE,
                    }
              }
              type={newPasswordShown ? 'text' : 'password'}
              onChange={(event) => setNewPasswordInput(event.target.value)}
            />
            <div
              style={
                isBigScreen
                  ? { ...styles.iconBackground }
                  : { ...styles.iconBackground, ...responsiveStyles.iconBackground }
              }
              onKeyDown={() => setNewPasswordShown((v) => !v)}
              tabIndex={0}
              role="button"
              onClick={() => setNewPasswordShown((v) => !v)}
            >
              <EyeIcon
                isClosed={!newPasswordShown}
                color="white"
                style={{
                  ...styles.icon,
                  width: isBigScreen ? Rpx(35) : SRpx(17),
                  margin: 'auto',
                }}
              />
            </div>
          </div>
        </div>
        <div
          style={
            isBigScreen
              ? { ...styles.inputContainer }
              : { ...styles.inputContainer, ...responsiveStyles.inputContainer }
          }
        >
          <div
            style={
              isBigScreen ? { ...styles.inputTitleDiv } : { ...styles.inputTitleDiv, ...responsiveStyles.inputTitleDiv }
            }
          >
            <p
              style={
                isBigScreen
                  ? {
                      ...styles.inputParagraph,
                      color:
                        !initStateRender && (!InputMistakes.isPasswordMatched || InputMistakes.isNewPasswordWeak)
                          ? 'red'
                          : 'black',
                    }
                  : {
                      ...styles.inputParagraph,
                      ...responsiveStyles.inputParagraph,
                      color:
                        !initStateRender && (!InputMistakes.isPasswordMatched || InputMistakes.isNewPasswordWeak)
                          ? 'red'
                          : 'black',
                    }
              }
            >
              Confirmer le nouveau mot de passe
            </p>
          </div>
          <div style={{ position: 'relative' }}>
            <input
              autoComplete="on"
              style={
                isBigScreen
                  ? {
                      ...styles.input,
                      borderColor:
                        !initStateRender && (!InputMistakes.isPasswordMatched || InputMistakes.isNewPasswordWeak)
                          ? DocColor.LIGHTRED
                          : DocColor.CLOUDYWHITE,
                    }
                  : {
                      ...styles.input,
                      ...responsiveStyles.input,
                      borderColor:
                        !initStateRender && (!InputMistakes.isPasswordMatched || InputMistakes.isNewPasswordWeak)
                          ? DocColor.LIGHTRED
                          : DocColor.CLOUDYWHITE,
                    }
              }
              type={matchPasswordShown ? 'text' : 'password'}
              onChange={(event) => setMatchNewPasswordInput(event.target.value)}
            />
            <div
              style={
                isBigScreen
                  ? { ...styles.iconBackground }
                  : { ...styles.iconBackground, ...responsiveStyles.iconBackground }
              }
              onKeyDown={() => setMatchPasswordShown((v) => !v)}
              tabIndex={0}
              role="button"
              onClick={() => setMatchPasswordShown((v) => !v)}
            >
              <EyeIcon
                isClosed={!matchPasswordShown}
                color="white"
                style={{
                  ...styles.icon,
                  width: isBigScreen ? Rpx(35) : SRpx(17),
                  margin: 'auto',
                }}
              />
            </div>
          </div>
        </div>

        <div style={{ marginTop: isBigScreen ? Rpx(20) : SRpx(10) }}>
          {!initStateRender && !InputMistakes.isPasswordMatched && (
            <p
              style={
                isBigScreen
                  ? { ...styles.mistakeParagraph }
                  : { ...styles.mistakeParagraph, ...responsiveStyles.mistakeParagraph }
              }
            >
              • Les deux mots de passe ne sont pas compatibles.
            </p>
          )}
          {!initStateRender && InputMistakes.isNewPasswordWeak && (
            <p
              style={
                isBigScreen
                  ? { ...styles.mistakeParagraph }
                  : { ...styles.mistakeParagraph, ...responsiveStyles.mistakeParagraph }
              }
            >
              • Le nouveau mot de passe est faible.
            </p>
          )}
        </div>
        <button
          disabled={!initStateRender && (!InputMistakes.isPasswordMatched || InputMistakes.isNewPasswordWeak)}
          style={
            isBigScreen
              ? {
                  ...styles.submitButton,
                  background:
                    !initStateRender && (!InputMistakes.isPasswordMatched || InputMistakes.isNewPasswordWeak)
                      ? DocColor.GRAY
                      : DocColor.DARKBLUE,
                }
              : {
                  ...styles.submitButton,
                  ...responsiveStyles.submitButton,
                  background:
                    !initStateRender && (!InputMistakes.isPasswordMatched || InputMistakes.isNewPasswordWeak)
                      ? DocColor.GRAY
                      : DocColor.DARKBLUE,
                }
          }
          type="button"
          onClick={onSubmitButtonClickHandler}
        >
          valider
        </button>
      </div>
    </div>
  );
};

const styles: StyleSheet = {
  container: {
    flex: 1,
  },
  resetPasswordContainer: {
    margin: 'auto',
    textAlign: 'center',
  },
  title: {
    color: DocColor.DARKBLUE,
    fontSize: Rpx(64),
    fontWeight: 700,
    letterSpacing: Rpx(1),
  },
  paragraph: {
    letterSpacing: Rpx(3),
    margin: `${Rpx(30)} auto ${Rpx(55)} auto`,
    fontSize: Rpx(24),
    fontWeight: 500,
    width: '80%',
    textAlign: 'center',
    color: DocColor.DARKBLUE,
  },
  inputContainer: {
    width: '30vw',
    margin: `${Rpx(20)} auto 0 auto`,
  },
  inputTitleDiv: {
    flexDirection: 'row',
    marginLeft: Rpx(10),
  },
  inputParagraph: {
    letterSpacing: Rpx(2),
    marginLeft: Rpx(2),
    marginBottom: Rpx(15),
    fontSize: Rpx(24),
    fontWeight: 600,
  },
  input: {
    maxWidth: '100%',
    height: Rpx(78),
    background: DocColor.CLOUDYWHITE,
    border: 'none',
    borderRadius: Rpx(9),
    outline: 'none',
    caretColor: DocColor.DARKBLUE,
    borderColor: DocColor.CLOUDYWHITE,
    borderWidth: Rpx(4),
    borderStyle: 'solid',
    fontSize: Rpx(25),
  },
  iconBackground: {
    background: DocColor.MEDIUMBLUE,
    borderRadius: Rpx(40),
    width: Rpx(55),
    height: Rpx(55),
    position: 'absolute',
    margin: 'auto 3% auto auto',
    inset: 0,
    cursor: 'pointer',
  },
  mistakeParagraph: {
    width: 'fit-content',
    margin: `${Rpx(5)} auto 0 auto`,
    color: 'red',
    fontSize: Rpx(24),
    fontWeight: 700,
    letterSpacing: Rpx(3),
  },
  submitButton: {
    letterSpacing: Rpx(3),
    color: 'white',
    width: '20vw',
    height: Rpx(58),
    margin: `${Rpx(40)} auto 0 auto`,
    background: DocColor.DARKBLUE,
    borderRadius: Rpx(15),
    textTransform: 'uppercase',
    fontSize: Rpx(30),
    fontWeight: 600,
  },
};

const responsiveStyles: StyleSheet = {
  title: {
    fontSize: SRpx(25),
    letterSpacing: SRpx(2),
    marginBottom: SRpx(5),
  },
  titleLine: {
    width: '80%',
    height: SRpx(3),
    background: DocColor.DARKBLUE,
    borderRadius: SRpx(3),
    margin: '0 auto',
  },
  paragraph: {
    letterSpacing: SRpx(1),
    margin: `${SRpx(15)} auto 0 auto`,
    fontSize: SRpx(14),
  },
  inputContainer: {
    width: '80vw',
    margin: `${SRpx(20)} auto 0 auto`,
  },
  inputTitleDiv: {
    marginLeft: SRpx(5),
  },
  inputParagraph: {
    letterSpacing: SRpx(2),
    marginLeft: SRpx(10),
    fontSize: SRpx(12),
  },
  input: {
    height: SRpx(45),
    borderRadius: SRpx(5),
    borderWidth: SRpx(3),
    fontSize: SRpx(12),
  },
  iconBackground: {
    borderRadius: SRpx(20),
    width: SRpx(27),
    height: SRpx(27),
  },
  mistakeParagraph: {
    margin: `${SRpx(3)} auto 0 auto`,
    fontSize: SRpx(10),
    letterSpacing: Rpx(2),
  },
  submitButton: {
    letterSpacing: SRpx(2),
    width: '50vw',
    height: SRpx(29),
    margin: `${SRpx(20)} auto ${SRpx(20)} auto`,
    borderRadius: SRpx(8),
    fontSize: SRpx(15),
  },
};
