import React, { useEffect, useRef, useState } from 'react';
import { DispatchAsyncResult } from 'react-redux-dispatch-async/lib/dispatchAsync';
import { useMediaQuery } from 'react-responsive';
import { TreatmentsAntecedentsRow } from './TreatmentsAntecedentsRow';
import { DocColor, StyleSheet } from '../../../../assets/styles/constantStyles';
import { CheckMarkIcon } from '../../../../assets/svg/CheckMarkIcon';
import { EditIcon } from '../../../../assets/svg/EditIcon';
import { ConfirmOverlay } from '../../../../common/ui/ConfirmOverlay';
import { Rpx, SRpx } from '../../../../common/usecases/helpers/DimensionsConverter';
import {
  hardCodedEmptyTreatmentsAntecedents,
  TreatmentsAntecedents,
} from '../../../domain/entities/TreatmentsAntecedents';
import { useNotificationContext } from '../../../../common/configuration/notificationContextProvider';
import { bigScreenBreakpoint } from '../../../../common/domain/entities/ReactResponsiveBreakpoints';

type TreatmentAntecedentComponentProps = {
  stateTreatmentsAntecedents: TreatmentsAntecedents | undefined;
  updateTreatmentsAntecedentsAsync(treatmentsAndAntecedents: TreatmentsAntecedents): Promise<DispatchAsyncResult>;
};

export const TreatmentAntecedentComponent = ({
  stateTreatmentsAntecedents,
  updateTreatmentsAntecedentsAsync,
}: TreatmentAntecedentComponentProps) => {
  // isBigScreen working as a media breakpoint
  const isBigScreen = useMediaQuery({ query: `(min-width: ${bigScreenBreakpoint}px)` });

  const notification = useNotificationContext();
  const containerRef = useRef<HTMLDivElement>(null);
  const [onEdit, setOnEdit] = useState<boolean>(false);
  const [isConfirmFragmentClosed, setIsConfirmFragmentClosed] = useState<boolean>(true);
  const [filledData, setFilledData] = useState<TreatmentsAntecedents>(hardCodedEmptyTreatmentsAntecedents);

  useEffect(() => {
    if (stateTreatmentsAntecedents) {
      setFilledData(stateTreatmentsAntecedents);
    }
  }, [stateTreatmentsAntecedents]);

  useEffect(() => {
    function handleClickOutside(event: MouseEvent): void {
      if (
        containerRef.current &&
        !containerRef.current.contains(event.target as Node) &&
        window.innerWidth - window.innerWidth / 100 > event.clientX
      ) {
        if (onEdit) {
          containerRef.current.scrollIntoView({
            behavior: 'smooth',
            block: 'center',
            inline: 'center',
          });
          onEditClickHandler();
        }
      }
    }
    // Bind the event listener
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener('mousedown', handleClickOutside);
    };
  });
  /// /////////////////////////////////////////////////////////////////////

  const onEditClickHandler = () => {
    if (onEdit) {
      if (JSON.stringify(filledData) === JSON.stringify(stateTreatmentsAntecedents)) {
        setOnEdit(false);
      } else {
        setIsConfirmFragmentClosed(false);
      }
    } else {
      setOnEdit(true);
    }
  };
  const onConfirmClick = () => {
    setOnEdit(false);
    updateTreatmentsAntecedentsAsync(filledData).then((res) => {
      if (res.success) {
        notification.setOnSuccess({
          message: `Les traitements et les antécédents sont modifiés avec succès!`,
        });
        containerRef.current?.scrollIntoView({
          behavior: 'smooth',
          block: 'center',
          inline: 'center',
        });
      }
    });

    setIsConfirmFragmentClosed(true);
  };
  const onCloseClick = () => {
    if (stateTreatmentsAntecedents) {
      setOnEdit(false);
      setIsConfirmFragmentClosed(true);
      setFilledData(stateTreatmentsAntecedents);
      containerRef.current?.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
        inline: 'center',
      });
    }
  };
  return (
    <div
      style={
        isBigScreen
          ? { ...styles.container, outlineColor: onEdit ? DocColor.MEDIUMBLUE : DocColor.DARKBLUE }
          : { ...responsiveStyles.container }
      }
      ref={containerRef}
    >
      <ConfirmOverlay
        onConfirmClick={onConfirmClick}
        onCloseClick={onCloseClick}
        message="êtes-vous sûr de vouloir enregistrer les données des traitements et des antécédents ?"
        isClosedState={isConfirmFragmentClosed}
        setIsClosedState={setIsConfirmFragmentClosed}
      />
      <div
        style={
          isBigScreen
            ? { ...styles.editDiv, background: onEdit ? DocColor.MEDIUMBLUE : DocColor.DARKBLUE }
            : {
                ...styles.editDiv,
                ...responsiveStyles.editDiv,
                background: onEdit ? DocColor.MEDIUMBLUE : DocColor.DARKBLUE,
              }
        }
        onKeyDown={onEditClickHandler}
        tabIndex={0}
        role="button"
        onClick={onEditClickHandler}
      >
        {!onEdit ? (
          <EditIcon
            style={{ margin: 'auto' }}
            width={isBigScreen ? Rpx(37) : SRpx(17)}
            height={isBigScreen ? Rpx(37) : SRpx(17)}
            color="white"
          />
        ) : (
          <CheckMarkIcon
            style={{ margin: 'auto' }}
            width={isBigScreen ? Rpx(25) : SRpx(13)}
            height={isBigScreen ? Rpx(25) : SRpx(13)}
            color="white"
          />
        )}
      </div>
      <p
        style={
          isBigScreen
            ? { ...styles.title, fontSize: Rpx(25, true), color: onEdit ? DocColor.MEDIUMBLUE : DocColor.DARKBLUE }
            : {
                ...styles.title,
                ...responsiveStyles.title,
                fontSize: SRpx(13, true),
                color: onEdit ? DocColor.MEDIUMBLUE : DocColor.DARKBLUE,
              }
        }
      >
        Traitements et Antécédents
      </p>
      <div style={{ overflow: 'auto' }}>
        <TreatmentsAntecedentsRow
          dataListState={[...filledData.currentTreatment]}
          title="Traitement actuel"
          titleWidth={Rpx(290)}
          addButtonTitle="Ajouter un autre traitement"
          onArrayChange={(arrayList) => {
            setFilledData((prevState) => ({ ...prevState, currentTreatment: arrayList }));
          }}
          disabled={!onEdit}
        />
        <TreatmentsAntecedentsRow
          dataListState={[...filledData.surgicalOperation]}
          title="Opération Chirurgicale"
          titleWidth={Rpx(290)}
          addButtonTitle="Ajouter une autre opération"
          onArrayChange={(arrayList) => {
            setFilledData((prevState) => ({ ...prevState, surgicalOperation: arrayList }));
          }}
          disabled={!onEdit}
        />
        <TreatmentsAntecedentsRow
          dataListState={[...filledData.toxicAntecedents]}
          title="Antécédents toxiques"
          titleWidth={Rpx(290)}
          addButtonTitle="Ajouter un autre antécédents toxiques"
          onArrayChange={(arrayList) => {
            setFilledData((prevState) => ({ ...prevState, toxicAntecedents: arrayList }));
          }}
          disabled={!onEdit}
        />
        <TreatmentsAntecedentsRow
          dataListState={[...filledData.drugAllergies]}
          title="Allergies Médicaments"
          titleWidth={Rpx(290)}
          addButtonTitle="Ajouter une autre allergie"
          onArrayChange={(arrayList) => {
            setFilledData((prevState) => ({ ...prevState, drugAllergies: arrayList }));
          }}
          disabled={!onEdit}
        />
        <TreatmentsAntecedentsRow
          dataListState={[...filledData.gynecoObstetrics]}
          title="Gynéco-obstétricaux"
          titleWidth={Rpx(290)}
          addButtonTitle="Ajouter un autre Gyn"
          onArrayChange={(arrayList) => {
            setFilledData((prevState) => ({ ...prevState, gynecoObstetrics: arrayList }));
          }}
          disabled={!onEdit}
        />
        <TreatmentsAntecedentsRow
          dataListState={[...filledData.familyAntecedents]}
          title="Antécédent familial"
          titleWidth={Rpx(290)}
          addButtonTitle="Ajouter un autre antécédent familial"
          onArrayChange={(arrayList) => {
            setFilledData((prevState) => ({ ...prevState, familyAntecedents: arrayList }));
          }}
          disabled={!onEdit}
        />
      </div>
    </div>
  );
};

const styles: StyleSheet = {
  container: {
    maxHeight: '70vh',
    padding: Rpx(45),
    position: 'relative',
    margin: `2vw 2vw 0`,
    outline: `${Rpx(10)} solid ${DocColor.DARKBLUE}`,
    borderRadius: Rpx(60),
    minHeight: Rpx(100),
  },
  editDiv: {
    position: 'absolute',
    right: 0,
    top: 0,
    margin: 'auto',
    width: Rpx(77),
    height: Rpx(50),
    borderRadius: `0 ${Rpx(39)} 0 ${Rpx(24)}`,
    background: DocColor.DARKBLUE,
    cursor: 'pointer',
  },
  title: {
    color: DocColor.DARKBLUE,
    fontWeight: 800,
  },
};

const responsiveStyles: StyleSheet = {
  container: {
    position: 'relative',
    width: `85%`,
    margin: `0 auto ${SRpx(20)} auto`,
  },
  editDiv: {
    position: 'absolute',
    inset: 0,
    margin: '0 0 0 auto',
    width: SRpx(25),
    height: SRpx(25),
    borderRadius: SRpx(5),
    background: DocColor.DARKBLUE,
    cursor: 'pointer',
  },
  title: {
    marginBottom: SRpx(15),
  },
};
