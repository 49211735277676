import React, { useState } from 'react';
import { DispatchAsyncResult } from 'react-redux-dispatch-async/lib/dispatchAsync';
import { DocColor, StyleSheet } from '../../../../assets/styles/constantStyles';
import { useNotificationContext } from '../../../../common/configuration/notificationContextProvider';
import { ToggleButton } from '../../../../common/ui/ToggleButton';
import { Rpx } from '../../../../common/usecases/helpers/DimensionsConverter';
import { UserSettings } from '../../../domain/entities/UserSettings';

interface SystemNotificationsComponentProps {
  userSettingsState: UserSettings;
  updateUserSettingsAsync(userSettings: UserSettings): Promise<DispatchAsyncResult<UserSettings>>;
  setChosenOption: React.Dispatch<React.SetStateAction<string>>;
}

export const SystemNotificationsComponent = ({
  userSettingsState,
  updateUserSettingsAsync,
  setChosenOption,
}: SystemNotificationsComponentProps) => {
  const notification = useNotificationContext();
  const [userSettingsUseState, setUserSettingsUseState] = useState<UserSettings>(userSettingsState);

  const onSubmitClickHandler = () => {
    if (JSON.stringify(userSettingsUseState) === JSON.stringify(userSettingsState)) {
      updateUserSettingsAsync(userSettingsUseState).then((res) => {
        if (res.success) {
          setChosenOption('');
          notification.setOnSuccess({
            message: `Vos préférences ont été mis à jour.`,
          });
        } else {
          notification.setOnError({
            message: `Nous n'avons pas pu mettre à jour votre préférences.`,
          });
        }
      });
    }
  };

  return (
    <div style={styles.container}>
      <div style={styles.titleDiv}>
        <p style={{ ...styles.titleParagraph, fontSize: Rpx(23, true) }}>Notifications système</p>
      </div>
      <div style={{ margin: 'auto', alignItems: 'center' }}>
        <p style={{ ...styles.explanationParagraph, fontSize: Rpx(28, true) }}>
          Modifier vos préférences pour les notifications système.
        </p>
        <div style={styles.horizontalLine} />
        <div style={styles.systemNotificationsContainer}>
          <div
            style={styles.systemNotificationsStateContainer}
            onKeyDown={() => {
              setUserSettingsUseState((prevData) => ({
                ...prevData,
                notificationsActionsState: !prevData.calendarEventReminderState,
              }));
            }}
            tabIndex={0}
            role="button"
            onClick={() => {
              setUserSettingsUseState((prevData) => ({
                ...prevData,
                notificationsActionsState: !prevData.calendarEventReminderState,
              }));
            }}
          >
            <ToggleButton
              selected={userSettingsUseState.calendarEventReminderState}
              width={Rpx(40, true)}
              height={Rpx(18, true)}
            />
            <p style={{ ...styles.systemNotificationsStateLabel, fontSize: Rpx(25, true) }}>
              Activer les rappels des événements du Calendrier.
            </p>
          </div>
          <div
            style={styles.systemNotificationsStateContainer}
            onKeyDown={() => {
              setUserSettingsUseState((prevData) => ({
                ...prevData,
                notificationsActionsState: !prevData.systemSoundState,
              }));
            }}
            tabIndex={0}
            role="button"
            onClick={() => {
              setUserSettingsUseState((prevData) => ({
                ...prevData,
                notificationsActionsState: !prevData.systemSoundState,
              }));
            }}
          >
            <ToggleButton selected={userSettingsUseState.systemSoundState} width={Rpx(40)} height={Rpx(18)} />
            <p style={{ ...styles.systemNotificationsStateLabel, fontSize: Rpx(25, true) }}>Activer le son</p>
          </div>
        </div>
        <div style={styles.systemNotificationsButtonsContainer}>
          <button
            style={{
              ...styles.button,
              height: Rpx(70, true),
              fontSize: Rpx(28, true),
              background:
                JSON.stringify(userSettingsUseState) !== JSON.stringify(userSettingsState)
                  ? DocColor.MEDIUMBLUE
                  : DocColor.GRAY,
            }}
            type="button"
            disabled={JSON.stringify(userSettingsUseState) === JSON.stringify(userSettingsState)}
            onClick={onSubmitClickHandler}
          >
            Valider
          </button>
          <button
            style={{ ...styles.button, height: Rpx(70, true), fontSize: Rpx(28, true) }}
            type="button"
            onClick={() => setChosenOption('')}
          >
            Annuler
          </button>
        </div>
      </div>
    </div>
  );
};

const styles: StyleSheet = {
  container: {
    position: 'relative',
    width: '52.5%',
    marginLeft: '5%',
    outline: `${Rpx(10)} solid ${DocColor.DARKBLUE}`,
    borderRadius: Rpx(60),
    height: '100%',
    overflow: 'hidden',
  },
  titleDiv: {
    width: 'fit-content',
    background: DocColor.DARKBLUE,
    borderRadius: `0 0 ${Rpx(15)} ${Rpx(15)}`,
    minHeight: Rpx(45),
    padding: `0 ${Rpx(40)}`,
    margin: `0 auto`,
    alignItems: 'center',
    justifyContent: 'center',
  },
  titleParagraph: {
    color: 'white',
    fontWeight: 600,
    marginBottom: Rpx(10),
  },
  explanationParagraph: {
    color: DocColor.DARKBLUE,
    fontWeight: 700,
    padding: `0 ${Rpx(32)}`,
    textAlign: 'center',
  },
  horizontalLine: {
    width: '80%',
    background: DocColor.MEDIUMBLUE,
    height: Rpx(8),
    borderRadius: Rpx(4),
    marginTop: Rpx(60),
  },
  systemNotificationsContainer: {
    width: '90%',
    minHeight: Rpx(50),
    background: DocColor.CLOUDYBLUE,
    margin: `${Rpx(60)} 5% 0 5%`,
    padding: `${Rpx(30)} 0 0 0`,
    borderRadius: Rpx(30),
    justifyContent: 'center',
    alignItems: 'center',
  },
  systemNotificationsStateContainer: {
    width: '100%',
    cursor: 'pointer',
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: Rpx(40),
    marginLeft: '15%',
    gap: Rpx(15),
  },
  systemNotificationsStateLabel: {
    color: DocColor.DARKBLUE,
    fontWeight: 600,
    width: '80%',
  },
  systemNotificationsButtonsContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    width: '65%',
    margin: `${Rpx(40)} auto`,
  },
  button: {
    width: 'fit-content',
    backgroundColor: DocColor.DARKBLUE,
    color: 'white',
    fontWeight: 700,
    borderRadius: Rpx(12),
    flexDirection: 'row',
    padding: `0px ${Rpx(40)}`,
  },
};
