import * as React from 'react';
import { Color } from '../styles/constantStyles';

export interface ToBottomArrowProps {
  color?: string;
  style?: React.CSSProperties;
  width?: string | number;
  height?: string | number;
}

export const ToBottomArrow = ({ width, height, color, style }: ToBottomArrowProps) => (
  <svg style={style} width={width} height={height} viewBox="0 0 42 25" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M3.6875 7.46821e-06L-9.10654e-07 3.68751L20.8333 24.5208L41.6667 3.68751L37.9792 5.96927e-06L20.8333 17.1458L3.6875 7.46821e-06Z"
      fill={color || Color.MONOGREY3}
    />
  </svg>
);
