// import { all, select, takeLatest } from 'redux-saga/effects';
// import { setTokensToFetch } from '../../adapter/fetch.helper';
// import { AuthTokens } from '../../domain/entities/AuthTokens';

// function* handleFailureSaga(err: any) {
// const tokens: AuthTokens = yield select(getAuthTokens);
// if (err && err.payload && err.payload.message === 'logout' && tokens.renewToken) {
//   yield put(sendSignOutFailure(err));
// }
// yield null;
// }

// function* watchTokenExpiry() {
// const interval = setInterval(() => {
// }, 1000);
// // return () => clearInterval(interval);
// const tokens: AuthTokens = yield select(getAuthTokens);
// setTokensToFetch(tokens);
// yield null;
// }

// const matchFailedActionType = ({ type }: { type: string }) => type.endsWith('persist/PERSIST');

export function* applicationSagas(): any {
  //  yield all([takeLatest(matchFailedActionType, handleFailureSaga), watchTokenExpiry()]);
}
