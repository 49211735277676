import React from 'react';
import { PatientConsutationRow } from './PatientConsutationRow';
import { AddIcon } from '../../../../assets/svg/Addlcon';
import CommonFunctions from '../../../../common/usecases/helpers/CommonFunctions';
import { DocColor, StyleSheet } from '../../../../assets/styles/constantStyles';
import { Rpx } from '../../../../common/usecases/helpers/DimensionsConverter';
import { User } from '../../../../authContext/domain/entities/User';
import { NavigationPayload } from '../../../../navigationContext/domain/entities/NavigationPayload';
import { PatientConsultation } from '../../../domain/entities/PatientConsultation';

type PatientConsultationsComponentProps = {
  stateConnectedUser: User | undefined;
  patientsConsultationsData: PatientConsultation[];
  searchOnChange: { value: string; result: string[] };
  dataState: string;
  loadMoreButtonVisibility: boolean;
  onDeleteClick: (consultationId: string) => void;
  onLoadMoreClick: () => void;
  navigateToAsync: (p: NavigationPayload) => void;
};

export const PatientConsultationsComponent = ({
  stateConnectedUser,
  patientsConsultationsData,
  searchOnChange,
  dataState,
  loadMoreButtonVisibility,
  onDeleteClick,
  onLoadMoreClick,
  navigateToAsync,
}: PatientConsultationsComponentProps) => {
  let dateToCompare: string;

  return (
    <div style={styles.container}>
      <p style={{ ...styles.historyTitle, fontSize: Rpx(32, true) }}>Historique des consultations</p>
      <div style={styles.borderContainer}>
        {(searchOnChange.value !== '' && searchOnChange.result.length > 0) ||
        (searchOnChange.value === '' && patientsConsultationsData.length > 0) ? (
          <div style={{ overflow: 'auto' }}>
            {(searchOnChange.value !== '' ? searchOnChange.result : patientsConsultationsData).map((obj: any) => {
              const startDateTime = new Date(obj.startDateTime);
              const objDateInString = CommonFunctions.formatDate(startDateTime);
              if (dateToCompare !== objDateInString) {
                dateToCompare = objDateInString;
                return (
                  <div key={objDateInString}>
                    <div style={{ ...styles.historyDateDiv, minHeight: Rpx(75, true) }}>
                      <p
                        key="firstHistoryDateDiv"
                        style={{ ...styles.historyDateParagraph, fontSize: Rpx(32, true) }}
                      >{`${stateConnectedUser?.role === 'DOCTOR' ? 'Mes ' : ''}consultations du ${objDateInString}`}</p>
                    </div>
                    <PatientConsutationRow
                      stateConnectedUser={stateConnectedUser}
                      key={obj.id}
                      patientConsultationData={obj}
                      onDeleteClick={(id) => onDeleteClick(id)}
                      navigateToAsync={navigateToAsync}
                    />
                  </div>
                );
              }
              return (
                <PatientConsutationRow
                  stateConnectedUser={stateConnectedUser}
                  key={obj.id}
                  patientConsultationData={obj}
                  onDeleteClick={(id) => onDeleteClick(id)}
                  navigateToAsync={navigateToAsync}
                />
              );
            })}
            <div
              style={{
                ...styles.loadMoreButton,
                minHeight: Rpx(50, true),
                display: loadMoreButtonVisibility ? '' : 'none',
              }}
              onKeyDown={() => onLoadMoreClick()}
              tabIndex={0}
              role="button"
              onClick={() => onLoadMoreClick()}
            >
              <AddIcon style={styles.icon} width={Rpx(15, true)} height={Rpx(15, true)} />
              <p style={{ ...styles.loadMoreButtonParagraph, fontSize: Rpx(28, true) }}>Afficher plus...</p>
            </div>
          </div>
        ) : (
          <div style={{ ...styles.dataStateRow, height: Rpx(70, true), fontSize: Rpx(25, true) }}>
            <p>{dataState}</p>
          </div>
        )}
      </div>
    </div>
  );
};

const styles: StyleSheet = {
  container: {
    margin: `${Rpx(90)} 2vw 0`,
  },
  historyTitle: {
    fontWeight: 700,
    color: DocColor.DARKBLUE,
    margin: `0 0 ${Rpx(30)} 2vw`,
  },
  borderContainer: {
    position: 'relative',
    outline: `${Rpx(10)} solid ${DocColor.DARKBLUE}`,
    borderRadius: Rpx(60),
    minHeight: Rpx(100),
    overflow: 'hidden',
    paddingRight: Rpx(45),
    maxHeight: '100vh',
  },
  historyDateDiv: {
    width: 'fit-content',
    background: DocColor.DARKBLUE,
    borderRadius: `0 0 ${Rpx(20)} 0`,
    padding: `0 ${Rpx(40)}`,
    marginBottom: Rpx(15),
  },
  historyDateParagraph: {
    color: 'white',
    fontWeight: 600,
    fontStyle: 'italic',
    margin: 'auto',
  },
  icon: {
    border: '2px solid white',
    borderRadius: Rpx(20),
    padding: Rpx(5),
    margin: 'auto 0',
  },
  loadMoreButton: {
    flexDirection: 'row',
    width: 'fit-content',
    backgroundColor: DocColor.DARKBLUE,
    color: 'white',
    borderRadius: `${Rpx(20)} ${Rpx(20)} 0 0`,
    cursor: 'pointer',
    padding: `0px 20px`,
    margin: '0 auto',
  },
  loadMoreButtonParagraph: {
    margin: `auto 0px auto ${Rpx(10)}`,
    fontWeight: 500,
  },
  dataStateRow: {
    width: '100%',
    backgroundColor: DocColor.LIGHTGRAY,
    margin: `${Rpx(30)} 0 ${Rpx(30)} ${Rpx(25)}`,
    borderRadius: Rpx(9),
    textAlign: 'center',
    fontWeight: 500,
    whiteSpace: 'nowrap',
    justifyContent: 'center',
    color: DocColor.COALBLACK,
  },
};
