import * as React from 'react';
import { Color } from '../styles/constantStyles';

export interface CloseProps {
  color?: string;
  style?: React.CSSProperties;
  width?: string | number;
  height?: string | number;
}

export const CloseIcon = ({ color, width, height, style }: CloseProps) => (
  <svg style={style} width={width} height={height} viewBox="0 0 20 19" xmlns="http://www.w3.org/2000/svg">
    <path
      fill={color || Color.MONOGREY3}
      d="M12.4313 9.38426L19.3417 2.8553C19.9699 2.26175 19.9699 1.28528 19.3417 0.691739C18.7135 0.0981964 17.68 0.0981964 17.0517 0.691739L10.1414 7.22071L3.23102 0.691739C2.6028 0.0981964 1.56929 0.0981964 0.941071 0.691739C0.626963 0.98851 0.464844 1.38101 0.464844 1.77352C0.464844 2.16602 0.626963 2.55853 0.941071 2.8553L7.85143 9.38426L0.941071 15.9132C0.626963 16.21 0.464844 16.6025 0.464844 16.995C0.464844 17.3875 0.626963 17.78 0.941071 18.0768C1.56929 18.6703 2.6028 18.6703 3.23102 18.0768L10.1414 11.5478L17.0517 18.0768C17.68 18.6703 18.7135 18.6703 19.3417 18.0768C19.9699 17.4832 19.9699 16.5068 19.3417 15.9132L12.4313 9.38426Z"
    />
  </svg>
);
