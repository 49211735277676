import { AuthTokens } from '../../common/domain/entities/AuthTokens';
import { createAsyncActions } from '../../common/usecases/helpers/actions.helper';
import { User } from '../domain/entities/User';
import { UserAccount } from '../domain/entities/UserAccount';
// ADMIN DEV TOOLS SECTION #################################################################################################################

export const {
  request: loadUsersRequest,
  success: loadUsersSuccess,
  failure: loadUsersFailure,
} = createAsyncActions<void, User[], unknown>('LOAD_USERS');

export const {
  request: loadUserRequest,
  success: loadUserSuccess,
  failure: loadUserFailure,
} = createAsyncActions<string, User, unknown>('LOAD_USER');

export const {
  request: fetchUserRequest,
  success: fetchUserSuccess,
  failure: fetchUserFailure,
} = createAsyncActions<string, User, unknown>('FETCH_USER');
// #################################################################################################################

export const {
  request: resetPasswordRequest,
  success: resetPasswordSuccess,
  failure: resetPasswordFailure,
} = createAsyncActions<{ id: string; accessToken: string; newPassword: string }, User, unknown>('RESET_PASSWORD');

export const {
  request: sendSignUpRequest,
  success: sendSignUpSuccess,
  failure: sendSignUpFailure,
} = createAsyncActions<User, User, unknown>('SEND_SIGN_UP');

export const {
  request: sendSignInRequest,
  success: sendSignInSuccess,
  failure: sendSignInFailure,
} = createAsyncActions<UserAccount, AuthTokens, unknown>('SEND_SIGN_IN');

export const {
  request: sendAutoSignInRequest,
  success: sendAutoSignInSuccess,
  failure: sendAutoSignInFailure,
} = createAsyncActions<void, AuthTokens, unknown>('SEND_AUTO_SIGN_IN');

export const {
  request: sendSignOutRequest,
  success: sendSignOutSuccess,
  failure: sendSignOutFailure,
} = createAsyncActions<void, undefined, unknown>('SEND_SIGN_OUT');

export const {
  request: sendResetPasswordRequestRequest,
  success: sendResetPasswordRequestSuccess,
  failure: sendResetPasswordRequestFailure,
} = createAsyncActions<string, boolean, unknown>('SEND_RESET_PASSWORD_REQUEST');
