import React, { ReactNode, useContext, useMemo, useState } from 'react';
import { NotificationContextType } from '../domain/entities/NotificationContextType';
import { NotificationProps } from '../domain/entities/NotificationProps';

export const NotificationContext = React.createContext<NotificationContextType | null>(null);

export const NotificationContextProvider = ({ children }: { children: ReactNode }) => {
  const [onSuccess, setOnSuccess] = useState<NotificationProps>({
    message: '',
  });
  const [onError, setOnError] = useState<NotificationProps>({
    message: '',
  });
  const props = useMemo(() => ({ onSuccess, setOnSuccess, onError, setOnError }), [onSuccess, onError]);
  return <NotificationContext.Provider value={props}>{children}</NotificationContext.Provider>;
};

export const useNotificationContext = () => {
  const context = useContext(NotificationContext);

  if (!context) {
    throw new Error('<useNotificationState> must be provided');
  }
  return context;
};
