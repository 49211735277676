import * as React from 'react';
import { Color } from '../styles/constantStyles';

export interface CardIconProps {
  color?: string;
  style?: React.CSSProperties;
  width?: string | number;
  height?: string | number;
}

export const CardIcon = ({ color, width, height, style }: CardIconProps) => (
  <svg style={style} width={width} height={height} viewBox="0 0 39 39" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M23.1562 17.0625H29.25"
      stroke={color || Color.MONOGREY3}
      strokeWidth="3"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M23.1562 21.9375H29.25"
      stroke={color || Color.MONOGREY3}
      strokeWidth="3"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M14.0312 21.9375C16.0505 21.9375 17.6875 20.3005 17.6875 18.2812C17.6875 16.262 16.0505 14.625 14.0312 14.625C12.012 14.625 10.375 16.262 10.375 18.2812C10.375 20.3005 12.012 21.9375 14.0312 21.9375Z"
      stroke={color || Color.MONOGREY3}
      strokeWidth="3"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M9.30859 25.5935C9.57709 24.5449 10.1869 23.6155 11.042 22.9517C11.8971 22.288 12.9488 21.9277 14.0312 21.9277C15.1137 21.9277 16.1654 22.288 17.0205 22.9517C17.8756 23.6155 18.4854 24.5449 18.7539 25.5935"
      stroke={color || Color.MONOGREY3}
      strokeWidth="3"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M32.9062 7.3125H6.09375C5.42065 7.3125 4.875 7.85815 4.875 8.53125V30.4688C4.875 31.1418 5.42065 31.6875 6.09375 31.6875H32.9062C33.5793 31.6875 34.125 31.1418 34.125 30.4688V8.53125C34.125 7.85815 33.5793 7.3125 32.9062 7.3125Z"
      stroke={color || Color.MONOGREY3}
      strokeWidth="3"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
