import React, { useEffect, useState } from 'react';
import { DispatchAsyncResult } from 'react-redux-dispatch-async/lib/dispatchAsync';
import { SettingsOptionComponent } from './components/SettingsOptionComponent';
import { ChangeUsernameComponent } from './components/ChangeUsernameComponent';
import { ChangePasswordComponent } from './components/ChangePasswordComponent';
import { NotificationsActionsComponent } from './components/NotificationsActionsComponent';
import { EmailNotificationsComponent } from './components/EmailNotificationsComponent';
import { SystemNotificationsComponent } from './components/SystemNotificationsComponent';
import { FontSizeComponent } from './components/FontSizeComponent';
import { DocColor, StyleSheet } from '../../../assets/styles/constantStyles';
import { SettingsIcon2 } from '../../../assets/svg/SettingsIcon2';
import { Rpx } from '../../../common/usecases/helpers/DimensionsConverter';
import { User } from '../../../authContext/domain/entities/User';
import { UserSettings } from '../../domain/entities/UserSettings';

interface SettingsProps {
  connectedUser: User | undefined;
  userSettingsState: UserSettings | undefined;
  loadUserSettingsAsync(): Promise<DispatchAsyncResult<User>>;
  updateUsernameAsync(id: string, firstName: string, lastName: string): Promise<DispatchAsyncResult<User>>;
  updatePasswordAsync(id: string, currentPassword: string, newPassword: string): Promise<DispatchAsyncResult<User>>;
  updateUserSettingsAsync(userSettings: UserSettings): Promise<DispatchAsyncResult<UserSettings>>;
}
export const SettingsdDumb = ({
  connectedUser,
  userSettingsState,
  loadUserSettingsAsync,
  updateUsernameAsync,
  updatePasswordAsync,
  updateUserSettingsAsync,
}: SettingsProps) => {
  const [chosenOption, setChosenOption] = useState<string>('');
  const [containerHeight, setContainerHeight] = useState<string>(Rpx(800));

  useEffect(() => {
    if (chosenOption === 'change username' || chosenOption === 'font size') {
      setContainerHeight(Rpx(850));
    } else if (
      chosenOption === 'change password' ||
      chosenOption === 'notifications actions' ||
      chosenOption === 'system notifications'
    ) {
      setContainerHeight(Rpx(900));
    } else if (chosenOption === 'email notifications') {
      setContainerHeight(Rpx(950));
    } else {
      setContainerHeight(Rpx(800));
    }
  }, [chosenOption]);

  return (
    <div style={styles.container}>
      <div style={styles.titleDiv}>
        <SettingsIcon2 color={DocColor.DARKBLUE} width={Rpx(42, true)} height={Rpx(42, true)} />
        <p style={{ ...styles.title, fontSize: Rpx(45, true) }}>Les Paramètres</p>
      </div>
      <div style={{ ...styles.settingsContainer, height: containerHeight }}>
        <div
          style={{
            ...styles.settingsOptionsContainer,
            width: chosenOption !== '' ? '42.5%' : '100%',
          }}
        >
          <div style={{ ...styles.settingsOptionsTitleDiv, minHeight: Rpx(60, true) }}>
            <p style={{ ...styles.settingsOptionsTitleParagraph, fontSize: Rpx(32, true) }}>Compte</p>
          </div>
          <SettingsOptionComponent
            optionOnClick={() => setChosenOption('change username')}
            isSelected={chosenOption === 'change username'}
            optionTitle="Changer le nom d’utilisateur"
          />
          <SettingsOptionComponent
            optionOnClick={() => setChosenOption('change password')}
            isSelected={chosenOption === 'change password'}
            optionTitle="Changer le mot de passe"
          />
          <div style={{ ...styles.settingsOptionsTitleDiv, minHeight: Rpx(60, true) }}>
            <p style={{ ...styles.settingsOptionsTitleParagraph, fontSize: Rpx(32, true) }}>Notifications</p>
          </div>
          <SettingsOptionComponent
            optionOnClick={() => setChosenOption('notifications actions')}
            isSelected={chosenOption === 'notifications actions'}
            optionTitle="Notifications d’action"
          />
          <SettingsOptionComponent
            optionOnClick={() => setChosenOption('email notifications')}
            isSelected={chosenOption === 'email notifications'}
            optionTitle="Notifications par email"
          />
          {/* <SettingsOptionComponent
            optionOnClick={() => setChosenOption('system notifications')}
            isSelected={chosenOption === 'system notifications'}
            optionTitle="Notifications système"
  /> */}
          <div style={{ ...styles.settingsOptionsTitleDiv, minHeight: Rpx(60, true) }}>
            <p style={{ ...styles.settingsOptionsTitleParagraph, fontSize: Rpx(32, true) }}>Autres</p>
          </div>
          <SettingsOptionComponent
            optionOnClick={() => setChosenOption('font size')}
            isSelected={chosenOption === 'font size'}
            optionTitle="Taille de police"
          />
        </div>
        {chosenOption === 'change username' && (
          <ChangeUsernameComponent
            connectedUser={connectedUser}
            updateUsernameAsync={updateUsernameAsync}
            setChosenOption={setChosenOption}
          />
        )}
        {chosenOption === 'change password' && (
          <ChangePasswordComponent
            connectedUser={connectedUser}
            updatePasswordAsync={updatePasswordAsync}
            setChosenOption={setChosenOption}
          />
        )}
        {userSettingsState && chosenOption === 'notifications actions' && (
          <NotificationsActionsComponent
            setChosenOption={setChosenOption}
            loadUserSettingsAsync={loadUserSettingsAsync}
            userSettingsState={userSettingsState}
            updateUserSettingsAsync={updateUserSettingsAsync}
          />
        )}
        {userSettingsState && chosenOption === 'email notifications' && (
          <EmailNotificationsComponent
            connectedUser={connectedUser}
            loadUserSettingsAsync={loadUserSettingsAsync}
            userSettingsState={userSettingsState}
            updateUserSettingsAsync={updateUserSettingsAsync}
            setChosenOption={setChosenOption}
          />
        )}
        {userSettingsState && chosenOption === 'system notifications' && (
          <SystemNotificationsComponent
            userSettingsState={userSettingsState}
            updateUserSettingsAsync={updateUserSettingsAsync}
            setChosenOption={setChosenOption}
          />
        )}
        {userSettingsState && chosenOption === 'font size' && (
          <FontSizeComponent
            userSettingsState={userSettingsState}
            loadUserSettingsAsync={loadUserSettingsAsync}
            updateUserSettingsAsync={updateUserSettingsAsync}
            setChosenOption={setChosenOption}
          />
        )}
      </div>
    </div>
  );
};

const styles: StyleSheet = {
  container: {
    flex: 1,
    marginBottom: Rpx(80),
  },
  titleDiv: {
    flexDirection: 'row',
    margin: `${Rpx(155)} auto 0 auto`,
    gap: Rpx(15),
    justifyContent: 'center',
    alignItems: 'center',
  },
  title: {
    color: DocColor.DARKBLUE,
    fontWeight: 700,
  },
  settingsContainer: {
    flexDirection: 'row',
    width: '90%',
    margin: `${Rpx(80)} auto 0 auto`,
    transition: 'all 0.3s ease',
  },
  settingsOptionsContainer: {
    position: 'relative',
    width: '100%',
    outline: `${Rpx(10)} solid ${DocColor.DARKBLUE}`,
    borderRadius: Rpx(60),
    transition: 'all 0.3s ease',
    overflow: 'hidden',
  },
  settingsOptionsTitleDiv: {
    width: 'fit-content',
    minWidth: '15%',
    background: DocColor.DARKBLUE,
    borderRadius: `0 0 ${Rpx(20)} 0`,
    padding: `0 ${Rpx(40)}`,
    marginBottom: Rpx(45),
  },
  settingsOptionsTitleParagraph: {
    color: 'white',
    fontWeight: 600,
    fontStyle: 'italic',
    margin: 'auto',
  },
};
