import React, { useEffect, useState } from 'react';
import { DocColor, StyleSheet } from '../../../../assets/styles/constantStyles';
import { CalendarDateIcon } from '../../../../assets/svg/CalendarDateIcon';
import { RectangleIcon } from '../../../../assets/svg/RectangleIcon';
import { ToBottomArrow } from '../../../../assets/svg/ToBottomArrow';
import { Rpx } from '../../../../common/usecases/helpers/DimensionsConverter';

type CustomDateSelectorProps = {
  value?: 'month' | 'week' | 'work_week' | 'day' | 'agenda';
  onChange?: (value: 'month' | 'week' | 'work_week' | 'day' | 'agenda') => void;
};

export const CustomDateSelector = ({ value, onChange }: CustomDateSelectorProps) => {
  const [inputValue, setInputValue] = useState<'month' | 'week' | 'work_week' | 'day' | 'agenda'>('month');
  const [isDropDownClosed, setIsDropDownClosed] = useState<boolean>(true);
  const optionsArray: ('month' | 'week' | 'work_week' | 'day' | 'agenda')[] = ['month', 'week', 'day'];

  useEffect(() => {
    if (value) {
      setInputValue(value);
    }
  }, [value]);

  const onOptionClickHandler = (obj: 'month' | 'week' | 'work_week' | 'day' | 'agenda') => {
    if (onChange) {
      onChange(obj);
    }
    setInputValue(obj);
    setIsDropDownClosed(true);
  };
  return (
    <div>
      <div style={styles.selectorDiv}>
        <CalendarDateIcon height={Rpx(35, true)} color="white" />
        <div style={{ width: 'fit-content' }}>
          <div
            style={{ ...styles.selector, fontSize: Rpx(32, true) }}
            onKeyDown={() => setIsDropDownClosed((v) => !v)}
            tabIndex={0}
            role="button"
            onClick={() => setIsDropDownClosed((v) => !v)}
          >
            <p>
              {inputValue === 'month'
                ? 'Mois'
                : inputValue === 'week'
                ? 'Semaine'
                : inputValue === 'day'
                ? 'Jour'
                : inputValue === 'work_week'
                ? 'Semaine de travail'
                : 'Ordre du jour'}
            </p>
            <ToBottomArrow style={styles.arrowIcon} width={Rpx(20, true)} height={Rpx(20, true)} color="white" />
          </div>
          <div
            style={{
              ...styles.dropDownSelector,
              maxHeight: isDropDownClosed ? Rpx(0) : Rpx(600),
              border: `${isDropDownClosed ? '0' : Rpx(10)} solid ${DocColor.MEDIUMBLUE}`,
            }}
          >
            {optionsArray.map((obj) => (
              <div key={obj}>
                <div style={styles.optionLine} />
                <div
                  style={styles.optionSelector}
                  onKeyDown={() => {
                    onOptionClickHandler(obj);
                  }}
                  tabIndex={0}
                  role="button"
                  onClick={() => {
                    onOptionClickHandler(obj);
                  }}
                >
                  <RectangleIcon
                    style={{ marginRight: Rpx(10, true) }}
                    color={DocColor.MEDIUMBLUE}
                    width={Rpx(10)}
                    height={Rpx(10)}
                  />
                  <p style={{ fontSize: Rpx(25, true), fontWeight: 700 }}>
                    {obj === 'month'
                      ? 'Mois'
                      : obj === 'week'
                      ? 'Semaine'
                      : obj === 'day'
                      ? 'Jour'
                      : obj === 'work_week'
                      ? 'Semaine de travail'
                      : 'Ordre du jour'}
                  </p>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

const styles: StyleSheet = {
  selectorDiv: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  selectorName: {
    fontSize: Rpx(27),
    fontWeight: 500,
    marginRight: Rpx(20),
    whiteSpace: 'nowrap',
  },
  selector: {
    position: 'relative',
    flexDirection: 'row',
    maxWidth: '100%',
    height: Rpx(70),
    fontWeight: 600,
    color: 'white',
    cursor: 'pointer',
    marginLeft: Rpx(10),
    alignItems: 'center',
    justifyContent: 'center',
  },
  dropDownSelector: {
    position: 'absolute',
    height: 'fit-content',
    width: Rpx(290),
    background: DocColor.LIGHTGRAY,
    color: DocColor.MEDIUMBLUE,
    inset: 0,
    overflow: 'hidden',
    transition: 'all .3s ease',
    borderRadius: `0 0 ${Rpx(30)} ${Rpx(30)}`,
    zIndex: 5,
    margin: `${Rpx(70)} ${Rpx(15)} 0px auto`,
  },
  optionLine: {
    width: '80%',
    height: Rpx(2),
    margin: '0 auto',
    background: DocColor.GRAY,
  },
  optionSelector: {
    flexDirection: 'row',
    margin: '0 auto',
    alignItems: 'center',
    height: Rpx(70),
    cursor: 'pointer',
  },
  arrowIcon: {
    margin: `${Rpx(20)} ${Rpx(0)} ${Rpx(10)} ${Rpx(10)}`,
  },
};
