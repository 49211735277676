import { fetchWithToken } from '../../../common/adapter/fetch.helper';
import { BACKEND_CONFIG } from '../../../configuration';
import { CalendarEvent } from '../../domain/entities/CalendarEvent';
import { CalendarInteractor } from '../../domain/gateways/Calendar.interactor';

export class RealCalendarInteractor implements CalendarInteractor {
  async loadCalendarEvents() {
    const url = `${BACKEND_CONFIG.endpoints.events}/loadEvents`;
    const response = await fetchWithToken(url); // need to do token stuff first
    const result = await response.json();

    return result;
  }

  async insertCalendarEvent(calendarEvent: CalendarEvent) {
    const url = `${BACKEND_CONFIG.endpoints.events}/insertEvent`;
    const response = await fetchWithToken(url, {
      method: 'POST',
      body: JSON.stringify(calendarEvent),
    });
    // const response = await fetchWithToken(url); // need to do token stuff first
    const result = await response.json();
    return result;
  }

  async updateCalendarEvent(calendarEvent: CalendarEvent) {
    const url = `${BACKEND_CONFIG.endpoints.events}/updateEvent`;
    const response = await fetchWithToken(url, {
      method: 'PUT',
      body: JSON.stringify(calendarEvent),
    });
    // const response = await fetchWithToken(url); // need to do token stuff first
    const result = await response.json();
    return result;
  }

  async deleteCalendarEvent(lookId: string) {
    const url = `${BACKEND_CONFIG.endpoints.events}/deleteEvent`;
    const response = await fetchWithToken(`${url}?lookId=${lookId}`, { method: 'DELETE' });
    // const response = await fetchWithToken(url); // need to do token stuff first
    const result = await response.json();
    return result;
  }

  async loadNextAppointment() {
    const url = `${BACKEND_CONFIG.endpoints.events}/loadNextAppointment`;
    const response = await fetchWithToken(url); // need to do token stuff first
    const result = await response.json();
    return result;
  }

  async loadProgramedAppointments(start: string, end: string): Promise<CalendarEvent[]> {
    const url = `${BACKEND_CONFIG.endpoints.events}/loadProgramedAppointments`;
    const response = await fetchWithToken(`${url}?start=${start}&end=${end}`); // need to do token stuff first
    const result = await response.json();
    return result;
  }
}
