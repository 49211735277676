import React, { useEffect, useState } from 'react';
import io from 'socket.io-client';
import { DocColor, StyleSheet } from '../../../../assets/styles/constantStyles';
import { AlarmIcon } from '../../../../assets/svg/Alarmlcon';
import { User } from '../../../../authContext/domain/entities/User';
import { Rpx } from '../../../../common/usecases/helpers/DimensionsConverter';
import { TodaysConsultationsStats } from '../../../domain/entities/TodaysConsultationsStats';
import basicInfo from '../../../../env.json';

type ConsultationsStatsComponentProps = {
  linkedUser: User | undefined;
  stateTodaysConsultationsStats: TodaysConsultationsStats | undefined;
  isVisible?: boolean;
};

export const ConsultationsStatsComponent = ({
  linkedUser,
  stateTodaysConsultationsStats,
  isVisible,
}: ConsultationsStatsComponentProps) => {
  const [linkedUserConnectionState, setlinkedUserConnectionState] = useState<boolean>(false);

  useEffect(() => {
    const socket = io(basicInfo.backendConfig.endpoints.ws);
    if (linkedUser) {
      userConnectionState(socket);
    }
    return () => {
      socket.disconnect();
    };
  }, [linkedUser]);

  const userConnectionState = (socket: any) => {
    socket.emit('userConnectionState', { userId: linkedUser?.id }, (dataFromServer: any) => {
      setlinkedUserConnectionState(dataFromServer);
    });
    setTimeout(() => {
      userConnectionState(socket);
    }, 1000);
  };

  return (
    <div style={{ ...styles.container, display: isVisible ? 'none' : '' }}>
      <p style={{ ...styles.title, fontSize: Rpx(40, true) }}>Aujourd’hui</p>
      <div style={styles.statsDiv}>
        <div style={styles.statsTicket}>
          <p style={{ ...styles.statsTicketResult, fontSize: Rpx(65, true) }}>
            {stateTodaysConsultationsStats ? stateTodaysConsultationsStats?.scheduledConsultations.toString() : '-'}
          </p>
          <p style={{ ...styles.statsTicketParagraph, fontSize: Rpx(30, true) }}>
            {stateTodaysConsultationsStats?.scheduledConsultations !== undefined &&
              (stateTodaysConsultationsStats?.scheduledConsultations === 1 ||
              stateTodaysConsultationsStats?.scheduledConsultations === 0
                ? 'Consultation a été programmée'
                : 'Consultations ont été programmées')}
          </p>
        </div>
        <div style={styles.statsTicket}>
          <p style={{ ...styles.statsTicketResult, fontSize: Rpx(65, true) }}>
            {stateTodaysConsultationsStats ? stateTodaysConsultationsStats?.finalizedConsultations.toString() : '-'}
          </p>
          <p style={{ ...styles.statsTicketParagraph, fontSize: Rpx(30, true) }}>
            {stateTodaysConsultationsStats?.finalizedConsultations !== undefined &&
              (stateTodaysConsultationsStats?.finalizedConsultations === 1 ||
              stateTodaysConsultationsStats?.finalizedConsultations === 0
                ? 'Consultation a été finalisée'
                : 'Consultations ont été finalisées ')}
          </p>
        </div>
        <div style={styles.statsTicket}>
          <p style={{ ...styles.statsTicketResult, fontSize: Rpx(65, true) }}>
            {stateTodaysConsultationsStats ? stateTodaysConsultationsStats?.remainingConsultations.toString() : '-'}
          </p>
          <p style={{ ...styles.statsTicketParagraph, fontSize: Rpx(30, true) }}>
            {stateTodaysConsultationsStats?.remainingConsultations !== undefined &&
              (stateTodaysConsultationsStats?.remainingConsultations === 1 ||
              stateTodaysConsultationsStats?.remainingConsultations === 0
                ? 'Consultation restante'
                : 'Consultations restantes')}
            <br />
            <span style={{ ...styles.statsTicketParagraph, fontSize: Rpx(20, true) }}>
              (à partir de votre calendrier)
            </span>
          </p>
        </div>
      </div>
      {linkedUser && linkedUserConnectionState && (
        <div style={styles.ongoingConsultationDiv}>
          <div style={styles.ongoingConsultationIcon}>
            <AlarmIcon color="white" width="90%" height="90%" />
          </div>
          <p style={{ ...styles.ongoingConsultationParagraph, fontSize: Rpx(35, true) }}>
            Consultation en cours avec :
            <span style={styles.ongoingConsultationSpan}>{` ${linkedUser?.firstName} ${linkedUser?.lastName}`}</span>{' '}
          </p>
        </div>
      )}
    </div>
  );
};

const styles: StyleSheet = {
  container: {
    width: '90%',
    margin: `${Rpx(90)} 5% 0`,
    justifyContent: 'center',
    alignItems: 'center',
  },
  title: {
    color: DocColor.DARKBLUE,
    fontWeight: 700,
    letterSpacing: Rpx(3),
    margin: '0 auto',
  },
  statsDiv: {
    width: 'fit-content',
    flexDirection: 'row',
    gap: Rpx(70),
    marginTop: Rpx(50),
  },
  statsTicket: {
    width: Rpx(340),
    minHeight: Rpx(280),
    background: DocColor.CLOUDYBLUE,
    borderRadius: Rpx(15),
    justifyContent: 'center',
    alignItems: 'center',
  },
  statsTicketResult: {
    color: DocColor.MEDIUMBLUE,
    fontWeight: 800,
  },
  statsTicketParagraph: {
    width: 'fit-content',
    maxWidth: '90%',
    color: DocColor.DARKBLUE,
    fontWeight: 700,
    textAlign: 'center',
    marginBottom: Rpx(20),
  },
  ongoingConsultationDiv: {
    flexDirection: 'row',
    marginTop: Rpx(50),
    gap: Rpx(15),
    alignItems: 'center',
  },
  ongoingConsultationIcon: {
    width: Rpx(48),
    height: Rpx(48),
    background: DocColor.DARKBLUE,
    borderRadius: Rpx(10),
    justifyContent: 'center',
    alignItems: 'center',
  },
  ongoingConsultationParagraph: {
    fontWeight: 600,
    color: DocColor.DARKBLUE,
  },
  ongoingConsultationSpan: {
    fontWeight: 700,
    color: DocColor.MEDIUMBLUE,
    fontStyle: 'italic',
  },
};
