import { Action } from 'redux';
import { connect } from 'react-redux';
import { dispatchAsync } from 'react-redux-dispatch-async';
import { NavigatorDumb } from './Navigator.dumb';
import { AppState } from '../../../common/configuration/app.states';
import { getConnectedUser, getTryingToSignIn } from '../../../authContext/usecases/Auth.selectors';
import { loadUserRequest, sendAutoSignInRequest, sendSignOutRequest } from '../../../authContext/usecases/Auth.actions';
import { NavigationPayload } from '../../domain/entities/NavigationPayload';
import { navigateBackRequest, navigateReplaceRequest, navigateToRequest } from '../../usecases/navigation.actions';
import { loadUserSettingsRequest } from '../../../settingsContext/usecases/UserSettings.actions';

const mapState = (state: AppState) => ({
  stateConnectedUser: getConnectedUser(state),
  stateTryingToSignIn: getTryingToSignIn(state),
});

const mapDispatch = (dispatch: (a: Action) => void) => ({
  navigateTo: (p: NavigationPayload) => dispatch(navigateToRequest(p)),
  navigateReplace: (nav: NavigationPayload) => dispatch(navigateReplaceRequest(nav)),
  sendSignOutAsync: () => dispatchAsync(dispatch, sendSignOutRequest()),
  navigateBack: () => dispatch(navigateBackRequest()),
  sendAutoSignInAsync: () => dispatchAsync(dispatch, sendAutoSignInRequest()),
  loadUserSettingsAsync: () => dispatchAsync(dispatch, loadUserSettingsRequest()),
  loadUserAsync: (userId: string) => dispatchAsync(dispatch, loadUserRequest(userId)),
});

export const NavigatorSmart = connect(mapState, mapDispatch)(NavigatorDumb);
