import React, { useEffect, useState } from 'react';
import { ExaminationToRequestRow } from './ExaminationToRequestRow';
import { ExaminationsToRequestTemplateOverlay } from './ExaminationsToRequestTemplateOverlay';
import { DocColor, StyleSheet } from '../../../../assets/styles/constantStyles';
import { AddIcon } from '../../../../assets/svg/Addlcon';
import { EyeIcon } from '../../../../assets/svg/EyeIcon';
import { TrashIcon } from '../../../../assets/svg/TrashIcon';
import { ButtonWithIcon } from '../../../../common/ui/ButtonWithIcon';
import { ConfirmOverlay } from '../../../../common/ui/ConfirmOverlay';
import { Rpx } from '../../../../common/usecases/helpers/DimensionsConverter';
import { ConsultationAdditionFilledDataType } from '../../../domain/entities/ConsultationAdditionFilledDataType';
import {
  ExaminationToRequest,
  hardCodedEmptyExaminationsToRequest,
} from '../../../domain/entities/ExaminationToRequest';
import { useLoaderContext } from '../../../../common/configuration/loaderContextProvider';
import { Patient } from '../../../../patientsContext/domain/entities/Patient';

type ExaminationsToRequestComponentProps = {
  patient?: Patient | null;
  temporaryPatientName: string;
  examinationsToRequestData: ExaminationToRequest[] | undefined;
  isOnlyReadable?: boolean;
  disabled?: boolean;
  setFilledData: React.Dispatch<React.SetStateAction<ConsultationAdditionFilledDataType>>;
};

export const ExaminationsToRequestComponent = ({
  patient,
  temporaryPatientName,
  examinationsToRequestData,
  isOnlyReadable,
  disabled,
  setFilledData,
}: ExaminationsToRequestComponentProps) => {
  const loader = useLoaderContext();
  const [examinationsToRequestDataState, setExaminationsToRequestDataState] = useState<ExaminationToRequest[]>([
    ...hardCodedEmptyExaminationsToRequest,
  ]);
  const [isConfirmFragmentClosed, setIsConfirmFragmentClosed] = useState<boolean>(true);
  const [isExaminationsToRequestTemplateOverlayClosed, setIsExaminationsToRequestTemplateOverlayClosed] =
    useState<boolean>(true);
  const [onDeleteIndex, setonDeleteIndex] = useState<number>(0);

  useEffect(() => {
    if (isOnlyReadable === true && examinationsToRequestData && examinationsToRequestData.length > 0) {
      setExaminationsToRequestDataState([...examinationsToRequestData]);
    }
  }, [examinationsToRequestData]);

  useEffect(() => {
    if (isOnlyReadable !== true) {
      if (examinationsToRequestDataState[examinationsToRequestDataState.length - 1].type !== '') {
        setFilledData((prevState) => ({ ...prevState, examinationsToRequest: examinationsToRequestDataState }));
      } else if (examinationsToRequestDataState.length === 1 && examinationsToRequestDataState[0].type === '') {
        setFilledData((prevState) => ({ ...prevState, examinations: [] }));
      }
    }
  }, [examinationsToRequestDataState]);

  const addElementHandler = () => {
    setExaminationsToRequestDataState((objs) => [
      ...objs,
      {
        type: '',
        examinationsList: [],
      },
    ]);
  };

  const setExaminationToRequest = (id: number, examinationToRequest: ExaminationToRequest) => {
    const tempData: ExaminationToRequest[] = examinationsToRequestDataState;
    tempData[id] = examinationToRequest;
    setExaminationsToRequestDataState([...tempData]);
  };

  const onDeleteClickHandler = (index: number) => {
    const tempData: ExaminationToRequest[] = [...examinationsToRequestDataState];
    if (examinationsToRequestDataState.length === 1) {
      tempData[0].type = '';
      tempData[0].examinationsList = [];
    } else {
      tempData.splice(index, 1);
    }
    setExaminationsToRequestDataState([...tempData]);
  };

  const onConfirmClick = () => {
    onDeleteClickHandler(onDeleteIndex);
    setIsConfirmFragmentClosed(true);
  };
  const showExaminationsToRequestPdfHandler = () => {
    loader.setMessage('Ouverture du fichier en cours');
    setIsExaminationsToRequestTemplateOverlayClosed(false);
  };

  return (
    <div style={{ ...styles.container, outlineColor: disabled ? DocColor.GRAY : DocColor.DARKBLUE }}>
      <ConfirmOverlay
        onConfirmClick={onConfirmClick}
        message="L’examen demandé sera supprimé
        Êtes-vous sûr ?"
        isClosedState={isConfirmFragmentClosed}
        setIsClosedState={setIsConfirmFragmentClosed}
      />

      {!isExaminationsToRequestTemplateOverlayClosed && (
        <ExaminationsToRequestTemplateOverlay
          onSuccess={() => {
            setIsExaminationsToRequestTemplateOverlayClosed(true);
            loader.setMessage('');
          }}
          onFailure={() => {
            setIsExaminationsToRequestTemplateOverlayClosed(true);
            loader.setMessage('');
          }}
          patient={patient}
          temporaryPatientName={temporaryPatientName}
          examinationsToRequestData={examinationsToRequestDataState}
        />
      )}
      <div style={{ ...styles.titleDiv, background: disabled ? DocColor.GRAY : DocColor.DARKBLUE }}>
        <p key="firstHistoryDateDiv" style={{ ...styles.title, fontSize: Rpx(32, true) }}>
          Examens à demander
        </p>
      </div>
      <div style={{ width: '92%', margin: '0 auto' }}>
        {examinationsToRequestDataState &&
          examinationsToRequestDataState.map((obj, index) => {
            const key = `examinationToRequest ${index}`;
            return (
              <div key={key} style={{ marginTop: Rpx(44), flexDirection: 'row' }}>
                <ExaminationToRequestRow
                  inputName={`Bilan ${index + 1}`}
                  examinationToRequestData={{ ...obj }}
                  onDataChange={(examinationToRequestState) =>
                    setExaminationToRequest(index, examinationToRequestState)
                  }
                  isOnlyReadable={isOnlyReadable}
                  disabled={disabled}
                />
                <ButtonWithIcon
                  disabled={disabled}
                  style={{
                    width: Rpx(45, true),
                    height: Rpx(45, true),
                    borderRadius: Rpx(16),
                    margin: `${Rpx(2)} ${Rpx(20)}`,
                    display:
                      (examinationsToRequestDataState.length === 1 && examinationsToRequestDataState[0].type === '') ||
                      isOnlyReadable
                        ? 'none'
                        : '',
                  }}
                  icon={TrashIcon({
                    width: Rpx(30, true),
                    height: Rpx(30, true),
                    color: 'white',
                    style: { margin: 'auto 0' },
                  })}
                  onClick={() => {
                    setonDeleteIndex(index);
                    setIsConfirmFragmentClosed(false);
                  }}
                />
              </div>
            );
          })}

        <div
          style={{
            ...styles.addButton,
            display:
              examinationsToRequestDataState[examinationsToRequestDataState.length - 1].type === '' ||
              disabled ||
              isOnlyReadable
                ? 'none'
                : '',
          }}
          onClick={() => addElementHandler()}
          onKeyDown={() => addElementHandler()}
          tabIndex={0}
          role="button"
        >
          <AddIcon width={Rpx(14, true)} height={Rpx(14, true)} color={DocColor.MEDIUMBLUE} style={styles.buttonIcon} />
          <p style={{ ...styles.buttonParagraph, fontSize: Rpx(25, true) }}>Ajouter un autre Bilan</p>
        </div>
        <div
          style={{
            flexDirection: 'row',
            justifyContent: 'end',
            marginTop: Rpx(30),
            display:
              examinationsToRequestDataState.length === 1 && examinationsToRequestDataState[0]?.type === ''
                ? 'none'
                : '',
          }}
        >
          <ButtonWithIcon
            disabled={disabled}
            label="Afficher un aperçu des examens demandés"
            icon={EyeIcon({
              width: Rpx(25, true),
              height: Rpx(25, true),
              color: 'white',
              isClosed: false,
              style: { margin: `auto 0 auto ${Rpx(10)}` },
            })}
            onClick={() => showExaminationsToRequestPdfHandler()}
          />
          {/* <ButtonWithIcon
              disabled={disabled}
              label="Imprimer la liste des examens demandés"
              icon={PrinterIcon({
                width: Rpx(25, true),
                height: Rpx(25, true),
                color: 'white',
                style: { margin: `auto 0 auto ${Rpx(10)}` },
              })}
            /> */}
        </div>
      </div>
    </div>
  );
};
const styles: StyleSheet = {
  container: {
    position: 'relative',
    margin: `2vw 2vw 0`,
    outline: `${Rpx(10)} solid ${DocColor.DARKBLUE}`,
    borderRadius: Rpx(60),
    minHeight: Rpx(100),
    paddingBottom: Rpx(47),
  },
  titleDiv: {
    width: 'fit-content',
    background: DocColor.DARKBLUE,
    borderRadius: `${Rpx(50)} 0 ${Rpx(20)} 0`,
    minHeight: Rpx(60),
    padding: `0 ${Rpx(40)}`,
    marginBottom: Rpx(15),
  },
  title: {
    color: 'white',
    fontWeight: 600,
    fontStyle: 'italic',
    margin: 'auto',
  },
  addButton: {
    flexDirection: 'row',
    margin: `${Rpx(17)} 0 0 ${Rpx(260)}`,
    cursor: 'pointer',
    marginTop: 0,
  },
  buttonParagraph: {
    fontWeight: 500,
    fontStyle: 'italic',
    color: DocColor.MEDIUMBLUE,
  },
  buttonIcon: {
    outline: `${Rpx(3)} solid ${DocColor.MEDIUMBLUE}`,
    borderRadius: Rpx(20),
    padding: Rpx(3),
    margin: `auto ${Rpx(15)} auto 0`,
  },
};
