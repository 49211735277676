import * as React from 'react';
import { Color } from '../styles/constantStyles';

export interface AddPatientProps {
  color?: string;
  style?: React.CSSProperties;
  width?: string | number;
  height?: string | number;
}

export const AddPatientIcon = ({ color, width, height, style }: AddPatientProps) => (
  <svg style={style} width={width} height={height} viewBox="0 0 31 30" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M27.0526 9.78054C27.0526 9.16067 26.5501 8.65816 25.9303 8.65816C25.3104 8.65816 24.8079 9.16067 24.8079 9.78054H27.0526ZM24.8079 17.263C24.8079 17.8829 25.3104 18.3854 25.9303 18.3854C26.5501 18.3854 27.0526 17.8829 27.0526 17.263H24.8079ZM22.189 12.3994C21.5692 12.3994 21.0667 12.9019 21.0667 13.5218C21.0667 14.1416 21.5692 14.6441 22.189 14.6441V12.3994ZM29.6715 14.6441C30.2914 14.6441 30.7939 14.1416 30.7939 13.5218C30.7939 12.9019 30.2914 12.3994 29.6715 12.3994V14.6441ZM25.9303 28.4867V29.6091C26.5501 29.6091 27.0526 29.1066 27.0526 28.4867H25.9303ZM1.98633 28.4867H0.863956C0.863956 29.1066 1.36646 29.6091 1.98633 29.6091L1.98633 28.4867ZM24.8079 9.78054V17.263H27.0526V9.78054H24.8079ZM22.189 14.6441H29.6715V12.3994H22.189V14.6441ZM7.97231 20.6301H19.9443V18.3854H7.97231V20.6301ZM24.8079 25.4937V28.4867H27.0526V25.4937H24.8079ZM3.1087 28.4867V25.4937H0.863956V28.4867H3.1087ZM19.9443 20.6301C22.6304 20.6301 24.8079 22.8076 24.8079 25.4937H27.0526C27.0526 21.5679 23.8701 18.3854 19.9443 18.3854V20.6301ZM7.97231 18.3854C4.04648 18.3854 0.863956 21.5679 0.863956 25.4937H3.1087C3.1087 22.8076 5.28621 20.6301 7.97231 20.6301V18.3854ZM18.8219 7.53579C18.8219 10.2219 16.6444 12.3994 13.9583 12.3994V14.6441C17.8841 14.6441 21.0667 11.4616 21.0667 7.53579H18.8219ZM13.9583 12.3994C11.2722 12.3994 9.09469 10.2219 9.09469 7.53579H6.84994C6.84994 11.4616 10.0325 14.6441 13.9583 14.6441V12.3994ZM9.09469 7.53579C9.09469 4.84969 11.2722 2.67218 13.9583 2.67218V0.427432C10.0325 0.427432 6.84994 3.60995 6.84994 7.53579H9.09469ZM13.9583 2.67218C16.6444 2.67218 18.8219 4.84969 18.8219 7.53579H21.0667C21.0667 3.60995 17.8841 0.427432 13.9583 0.427432V2.67218ZM1.98633 29.6091H25.9303V27.3644H1.98633V29.6091Z"
      fill={color || Color.MONOGREY3}
    />
  </svg>
);
