import * as React from 'react';
import { Color } from '../styles/constantStyles';

export interface CalendarAddPatientIconProps {
  color?: string;
  style?: React.CSSProperties;
  width?: string | number;
  height?: string | number;
}

export const CalendarAddPatientIcon = ({ color, width, height, style }: CalendarAddPatientIconProps) => (
  <svg style={style} width={width} height={height} viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M13 17C15.7614 17 18 14.7614 18 12C18 9.23858 15.7614 7 13 7C10.2386 7 8 9.23858 8 12C8 14.7614 10.2386 17 13 17Z"
      stroke="white"
      strokeWidth="2"
      strokeMiterlimit="10"
    />
    <path
      d="M4.97656 21.9258C5.72895 20.4438 6.87701 19.1991 8.29349 18.3297C9.70997 17.4602 11.3395 17 13.0016 17C14.6636 17 16.2932 17.4602 17.7096 18.3297C19.1261 19.1991 20.2742 20.4438 21.0266 21.9258"
      stroke={color || Color.MONOGREY3}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path d="M19 4H25" stroke={color || Color.MONOGREY3} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M22 1V7" stroke={color || Color.MONOGREY3} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    <path
      d="M24.85 11.1125C24.9511 11.7366 25.0012 12.3678 25 13C25 15.3734 24.2962 17.6935 22.9776 19.6669C21.6591 21.6403 19.7849 23.1783 17.5922 24.0866C15.3995 24.9948 12.9867 25.2325 10.6589 24.7694C8.33115 24.3064 6.19295 23.1635 4.51472 21.4853C2.83649 19.8071 1.6936 17.6689 1.23058 15.3411C0.767559 13.0133 1.0052 10.6005 1.91345 8.40782C2.8217 6.21511 4.35977 4.34096 6.33316 3.02239C8.30655 1.70381 10.6266 1.00002 13 1.00002C13.6322 0.998782 14.2634 1.04895 14.8875 1.15002"
      stroke={color || Color.MONOGREY3}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
