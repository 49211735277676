import React from 'react';
import { DocColor, StyleSheet } from '../../../../assets/styles/constantStyles';
import { NoteBlankIcon } from '../../../../assets/svg/NoteBlankIcon';
import { ButtonWithIcon } from '../../../../common/ui/ButtonWithIcon';
import { CustomInput } from '../../../../common/ui/CustomInput';
import CommonFunctions from '../../../../common/usecases/helpers/CommonFunctions';
import { Rpx } from '../../../../common/usecases/helpers/DimensionsConverter';
import { NavigationPayload } from '../../../../navigationContext/domain/entities/NavigationPayload';
import { Patient } from '../../../../patientsContext/domain/entities/Patient';

type PersonalInformationComponentProps = {
  patient?: Patient | null;
  navigateToAsync: (p: NavigationPayload) => void;
};

export const PersonalInformationComponent = ({ patient, navigateToAsync }: PersonalInformationComponentProps) => {
  let age: number | undefined;
  const patientBirthDay: Date | undefined = patient ? patient.birthDate : undefined;
  const today = Date.now();
  if (patientBirthDay) {
    const birthDate = new Date(patientBirthDay);
    const msDiff = today - birthDate.getTime();
    age = Math.floor(msDiff / (365.25 * 24 * 60 * 60 * 1000));
  }
  const lastConsultationDate: Date | undefined =
    patient && patient.lastConsultationDate ? new Date(patient.lastConsultationDate) : undefined;

  const showPatientConsultationsHandler = () => {
    navigateToAsync({
      routeName: 'Mes Consultations',
      params: {
        patient,
      },
    });
  };

  return (
    <div style={styles.container}>
      <div
        style={{
          ...styles.spacedRowDivs,
          marginBottom: Rpx(40),
        }}
      >
        <CustomInput
          style={{ ...styles.customInput, height: Rpx(45, true), fontSize: Rpx(25, true) }}
          inputName="Nom du patient"
          disabled
          width="40%"
          defaultValue={
            patient && patient.temporaryPatient === null
              ? `${patient.firstName} ${patient.lastName}`
              : patient && patient.temporaryPatient !== null
              ? `Temp ${patient.temporaryPatient}`
              : '-'
          }
        />
        <CustomInput
          style={{ ...styles.customInput, height: Rpx(45, true), fontSize: Rpx(25, true) }}
          inputName="Age"
          disabled
          width="25%"
          defaultValue={age?.toString() || '-'}
        />
        <CustomInput
          style={{ ...styles.customInput, height: Rpx(45, true), fontSize: Rpx(25, true) }}
          inputName="Sexe"
          disabled
          width="30%"
          defaultValue={
            patient && patient.temporaryPatient === null ? (patient.gender === 'male' ? 'mâle' : 'femelle') : '-'
          }
        />
      </div>
      <div
        style={{
          ...styles.spacedRowDivs,
          marginBottom: Rpx(55),
        }}
      >
        <CustomInput
          style={{ ...styles.customInput, height: Rpx(45, true), fontSize: Rpx(25, true) }}
          inputName="Nombre total des consultations"
          disabled
          width="50%"
          defaultValue={patient && patient.countConsultations ? `${patient.countConsultations}` : '-'}
        />
        <CustomInput
          style={{ ...styles.customInput, height: Rpx(45, true), fontSize: Rpx(25, true) }}
          inputName="Dernière consultation le"
          disabled
          width="48%"
          defaultValue={lastConsultationDate ? CommonFunctions.formatDate(lastConsultationDate) : '-'}
        />
      </div>
      <div
        style={{
          flexDirection: 'row',
          justifyContent: 'end',
        }}
      >
        <ButtonWithIcon
          disabled={patient?.temporaryPatient !== null}
          label="Accéder à l’historique de Consultation du patient"
          icon={NoteBlankIcon({
            width: Rpx(25, true),
            height: Rpx(25, true),
            color: 'white',
            style: { margin: `auto 0 auto ${Rpx(10)}` },
          })}
          onClick={() => showPatientConsultationsHandler()}
        />
      </div>
    </div>
  );
};
const styles: StyleSheet = {
  container: {
    padding: Rpx(45),
    position: 'relative',
    margin: `2vw 2vw 0`,
    outline: `${Rpx(10)} solid ${DocColor.DARKBLUE}`,
    borderRadius: Rpx(60),
    minHeight: Rpx(100),
    overflow: 'hidden',
  },
  spacedRowDivs: {
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  customInput: {
    textAlign: 'center',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    fontWeight: 700,
    color: DocColor.DARKBLUE,
    borderRadius: Rpx(5),
  },
};
