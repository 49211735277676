import React, { useCallback, useEffect, useState } from 'react';
import { DispatchAsyncResult } from 'react-redux-dispatch-async/lib/dispatchAsync';
import { v4 as uuidv4 } from 'uuid';
import { DocColor, StyleSheet } from '../../../../assets/styles/constantStyles';
import { CloseIcon } from '../../../../assets/svg/CloseIcon';
import { CustomInput } from '../../../../common/ui/CustomInput';
import CommonFunctions from '../../../../common/usecases/helpers/CommonFunctions';
import { Rpx } from '../../../../common/usecases/helpers/DimensionsConverter';
import { Patient } from '../../../../patientsContext/domain/entities/Patient';

export interface NameEntranceOverlayProps {
  setIsClosedState: React.Dispatch<React.SetStateAction<boolean>>;
  loadPatientAsync: (id: string) => Promise<DispatchAsyncResult>;
  getTemporaryPatientNumberAsync: () => Promise<DispatchAsyncResult>;
  loadPatientsNamesAsync: () => Promise<DispatchAsyncResult>;
  setPatientState: React.Dispatch<React.SetStateAction<Patient | undefined | null>>;
  navigateBack: () => void;
}
//  || !patientsNames.includes(inputValue)
export const NameEntranceOverlay = ({
  setIsClosedState,
  loadPatientAsync,
  getTemporaryPatientNumberAsync,
  loadPatientsNamesAsync,
  setPatientState,
  navigateBack,
}: NameEntranceOverlayProps) => {
  const [chosenOption, setChosenOption] = useState<{ id: string; value: string }>({ id: '', value: '' });
  const [inputOptionsState, setInputOptionsState] = useState<{ id: string; value: string }[]>([]);
  useEffect(() => {
    document.addEventListener('keydown', escFunction, false);
    loadPatientsNamesAsync().then((res) => {
      if (res.success) {
        res.result.forEach((patient: { patientId: string; fullName: string }) => {
          setInputOptionsState((prevData) => [...prevData, { id: patient.patientId, value: patient.fullName }]);
        });
      }
    });
    return () => {
      document.removeEventListener('keydown', escFunction, false);
    };
  }, []);

  const escFunction = useCallback((event) => {
    if (event.key === 'Escape') {
      navigateBack();
    }
  }, []);

  const onSubmitClickHandler = () => {
    if (CommonFunctions.isEmptyOrSpaces(chosenOption.value)) {
      getTemporaryPatientNumberAsync().then((res) => {
        if (res.success) {
          const temporaryPatientUuid = uuidv4();
          setPatientState({ id: temporaryPatientUuid, temporaryPatient: res.result });
          setIsClosedState(true);
        }
      });
    } else if (chosenOption) {
      loadPatientAsync(chosenOption.id).then((res) => {
        if (res.success) {
          setPatientState(res.result[0]);
          setIsClosedState(true);
        }
      });
    }
  };

  return (
    <div
      style={styles.container}
      onClick={(e) => {
        if (e.target !== e.currentTarget) {
          return;
        }
        navigateBack();
      }}
      onKeyDown={(e) => {
        if (e.target !== e.currentTarget) {
          return;
        }
        navigateBack();
      }}
      tabIndex={0}
      role="button"
    >
      <div style={styles.NameEntranceContainer}>
        <div
          style={styles.closeButton}
          onKeyDown={() => {
            navigateBack();
          }}
          tabIndex={0}
          role="button"
          onClick={() => {
            navigateBack();
          }}
        >
          <CloseIcon color={DocColor.DARKBLUE} />
        </div>
        <p style={{ ...styles.title, fontSize: Rpx(36, true) }}>Nouvelle consultation</p>
        <p style={{ ...styles.paragraph, fontSize: Rpx(32, true) }}>Entrer le nom ou le code du patient</p>
        <CustomInput
          onOptionChange={(id, value) => {
            setChosenOption({ id, value });
          }}
          options={[...inputOptionsState]}
          style={styles.input}
          OnMistake={chosenOption.id === '' && !CommonFunctions.isEmptyOrSpaces(chosenOption.value)}
          height={Rpx(70, true)}
        />
        <button
          style={{
            ...styles.button,
            height: Rpx(75, true),
            fontSize: Rpx(30, true),
            background:
              !CommonFunctions.isEmptyOrSpaces(chosenOption.value) && chosenOption.id === ''
                ? DocColor.GRAY
                : DocColor.MEDIUMBLUE,
          }}
          disabled={!CommonFunctions.isEmptyOrSpaces(chosenOption.value) && chosenOption.id === ''}
          type="submit"
          onClick={onSubmitClickHandler}
        >
          {CommonFunctions.isEmptyOrSpaces(chosenOption.value) ? 'Patient temporaire' : 'Sélectionner'}
        </button>
      </div>
    </div>
  );
};

const styles: StyleSheet = {
  container: {
    position: 'fixed',
    inset: 0,
    width: '100%',
    height: '100%',
    backgroundColor: DocColor.HALFTRANSPARENTBLACK,
    zIndex: 2,
  },
  NameEntranceContainer: {
    position: 'relative',
    justifyContent: 'center',
    alignItems: 'center',
    margin: 'auto',
    backgroundColor: 'white',
    maxWidth: '45%',
    width: 'fit-content',
    height: 'fit-content',
    padding: `${Rpx(45)} 0`,
    borderRadius: Rpx(33),
    zIndex: 5,
  },
  closeButton: {
    position: 'absolute',
    inset: 0,
    margin: `${Rpx(40)} ${Rpx(40)} 0 auto`,
    background: DocColor.AQUAHAZE,
    width: Rpx(30),
    height: Rpx(30),
    borderRadius: Rpx(7),
    padding: Rpx(10),
    cursor: 'pointer',
    zIndex: 2,
  },
  title: {
    fontWeight: 700,
  },
  paragraph: {
    fontWeight: 500,
    margin: `${Rpx(50)} auto ${Rpx(15)} auto`,
  },
  input: {
    background: DocColor.LIGHTGRAY,
    fontSize: Rpx(25),
    fontWeight: 600,
    width: Rpx(640),
    margin: `0 5vw ${Rpx(25)} 5vw`,
    color: DocColor.DARKBLUE,
  },
  button: {
    width: 'fit-content',
    backgroundColor: DocColor.MEDIUMBLUE,
    color: 'white',
    borderRadius: Rpx(15),
    flexDirection: 'row',
    cursor: 'pointer',
    padding: `0 ${Rpx(20)}`,
    fontWeight: 700,
  },
};
