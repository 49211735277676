import { Action } from 'redux';
import { connect } from 'react-redux';
import { dispatchAsync } from 'react-redux-dispatch-async';
import { SignInDumb } from './SignIn.dumb';
import { sendSignInRequest } from '../../usecases/Auth.actions';
import { NavigationPayload } from '../../../navigationContext/domain/entities/NavigationPayload';
import { navigateToRequest } from '../../../navigationContext/usecases/navigation.actions';
import { loadUserSettingsRequest } from '../../../settingsContext/usecases/UserSettings.actions';
import { UserAccount } from '../../domain/entities/UserAccount';

const mapState = () => ({});

const mapDispatch = (dispatch: (a: Action) => void) => ({
  sendSignInAsync: (userAccount: UserAccount) => dispatchAsync(dispatch, sendSignInRequest(userAccount)),
  loadUserSettingsAsync: () => dispatchAsync(dispatch, loadUserSettingsRequest()),
  navigateToAsync: (nav: NavigationPayload) => dispatch(navigateToRequest(nav)),
});

export const SignInSmart = connect(mapState, mapDispatch)(SignInDumb);
