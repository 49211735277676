import * as React from 'react';
import { Color } from '../styles/constantStyles';

export interface PaperClipProps {
  color?: string;
  style?: React.CSSProperties;
  width?: string | number;
  height?: string | number;
}

export const PaperClipIcon = ({ color, width, height, style }: PaperClipProps) => (
  <svg style={style} width={width} height={height} viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M20.0005 10.0005L9.58804 20.588C9.24332 20.9684 9.05811 21.4668 9.07075 21.9801C9.08338 22.4933 9.2929 22.982 9.65593 23.3451C10.0189 23.7081 10.5077 23.9176 11.0209 23.9303C11.5342 23.9429 12.0326 23.7577 12.413 23.413L24.8255 10.8255C25.515 10.0646 25.8854 9.06767 25.8601 8.04119C25.8349 7.01472 25.4158 6.03727 24.6898 5.31122C23.9637 4.58517 22.9863 4.16613 21.9598 4.14086C20.9333 4.11559 19.9364 4.48602 19.1755 5.17546L6.76304 17.763C5.63919 18.8868 5.00781 20.4111 5.00781 22.0005C5.00781 23.5898 5.63919 25.1141 6.76304 26.238C7.8869 27.3618 9.41117 27.9932 11.0005 27.9932C12.5899 27.9932 14.1142 27.3618 15.238 26.238L25.5005 16.0005"
      stroke={color || Color.MONOGREY3}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
