import React from 'react';
import { DocColor, StyleSheet } from '../../assets/styles/constantStyles';
import { CrossIcon } from '../../assets/svg/Crosslcon';
import { Rpx } from '../usecases/helpers/DimensionsConverter';

type CustomCheckBoxProps = {
  checkBoxName?: string;
  checkBoxNameStyle?: React.CSSProperties;
  selected: boolean;
  width: string | number;
  height: string | number;
  activeColor: string;
  backgroundColor?: string;
  style?: React.CSSProperties;
  checkBoxStyle?: 'CIRCLE' | 'SQUARE' | 'CROSS';
  onCheckBoxClick?: () => void;
};

export const CustomCheckBox = ({
  checkBoxName,
  checkBoxNameStyle,
  selected,
  width,
  height,
  activeColor,
  style,
  backgroundColor,
  checkBoxStyle,
  onCheckBoxClick,
}: CustomCheckBoxProps) => (
  <div
    style={{ ...styles.container, ...style }}
    onKeyDown={onCheckBoxClick}
    tabIndex={0}
    role="button"
    onClick={onCheckBoxClick}
  >
    <div
      style={{
        ...styles.space,
        borderRadius: checkBoxStyle === 'SQUARE' ? '20%' : checkBoxStyle === 'CROSS' ? '5%' : '100%',
        width,
        height,
        background: backgroundColor || DocColor.LIGHTGRAY,
      }}
    >
      {checkBoxStyle !== 'CROSS' ? (
        <div
          style={{
            ...styles.selectSpace,
            borderRadius: checkBoxStyle === 'SQUARE' ? '20%' : '100%',
            background: activeColor,
            display: selected ? '' : 'none',
          }}
        />
      ) : (
        <div
          style={{
            display: selected ? '' : 'none',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <CrossIcon color={activeColor} width="80%" height="80%" />
        </div>
      )}
    </div>
    <p style={{ ...styles.nameParagraph, fontSize: Rpx(25, true), ...checkBoxNameStyle }}>{checkBoxName}</p>
  </div>
);

const styles: StyleSheet = {
  container: {
    cursor: 'pointer',
    flexDirection: 'row',
    alignItems: 'center',
  },
  space: {
    background: DocColor.LIGHTGRAY,
    borderRadius: '100%',
    alignItems: 'center',
    justifyContent: 'center',
  },
  selectSpace: {
    width: '50%',
    height: '50%',
    borderRadius: '100%',
  },
  nameParagraph: {
    fontWeight: 600,
    marginRight: Rpx(20),
    whiteSpace: 'nowrap',
    marginLeft: Rpx(30),
    textOverflow: 'ellipsis',
    overflow: 'hidden',
  },
};
