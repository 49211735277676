import { NavigationScreen } from '../domain/entities/NavigationScreen';
import * as PatientContextScreens from '../../patientsContext/ui';
import * as ConsultationContextScreens from '../../consultationsContext/ui';
import * as AuthContextScreens from '../../authContext/ui';
import * as CalendarContextScreens from '../../calendarContext/ui';
import * as SettingsContextScreens from '../../settingsContext/ui';
import { HomePageIcon } from '../../assets/svg/HomePageIcon';
import { ConsultationIcon } from '../../assets/svg/ConsultationIcon';
import { CalendarIcon } from '../../assets/svg/CalendarIcon';
import { PatientIcon } from '../../assets/svg/PatientIcon';
// Add4Nav
// this file is mostly for determining which component shows on which  string in the address bar
export const combinedScreens = {
  ...PatientContextScreens,
  ...ConsultationContextScreens,
  ...AuthContextScreens,
  ...CalendarContextScreens,
  ...SettingsContextScreens,
};

export const authNavScreens: NavigationScreen[] = [
  {
    routeName: 'Accueil',
    component: combinedScreens.DashBoard,
  },
  {
    routeName: 'Mon Calendrier',
    component: combinedScreens.Calendar,
  },
  {
    routeName: 'Mes Patients',
    component: combinedScreens.PatientsList,
  },
  {
    routeName: 'Patient Addition',
    component: combinedScreens.PatientsAddition,
  },
  {
    routeName: 'Patient details',
    component: combinedScreens.PatientDetails,
  },
  {
    routeName: 'Historique des consultations',
    component: combinedScreens.ConsultationsHistory,
  },
  {
    routeName: 'Pièces jointes',
    component: combinedScreens.Attchments,
  },
  {
    routeName: 'Mes Consultations',
    component: combinedScreens.PatientConsultations,
  },
  {
    routeName: 'Consultation Addition',
    component: combinedScreens.ConsultationAddition,
  },
  {
    routeName: 'Paramètres',
    component: combinedScreens.Settings,
  },
];

export const unAuthScreens: NavigationScreen[] = [
  {
    routeName: '',
    component: combinedScreens.SignIn,
  },
  {
    routeName: 'SignIn',
    component: combinedScreens.SignIn,
  },
  {
    routeName: 'AdminDevTools',
    component: combinedScreens.AdminDevTools,
  },
  {
    routeName: 'Mot de passe oublié',
    component: combinedScreens.ForgotPassword,
  },
  {
    routeName: 'Réinitialisation du mot de passe',
    component: combinedScreens.ResetPassword,
  },
  // {
  // 	routeShownName: "Admin tools",
  // 	routeName: "AdminDevTools",
  // 	component: combinedScreens.AdminDevTools,
  // 	routeIcon: ConsultationIcon({
  // 		color: "white",
  // 		width: "20px",
  // 		height: "20px",
  // 		style: { margin: "auto" },
  // 	}),
  // },
];
export const footerScreens: NavigationScreen[] = [];

export const sideBarScreens: NavigationScreen[] = [
  {
    routeName: 'Accueil',
    component: combinedScreens.DashBoard,
    routeIcon: (color, style, width, height) => HomePageIcon({ color, width, height, style }),
  },
  {
    routeName: 'Mon Calendrier',
    component: combinedScreens.Calendar,
    routeIcon: (color, style, width, height) => CalendarIcon({ color, width, height, style }),
  },
  {
    routeName: 'Mes Patients',
    component: combinedScreens.PatientsList,
    routeIcon: (color, style, width, height) => PatientIcon({ color, width, height, style }),
  },
  {
    routeName: 'Mes Consultations',
    component: combinedScreens.PatientConsultations,
    routeIcon: (color, style, width, height) => ConsultationIcon({ color, width, height, style }),
  },
];
