import { all, call, put, takeLatest } from 'redux-saga/effects';
import { Action } from 'redux-actions';
import {
  deleteCalendarEventFailure,
  deleteCalendarEventRequest,
  deleteCalendarEventSuccess,
  insertCalendarEventFailure,
  insertCalendarEventRequest,
  insertCalendarEventSuccess,
  loadCalendarEventsFailure,
  loadCalendarEventsRequest,
  loadCalendarEventsSuccess,
  loadNextAppointmentFailure,
  loadNextAppointmentRequest,
  loadNextAppointmentSuccess,
  loadProgramedAppointmentsFailure,
  loadProgramedAppointmentsRequest,
  loadProgramedAppointmentsSuccess,
  updateCalendarEventFailure,
  updateCalendarEventRequest,
  updateCalendarEventSuccess,
} from './Calendar.actions';
import { getDependencies } from '../../common/usecases/common.sagas';
import { DependenciesType } from '../../configuration/dependencies';
import { CalendarEvent } from '../domain/entities/CalendarEvent';

function* loadCalendarEventsSaga() {
  try {
    // const membership: Membership = yield select(getMembership)
    const dep: DependenciesType = yield getDependencies();
    const res: CalendarEvent[] = yield call(dep.calendarInteractor.loadCalendarEvents);
    yield put(loadCalendarEventsSuccess(res));
  } catch (error) {
    yield put(loadCalendarEventsFailure(error));
  }
}

function* insertCalendarEventSaga(action: Action<CalendarEvent>) {
  try {
    const calendarEvent: CalendarEvent = action.payload;
    // const membership: Membership = yield select(getMembership)
    const dep: DependenciesType = yield getDependencies();
    const res: CalendarEvent = yield call(dep.calendarInteractor.insertCalendarEvent, calendarEvent);
    yield put(insertCalendarEventSuccess(res));
  } catch (error) {
    yield put(insertCalendarEventFailure(error));
  }
}

function* updateCalendarEventSaga(action: Action<CalendarEvent>) {
  try {
    const calendarEvent: CalendarEvent = action.payload;
    // const membership: Membership = yield select(getMembership)
    const dep: DependenciesType = yield getDependencies();
    const res: CalendarEvent = yield call(dep.calendarInteractor.updateCalendarEvent, calendarEvent);
    yield put(updateCalendarEventSuccess(res));
  } catch (error) {
    yield put(updateCalendarEventFailure(error));
  }
}

function* deleteCalendarEventSaga(action: Action<string>) {
  try {
    const lookId: string = action.payload;
    // const membership: Membership = yield select(getMembership)
    const dep: DependenciesType = yield getDependencies();
    const res: boolean = yield call(dep.calendarInteractor.deleteCalendarEvent, lookId);
    yield put(deleteCalendarEventSuccess(res));
  } catch (error) {
    yield put(deleteCalendarEventFailure(error));
  }
}

function* loadNextAppointmentSaga() {
  try {
    // const membership: Membership = yield select(getMembership)
    const dep: DependenciesType = yield getDependencies();
    const res: CalendarEvent[] = yield call(dep.calendarInteractor.loadNextAppointment);
    yield put(loadNextAppointmentSuccess(res));
  } catch (error) {
    yield put(loadNextAppointmentFailure(error));
  }
}

function* loadProgramedAppointmentSaga(action: Action<{ start: string; end: string }>) {
  try {
    // const membership: Membership = yield select(getMembership)
    const props: { start: string; end: string } = action.payload;
    const dep: DependenciesType = yield getDependencies();
    const res: CalendarEvent[] = yield call(dep.calendarInteractor.loadProgramedAppointments, props.start, props.end);
    yield put(loadProgramedAppointmentsSuccess(res));
  } catch (error) {
    yield put(loadProgramedAppointmentsFailure(error));
  }
}

export function* calendarSagas() {
  yield all([
    takeLatest(loadCalendarEventsRequest, loadCalendarEventsSaga),
    takeLatest(insertCalendarEventRequest, insertCalendarEventSaga),
    takeLatest(updateCalendarEventRequest, updateCalendarEventSaga),
    takeLatest(deleteCalendarEventRequest, deleteCalendarEventSaga),
    takeLatest(loadNextAppointmentRequest, loadNextAppointmentSaga),
    takeLatest(loadProgramedAppointmentsRequest, loadProgramedAppointmentSaga),
  ]);
}
